/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import { Form, Button, Input, Spin, message, Select, InputNumber, Radio, Space, Checkbox } from 'antd';
import s from './s.module.less';
import { ChannelBasicInformationFormInterface } from 'types/channel';
import commonS from 'styles/common.module.less';
import FileUpload from 'components/form/FileUpload';
import { getStrWithLineBreaker } from 'utils/common';
import useGetSettingData from 'hooks/useGetSettingData';
import { EMPTY_PLACEHOLDER, NOT_SET, URL_LIMIT } from 'constants/common';
import TextArea from 'antd/es/input/TextArea';
import LicenseImg from 'components/LicenseImg';
import { updateChannelDetail } from 'api/operation';
import { ChannelItem } from 'types/common';
import { EnumFields } from 'types/enumerationData';
import { EChannelPayType } from 'types/subscription';
import { CheckboxValueType } from 'antd/es/checkbox/Group';

type Props = {
    editable: boolean,
    initValue?: Partial<ChannelBasicInformationFormInterface>,
    channelId?: number,
    viewOnly?: boolean,
    onChange?: (value: Partial<ChannelItem>) => void,
    onNullState?: () => void,
    isOperation?: boolean,
};

//hardcode logic
const SHOW_PRICE_TYPE = EChannelPayType.PREMIUM;

const BasicInformationComp = (props: Props) => {
    const { editable = true, viewOnly, initValue, channelId, onChange, onNullState, isOperation } = props;
    const [enable, setEnable] = React.useState(!!editable);
    const [form] = Form.useForm<ChannelBasicInformationFormInterface>();
    const [submiting, setSubmiting] = React.useState(false);
    const [payPlanOptions] = useGetSettingData(EnumFields.CHANNEL_PAY_PLAN_TYPE);
    const [billingCycleOptions] = useGetSettingData(EnumFields.BILLING_CYCLE);
    const [currentPayType, setCurrentPayType] = React.useState<string | undefined>();
    const [, forceUpdate] = React.useState({});
    const [klarityPlanVal, setKlarityPlanVal] = React.useState<number[]>([]);

    const onSubmit = async () => {
        try {
            setSubmiting(true);
            const formValues = await form.validateFields();
            let klarityPlanLevel = 0;
            const klarityPlanLevelArr = klarityPlanVal;
            if (klarityPlanLevelArr.includes(1)) {
                klarityPlanLevel = 1;
            } else if (klarityPlanLevelArr.includes(2)) {
                klarityPlanLevel = 2;
            } else if (klarityPlanLevelArr.includes(3)) {
                klarityPlanLevel = 3;
            }
            const item: Partial<ChannelItem> = {
                name: formValues.name,
                logo: formValues.logo,
                website: formValues.website,
                intro: formValues.intro,
                rectangleLogo: formValues.rectangleLogo,
                payPlanType: formValues.payPlanType,
                kiwiPrice: formValues.kiwiPrice,
                originalPrice: formValues.originalPrice,
                billingCycle: formValues.billingCycle,
                subscriptionPriceId: formValues.subscriptionPriceId,
                freeTrialDays: formValues.freeTrialDays,
                reviewAvailable: Boolean(formValues.reviewAvailable),
                reviewRanking: formValues.reviewRanking,
                klarityPlanLevel: klarityPlanLevel,
            };
            if (channelId) {
                item.id = channelId;
            }
            const result = await updateChannelDetail(item);

            if (!result.error) {
                const id = result.data.data;
                message.success('Channel info has updated');
                setEnable(false);
                if (id && onChange) {
                    //create new
                    onChange({ id });
                }
            } else {
                message.error(result.error);
            }
        } catch (e: any) {
            console.log(e);
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };

    React.useEffect(() => {
        if (initValue) {
            const newValue: any = {};
            Object.keys(initValue).forEach((key) => {
                const value = (initValue as any)[key];
                if (key === 'klarityPlanLevel') {
                    newValue[key] = value || form.getFieldValue(key);
                    setKlarityPlanVal(value || form.getFieldValue(key));
                } else {
                    newValue[key] = form.getFieldValue(key) || value;
                }

            });
            form.setFieldsValue(newValue);
            setCurrentPayType(initValue.payPlanType);
            forceUpdate({});
        }
    }, [initValue]);

    let payPlanDisplay = form.getFieldValue('payPlanType');
    if (payPlanDisplay) {
        const target = payPlanOptions?.find((item) => { return item.dictKey === payPlanDisplay; });
        if (target) {
            payPlanDisplay = target.content;
        }
    }

    let klarityPlanText = '';

    if (form.getFieldValue('klarityPlanLevel')?.includes(3)) {
        klarityPlanText = 'Klarity Pro';
    } else if (form.getFieldValue('klarityPlanLevel')?.includes(2)) {
        klarityPlanText = 'Klarity Plus';
    } else if (form.getFieldValue('klarityPlanLevel')?.includes(1)) {
        klarityPlanText = 'Klarity Starter';
    } else {
        klarityPlanText = NOT_SET;
    }

    return (
        <div className={s.wrap}>
            <Form
                form={form}
                name="basic"
                className={commonS.formStyle1}
                autoComplete="off"
                layout="vertical"
            >
                <Spin spinning={submiting}>
                    <div className={s.module}>
                        <h3><span>Basic information</span>
                            {!enable && !viewOnly &&
                                <Button
                                    size="small"
                                    onClick={() => {
                                        setEnable(true);
                                    }}
                                >
                                    Edit
                                </Button>}
                        </h3>
                        {
                            enable &&
                            <>
                                <div className={s.row}>
                                    <div className={s.rowItem}>
                                        <Form.Item
                                            style={{ width: '360px' }}
                                            label="Name"
                                            name={['name']}
                                            validateFirst
                                            rules={[
                                                { required: true, message: 'Channel name is required' },
                                            ]}
                                        >
                                            <Input maxLength={25} placeholder="" />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className={s.rowItem}>
                                    <Form.Item
                                        style={{ width: '360px' }}
                                        label="Url"
                                        name={['website']}
                                        rules={[{ required: true, message: 'Website URL is required' }]}
                                    >
                                        <Input maxLength={URL_LIMIT} placeholder="" />
                                    </Form.Item>
                                </div>
                                <div className={s.rowItem}>
                                    <Form.Item
                                        style={{ width: '360px' }}
                                        label="Pay Plan Type"
                                        name={['payPlanType']}
                                        rules={[{ required: true, message: 'Pay Plan Type is required' }]}
                                    >
                                        <Select
                                            onChange={(val: string) => {
                                                setCurrentPayType(val);
                                            }}
                                            fieldNames={{ label: 'content', value: 'dictKey' }}
                                            options={payPlanOptions}
                                        />
                                    </Form.Item>
                                </div>
                                {
                                    currentPayType === SHOW_PRICE_TYPE &&
                                    <>
                                        <div className={s.row}>
                                            <div className={s.rowItem}>
                                                <Form.Item
                                                    style={{ width: '360px' }}
                                                    label="Kiwi Price"
                                                    name={['kiwiPrice']}
                                                    validateFirst
                                                    rules={[
                                                        { required: true, message: 'Kiwi Price is required' },
                                                    ]}
                                                >
                                                    <InputNumber style={{ width: '100%' }} addonBefore="$" precision={2} placeholder="" />
                                                </Form.Item>
                                            </div>
                                            <div className={s.rowItem}>
                                                <Form.Item
                                                    style={{ width: '150px' }}
                                                    label="Billing cycle"
                                                    name={['billingCycle']}
                                                    rules={[{ required: true, message: 'Billing cycle is required' }]}
                                                >
                                                    <Select
                                                        fieldNames={{ label: 'content', value: 'dictKey' }}
                                                        options={billingCycleOptions}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className={s.rowItem}>
                                                <Form.Item
                                                    style={{ width: '360px' }}
                                                    label="Subscription price ID"
                                                    name={['subscriptionPriceId']}
                                                    validateFirst
                                                    rules={[
                                                        { required: true, message: 'Price id is required' },
                                                    ]}
                                                >
                                                    <Input style={{ width: '100%' }} placeholder="" />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className={s.row}>
                                            <div className={s.rowItem}>
                                                <Form.Item
                                                    style={{ width: '360px' }}
                                                    label="Original Price"
                                                    name={['originalPrice']}
                                                    validateFirst
                                                    rules={[
                                                        { required: false, message: 'Original Price is required' },
                                                    ]}
                                                >
                                                    <InputNumber style={{ width: '100%' }} addonBefore="$" precision={2} placeholder="" />
                                                </Form.Item>
                                            </div>
                                            <div className={s.rowItem}>
                                                <Form.Item
                                                    style={{ width: '360px' }}
                                                    label="Free trial days"
                                                    name={['freeTrialDays']}
                                                    validateFirst
                                                    rules={[
                                                        { required: false, message: 'Original Price is required' },
                                                    ]}
                                                >
                                                    <InputNumber style={{ width: '100%' }} addonBefore="$" precision={0} placeholder="" />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className={s.rowItem}>
                                    <Form.Item
                                        style={{ width: '500px' }}
                                        label="Patient review available"
                                        name={['reviewAvailable']}
                                        rules={[{ required: true, message: 'Patient review available is required' }]}
                                    >
                                        <Radio.Group>
                                            <Space direction="vertical">
                                                <Radio value={true}>Yes</Radio>
                                                <Radio value={false}>No</Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                                <div className={s.rowItem}>
                                    <Form.Item
                                        style={{ width: '500px' }}
                                        label="Klarity plan"
                                        name={['klarityPlanLevel']}
                                    >
                                        <Checkbox.Group
                                            onChange={(val: CheckboxValueType[]) => {
                                                const items = val as number[];
                                                if (items.includes(1)) {
                                                    setKlarityPlanVal([1, 2, 3]);
                                                } else if (items.includes(2)) {
                                                    setKlarityPlanVal([2, 3]);
                                                } else {
                                                    setKlarityPlanVal(items)
                                                }
                                            }}
                                        >
                                            <Checkbox value={3} disabled={klarityPlanVal.includes(1) || klarityPlanVal.includes(2)} checked={klarityPlanVal.includes(3)}>Klarity Pro</Checkbox>
                                            <Checkbox value={2} disabled={klarityPlanVal.includes(1)} checked={klarityPlanVal.includes(2)}>Klarity Plus</Checkbox>
                                            <Checkbox value={1} checked={klarityPlanVal.includes(1)}>Klarity Starter</Checkbox>
                                        </Checkbox.Group>
                                    </Form.Item>
                                </div>
                                <div className={s.rowItem}>
                                    <Form.Item
                                        style={{ width: '500px' }}
                                        label="Review ranking"
                                        name={['reviewRanking']}
                                    >
                                        <InputNumber />
                                    </Form.Item>
                                </div>
                                <div className={s.rowItem}>
                                    <Form.Item
                                        style={{ width: '500px' }}
                                        label="Logo"
                                        name={['logo']}
                                        rules={[{ required: true, message: 'Logo is required' }]}
                                    >
                                        <FileUpload />
                                    </Form.Item>
                                </div>
                                <div className={s.rowItem}>
                                    <Form.Item
                                        style={{ width: '500px' }}
                                        label="Rectangle Logo"
                                        name={['rectangleLogo']}
                                        rules={[{ required: false, message: 'Rectangle Logo is required' }]}
                                    >
                                        <FileUpload />
                                    </Form.Item>
                                </div>
                                <div className={s.rowItem}>
                                    <Form.Item
                                        style={{ width: '80%' }}
                                        label="Short Pitch"
                                        name={['intro']}
                                        rules={[{ required: true, message: 'Short Pitch is required' }]}
                                    >
                                        <TextArea rows={5} placeholder="Ex. A mental health platform that connects directly to Kiwi Health. It is the easiest platform to bring in a large volume of new patients with a strong care coordination and patient support team." />
                                    </Form.Item>
                                </div>
                                <div className={s.buttonWrap}>
                                    <Button
                                        className={commonS.lightBtn}
                                        size="small"
                                        onClick={onSubmit}
                                    >Save
                                    </Button>
                                </div>
                            </>
                        }
                        {
                            !enable &&
                            <>
                                <div className={s.row}>
                                    <div
                                        style={{
                                            width: '360px',
                                        }}
                                        className={s.displayItem}
                                    >
                                        <div className={s.label}>Name</div>
                                        <div className={s.value}>{form.getFieldValue('name')}</div>
                                    </div>
                                </div>
                                <div className={s.row}>
                                    <div
                                        style={{
                                            width: '360px',
                                        }}
                                        className={s.displayItem}
                                    >

                                        <div className={s.label}>Url</div>
                                        <div className={s.value}>{form.getFieldValue('website')}</div>
                                    </div>
                                </div>
                                <div className={s.row}>
                                    <div
                                        style={{
                                            width: '360px',
                                        }}
                                        className={s.displayItem}
                                    >

                                        <div className={s.label}>Pay Plan Type</div>
                                        <div className={s.value}>{payPlanDisplay || EMPTY_PLACEHOLDER}</div>
                                    </div>
                                </div>
                                {
                                    currentPayType === SHOW_PRICE_TYPE &&
                                    <>
                                        <div className={s.row}>
                                            <div
                                                style={{
                                                    width: '360px',
                                                }}
                                                className={s.displayItem}
                                            >
                                                <div className={s.label}>Kiwi Price</div>
                                                <div className={s.value}>{form.getFieldValue('kiwiPrice') || NOT_SET}</div>
                                            </div>
                                            <div
                                                style={{
                                                    width: '360px',
                                                }}
                                                className={s.displayItem}
                                            >
                                                <div className={s.label}>Billing cycle</div>
                                                <div className={s.value}>{form.getFieldValue('billingCycle') || NOT_SET}</div>
                                            </div>
                                            <div
                                                style={{
                                                    width: '360px',
                                                }}
                                                className={s.displayItem}
                                            >
                                                <div className={s.label}>Subscription price ID</div>
                                                <div className={s.value}>{form.getFieldValue('subscriptionPriceId') || NOT_SET}</div>
                                            </div>
                                        </div>
                                        <div className={s.row}>
                                            <div
                                                style={{
                                                    width: '360px',
                                                }}
                                                className={s.displayItem}
                                            >
                                                <div className={s.label}>Original Price</div>
                                                <div className={s.value}>{form.getFieldValue('originalPrice') || NOT_SET}</div>
                                            </div>
                                            <div
                                                style={{
                                                    width: '360px',
                                                }}
                                                className={s.displayItem}
                                            >
                                                <div className={s.label}>Free trial days</div>
                                                <div className={s.value}>{form.getFieldValue('freeTrialDays') || NOT_SET}</div>
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className={s.row}>
                                    <div
                                        style={{
                                            width: '360px',
                                        }}
                                        className={s.displayItem}
                                    >

                                        <div className={s.label}>Patient review available</div>
                                        <div className={s.value}>{form.getFieldValue('reviewAvailable')?.toString()}</div>
                                    </div>
                                </div>
                                <div className={s.row}>
                                    <div
                                        style={{
                                            width: '360px',
                                        }}
                                        className={s.displayItem}
                                    >
                                        <div className={s.label}>Klarity plan</div>
                                        <div className={s.value}>
                                            {klarityPlanText}
                                        </div>
                                    </div>

                                </div>
                                <div className={s.row}>
                                    <div
                                        style={{
                                            width: '360px',
                                        }}
                                        className={s.displayItem}
                                    >

                                        <div className={s.label}>Review ranking</div>
                                        <div className={s.value}>{form.getFieldValue('reviewRanking')}</div>
                                    </div>
                                </div>
                                <div className={s.row}>
                                    <div
                                        style={{
                                            width: '80%',
                                        }}
                                        className={s.displayItem}
                                    >
                                        <div className={s.label}>Logo</div>
                                        <div className={s.value}><LicenseImg src={form.getFieldValue('logo')} /></div>
                                    </div>
                                </div>
                                <div className={s.row}>
                                    <div
                                        style={{
                                            width: '80%',
                                        }}
                                        className={s.displayItem}
                                    >
                                        <div className={s.label}>Rectangle Logo</div>
                                        <div className={s.value}>{form.getFieldValue('rectangleLogo') ? <LicenseImg src={form.getFieldValue('rectangleLogo')} /> : EMPTY_PLACEHOLDER}</div>
                                    </div>
                                </div>
                                <div className={s.row}>
                                    <div
                                        style={{
                                            width: '80%',
                                        }}
                                        className={s.displayItem}
                                    >
                                        <div className={s.label}>Short Pitch</div>
                                        <div className={s.value}>{getStrWithLineBreaker(form.getFieldValue('intro'))}</div>
                                    </div>
                                </div>
                            </>
                        }

                    </div>
                </Spin>
            </Form>
        </div >
    );
};

export default BasicInformationComp;
