import React, { useCallback, useMemo, useState } from 'react';
import s from './s.module.less';
import { Button, message, Modal, Spin, Upload } from 'antd';
import type { UploadProps } from 'antd';
import { uploadUniprofile } from 'api/uniprofileResume';
import { RcFile } from 'antd/es/upload';
import { setAvaDocumentId } from 'utils/localstore';
import mammoth from 'mammoth';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';

interface IProps {
    onUploadSuccess: (id?: string) => void;
}

const UploadUniprofilePage = ({
    onUploadSuccess,
}: IProps) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [uploadedFileUrl, setUploadedFileUrl] = useState<string | null>();
    const [iframeUsed, setIframeUsed] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const resetUpload = useCallback(() => {
        setShowModal(false);
        setUploadedFileUrl(null);
        setIframeUsed(false);
    }, []);

    const handlePreview = useCallback((file: RcFile) => {
        const docxPreviewEl = document.getElementById('docxPreview');
        if (docxPreviewEl) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const arrayBuffer = reader.result;
                mammoth.convertToHtml({ arrayBuffer })
                    .then((result) => {
                        docxPreviewEl.innerHTML = result.value; // 显示转换后的 HTML
                    })
                    .catch((err) => {
                        console.error('Error converting DOCX to HTML:', err);
                    });
            };
            reader.readAsArrayBuffer(file);
        }
    }, []);

    const props: UploadProps = {
        name: 'file',
        action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
        headers: {
            authorization: 'authorization-text',
        },
        showUploadList: false,
        multiple: false,
        accept: '.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.txt',
        beforeUpload(file) {
            return new Promise((resolve, reject) => {
                const isLt10M = file.size / 1024 / 1024 <= 10;
                if (!isLt10M) {
                    message.error('File must smaller than 10MB!');
                    reject(false);
                } else {
                    resolve(true);
                }
            });
        },
        customRequest: async (data) => {
            setLoading(true);
            try {
                const formData = new FormData();
                formData.append('file', data.file);

                // handlePreview(data.file);
                const res = await uploadUniprofile(formData);
                if (!res.error) {
                    const doc = res.data?.data;
                    if (doc) {
                        const [documentId, docUrl] = Object.entries(doc)[0];
                        setUploadedFileUrl(docUrl as string);
                        setAvaDocumentId(documentId as string);
                        if (data.file.type === 'text/plain' || data.file.type === 'application/pdf') {
                            setIframeUsed(true);
                        } else {
                            setIframeUsed(false);
                            setTimeout(() => {
                                handlePreview(data.file);
                            }, 500);
                        }
                        onUploadSuccess(res.data?.data);
                    }
                } else {
                    setShowModal(true);
                }
            } catch (e) {
                console.error(e);
                setShowModal(true);
            } finally {
                setLoading(false);
            }
        },
    };

    const handleRemove = useCallback(() => {
        onUploadSuccess();
        setUploadedFileUrl(null);
    }, [onUploadSuccess]);

    const previewAreaRender = useMemo(() => {
        if (iframeUsed) {
            return <iframe title="previewIframe" src={uploadedFileUrl as string} />;
        }

        return (
            <div className={s.docPreviewWrap}>
                <div id="docxPreview" />
            </div>
        );
    }, [iframeUsed, uploadedFileUrl]);

    return (
        <div className={s.wrap}>
            <Modal
                width={780}
                closable={false}
                open={showModal}
                footer={
                    <div className={s.modalFooter}>
                        <Button onClick={() => {
                            navigate(`${PATH.CREATE_UNI_PROFILE}?type=uniprofile`);
                        }}
                        >Enter information manually
                        </Button>
                        <Button type="primary" onClick={resetUpload}>Retry upload</Button>
                    </div>
                }
            >
                <div className={s.modalWrap}>
                    <div className={s.tipIcon} />
                    <div className={s.content}>
                        <div className={s.title}>Uh-oh! Resume upload failed</div>
                        <div className={s.descBox}>
                            <div className={s.desc}>Sorry, we couldn't process your resume. Possible reasons include:</div>
                            <ul>
                                <li><strong>File type:</strong> Ava has an easier time reading PDFs than Word documents. For best results, try uploading a PDF version of your resume.</li>
                                <li><strong>Visual elements:</strong> If your resume contains lots of images or colorful banners, it may affect Ava's ability to extract information. Consider uploading a simplified version for optimal processing.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Modal>
            {
                !uploadedFileUrl ?
                    <div className={s.upload}>
                        {
                            loading ?
                                <div className={s.loadingMask}>
                                    <Spin className={s.spinner} />
                                </div> :
                                <Upload.Dragger {...props}>
                                    <div className={s.uploadBox}>
                                        <div className={s.uploadIcon} />
                                        <div className={s.title}>Upload your resume here</div>
                                        <div className={s.desc}>click to upload or drag your file and drop</div>
                                        <div className={s.desc}>PDF, .word, .txt up to 10MB</div>
                                    </div>
                                </Upload.Dragger>
                        }
                    </div>
                    : previewAreaRender
            }
        </div>
    );
};

export default UploadUniprofilePage;
