import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import useSettings from 'hooks/useSettings';
import { EnumFields } from 'types/enumerationData';
import { Meta } from 'types/common';

type InsuranceSelectProps = {
    value?: string;
    onChange?: (val: string) => void,
    disableValues?:string[],
    showOthers?:boolean,
} & SelectProps;

const InsuranceSelect = (props:InsuranceSelectProps) => {
    const { onChange, showOthers, disableValues, ...reset } = props;
    const [setting, loading] = useSettings();
    const options = setting[EnumFields.INSURANCE] || [];
    return (
        <Select {...reset} onChange={onChange} loading={loading} showSearch>
            {
                options.map((option:Meta) => {
                    return (
                        <Select.Option
                            disabled={!!(disableValues?.includes(option.value as string) && reset.value !== option.value)}
                            key={option.value}
                            value={option.value}
                        >
                            {option.value}
                        </Select.Option>
                    );
                })
            }
            {
                showOthers && (
                    <Select.Option
                        key="others"
                        value="others"
                    >
                        Other In-Network Insurance Companies
                    </Select.Option>
                )
            }
        </Select>
    );
};

export default InsuranceSelect;
