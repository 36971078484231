import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AdditionalPracticeFormFieldEnum, PracticeFormInterface } from 'pages/CreateUniProfilePage/types';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { Button, Form, Select } from 'antd';
import useGetSpecialtiesAndConditions from 'hooks/useGetSpecialtiesAndConditions';
import { ISpecialtiesOption, ISpecialtiesOptions } from 'types/applicationForm';
import ErrorBlock from '../ErrorBlock';
import { EditModuleEnum, ISpecialtiesApproaches } from 'types/practiceFront';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';

interface IProps {
    isOps?: boolean;
    showEditButtons?: boolean;
    initFormValue: ISpecialtiesApproaches,
    isEditing: boolean;
    onEditStatusChange: (v: boolean, formName: string) => void;
    onSubmit: (formValue: ISpecialtiesApproaches, formName: string) => void;
    showEditingTip?: boolean;
    // initFormValue: ISpecialtiesApproaches;
    // onEditStatusChange: (status: boolean, field: EditModuleEnum) => void;
    // onSubmit: (value: ISpecialtiesApproachesInput, field: EditModuleEnum) => void
}

const Specialties = ({
    showEditingTip,
    isEditing,
    isOps = false,
    initFormValue,
    showEditButtons = true,
    onSubmit,
    onEditStatusChange,
}: IProps) => {
    const [specialtiesOptions] = useGetSpecialtiesAndConditions({ isOps });
    const [conditionOptions, setConditionOptions] = useState<ISpecialtiesOptions>([]);
    const [specialtyForm] = Form.useForm<ISpecialtiesApproaches>();

    const {
        specialtyList = [],
        conditionTreatedList = [],
    } = initFormValue || {};

    useEffect(() => {
        if (initFormValue) {
            specialtyForm.setFieldsValue(initFormValue);
        }
    }, [conditionTreatedList, initFormValue, specialtyForm, specialtyList]);

    const handleSetConditionOptions = useCallback((specialties: string[] = []) => {
        const conditionOpts = specialtiesOptions
            .filter((option) => {
                const { value } = option;
                return !!specialties?.includes(value);
            })
            .reduce((r:ISpecialtiesOptions, c: ISpecialtiesOption) => {
                const { children = [] } = c;

                return r.concat(children);
            }, []);

        setConditionOptions(uniqWith(conditionOpts, isEqual));
    }, [specialtiesOptions]);

    useEffect(() => {
        handleSetConditionOptions(initFormValue.specialtyList);
    }, [handleSetConditionOptions, initFormValue]);

    // 每次conditionOptions改变的时候，需要判断下目前表单中选中的condition 是否存在于conditionOptions
    // 如果不存在，那么删除掉
    // useEffect(() => {

    // }, []);

    const handleFormFieldChange = useCallback((changedFields) => {
        const fieldName = changedFields[0].name[0];

        if (fieldName === AdditionalPracticeFormFieldEnum.SPECIALTY_LIST) {
            const specialties = specialtyForm.getFieldValue(AdditionalPracticeFormFieldEnum.SPECIALTY_LIST);

            handleSetConditionOptions(specialties);
        }
    }, [handleSetConditionOptions, specialtyForm]);

    const handleFormFinish = useCallback(async () => {
        specialtyForm.validateFields().then((values: ISpecialtiesApproaches) => {
            if (typeof onSubmit === 'function') {
                onSubmit({
                    ...initFormValue,
                    ...values,
                }, EditModuleEnum.SEPCIALITIESAPPROACH);
            }
        }).catch((e) => {
            console.error(e);
        });
        // onSubmit(formName, formValue);
        // setSubmitData(formValue);
    }, [initFormValue, onSubmit, specialtyForm]);

    const renderEditButtons = useMemo(() => {
        if (showEditButtons) {
            return !isEditing ? (
                <div key="formName_disable" className={s.editButtons}>
                    <Button
                        // size="small"
                        onClick={() => {
                            onEditStatusChange(true, EditModuleEnum.SEPCIALITIESAPPROACH);
                        }}
                    >
                        Edit
                    </Button>
                </div>
            ) : (
                <div key="formName_enable" className={s.editButtons}>
                    <Button
                        // size="small"
                        onClick={() => {
                            onEditStatusChange(false, EditModuleEnum.SEPCIALITIESAPPROACH);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        // size="small"
                        type="primary"
                        onClick={handleFormFinish}
                    >
                        Save changes
                    </Button>
                </div>
            );
        }
        return null;
    }, [isEditing, onEditStatusChange, showEditButtons, handleFormFinish]);

    return (
        <div className={s.wrap} id={EditModuleEnum.SEPCIALITIESAPPROACH}>
            <h4>
                Specialties & Conditions
                {renderEditButtons}
            </h4>
            {
                showEditingTip && isEditing && <ErrorBlock />
            }
            <div className={s.content}>
                {
                    !isEditing ?
                        <>
                            <div className={s.row}>
                                <div
                                    className={s.displayItem}
                                >
                                    <div className={`${s.label} ${s.required}`}>Specialties</div>
                                    <div className={s.value}>
                                        {
                                            specialtyList && specialtyList.length > 0 ?
                                                <ul>
                                                    {
                                                        specialtyList?.map((item, i) => <li key={i}>{item}</li>)
                                                    }
                                                </ul> :
                                                <div className={s.noncontent}>Nothing here yet</div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div
                                    className={s.displayItem}
                                >
                                    <div className={`${s.label} ${s.required}`}>Conditions treated</div>
                                    <div className={s.value}>
                                        {
                                            conditionTreatedList && conditionTreatedList.length > 0 ?
                                                conditionTreatedList?.map((item, i) => <div className={s.conditionTag} key={i}>{item}</div>) :
                                                <div className={s.noncontent}>Nothing here yet</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </> :
                        <Form
                            form={specialtyForm}
                            name="formName"
                            className={commonS.formStyle1}
                            autoComplete="off"
                            layout="vertical"
                            onFieldsChange={handleFormFieldChange}
                            scrollToFirstError
                        >
                            <div className={s.row}>
                                <div
                                    className={s.displayItem}
                                >
                                    <Form.Item
                                        label="Specialties"
                                        name={AdditionalPracticeFormFieldEnum.SPECIALTY_LIST}
                                        validateFirst
                                        rules={[
                                            { required: true, message: 'Specialties is a required field' },
                                        ]}
                                    >
                                        <Select
                                            options={specialtiesOptions}
                                            mode="tags"
                                            allowClear
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div
                                    className={s.displayItem}
                                >
                                    <Form.Item
                                        label="Condition treated"
                                        name={AdditionalPracticeFormFieldEnum.CONDITION_TREATED_LIST}
                                        validateFirst
                                        rules={[
                                            { required: true, message: 'Condition treated is a required field' },
                                        ]}
                                    >
                                        <Select
                                            options={conditionOptions}
                                            mode="tags"
                                            allowClear
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                }
            </div>
        </div>
    );
};

export default Specialties;
