// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--tb9iK {
  box-shadow: 0px 2px 3px #999;
}
.s-module__wrap--tb9iK .s-module__wrapIn--PDRw8 {
  line-height: 84px;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: var(--content-width);
  margin: 0 auto;
  width: 90%;
}
.s-module__wrap--tb9iK .s-module__wrapIn--PDRw8 img {
  width: 25px;
  margin-right: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/s.module.less"],"names":[],"mappings":"AAAA;EACE,4BAAA;AACF;AAFA;EAGI,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,+BAAA;EACA,cAAA;EACA,UAAA;AAEJ;AAbA;EAcM,WAAA;EACA,kBAAA;AAEN","sourcesContent":[".wrap {\n  box-shadow: 0px 2px 3px #999;\n  .wrapIn {\n    line-height: 84px;\n    font-size: 24px;\n    font-weight: bold;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    max-width: var(--content-width);\n    margin: 0 auto;\n    width: 90%;\n\n    img {\n      width: 25px;\n      margin-right: 12px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--tb9iK`,
	"wrapIn": `s-module__wrapIn--PDRw8`
};
export default ___CSS_LOADER_EXPORT___;
