// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--H6hA6 .s-module__textArea--ooK3c {
  margin-bottom: 10px;
  height: 70px;
  border: none;
  width: 100%;
}
.s-module__wrap--H6hA6 .s-module__optionsBox--OCIv6 {
  margin-bottom: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
}
.s-module__wrap--H6hA6 .s-module__option--bWhsZ {
  padding: 10px 12px;
  color: #485149;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  clip-path: inset(0 round 3px);
  cursor: pointer;
  border-radius: 60px;
  border: transparent 1px solid;
  background: linear-gradient(#fff, #fff) padding-box, linear-gradient(120.97deg, #C8E6C9 0.03%, #B3E5FC 100.03%) border-box;
}
.s-module__wrap--H6hA6 .s-module__option--bWhsZ.s-module__selected--u2RFi {
  background: linear-gradient(#c8e6c9, #b3e5fc) padding-box, linear-gradient(270deg, #02FF74 -10.14%, #0BCA88 12.21%, #000AF0 109.45%) border-box;
}
`, "",{"version":3,"sources":["webpack://./src/components/HeadlinesEditor/s.module.less"],"names":[],"mappings":"AAAA;EAEQ,mBAAA;EACA,YAAA;EACA,YAAA;EACA,WAAA;AAAR;AALA;EASQ,kBAAA;EACA,aAAA;EACA,QAAA;EACA,mBAAA;AADR;AAXA;EAeQ,kBAAA;EACA,cAAA;EAMA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,gBAAA;EACA,6BAAA;EACA,eAAA;EACA,mBAAA;EACA,6BAAA;EACA,0HAAA;AAJR;AAOQ;EAEI,+IAAA;AANZ","sourcesContent":[".wrap {\n    .textArea {\n        margin-bottom: 10px;\n        height: 70px;\n        border: none;\n        width: 100%;\n    }\n\n    .optionsBox {\n        margin-bottom: 8px;\n        display: flex;\n        gap: 8px;\n        align-items: center;\n    }\n    .option {\n        padding: 10px 12px;\n        color: #485149;\n        font-size: 16px;\n        font-weight: 450;\n        // border: 1px solid;\n        // border-image: linear-gradient(120.97deg, #C8E6C9 0.03%, #B3E5FC 100.03%) 1;\n        // border-radius: 60px;\n        font-family: Inter;\n        font-size: 12px;\n        font-weight: 500;\n        line-height: 14.52px;\n        text-align: left;\n        clip-path: inset(0 round 3px);\n        cursor: pointer;\n        border-radius: 60px;\n        border: transparent 1px solid;\n        background: linear-gradient(#fff, #fff) padding-box, linear-gradient(120.97deg, #C8E6C9 0.03%, #B3E5FC 100.03%) border-box;\n\n\n        &.selected {\n            // border-image: linear-gradient(270deg, #02FF74 -10.14%, #0BCA88 12.21%, #000AF0 109.45%) 1;\n            background: linear-gradient(rgb(200, 230, 201), rgb(179, 229, 252)) padding-box, linear-gradient(270deg, #02FF74 -10.14%, #0BCA88 12.21%, #000AF0 109.45%) border-box;\n            // background: linear-gradient(120.97deg, rgba(200, 230, 201, 0.5) 0.03%, rgba(179, 229, 252, 0.5) 100.03%);\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--H6hA6`,
	"textArea": `s-module__textArea--ooK3c`,
	"optionsBox": `s-module__optionsBox--OCIv6`,
	"option": `s-module__option--bWhsZ`,
	"selected": `s-module__selected--u2RFi`
};
export default ___CSS_LOADER_EXPORT___;
