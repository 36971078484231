import StepIcon1 from 'assets/survey/welcomPic1.svg';
import StepIcon2 from 'assets/survey/welcomPic2.svg';
import StepIcon3 from 'assets/survey/welcomPic3.svg';

export const WelcomeSteps = [
    {
        title: 'Send survey to existing patients to gather baseline feedback',
        desc: 'Tailor your survey content to gather the feedback that matters most to you and your practice. Adjust questions and email communications to align with your unique approach to patient care.',
        image: StepIcon1,
    },
    {
        title: 'Add your patient list',
        desc: 'Our system helps you target the right audience for desired feedback.',
        image: StepIcon2,
    },
    {
        title: 'Set up automated review invitations',
        desc: 'Maximize positive feedback by setting up automated review invitations! Select eligible review recipients to encourage happy patients to share their good experiences on your preferred channels.',
        image: StepIcon3,
    },
];

export const wildcard = ['\\[provider name\\]', '\\[Patient Name\\]', '\\[Credentials\\]', '\\[Provider Credential\\]'];
