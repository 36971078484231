import React, { useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { Service } from 'types/common';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields } from 'types/enumerationData';
import { getChannelMultiple } from 'api/channel';

interface IMultiServices {
    providerServiceList: Array<Service>;
    type: string;
}

interface IProps {
    logo: string;
    name: string;
    checkedIds?: Array<number>;
    onChange: (ids: Array<number>) => void;
    onServiceListFeedback?: (status: boolean) => void;
}

const SingleServiceTypeChannelModal = ({
    logo,
    name = '',
    checkedIds,
    onChange,
    onServiceListFeedback,
}: IProps) => {
    const [multiService, setMultiService] = useState<Array<IMultiServices>>([]);
    const [colorOptions] = useGetSettingData(EnumFields.COLOR);
    const [selectedData, setSelectedData] = useState<Record<string, number>>({});

    const getMulitService = useCallback(async () => {
        const res = await getChannelMultiple();

        if (!res.error) {
            const { data } = res.data;
            if (data?.length > 0) {
                setMultiService(data);
                setSelectedData(data.reduce((r, c) => {
                    const { type } = c;
                    r[type] = null;

                    return r;
                }, {}));
            } else {
                onServiceListFeedback?.(true);
            }
        }
    }, [onServiceListFeedback]);

    useEffect(() => {
        getMulitService();
    }, [getMulitService]);

    const handleClick = useCallback((id: number, serviceType: string) => {
        selectedData[serviceType] = id;
        onChange(Object.values(selectedData));
    }, [onChange, selectedData]);

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <div className={s.logo} style={{ backgroundImage: `url(${logo})` }} />
                <div className={s.name}>{name}</div>
            </div>
            <div className={s.tip}>
                <div className={s.infoIcon} />
                <div className={s.tipContent}>{name} can only have one service in each type, please choose one from your Services to list on {name}. </div>
            </div>
            <div className={s.listWrap}>
                {
                    multiService.length > 0 ?
                        multiService.map((services, i) => {
                            const { type: serviceType, providerServiceList } = services;

                            return (
                                <div key={i} className={s.serviceBox}>
                                    <div className={s.servicesTitle}>{serviceType}</div>
                                    <div className={s.serviceList}>
                                        {
                                            providerServiceList.map((service) => {
                                                const { id, type, colorId, name, description, duration, intakeForm, telehealthType, personType, personAddress, personCity, personState } = service;
                                                const markColor = colorOptions?.find((option) => Number(option.dictKey) === Number(colorId));
                                                const noMeetingtype = !telehealthType && !personType;
                                                const telehealthCheckStatus = telehealthType;
                                                const inpersonCheckStatus = personType;
                                                const officeAddress = `${personAddress},${personCity},${personState}`;
                                                const isChecked = checkedIds?.includes(id as number);

                                                return (
                                                    <div key={id} className={`${s.wrapper} ${isChecked ? s.selected : ''}`} onClick={() => id && handleClick(id, serviceType)}>
                                                        <h4>
                                                            <div className={s.checkboxTitleBox}>
                                                                <div className={s.checkboxText}>
                                                                    { type && <div className={s.serviceTypeTag}>{type}</div> }
                                                                    <div className={s.titleBox}>
                                                                        <div className={s.colorMark} style={{ backgroundColor: markColor?.content }} />
                                                                        <div className={s.checkboxTitle}>{name}</div>
                                                                    </div>
                                                                    <div className={s.des}>{description}</div>
                                                                </div>

                                                            </div>
                                                            <div className={s.selectText}>select</div>
                                                        </h4>
                                                        <div className={s.correctIcon} />
                                                        <div className={s.info}>
                                                            <div className={s.row}>
                                                                <div
                                                                    className={s.displayItem}
                                                                >
                                                                    <div className={`${s.label} ${s.required}`}>Duration(minutes)</div>
                                                                    <div className={s.value}>{duration}</div>
                                                                </div>
                                                                {/* <div className={s.displayItem}>
                                                                    <div className={`${s.label} ${s.required}`}>Intake form</div>
                                                                    <div className={s.value}>{intakeForm}</div>
                                                                </div> */}
                                                            </div>
                                                            <div className={s.row}>
                                                                <div
                                                                    className={s.displayItem}
                                                                >
                                                                    <div className={`${s.label}`}>Meeting type</div>
                                                                    {
                                                                        noMeetingtype &&
                                                                        <div className={s.box}>
                                                                            <div className={s.wrongIcon} />
                                                                            <div className={s.info}>
                                                                                <div className={s.title}>No meeting needed</div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        telehealthCheckStatus &&
                                                                        <div className={s.box}>
                                                                            <div className={`${telehealthCheckStatus ? s.correctIcon : s.wrongIcon}`} />
                                                                            <div className={s.info}>
                                                                                <div className={s.title}>Telehealth</div>
                                                                                <div className={s.row}>
                                                                                    <div
                                                                                        className={s.displayItem}
                                                                                    >
                                                                                        <div className={`${s.label} ${s.required}`}>Patient type</div>
                                                                                        <div className={s.value}>{telehealthType}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        inpersonCheckStatus &&
                                                                        <div className={s.box}>
                                                                            <div className={`${inpersonCheckStatus ? s.correctIcon : s.wrongIcon}`} />
                                                                            <div className={s.info}>
                                                                                <div className={s.title}>In-person</div>
                                                                                <div className={s.row}>
                                                                                    <div
                                                                                        className={s.displayItem}
                                                                                    >
                                                                                        <div className={`${s.label} ${s.required}`}>Office address</div>
                                                                                        <div className={s.value}>{officeAddress}</div>
                                                                                    </div>
                                                                                    <div className={s.displayItem}>
                                                                                        <div className={`${s.label} ${s.required}`}>Patient type</div>
                                                                                        <div className={s.value}>{personType}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            );
                        })
                        : 'There is no duplicate service-type, please click \'OK\' and continue to submit'
                }
            </div>
        </div>
    );
};

export default SingleServiceTypeChannelModal;
