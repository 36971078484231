import React from 'react';
import Logo from 'assets/common/Logo.svg';
import s from './s.module.less';

const Header = () => {
    return (
        <div className={s.wrap}>
            <h1 className={s.wrapIn}>
                <img src={Logo} alt="Kiwi Health logo" />
                <span>Kiwi Health</span>
            </h1>
        </div>
    );
};

export default Header;
