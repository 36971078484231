import React, { useState, useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import s from './s.module.less';

interface IProps {
    value?: string;
    onChange?: (value: string) => void;
    maxLength?: number;
    showCount?: boolean;
    cls?: string;
    height?: string;
    plcaeholder?: string;
}

const QuillRichText = ({
    value,
    onChange,
    maxLength = 100,
    showCount = true,
    cls,
    height = '200px',
    plcaeholder,
}: IProps) => {
  const [editorState, setEditorState] = useState(''); // 跟踪内容

  useEffect(() => {
    setEditorState(value || '');
  }, [value])

  const handleChange = (content) => {
    // 若超过最大字符限制，不更新内容
    const valueRemovedHTML = content.replace(/<[^>]*>/g, ''); // 移除 HTML 标签
    // if (valueRemovedHTML.length <= maxLength) {
      const resultValue = valueRemovedHTML.length === 0 ? '' : content;
      setEditorState(resultValue);
      onChange?.(resultValue);
    // }
  };

  // 计算已输入字符数（忽略 HTML 标签）
  const getPlainTextLength = (content) => {
    const plainText = content.replace(/<[^>]*>/g, ''); // 移除 HTML 标签
    return plainText.length;
  };

  return (
    <div className={`${s.wrap} ${s.cls}`}>
      <ReactQuill
        value={editorState}
        onChange={handleChange}
        style={{height}}
        modules={{
          toolbar: [
            [{ 'size': ['small', false, 'large', 'huge'] }],
            ['bold', 'italic'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'align': [] }],
            ['link'],
          ],
          history: {
            delay: 1000,
            maxStack: 50,
          },
        }}
        placeholder={plcaeholder}
      />

    {/* 字数计数显示 */}
    {
        showCount && (
            <div style={{ marginTop: 10, textAlign: 'right', color: 'rgba(0, 0, 0, 0.45)' }}>
                {getPlainTextLength(editorState)}/{maxLength}
            </div>
        )
    }
    </div>
  );
};

export default QuillRichText;
