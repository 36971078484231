export const patientGroup = [
    {
        value: 'Children (5-12 years old)',
        label: 'Children (5-12 years old)'
    },
    {
        value: 'Adolescents (13-17 years old)',
        label: 'Adolescents (13-17 years old)'
    },
    {
        value: 'Adults (18-65 years old)',
        label: 'Adults (18-65 years old)'
    },
    {
        value: 'Seniors (65+ years old)',
        label: 'Seniors (65+ years old)'
    },
    {
        value: 'Couples',
        label: 'Couples',
    },
    {
        value: 'Group session',
        label: 'Group session'
    },
    {
        value: 'Family',
        label: 'Family'
    },
];

export const sourceType = {
    PROGRAM_WL: 'PROGRAM',
    PROVIDER: 'PROVIDER',
}

export const frequency = {
    ONE_TIME: 'One-Time',
    MONTHLY: 'Monthly'
}

export const frequencyOptions = [
    {
        label: frequency.ONE_TIME,
        value: frequency.ONE_TIME
    },
    {
        label: frequency.MONTHLY,
        value: frequency.MONTHLY
    }
]
