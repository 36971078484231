import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { Meta, IntakeqForm } from 'types/common';
import useIntakeqForm from 'hooks/useIntakeqForm';
import s from './s.module.less';

type IntakeqFormSelectProps = {
    isOps?: boolean;
    showCreationOption?: boolean;
    width?: string;
    value?: string;
    onChange?: (val: string) => void,
    onRedirect?: () => void,
} & SelectProps;

const IntakeqFormSelect = (props:IntakeqFormSelectProps) => {
    const { onChange, showCreationOption, onRedirect, isOps, ...reset } = props;

    const [services, loading] = useIntakeqForm({
        isOps,
    });
    const options:Meta[] = services?.map((item:IntakeqForm) => {
        return {
            label: item.name,
            value: item.id!,
        };
    }) || [];
    return (
        <Select {...reset} onChange={onChange} loading={loading}>
            {
                showCreationOption ?
                    <Select.Option disabled value="" label="">
                        <div className={`${s.optionLabelItem} ${s.optionLink}`} onClick={onRedirect}>Create a new intake form</div>
                    </Select.Option> :
                    null
            }
            {
                options.map((option:Meta) => {
                    return (
                        <Select.Option
                            key={option.value}
                            value={option.value}
                        >
                            {option.label}
                        </Select.Option>
                    );
                })
            }
        </Select>
    );
};

export default IntakeqFormSelect;
