import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import useCalendarColors from 'hooks/useCalendarColors';
import { Color } from 'types/calendar';
import { ColorKey } from 'constants/calendar';

type ColorSelectProps = {
    useAdminApi?: boolean;
    value?: string;
    onChange?: (val: string) => void,
    colorOptions?: Color[],
} & SelectProps;

const ColorSelect = (props:ColorSelectProps) => {
    const { onChange, colorOptions, useAdminApi, ...reset } = props;
    const [colors, loading] = useCalendarColors({ useAdminApi });
    const displayColors = colorOptions || colors?.filter((color:Color) => { return ![ColorKey.blockTime, ColorKey.event].includes(color.dictKey); }) || [];
    return (
        <Select {...reset} onChange={onChange} loading={loading}>
            {
                displayColors.map((option:Color) => {
                    return (
                        <Select.Option
                            key={option.id}
                            value={option.dictKey}
                        >
                            <div style={{ backgroundColor: `${option.content}`, width: '100%', height: '100%' }} />
                        </Select.Option>
                    );
                })
            }
        </Select>
    );
};

export default ColorSelect;
