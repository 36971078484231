import React, { useCallback } from 'react';
import s from './s.module.less';
import campainIcon from 'assets/campain/blackfriday2024/campainIcon.svg';
import { ICampaign } from 'types/campaign';
import dayjs from 'utils/dayjs';
import CampaignStore from 'store/Campaign';
import { showBlackFriday2024JoinCampaignModal, showBlackFriday2024LeaveCampaignModal } from 'utils/globalLayerControl';

interface IProps {
    campain: ICampaign;
}

const Blackfriday2024 = ({
    campain,
}: IProps) => {
    const [,setSelectedCampaign] = CampaignStore.useStore();
    const { campaignName, description, providerEndDateTime, providerStartDateTime, joined, joinedCount = 108 } = campain;

    const handleJoin = useCallback(() => {
        setSelectedCampaign('selectedCampaign', campain);
        showBlackFriday2024JoinCampaignModal();
    }, [campain]);

    const handleLeaveGroup = useCallback(() => {
        setSelectedCampaign('selectedCampaign', campain);
        showBlackFriday2024LeaveCampaignModal();
    }, []);

    return (
        <div className={s.wrap}>
            <div className={s.leftWrap}>
                <img className={s.campaignIcon} src={campainIcon} />
                <div className={s.info}>
                    {
                        !providerStartDateTime && !providerEndDateTime ? '' : (
                            <div className={s.timeLimit}>Limited-time: {providerStartDateTime ? dayjs(providerStartDateTime || '').format('MMM DD') : ''} - {providerEndDateTime ? dayjs(providerEndDateTime || '').format('MMM DD') : ''}</div>
                        )
                    }
                    <div className={s.name}>{campaignName}</div>
                    <div className={s.desc}>{description}</div>
                </div>
            </div>
            <div className={s.rightWrap}>
                <div className={s.providerAvatars}>
                    <div className={s.avatarGroup} />
                </div>
                <div className={s.text} onClick={handleJoin}>{joinedCount} providers joined</div>
                {
                    joined ?
                        <div className={`${s.button} ${s.joined}`} onClick={handleLeaveGroup} /> :
                        <div className={`${s.button} ${s.joinBtn}`} onClick={handleJoin}>Join now</div>
                }
            </div>
        </div>
    );
};

export default Blackfriday2024;
