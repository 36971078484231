import React, { useCallback, useState, useMemo } from 'react';
import {
    IProfileFormSubmit,
} from 'types/channelApplicationProvider';
import Profile from 'components/ApplicationFormComponents/Profile';
import Services from 'components/ApplicationFormComponents/Services';
import Practice from 'components/ApplicationFormComponents/Practice';
import HealthcareExperience from 'components/ApplicationFormComponents/HealthcareExperience';
import Education from 'components/ApplicationFormComponents/Education';
import AdditionalInformation from 'components/ApplicationFormComponents/AdditionalInformation';
import License from 'components/ApplicationFormComponents/License';
import s from './s.module.less';
import { StepValue } from 'pages/CreateUniProfilePage/types';
import globalFormsEditStatusStore from 'store/ChannelApplicationProvider/FormsEditStatus';
import { NoticeType } from 'antd/es/message/interface';
import Insurance from 'components/ApplicationFormComponents/Insurance';
import { UniprofileFormData } from 'types/provider';
import Biographic from 'components/ApplicationFormComponents/Biographic';

type ISubmitData = Partial<IProfileFormSubmit>;

interface IProps {
    providerId: number;
    channelId: number;
    formData: UniprofileFormData;
    onSubmit: (value: ISubmitData, step: StepValue) => void;
    refetch: () => void;
    onShowMessage: (type: NoticeType, content: string) => void;
    supportEdit: boolean;
}

const UniprofilePreview = ({
    providerId,
    channelId,
    formData,
    onSubmit,
    refetch,
    onShowMessage,
    supportEdit,
}: IProps) => {
    const isUniprofileDetail = channelId === -1;
    const [showEditButtons, setShowEditButtons] = useState<boolean>(supportEdit);
    const [editEnableMap, setEditEnableMap] = useState({
        [StepValue.PROFILE]: false,
        [StepValue.SERVICES]: false,
        [StepValue.PRACTICE]: false,
        [StepValue.HEALTHCARE_EXPERIENCE]: false,
        [StepValue.EDUCATION]: false,
        [StepValue.ADDITIONAL_INFORMATION]: false,
        [StepValue.INSURANCE]: false,
        [StepValue.BIOGRAPHIC]: false,
        [StepValue.LICENSE]: false,
    });

    const [getGlobalEditStatus, setGloalEditStatus] = globalFormsEditStatusStore.useStore();
    const globalFormsEditStatus = getGlobalEditStatus('globalEditStatus');

    const { profile, services, practice, experienceList, educationList, addtional, insurance, licenses, physicians, malpracticeInsuranceCertificate, assignee = '', notes = '', biographic } = formData;
    const initPracticeName = useMemo(
        () => `${profile.firstName} ${profile.lastName}'s Practice`,
        [profile],
    );

    const handleStepEditStatusChange = useCallback((status: boolean, step: StepValue, callback?: () => void) => {
        if (!globalFormsEditStatus || !status) {
            setEditEnableMap({
                ...editEnableMap,
                [step]: status,
            });
            callback?.();

            setGloalEditStatus('globalEditStatus', status);
        } else {
            onShowMessage('error', 'Please save your changes before proceeding!');
        }
    }, [editEnableMap, globalFormsEditStatus, onShowMessage, setGloalEditStatus]);

    return (
        <div className={s.wrap}>
            <div className={s.block}>
                <Profile isOps channelId={channelId} enablePhotoSelect={isUniprofileDetail} showEditButtons={showEditButtons} editable={editEnableMap[StepValue.PROFILE]} initFormValue={profile} onSubmit={onSubmit} onEditableChange={handleStepEditStatusChange} refetch={refetch} />
            </div>
            <div className={s.block}>
                <Services isOps providerId={providerId} addressList={practice?.addressList} onShowMessage={onShowMessage} showEditButtons={showEditButtons} initFormValue={services} refetch={refetch} />
            </div>
            <div className={s.block}>
                <Practice isOps showEditButtons={showEditButtons} editable={editEnableMap[StepValue.PRACTICE]} initFormValue={practice} initPracticeName={initPracticeName} onSubmit={onSubmit} refetch={refetch} onEditableChange={handleStepEditStatusChange} />
            </div>
            <div className={s.block}>
                <HealthcareExperience showEditButtons={showEditButtons} editable={editEnableMap[StepValue.HEALTHCARE_EXPERIENCE]} initFormValue={experienceList} onSubmit={onSubmit} onEditableChange={handleStepEditStatusChange} />
            </div>
            <div className={s.block}>
                <Education showEditButtons={showEditButtons} editable={editEnableMap[StepValue.EDUCATION]} initFormValue={educationList} onSubmit={onSubmit} onEditableChange={handleStepEditStatusChange} />
            </div>
            <div className={s.block}>
                <AdditionalInformation showEditButtons={showEditButtons} editable={editEnableMap[StepValue.ADDITIONAL_INFORMATION]} initFormValue={addtional} onSubmit={onSubmit} refetch={refetch} />
            </div>
            <div className={s.block}>
                <Insurance showEditButtons={showEditButtons} editable={editEnableMap[StepValue.INSURANCE]} initFormValue={insurance} onSubmit={onSubmit} onEditableChange={handleStepEditStatusChange} />
            </div>
            <div className={s.block}>
                <License
                    editable={editEnableMap[StepValue.LICENSE]}
                    initLicenseFormValue={licenses}
                    initPhysicianFormValue={physicians}
                    initMalpracticeInsuranceCertificateValue={malpracticeInsuranceCertificate}
                    onSubmit={onSubmit}
                    onEditableChange={handleStepEditStatusChange}
                    isOps
                    providerId={providerId}
                    viewOnly={!showEditButtons}
                />
            </div>
            <div className={s.block}>
                <Biographic
                    showEditButtons={showEditButtons}
                    editable={editEnableMap[StepValue.BIOGRAPHIC]}
                    initFormValue={biographic}
                    onSubmit={onSubmit}
                    onEditableChange={handleStepEditStatusChange}
                />
            </div>
        </div>
    );
};

export default UniprofilePreview;
