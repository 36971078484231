// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__radio--UfxAC {
  display: flex;
  align-items: center;
}
.s-module__radio--UfxAC .s-module__recommended--ZM2N2 {
  margin-left: 8px;
  display: flex;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: var(--green-100);
  color: var(--green-800);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/PorivderMappingModals/MappingModal/s.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;AACJ;AAHA;EAKQ,gBAAA;EACA,aAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;EACA,4BAAA;EACA,uBAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACR","sourcesContent":[".radio {\n    display: flex;\n    align-items: center;\n\n    .recommended {\n        margin-left: 8px;\n        display: flex;\n        padding: 2px 10px;\n        justify-content: center;\n        align-items: center;\n        border-radius: 10px;\n        background: var(--green-100);\n        color: var(--green-800);\n        text-align: center;\n        font-family: Inter;\n        font-size: 12px;\n        font-style: normal;\n        font-weight: 500;\n        line-height: 16px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radio": `s-module__radio--UfxAC`,
	"recommended": `s-module__recommended--ZM2N2`
};
export default ___CSS_LOADER_EXPORT___;
