import React, { useEffect, useMemo, useRef, useState } from 'react';
import s from './s.module.less';
import { EProfileStatus } from 'types/channel';

interface IProps {
    status?: EProfileStatus;
}

const ProviderUniprofileStatusTag = ({
    status = EProfileStatus.REQUIRED,
}: IProps) => {
    const textMapping = useRef({
        [EProfileStatus.APPROVED]: 'Approved',
        [EProfileStatus.REJECTED]: 'Disqualified',
        [EProfileStatus.UPDATE_SUBMITTED]: 'Update submitted',
        [EProfileStatus.UNDER_REVIEW]: 'Under review',
        [EProfileStatus.UPDATE_IN_PROGRESS]: 'Update in progress',
        [EProfileStatus.REQUIRED]: 'Not submitted',
    });


    const statusClass = useMemo(() => {
        let cls = '';
        switch (status) {
            case EProfileStatus.APPROVED:
                cls = s.approved;
                break;
            case EProfileStatus.REJECTED:
                cls = s.rejected;
                break;
            case EProfileStatus.UPDATE_SUBMITTED:
            case EProfileStatus.UNDER_REVIEW:
            case EProfileStatus.UPDATE_IN_PROGRESS:
                cls = s.followup;
                break;
            // case EListStatus.NOT_LISTED:
            //     cls = s.pending;
            //     break;
            case EProfileStatus.REQUIRED:
                cls = '';
                break;
            default: cls = '';
        }

        return `${s.status} ${cls}`;
    }, [status]);

    return <div className={statusClass}>{textMapping.current[status] || 'Not submitted'}</div>;
};

export default ProviderUniprofileStatusTag;
