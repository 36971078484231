import React from 'react';
import { Form, Button, Spin, message, Modal } from 'antd';
import s from './s.module.less';
import { License, ELicenseType } from 'types/common';
import { DEFAULT_SECOND_TYPE } from 'constants/common';
import { FormLicense } from 'types/form';
import commonS from 'styles/common.module.less';
import { providerLicenseUpdate } from 'api/provider';
import { opLicenseUpdate } from 'api/operation';
import { formatDate, getFileNameFromUrl } from 'utils/common';
import SparklesIcon from 'assets/common/sparkles.png';
import NurseLicense from '../NurseLicense';
import { NurseAndPaFormLicenses } from 'types/provider';
import PhysiciansAssistantLicense from '../PhysiciansAssistantLicense';
import { LicenseData, transferFormLicenseTypeToServerData } from 'data/provider';
import { OTHERS_KEY } from 'constants/meta';
import LicenseImg from 'components/LicenseImg';

type Props = {
    paInited?:boolean,
    nurseInited?:boolean,
    nurseInitValue?: Partial<FormLicense>,
    paInitValue?: Partial<FormLicense>,
    state:string,
    providerId?:number,
    onChange?: (value:NurseAndPaFormLicenses)=>void
    value?: NurseAndPaFormLicenses,
    viewOnly?:boolean,
    onNullState?: ()=>void,
    isOperation?:boolean,
};

const NurseAndPhysiciansAssistantLicense = (props: Props) => {
    const { paInited, nurseInited, nurseInitValue, paInitValue, onChange, value, viewOnly, state, providerId, onNullState, isOperation } = props;
    const [submiting, setSubmiting] = React.useState(false);
    const [,forceUpdate] = React.useState({});

    //enable and show modal is little function cover..

    const [paEnable, setPaEnable] = React.useState(!paInited);
    const [nurseEnable, setNurseEnable] = React.useState(!nurseInited);

    const [showNurseModal, setShowNurseModal] = React.useState(false);
    const [showPaModal, setShowPaModal] = React.useState(false);
    const [nurseForm] = Form.useForm<FormLicense>();
    const [paForm] = Form.useForm<FormLicense>();

    const onSubmitPa = async () => {
        try {
            setSubmiting(true);
            const formValues = await paForm.validateFields();
            const licenseData:Partial<License> = {
                type: ELicenseType.PA,
                secondType: formValues.secondType,
                state,
                licenseNumber: formValues.licenseNumber,
                expireDate: formatDate(formValues.expireDate),
                // schedule: formValues.schedule.join(','),
                url: formValues.url,
            };
            const fn = isOperation ? opLicenseUpdate : providerLicenseUpdate;
            const result = await fn(licenseData, providerId!);

            if (!result.error) {
                message.success('Physician‘s assistant license info has updated');
                setPaEnable(false);
                setShowPaModal(false);
                if (onChange) {
                    onChange(value ? {
                        ...value,
                        pa: LicenseData.serverToForm(licenseData as License),
                    } : {
                        pa: LicenseData.serverToForm(licenseData as License),
                    });
                }
            } else {
                message.error(result.error);
            }
        } catch (e:any) {
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };

    const onSubmitNurse = async () => {
        try {
            setSubmiting(true);
            const formValues = await nurseForm.validateFields();
            const { licenseType } = formValues;
            const licenseTypeStr = transferFormLicenseTypeToServerData(licenseType);

            const licenseData:Partial<License> = {
                type: ELicenseType.NURSE,
                secondType: DEFAULT_SECOND_TYPE,
                state,
                licenseNumber: formValues.licenseNumber,
                expireDate: formatDate(formValues.expireDate),
                // schedule: formValues.schedule.join(','),
                url: formValues.url,
                licenseType: licenseTypeStr,
            };
            const result = await providerLicenseUpdate(licenseData, providerId!);

            if (!result.error) {
                message.success('Nurse license info has updated');
                setNurseEnable(false);
                setShowNurseModal(false);
                if (onChange) {
                    onChange(value ? {
                        ...value,
                        nurse: LicenseData.serverToForm(licenseData as License),
                    } : {
                        nurse: LicenseData.serverToForm(licenseData as License),
                    });
                }
            } else {
                message.error(result.error);
            }
        } catch (e:any) {
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };

    React.useEffect(() => {
        if (paInitValue) {
            paForm.setFieldsValue(paInitValue);
            forceUpdate({});
        }
        if (nurseInitValue) {
            nurseForm.setFieldsValue(nurseInitValue);
            forceUpdate({});
        }
    }, [paInitValue, nurseInitValue]);

    const nurceLicenseTypes:Record<string, string | Record<string, string>> = nurseForm.getFieldValue('licenseType');

    return (
        <div className={s.wrap}>
            <div>
                <Modal
                    title="Nurse practitioner license"
                    closable
                    className={commonS.modalFixHeightWrap}
                    width="80%"
                    destroyOnClose
                    onCancel={() => {
                        setShowNurseModal(false);
                    }}
                    okText="Save"
                    open={showNurseModal}
                    onOk={onSubmitNurse}
                >
                    <NurseLicense form={nurseForm} />
                </Modal>
                <Modal
                    title="Physician‘s assistant license"
                    closable
                    className={commonS.modalFixHeightWrap}
                    width="80%"
                    destroyOnClose
                    onCancel={() => {
                        setShowPaModal(false);
                    }}
                    okText="Save"
                    open={showPaModal}
                    onOk={onSubmitPa}
                >
                    <PhysiciansAssistantLicense form={paForm} />
                </Modal>
                <Spin spinning={submiting}>
                    <div className={s.module}>
                        <h3><span>Practice Licenses</span></h3>
                        {!viewOnly &&
                        <div className={commonS.tips}>
                            <img src={SparklesIcon} />
                            <p>You need either a nurse practitioner licenses or a physician’s assistant license to start your practice. These two licenses cannot be both empty. </p>
                        </div>
                        }
                        <div className={s.row}>
                            {nurseEnable && !viewOnly &&
                                <div className={s.rowItem}>
                                    <div
                                        className={s.box}
                                        onClick={() => {
                                            if (!state) {
                                                onNullState?.();
                                                return;
                                            }
                                            setShowNurseModal(true);
                                        }}
                                    >
                                        <span className={commonS.addIcon} />
                                        <p>Nurse Practitioner Licenses</p>
                                    </div>
                                </div>
                            }
                            {paEnable && !viewOnly &&
                                <div className={s.rowItem}>
                                    <div
                                        className={s.box}
                                        onClick={() => {
                                            setShowPaModal(true);
                                        }}
                                    >
                                        <span className={commonS.addIcon} />
                                        <p>Physician’s Assistant License</p>
                                    </div>
                                </div>
                            }
                        </div>
                        {
                            !nurseEnable &&
                            <div className={s.subModule}>
                                <h4><span>Nurse practitioner licenses</span>
                                    {!viewOnly &&
                                    <Button
                                        size="small"
                                        onClick={() => {
                                            if (!state) {
                                                onNullState?.();
                                                return;
                                            }
                                            setShowNurseModal(true);
                                        }}
                                    >Edit
                                    </Button>
                                    }
                                </h4>
                                <div className={s.row}>
                                    <div
                                        style={{
                                            width: '300px',
                                        }}
                                        className={s.displayItem}
                                    >
                                        <div className={s.label}>NP License number</div>
                                        <div className={s.value}>{ nurseForm.getFieldValue('licenseNumber')}</div>
                                    </div>
                                    <div className={s.displayItem}>
                                        <div className={s.label}>Expiration date</div>
                                        <div className={s.value}>{ nurseForm.getFieldValue('expireDate')?.format('MM-DD-YYYY')}</div>
                                    </div>
                                </div>
                                {/* <div className={s.row}>
                                    <div
                                        style={{
                                            width: '300px',
                                        }}
                                        className={s.displayItem}
                                    >
                                        <div className={s.label}>Schedules this license cover</div>
                                        <div className={s.value}>{ nurseForm.getFieldValue('schedule')?.join(', ') || ''}</div>
                                    </div>
                                </div> */}

                                {
                                    Object.keys(nurceLicenseTypes || {})?.map((type) => {
                                        if (type === OTHERS_KEY) {
                                            const otherObj:Record<string, string> = nurceLicenseTypes[OTHERS_KEY] as Record<string, string>;
                                            return (
                                                <>
                                                    {Object.keys(otherObj).map((otherType:string) => {
                                                        return (
                                                            <div className={s.row} key={`òther-${otherType}`}>
                                                                <div
                                                                    style={{
                                                                        width: '300px',
                                                                    }}
                                                                    className={s.displayItem}
                                                                >
                                                                    <div className={s.label}>{otherType}</div>
                                                                    <div className={s.value}>
                                                                        <LicenseImg src={otherObj[otherType]} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </>
                                            );
                                        } else {
                                            return (
                                                <div className={s.row} key={type}>
                                                    <div
                                                        style={{
                                                            width: '300px',
                                                        }}
                                                        className={s.displayItem}
                                                    >
                                                        <div className={s.label}>{type}</div>
                                                        <div className={s.value}>
                                                            <LicenseImg src={nurceLicenseTypes[type] as string} />
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })
                                }

                            </div>
                        }
                        {
                            !paEnable &&
                            <div className={s.subModule}>
                                <h4><span>Physician‘s assistant license</span>
                                    {!viewOnly &&
                                    <Button
                                        size="small"
                                        onClick={() => {
                                            setShowPaModal(true);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                    }
                                </h4>
                                <div className={s.row}>
                                    <div
                                        style={{
                                            width: '600px',
                                        }}
                                        className={s.displayItem}
                                    >
                                        <div className={s.label}>PA License type</div>
                                        <div className={s.value}>
                                            {paForm.getFieldValue('secondType') === '1' && 'Physician Assistant'}
                                            {paForm.getFieldValue('secondType') === '2' && 'Physician Assistant Certified'}
                                        </div>
                                    </div>
                                </div>
                                <div className={s.row}>
                                    <div
                                        style={{
                                            width: '300px',
                                        }}
                                        className={s.displayItem}
                                    >
                                        <div className={s.label}>PA license number</div>
                                        <div className={s.value}>{ paForm.getFieldValue('licenseNumber')}</div>
                                    </div>
                                    <div className={s.displayItem}>
                                        <div className={s.label}>Expiration date</div>
                                        <div className={s.value}>{ paForm.getFieldValue('expireDate')?.format('MM-DD-YYYY')}</div>
                                    </div>
                                </div>
                                {/* <div className={s.row}>
                                    <div
                                        style={{
                                            width: '300px',
                                        }}
                                        className={s.displayItem}
                                    >
                                        <div className={s.label}>Schedules this license cover</div>
                                        <div className={s.value}>{ paForm.getFieldValue('schedule')?.join(', ') || ''}</div>
                                    </div>
                                </div> */}
                                <div className={s.row}>
                                    <div
                                        style={{
                                            width: '80%',
                                        }}
                                        className={s.displayItem}
                                    >
                                        <div className={s.label}>Uploaded license</div>
                                        <div className={s.value}>
                                            <LicenseImg src={paForm.getFieldValue('url')} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </Spin>
            </div>
        </div>
    );
};

export default NurseAndPhysiciansAssistantLicense;
