import React from 'react';
import { Select, message } from 'antd';
import { SelectProps } from 'antd/lib/select';
import useSettings from 'hooks/useSettings';
import { EnumFields } from 'types/enumerationData';
import { Meta } from 'types/common';
import { sortByProperty } from 'utils/form';

type StateSelectProps = {
    value?: string;
    onChange?: (val: string | string[]) => void;
    disableValues?: string[];
    mode?: 'multiple' | '';
    isShowValue?: boolean;
    maxCount?: number;
} & SelectProps;

const StateSelect = (props:StateSelectProps) => {
    const { onChange, disableValues, mode, isShowValue, maxCount, ...reset } = props;
    const [setting, loading] = useSettings();
    const stateOption = sortByProperty(setting[EnumFields.STATE] || [], 'label');

    const handleChange = (val: string | string[]) => {
        if (maxCount && val.length > maxCount) {
            message.warning(`You can only select up to ${maxCount} items.`);
        } else {
            onChange?.(val);
        }
    };

    return (
        <Select
            {...reset}
            onChange={handleChange}
            loading={loading}
            showSearch
            virtual={false}
            mode={mode}
        >
            {stateOption.map((option: Meta) => {
                const disabled = disableValues?.includes(option.value as string) &&
                reset.value !== option.value;
                return (
                    <Select.Option
                        disabled={disabled}
                        key={option.value}
                        value={option.value}
                    >
                        {isShowValue ? `${option.value} (${option.label})` : option.label}
                    </Select.Option>
                );
            })}
        </Select>
    );
};

export default StateSelect;
