import React, { useCallback, useEffect, useMemo, useState } from 'react';
import s from './s.module.less';
import { Alert, Button, Checkbox, Form, Input, InputNumber, Select, Switch, Tooltip, message } from 'antd';
import { EditModuleEnum, FormFields, IService } from 'types/practiceFront';
import commonS from 'styles/common.module.less';
import ColorSelect from 'components/form/ColorSelect';
import DurationSelect from 'components/form/DurationSelect';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { PATIENT_TYPES } from 'constants/meta';
import Exclamation from 'assets/setting/Exclamation.svg';
import { ServicesFormFieldEnum } from 'pages/CreateUniProfilePage/types';
import useServiceTypeData from 'hooks/useServiceTemplateData';
import { Service } from 'types/common';

interface IProps {
    addressList: Array<{ value: number, label: string, isDefault: boolean }>;
    id?: number;
    index: number;
    data: IService;
    isEditting: boolean;
    onEditStatusChange: (status: boolean, index: number) => void;
    onSubmit: (value: Service, id?: number) => void;
    onRemove: (index: number, id?: number) => void;
}

const ServiceItem = ({
    addressList = [],
    id,
    index,
    data,
    isEditting,
    onEditStatusChange,
    onSubmit,
    onRemove,
}: IProps) => {
    const [isCheckedNoMeeting, setIsCheckedNoMeeting] = useState(false);
    const [isCheckedTelehealth, setIsCheckedTelehealth] = useState(false);
    const [isCheckedInPerson, setIsCheckedInPerson] = useState(false);
    const [showTypeError, setShowTypeError] = useState(false);
    const [formInstance] = Form.useForm<IService>();
    const formName = `${EditModuleEnum.EDUCATION}_${index}`;
    const [showInitialVisitError, setShowInitialVisitError] = useState(false);

    const [serviceTemplateData, serviceTemplateDataLoading] = useServiceTypeData(false);
    const [currentServiceTemplateData, setCurrentServiceTemplateData] = useState<Service>();
    const [enableSlidingScaleCheckStatus, setEnableSlidingScaleCheckStatus] = useState(false);

    useEffect(() => {
        if (data) {
            const { telehealthType, personType, personAddress, personCity, personState } = data;

            setIsCheckedTelehealth(!!(telehealthType && telehealthType.length > 0));
            setIsCheckedInPerson(!!(personType && personType.length > 0 && personAddress && personCity && personState));
        }
    }, [data]);

    const onSelectNoMeeting = useCallback(() => {
        setIsCheckedTelehealth(false);
        setIsCheckedInPerson(false);
        setIsCheckedNoMeeting(!isCheckedNoMeeting);
        formInstance.setFieldsValue({
            [FormFields.DURATION]: 0,
            [FormFields.TELEHEALTH_TYPE]: [],
            [FormFields.PERSON_ADDRESS]: '',
            [FormFields.PERSON_CITY]: '',
            [FormFields.PERSON_STATE]: '',
            [FormFields.PERSON_ZIP]: '',
            [FormFields.PERSON_TYPE]: [],
        });
    }, [formInstance, isCheckedNoMeeting]);

    const handleTelehealthChange = useCallback((e: CheckboxChangeEvent) => {
        setIsCheckedTelehealth(e.target.checked);
        setIsCheckedNoMeeting(false);
    }, []);

    const handleInPersonChange = useCallback((e: CheckboxChangeEvent) => {
        setIsCheckedInPerson(e.target.checked);
        setIsCheckedNoMeeting(false);
    }, []);

    useEffect(() => {
        if (currentServiceTemplateData && isEditting) {
            formInstance.setFieldsValue({
                [ServicesFormFieldEnum.SERVICE_TYPE]: currentServiceTemplateData.type,
                [ServicesFormFieldEnum.PRICE]: currentServiceTemplateData.price,
                [ServicesFormFieldEnum.COLOR_ID]: currentServiceTemplateData.colorId,
                [ServicesFormFieldEnum.DESCRIPTION]: currentServiceTemplateData.description,
                [ServicesFormFieldEnum.DURATION]: currentServiceTemplateData.duration,
                [ServicesFormFieldEnum.NAME]: currentServiceTemplateData.name,
                [ServicesFormFieldEnum.ADDRESS]: currentServiceTemplateData.personAddress,
                [ServicesFormFieldEnum.CITY]: currentServiceTemplateData.personCity,
                [ServicesFormFieldEnum.STATE]: currentServiceTemplateData.personState,
                [ServicesFormFieldEnum.PERSON_TYPE]: currentServiceTemplateData.personType?.split(','),
                [ServicesFormFieldEnum.ZIP_CODE]: currentServiceTemplateData.personZip,
                [ServicesFormFieldEnum.SEND_FORM]: currentServiceTemplateData.sendForm,
                [ServicesFormFieldEnum.LOWEST_PRICE]: currentServiceTemplateData.enableSlidingScale ? currentServiceTemplateData.lowestPrice : undefined,
                [ServicesFormFieldEnum.HIGHEST_PRICE]: currentServiceTemplateData.enableSlidingScale ? currentServiceTemplateData.highestPrice : undefined,
                [ServicesFormFieldEnum.TELEHEALTH_TYPE]: currentServiceTemplateData.telehealthType?.split(','),
            });
            setEnableSlidingScaleCheckStatus(!!currentServiceTemplateData.enableSlidingScale);
        }
    }, [currentServiceTemplateData, isEditting, formInstance]);

    useEffect(() => {
        if (data) {
            if (!data.enableSlidingScale) {
                data.lowestPrice = undefined;
                data.highestPrice = undefined;
            }
            formInstance.setFieldsValue(data);
            setEnableSlidingScaleCheckStatus(!!data.enableSlidingScale);
        }
    }, [data, formInstance]);

    const { description, duration, name, personType, telehealthType, price, enableSlidingScale, lowestPrice, highestPrice } = data;

    const handleEnableSlidingScaleChange = (check: boolean) => {
        setEnableSlidingScaleCheckStatus(check);
    };

    const handleSubmit = useCallback(() => {
        if (formInstance.getFieldValue(FormFields.SERVICE_TYPE) === 'Initial Visit' && !isCheckedInPerson && !isCheckedTelehealth) {
            setShowInitialVisitError(true);
        } else {
            setShowInitialVisitError(false);
            formInstance.validateFields().then((values) => {
                const lowestPriceVal = values[ServicesFormFieldEnum.LOWEST_PRICE] as number;
                const highestPriceVal = values[ServicesFormFieldEnum.HIGHEST_PRICE] as number;
                if (enableSlidingScaleCheckStatus && lowestPriceVal > highestPriceVal) {
                    message.error('Highest price should be greater than lowest price');
                    return;
                }
                if (!enableSlidingScaleCheckStatus) {
                    values.lowestPrice = undefined;
                    values.highestPrice = undefined;
                }
                const submitData = {
                    ...values,
                    personType: isCheckedInPerson ? values.personType?.join(',') : '',
                    telehealthType: isCheckedTelehealth ? values.telehealthType?.join(',') : '',
                    [FormFields.ENABLE_SLIDING_SCALE]: !!enableSlidingScaleCheckStatus,
                };
                onSubmit(submitData, id);
            }).catch((e) => {
                console.error(e);
            });
        }
    }, [formInstance, id, isCheckedInPerson, isCheckedTelehealth, onSubmit, enableSlidingScaleCheckStatus]);

    const visitTypeText = useMemo(() => {
        if (telehealthType && telehealthType.length > 0 && personType && personType.length > 0) {
            return 'Video or in-person visits';
        } else if (telehealthType && telehealthType.length > 0) {
            return 'Video visits only';
        } else if (personType && personType.length > 0) {
            return 'In-person visits only';
        }

        return 'No meeting needed';
    }, [personType, telehealthType]);

    const timeInfo = (
        <>
            <span>{duration}mins</span>
            <span>•</span>
            <span>{visitTypeText}</span>
            <span>•</span>
            <span>Existing patients only</span>
        </>
    );

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <div className={s.label}>Service {index + 1}</div>
                <div className={s.btns}>
                    {
                        isEditting ?
                            <>
                                <Button onClick={() => onEditStatusChange(false, index)}>Cancel</Button>
                                <Button type="primary" onClick={handleSubmit}>Save changes</Button>
                            </>
                            :
                            <>
                                <Button onClick={() => onRemove(index, id)}>Remove</Button>
                                <Button onClick={() => onEditStatusChange(true, index)}>Edit</Button>
                            </>
                    }
                </div>
            </div>
            <div className={s.intro}>
                {
                    !isEditting ?
                        <>
                            <div className={s.name}>{name} | ${price} / visit</div>
                            {
                                !!enableSlidingScale &&
                                    <div className={s.sliding}>
                                        <div className={s.label}>Enable sliding scale</div>
                                        <div className={s.value}>{(!lowestPrice || typeof lowestPrice !== 'number') ? 'Lowest price has not been set' : `$${lowestPrice!.toFixed(2)}`} ~ {(!highestPrice || typeof highestPrice !== 'number') ? 'Highest price has not been set' : `$${highestPrice!.toFixed(2)}`}</div>
                                    </div>
                            }
                            <div className={s.desc}>{description}</div>
                            <div className={s.timeloc}>
                                <span>{timeInfo}</span>
                            </div>
                        </>
                        :
                        <Form
                            form={formInstance}
                            name={formName}
                            className={commonS.formStyle1}
                            autoComplete="off"
                            layout="vertical"
                        >
                            <>
                                <h3>Basic Information</h3>
                                <Form.Item
                                    className={s.rowItem}
                                    label="Service Type"
                                    name={FormFields.SERVICE_TYPE}
                                    rules={[{ required: true, message: 'Service type is required' }]}
                                >
                                    <Select
                                        style={{ width: '300px' }}
                                        disabled={false}
                                        loading={serviceTemplateDataLoading}
                                        onChange={(val) => {
                                            const target = serviceTemplateData.find((item:Service) => item.type === val);
                                            setCurrentServiceTemplateData(target);
                                        }}
                                    >
                                        {
                                            serviceTemplateData.map((service:Service) => {
                                                return (
                                                    <Select.Option value={service.type} key={service.type}>
                                                        {service.type}
                                                    </Select.Option>
                                                );
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    className={s.rowItem}
                                    label="Service name"
                                    name={FormFields.NAME}
                                    rules={[{ required: true, message: 'Service name is required' }]}
                                >
                                    <Input style={{ width: '90%' }} />
                                </Form.Item>

                                <Form.Item
                                    className={s.rowItem}
                                    label="Description"
                                    name={FormFields.DESCRIPTION}
                                >
                                    <Input.TextArea style={{ width: '90%' }} maxLength={500} rows={4} />
                                </Form.Item>

                                <Form.Item
                                    className={s.rowItem}
                                    label="Duration"
                                    name={FormFields.DURATION}
                                >
                                    <DurationSelect disabled={isCheckedNoMeeting} style={{ width: '150px' }} />
                                </Form.Item>
                                <Form.Item
                                    className={s.rowItem}
                                    label="Price"
                                    name={FormFields.PRICE}
                                    rules={[{ required: true, message: 'Service price is required' }]}
                                >
                                    <InputNumber precision={2} addonBefore="$" addonAfter="USD" style={{ width: '220px' }} />
                                </Form.Item>

                                <div className={s.rowItem} style={{ marginTop: '12px', marginBottom: '12px' }}>
                                    <div className={s.switchItem}>
                                        <Switch defaultChecked={false} checked={enableSlidingScaleCheckStatus} onChange={handleEnableSlidingScaleChange} />
                                        <span className={s.switchLabel}>Enable sliding scale</span>
                                        <Tooltip
                                            overlayClassName={s.tipCard}
                                            overlayInnerStyle={{ padding: '12px' }}
                                            color="#FFFFFF"
                                            title={
                                                <div className={s.tipText}>Sliding fee scales adjust costs by income, improving healthcare access. Proof of income and household size is required.</div>
                                            }
                                        >
                                            <div className={s.questionMarkIcon} />
                                        </Tooltip>
                                    </div>
                                </div>

                                {
                                    enableSlidingScaleCheckStatus && (
                                        <div>
                                            <Form.Item
                                                className={s.rowItem}
                                                label="Lowest price"
                                                name={FormFields.LOWEST_PRICE}
                                                rules={[{ required: true, message: 'Lowest price is required' }]}
                                            >
                                                <InputNumber precision={2} addonBefore="$" addonAfter="USD" style={{ width: '220px' }} />
                                            </Form.Item>
                                            <Form.Item
                                                className={s.rowItem}
                                                label="Highest price"
                                                name={FormFields.HIGHEST_PRICE}
                                                rules={[{ required: true, message: 'Highest price is required' }]}
                                            >
                                                <InputNumber precision={2} addonBefore="$" addonAfter="USD" style={{ width: '220px' }} />
                                            </Form.Item>
                                        </div>
                                    )
                                }

                                <h3 id="meetingType">Meeting Types</h3>
                                {
                                    showInitialVisitError && <p className={s.errorText}>Initial Visit must have either Telehealth or In-person visit</p>
                                }
                                {
                                    showTypeError &&
                                    <Alert style={{ marginTop: 16, marginBottom: 16 }} message="Please select a meeting type for this service" type="error" showIcon />
                                }
                                <div className={s.row}>
                                    <div className={s.itemBox}>
                                        <Checkbox checked={isCheckedTelehealth} onChange={handleTelehealthChange}>Telehealth</Checkbox>
                                        <div className={s.des}>I can see patient via telehealth</div>
                                    </div>

                                    {
                                        isCheckedTelehealth && (
                                            <div className={s.checkboxDetail}>
                                                <div className={s.row}>
                                                    <div className={s.displayItem}>
                                                        <Form.Item
                                                            label="Select the type of patients you’re seeing for the telehealth visit"
                                                            name={FormFields.TELEHEALTH_TYPE}
                                                            validateFirst
                                                            rules={[
                                                                { required: true, message: 'patient type is required' },
                                                            ]}
                                                        >
                                                            <Checkbox.Group
                                                                className={s.mt12}
                                                                options={PATIENT_TYPES}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                <div>
                                    <div className={s.item}>
                                        <div className={s.itemBox}>
                                            <Checkbox checked={isCheckedInPerson} onChange={handleInPersonChange}>In-person</Checkbox>
                                            <div className={s.des}>I can see patient in my local office</div>
                                        </div>

                                        {
                                            !isCheckedInPerson && (
                                                <div className={`${commonS.tips} ${s.tips}`}>
                                                    <img src={Exclamation} />
                                                    <p>If you don&apos;t offer in-person visit, you can&apos;t prescribe controlled-medication periodically in certain states.</p>
                                                </div>
                                            )
                                        }

                                        {
                                            isCheckedInPerson && (
                                                <div className={s.checkboxDetail}>
                                                    <div className={s.title}>Enter your office address for the in-person visits.</div>
                                                    <div className={s.row}>
                                                        <div
                                                            className={s.displayItem}
                                                        >
                                                            <Form.Item
                                                                label="Office address"
                                                                name={FormFields.ADDRESS_ID}
                                                                validateFirst
                                                                rules={[
                                                                    { required: true, message: 'Office address is a required field' },
                                                                ]}
                                                            >
                                                                <Select options={addressList} />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                    <div className={s.row}>
                                                        <div
                                                            className={s.displayItem}
                                                        >
                                                            <Form.Item
                                                                label="Select the type of patients you’re seeing for the in-person visit"
                                                                name={FormFields.PERSON_TYPE}
                                                                validateFirst
                                                                rules={[
                                                                    { required: true, message: 'patient type is required' },
                                                                ]}
                                                            >
                                                                <Checkbox.Group
                                                                    className={s.mt12}
                                                                    options={PATIENT_TYPES}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>

                                <div className={s.itemBox}>
                                    <Checkbox checked={isCheckedNoMeeting} onChange={onSelectNoMeeting}><span>No meeting needed</span></Checkbox>
                                    <div className={s.des}>This service doesn’t need to see patient, and the duration will be 0 mins.</div>
                                </div>

                                <h3>Color code in your practice calendar</h3>
                                <div>
                                    <div className={s.item}>
                                        <Form.Item
                                            label=""
                                            name={FormFields.COLOR_ID}
                                        >
                                            <ColorSelect style={{ width: '150px' }} />
                                        </Form.Item>
                                    </div>
                                </div>
                            </>
                        </Form>
                }
            </div>
        </div>
    );
};

export default ServiceItem;
