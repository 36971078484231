import React, { useEffect, useMemo, useState } from 'react';
import useGetSettingData from 'hooks/useGetSettingData';
import s from './s.module.less';
import { EnumFields } from 'types/enumerationData';

interface IProps {
    status?: string;
    statusText?: string;
}

const StatusTag = ({
    status = '',
    statusText = '',
}: IProps) => {
    const [text, setText] = useState<string>();
    const [auditStatusOptions] = useGetSettingData(EnumFields.PROFILE_AUDIT_STATUS);

    useEffect(() => {
        if (statusText) {
            setText(statusText);
        }
    }, [statusText]);

    useEffect(() => {
        const auditStatus = auditStatusOptions?.find((item) => item.dictKey === status);

        if (auditStatus) {
            setText(auditStatus.content);
        }
    }, [auditStatusOptions, status]);

    const statusClass = useMemo(() => {
        let cls = '';
        switch (status?.toLowerCase()) {
            case 'follow-up':
                cls = s.followup;
                break;
            case 'pending':
                cls = s.pending;
                break;
            case 'rejected':
                cls = s.rejected;
                break;
            case 'approved':
                cls = s.approved;
                break;
            default: cls = '';
        }

        return `${s.status} ${cls}`;
    }, [status]);

    // const statText = useMemo(() => {
    //     let staText = '';
    //     switch (status.toLowerCase()) {
    //         case 'approved':
    //             staText = 'Approved';
    //             break;
    //         case 'rejected':
    //             staText = 'On-hold';
    //             break;
    //         // case 'follow':
    //         //     break;
    //         case 'pending':
    //             staText = 'Pending Review';
    //             break;
    //         case 'go-live':
    //             staText = 'Go-live';
    //             break;
    //         default: staText = '';
    //     }

    //     return staText;
    // }, [status]);
    return <div className={statusClass}>{text}</div>;
};

export default StatusTag;
