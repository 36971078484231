import { Event, ServerEvent } from 'types/calendar';

export const transferGoogleEventToServerEvent = (data:Event):ServerEvent => {
    return {
        start: data.start.dateTime,
        end: data.end.dateTime,
        eventId: data.id,
        colorId: data.channelId,
        description: data.description,
        email: data.attendees?.map((item) => item.email),
        location: data.location,
        summary: data.summary,
        timeZone: data.start.timeZone,
        providerId: data.providerId,
        channelId: data.channelId,
        serviceId: data.serviceId,
        duration: data.duration,
        meetingLink: data.meetingLink,
        patientName: data.patientName,
        patientPhone: data.patientPhone,
        sendForm: data.sendForm,
    };
};

export const transferServerEventToGoogleEvent = (data:ServerEvent):Event => {
    return {
        id: data.eventId,
        summary: data.summary,
        start: {
            timeZone: data.timeZone,
            dateTime: data.start,
        },
        end: {
            timeZone: data.timeZone,
            dateTime: data.end,
        },
        attendees: data.email?.map((email) => {
            return {
                email,
                displayName: email,
            };
        }),
        providerId: data.providerId,
        channelId: data.channelId,
        serviceId: data.serviceId,
        duration: data.duration,
        meetingLink: data.meetingLink,
        sendForm: data.sendForm,
        patientName: data.patientName,
        patientPhone: data.patientPhone,
        location: data.location,
        colorId: data.colorId,
        description: data.description,
    };
};

export const EventData = {
    transferGoogleEventToServerEvent,
    transferServerEventToGoogleEvent,
};
