import React from 'react';
import s from './s.module.less';
import { EditModuleEnum, IProviderStore, PracitceAddressForm } from 'types/practiceFront';
import {
    RightOutlined,
    LeftOutlined,
} from '@ant-design/icons';
import { EditLocalSEOInfoServer, LocalSEOInfo, LocalSEOInfoServer } from 'types/common';
import { getLocalSeoInfoByZipCode, providerRemoveSeo, providerAddSeo } from 'api/provider';
import { getLocalSeoInfoByZipCodeFromOps } from 'api/operation';
import { Button, message, Modal, Spin, Tabs } from 'antd';
import PracticeFrontPreviewStore from 'store/PracticeFrontPreview';
import useRefreshPreview from 'hooks/useRefreshPreview';
import Icon from './Sparkles.svg';
import { openSubscriptionModal } from 'utils/globalLayerControl';
import { isFreeUser, isFreeUserByInfo } from 'utils/provider';
import { EMetaType } from './types';
import MetaArea from './components/MetaArea';
import { EMetaTypeDesMap } from './constants';
import { getStateDisplayName, getLocalSEOInfoFromServerData } from 'utils/common';
import { EnumFields } from 'types/enumerationData';
import useGetSettingData from 'hooks/useGetSettingData';
import Remove from './removeIcon.svg';
import { TSubscriptionItemFromServer } from 'types/subscription';
import { PF_POI_AUTO_GEN_MAX_ACCOUNT } from 'constants/common';
import HomeStore from 'store/Home';

interface IProps {
    practiceAddress?: PracitceAddressForm[];
    refetch?: () => void;
    onSubmit?: (value: string, field: EditModuleEnum) => void;
    uniProfile?: IProviderStore;
    containSeoApp?: boolean,
    initFormValue?: Record<string, EditLocalSEOInfoServer>, //key is state
    isOps?: boolean;
    payPlan?: TSubscriptionItemFromServer, //only user for op
    providerId?: number,
    isEditing?: boolean,
}

const LocalSEO = (props: IProps) => {
    const [getHomeStore] = HomeStore.useStore();
    const homeInfo = getHomeStore('data');
    const { initFormValue, practiceAddress, onSubmit, refetch, uniProfile, containSeoApp, isOps, payPlan, providerId } = props;
    const [get, set] = PracticeFrontPreviewStore.useStore();
    const [loading, setLoading] = React.useState(false);
    const [isTring, setIsTring] = React.useState(false);
    const isFree = isOps ? isFreeUserByInfo(payPlan) : isFreeUser(homeInfo);
    const [setPreview] = useRefreshPreview();
    const initValueLength = initFormValue?.length || 0;
    const [currentTag, setCurrentTag] = React.useState<EMetaType>(EMetaType.LANDMARKS);
    const [seoInfoArray, setSeoInfoArray] = React.useState<LocalSEOInfo[]>([]);
    //use for pf preview
    const [previewSeoInfoArray, setPreviewSeoInfoArray] = React.useState<LocalSEOInfo[]>([]);
    const [currentState, setCurrentState] = React.useState<string | undefined>();
    const [stateData] = useGetSettingData(EnumFields.STATE);
    const [isEditing, setIsEditing] = React.useState(true);
    const [removing, setRemoving] = React.useState(false);
    const [showRemoveModal, setShowRemoveModal] = React.useState(false);
    const [hasRemoved, setHasRemoved] = React.useState(false);
    const loaded = isOps ? practiceAddress && practiceAddress.length > 0 : !!uniProfile?.email;
    const [editingAccount, setEditingAccount] = React.useState(0);

    const allApiPoiMap = React.useRef<Record<string, LocalSEOInfo>>({});
    const putInFrontApiPoiMap = React.useRef<Record<string, LocalSEOInfo>>({});

    const states:string[] = React.useMemo(() => {
        const ret:string[] = [];
        if (practiceAddress) {
            for (let i = 0; i < practiceAddress.length; i++) {
                const requestAddress = practiceAddress[i];
                const state = requestAddress?.practiceState;
                if (ret.indexOf(state) === -1) {
                    ret.push(state);
                }
            }
        }
        return ret;
    }, [practiceAddress]);

    const moreThanOneState = states.length > 1;

    const getTargetVal = (state:string, type:EMetaType):string[] => {
        //const serverTargetInfo = serverSeoInfoArray.find((item) => item.state === state);
        const targetInfo = seoInfoArray.find((item) => item.state === state);

        // if (serverTargetInfo && serverTargetInfo[type]) {
        //     return serverTargetInfo[type] || [];
        // }

        if (targetInfo && targetInfo[type]) {
            return targetInfo[type] || [];
        }

        return [];
    };

    const fetchCitys = async (addressList:PracitceAddressForm[]) => {
        // '' !== '[]'
        const serverSeoInfoArray:LocalSEOInfo[] = getLocalSEOInfoFromServerData(initFormValue);
        const newSeoInfoArray:LocalSEOInfo[] = [

        ];
        if (!addressList) {
            return;
        }
        for (let i = 0; i < addressList.length; i++) {
            const requestAddress = addressList[i];
            const state = requestAddress?.practiceState;
            let addSeoInfo:LocalSEOInfo | undefined = newSeoInfoArray.find((item) => item.state === state);
            if (!addSeoInfo) {
                addSeoInfo = {
                    state,
                    landmarks: [],
                    cities: [],
                    counties: [],
                    zip: [],
                };
                newSeoInfoArray.push(addSeoInfo);
            }
            allApiPoiMap.current[state] = {
                state,
                landmarks: [],
                cities: [],
                counties: [],
                zip: [],
            };
            putInFrontApiPoiMap.current[state] = {
                state,
                landmarks: [],
                cities: [],
                counties: [],
                zip: [],
            };
        }
        const notNeedSort = addressList.length === 1;
        for (let i = 0; i < addressList.length; i++) {
            const requestAddress = addressList[i];
            const state = requestAddress?.practiceState;
            const targetSeoInfo:LocalSEOInfo = newSeoInfoArray.find((item) => item.state === state)!;
            const address = `${requestAddress?.practiceAddress}, ${requestAddress?.practiceCity}, ${requestAddress?.practiceState}, ${requestAddress?.practiceZip}`;
            const zipCode = requestAddress?.practiceZip;
            if (!zipCode) {
                continue;
            }
            const fn = isOps ? getLocalSeoInfoByZipCodeFromOps : getLocalSeoInfoByZipCode;
            fn(zipCode).then((result) => {
                if (!result.error) {
                    const data:LocalSEOInfoServer = result.data?.data;
                    const targetServerSeoInfo:LocalSEOInfo | undefined = serverSeoInfoArray?.find((item) => item.state === targetSeoInfo.state);
                    if (data && data.city && data.city.length > 0) {
                        putInFrontApiPoiMap.current[state].cities?.push(data.city[0]);
                    }
                    if (data && data.landmark && data.landmark.length > 0) {
                        putInFrontApiPoiMap.current[state].landmarks?.push(data.landmark[0]);
                    }

                    if (data && data.country && data.country.length > 0) {
                        putInFrontApiPoiMap.current[state].counties?.push(data.country[0]);
                    }
                    if (data && data.zip && data.zip.length > 0) {
                        putInFrontApiPoiMap.current[state].zip?.push(data.zip[0]);
                    }

                    //cities
                    allApiPoiMap.current[state].cities = [...allApiPoiMap.current[state].cities || [], ...(data?.city || [])];
                    allApiPoiMap.current[state].cities?.sort((a, b) => {
                        if (!notNeedSort) {
                            return 0;
                        }
                        if (putInFrontApiPoiMap.current[state].cities?.includes(a)) {
                            return -1;
                        }
                        if (putInFrontApiPoiMap.current[state].cities?.includes(b)) {
                            return 1;
                        }
                        const strA = a.toLowerCase();
                        const strB = b.toLowerCase();
                        return strA.localeCompare(strB);
                    });
                    let cities = [...(allApiPoiMap.current[state].cities || [])];
                    cities = Array.from<string>(new Set(cities)).slice(0, PF_POI_AUTO_GEN_MAX_ACCOUNT);
                    if (targetServerSeoInfo && targetServerSeoInfo?.cities) {
                        cities = [...targetServerSeoInfo?.cities || []];
                        cities = Array.from<string>(new Set(cities));
                    }

                    //landmarks
                    allApiPoiMap.current[state].landmarks = [...allApiPoiMap.current[state].landmarks || [], ...(data?.landmark || [])];
                    allApiPoiMap.current[state].landmarks?.sort((a, b) => {
                        if (!notNeedSort) {
                            return 0;
                        }
                        if (putInFrontApiPoiMap.current[state].landmarks?.includes(a)) {
                            return -1;
                        }
                        if (putInFrontApiPoiMap.current[state].landmarks?.includes(b)) {
                            return 1;
                        }

                        const strA = a.toLowerCase();
                        const strB = b.toLowerCase();
                        return strA.localeCompare(strB);
                    });

                    let landmarks = [...(allApiPoiMap.current[state].landmarks || [])];
                    landmarks = Array.from<string>(new Set(landmarks)).slice(0, PF_POI_AUTO_GEN_MAX_ACCOUNT);
                    if (targetServerSeoInfo && targetServerSeoInfo?.landmarks) {
                        landmarks = [...targetServerSeoInfo?.landmarks || []];
                        landmarks = Array.from<string>(new Set(landmarks));
                    }

                    //counties
                    allApiPoiMap.current[state].counties = [...allApiPoiMap.current[state].counties || [], ...(data?.country || [])];
                    allApiPoiMap.current[state].counties?.sort((a, b) => {
                        if (!notNeedSort) {
                            return 0;
                        }

                        if (putInFrontApiPoiMap.current[state].counties?.includes(a)) {
                            return -1;
                        }
                        if (putInFrontApiPoiMap.current[state].counties?.includes(b)) {
                            return 1;
                        }

                        const strA = a.toLowerCase();
                        const strB = b.toLowerCase();
                        return strA.localeCompare(strB);
                    });

                    let counties = [...(allApiPoiMap.current[state].counties || [])];
                    counties = Array.from<string>(new Set(counties)).slice(0, PF_POI_AUTO_GEN_MAX_ACCOUNT);
                    if (targetServerSeoInfo && targetServerSeoInfo?.counties) {
                        counties = [...targetServerSeoInfo?.counties || []];
                        counties = Array.from<string>(new Set(counties));
                    }

                    //zip
                    allApiPoiMap.current[state].zip = [...allApiPoiMap.current[state].zip || [], ...(data?.zip || [])];
                    allApiPoiMap.current[state].zip?.sort((a, b) => {
                        if (!notNeedSort) {
                            return 0;
                        }
                        if (putInFrontApiPoiMap.current[state].zip?.includes(a)) {
                            return -1;
                        }
                        if (putInFrontApiPoiMap.current[state].zip?.includes(b)) {
                            return 1;
                        }

                        const strA = a.toLowerCase();
                        const strB = b.toLowerCase();
                        return strA.localeCompare(strB);
                    });
                    let zip = [...(allApiPoiMap.current[state].zip || [])];
                    zip = Array.from<string>(new Set(zip)).slice(0, PF_POI_AUTO_GEN_MAX_ACCOUNT);
                    if (targetServerSeoInfo && targetServerSeoInfo?.zip) {
                        zip = [...targetServerSeoInfo?.zip || []];
                        zip = Array.from<string>(new Set(zip));
                    }

                    targetSeoInfo.cities = cities;
                    targetSeoInfo.landmarks = landmarks;
                    targetSeoInfo.counties = counties;
                    targetSeoInfo.zip = zip;

                    try {
                        setSeoInfoArray(JSON.parse(JSON.stringify(newSeoInfoArray)));
                        setPreviewSeoInfoArray(JSON.parse(JSON.stringify(newSeoInfoArray)));
                    } catch (e) {
                        console.error(e);
                    }
                }
            });
        }
    };
    React.useEffect(() => {
        if (loaded) {
            fetchCitys(practiceAddress || []);
        }
    }, [loaded, initFormValue]);

    React.useEffect(() => {
        if (containSeoApp === true || containSeoApp === false) {
            setHasRemoved(!containSeoApp);
        }
    }, [containSeoApp]);

    const sentMessage = (isEdit:boolean, value:LocalSEOInfo[]) => {
        // if (!isFree) {
        //     return;
        // }
        set('showLocalSEO', isEdit);
        set('previewLocalSEO', value);
    };

    const handleRemove = async () => {
        setRemoving(true);
        const result = await providerRemoveSeo();
        if (!result.error) {
            message.info('Local SEO removed successfully');
            setShowRemoveModal(false);
            setHasRemoved(true);
            refetch?.();
            setPreview('flag', {});
        }
        setRemoving(false);
    };
    const handleAdd = async () => {
        setRemoving(true);
        const result = await providerAddSeo();
        if (!result.error) {
            message.info('Local SEO added successfully');
            setShowRemoveModal(false);
            setHasRemoved(false);
            refetch?.();
            setPreview('flag', {});
        }
        setRemoving(false);
    };

    if (states.length === 0) {
        return null;
    }

    const hideModule = (!isFree && hasRemoved) || (isFree && !isTring);

    const onEditStatusChange = (status: boolean) => {
        let newEditingAccount = editingAccount;
        if (status === true) {
            newEditingAccount += 1;
        } else {
            newEditingAccount -= 1;
        }
        const newIsEditing = newEditingAccount > 0;
        setEditingAccount(newEditingAccount);
        setIsEditing(newIsEditing);
        set('showLocalSEO', newIsEditing);
    };

    return (
        <div className={isOps ? `${s.wrap} ${s.opWrap}` : s.wrap}>
            <div className={s.header}>
                <div className={s.title}>
                    <h3>LocalSEO+ <span className={s.new}>New</span></h3>
                    {isFree && <div className={s.paid}>Paid-users only</div>}
                </div>
                {
                    !isFree && !hasRemoved && loaded &&
                    <Button
                        onClick={() => {
                            setShowRemoveModal(true);
                        }}
                        className={s.try}
                    >
                        Remove app
                    </Button>
                }
                {
                    !isFree && hasRemoved && loaded &&
                    <Button
                        onClick={() => {
                            handleAdd();
                        }}
                        className={s.try}
                    >
                        Add app
                    </Button>
                }
                {
                    isFree && !isTring && loaded &&
                    <Button
                        onClick={() => {
                            setIsTring(true);
                            const current = [...previewSeoInfoArray];
                            setIsEditing(true);
                            sentMessage(true, current);
                        }}
                        className={s.try}
                    >
                        Try
                    </Button>
                }
            </div>
            <div className={s.tips}>
                <div className={s.title}>
                    <img src={Icon} />
                    <div>
                        Having a local SEO section on your Practice Front can significantly improve your online visibility by targeting relevant local searches. It helps more patients find your healthcare practice when they search "healthcare providers near me" on channels like Google, Bing, Apple Map, Opendoor, etc. This proven technology will help you increase appointment and revenue without expensive advertising.
                    </div>
                </div>
                {isFree && (
                    <div
                        className={s.btn}
                        onClick={() => {
                            openSubscriptionModal();
                        }}
                    >
                        Upgrade plan
                    </div>
                )}
            </div>
            {
                loading && (
                    <div className={s.spin}><Spin spinning /></div>
                )
            }
            {
                (!moreThanOneState || currentState) && !hideModule && (
                    <div className={s.saWrap}>
                        {
                            moreThanOneState && (
                                <div className={s.mulHeader}>
                                    <div
                                        onClick={() => setCurrentState(undefined)}
                                        className={s.back}
                                    ><LeftOutlined /> Back
                                    </div>
                                    <h4>Local Seo for the state of {getStateDisplayName(currentState, stateData)} </h4>
                                </div>
                            )
                        }
                        <Tabs
                            defaultActiveKey="1"
                            activeKey={currentTag}
                            onChange={(key) => {
                                setCurrentTag(key as EMetaType);
                            }}
                            items={[
                                {
                                    label: <span className={s.tabLabel}>{EMetaTypeDesMap[EMetaType.LANDMARKS]}</span>,
                                    key: EMetaType.LANDMARKS,
                                    children: <MetaArea
                                        values={getTargetVal(currentState || states[0], EMetaType.LANDMARKS)}
                                        type={EMetaType.LANDMARKS}
                                        state={currentState || states[0]}
                                        onUpdated={(newVal:string[]) => {
                                            const state = currentState || states[0];
                                            const targetInfo = seoInfoArray?.find((item) => item.state === state);
                                            if (targetInfo) {
                                                targetInfo[EMetaType.LANDMARKS] = newVal;
                                            }
                                        }}
                                        onPendingUpdate={(val:string[]) => {
                                            const current = [...previewSeoInfoArray].slice();
                                            const target = current.find((item) => item.state === (currentState || states[0]));
                                            if (target) {
                                                target[EMetaType.LANDMARKS] = val;
                                            }
                                            sentMessage(isEditing, current);
                                        }}
                                        providerId={providerId}
                                        isOps={isOps}
                                        onEditStatusChange={onEditStatusChange}
                                    />,
                                },
                                {
                                    label: <span className={s.tabLabel}>{EMetaTypeDesMap[EMetaType.CITIES]}</span>,
                                    key: EMetaType.CITIES,
                                    children: <MetaArea
                                        values={getTargetVal(currentState || states[0], EMetaType.CITIES)}
                                        type={EMetaType.CITIES}
                                        state={currentState || states[0]}
                                        onUpdated={(newVal:string[]) => {
                                            const state = currentState || states[0];
                                            const targetInfo = seoInfoArray?.find((item) => item.state === state);
                                            if (targetInfo) {
                                                targetInfo[EMetaType.CITIES] = newVal;
                                            }
                                        }}
                                        onPendingUpdate={(val:string[]) => {
                                            const current = [...previewSeoInfoArray];
                                            const target = current.find((item) => item.state === (currentState || states[0]));
                                            if (target) {
                                                target[EMetaType.CITIES] = val;
                                            }
                                            sentMessage(isEditing, current);
                                        }}
                                        providerId={providerId}
                                        isOps={isOps}
                                        onEditStatusChange={onEditStatusChange}
                                    />,
                                },
                                {
                                    label: <span className={s.tabLabel}>{EMetaTypeDesMap[EMetaType.COUNTIES]}</span>,
                                    key: EMetaType.COUNTIES,
                                    children: <MetaArea
                                        values={getTargetVal(currentState || states[0], EMetaType.COUNTIES)}
                                        type={EMetaType.COUNTIES}
                                        state={currentState || states[0]}
                                        onUpdated={(newVal:string[]) => {
                                            //onSubmit?.(val, EditModuleEnum.LOCAL_SEO);
                                            const state = currentState || states[0];
                                            const targetInfo = seoInfoArray?.find((item) => item.state === state);
                                            if (targetInfo) {
                                                targetInfo[EMetaType.COUNTIES] = newVal;
                                            }
                                        }}
                                        onPendingUpdate={(val:string[]) => {
                                            const current = [...previewSeoInfoArray];
                                            const target = current.find((item) => item.state === (currentState || states[0]));
                                            if (target) {
                                                target[EMetaType.COUNTIES] = val;
                                            }
                                            sentMessage(isEditing, current);
                                        }}
                                        providerId={providerId}
                                        isOps={isOps}
                                        onEditStatusChange={onEditStatusChange}
                                    />,
                                },
                                {
                                    label: <span className={s.tabLabel}>{EMetaTypeDesMap[EMetaType.ZIP]}</span>,
                                    key: EMetaType.ZIP,
                                    children: <MetaArea
                                        values={getTargetVal(currentState || states[0], EMetaType.ZIP)}
                                        type={EMetaType.ZIP}
                                        state={currentState || states[0]}
                                        onUpdated={(newVal:string[]) => {
                                            //onSubmit?.(val, EditModuleEnum.LOCAL_SEO);
                                            const state = currentState || states[0];
                                            const targetInfo = seoInfoArray?.find((item) => item.state === state);
                                            if (targetInfo) {
                                                targetInfo[EMetaType.ZIP] = newVal;
                                            }
                                        }}
                                        onPendingUpdate={(val:string[]) => {
                                            const current = [...previewSeoInfoArray];
                                            const target = current.find((item) => item.state === (currentState || states[0]));
                                            if (target) {
                                                target[EMetaType.ZIP] = val;
                                            }
                                            sentMessage(isEditing, current);
                                        }}
                                        providerId={providerId}
                                        isOps={isOps}
                                        onEditStatusChange={onEditStatusChange}
                                    />,
                                },
                            ]}
                        />
                    </div>
                )
            }
            {
                moreThanOneState && !currentState && !hideModule &&
                <div className={s.stateList}>
                    {
                        states.map((state) => {
                            return (
                                <div
                                    onClick={() => {
                                        setCurrentState(state);
                                    }}
                                    className={s.stateItem}
                                    key={getStateDisplayName(state, stateData)}
                                >{state} <RightOutlined />
                                </div>
                            );
                        })
                    }
                </div>
            }
            <Modal
                width="512px"
                className={s.modal}
                footer={null}
                title={null}
                open={showRemoveModal}
                onCancel={() => {
                    setShowRemoveModal(false);
                }}
            >
                <div className={s.removeWrap}>
                    <div className={s.left}>
                        <img src={Remove} />
                    </div>
                    <div className={s.right}>
                        <h2>Remove LocalSEO+ app?</h2>
                        <div className={s.des}>
                            Are you sure you want to remove the LocalSEO+ app from your Practice Front? This could have a significant impact on your visibility and patient reach, making it harder for patients to find you both online and physically.
                        </div>
                        <div className={s.btns}>
                            <Button
                                onClick={() => {
                                    setShowRemoveModal(false);
                                }}
                                style={{ marginRight: 12 }}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleRemove}
                                type="primary"
                                danger
                                loading={removing}
                            >
                                Remove
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default LocalSEO;
