import React from 'react';
import { Form, Button, Input, Spin, message, InputNumber } from 'antd';
import { FormInstance } from 'antd/lib/form';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import FormValidationHelper from 'utils/validation';
import { providerBasicInfoUpdate } from 'api/provider';
import { opUpdateproviderBasicInfo } from 'api/operation';
import { ProfileFormFieldEnum, ProfileFormInterface, ProviderBasicInfo } from 'types/provider';
import CheckGroupWithInput from 'components/form/CheckGroupWithInput';
import useSettings from 'hooks/useSettings';
import { EnumFields } from 'types/enumerationData';
import { OTHERS_KEY } from 'constants/meta';

type Props = {
    editable:boolean,
    initValue?: Partial<ProfileFormInterface>,
    showSaveButton?:boolean,
    providerId?:number,
    viewOnly?:boolean,
    onChange?: (value:Partial<ProfileFormInterface>)=>void,
    onNullState?: ()=>void,
    isOperation?:boolean,
    form: FormInstance<ProfileFormInterface>,
};

const PracticeExperiencePanel = (props: Props) => {
    const { editable = true, form, viewOnly, showSaveButton, initValue, providerId, onChange, onNullState, isOperation } = props;
    const [enable, setEnable] = React.useState(!!editable);
    const [submiting, setSubmiting] = React.useState(false);
    const [,forceUpdate] = React.useState({});
    const fn = isOperation ? opUpdateproviderBasicInfo : providerBasicInfoUpdate;
    const [settings, loadingSettings] = useSettings();
    const supportPracticeExp = true;
    const specialitiesInPsychiatricMentalHealth = settings[EnumFields.SIPMH] || [];
    const specialitiesInPsychiatricMentalHealthOptions = [...specialitiesInPsychiatricMentalHealth, { label: 'Other, not listed here', value: OTHERS_KEY }];

    const specialitiesInFamily = settings[EnumFields.SIFAP] || [];
    const specialitiesInFamilyOptions = [...specialitiesInFamily, { label: 'Other, not listed here', value: OTHERS_KEY }];

    const [hasSetSpecialitiesInPsychiatric, setHasSetSpecialitiesInPsychiatric] = React.useState(!!(form.getFieldValue(ProfileFormFieldEnum.SPECIALITYINHEALTH)));
    const [hasSetSpecialitiesInFamily, setHasSetSpecialitiesInFamily] = React.useState(!!(form.getFieldValue(ProfileFormFieldEnum.SPECIALITYINFAMILY)));

    const onSubmit = async () => {
        try {
            setSubmiting(true);
            const formValues = await form.validateFields();
            setSubmiting(true);
            const basicData:Partial<ProviderBasicInfo> = {
                address: formValues[ProfileFormFieldEnum.MAILING_ADDRESS],
                specialityInFamily: formValues[ProfileFormFieldEnum.SPECIALITYINFAMILY]?.join(','),
                specialityInHealth: formValues[ProfileFormFieldEnum.SPECIALITYINHEALTH]?.join(','),
                npi: formValues[ProfileFormFieldEnum.NPI_NUMBER],
                state: formValues[ProfileFormFieldEnum.STATE],
                yearExp: formValues[ProfileFormFieldEnum.YEARS_OF_EXPERIENCE],
                zip: formValues[ProfileFormFieldEnum.ZIP_CODE],
                city: formValues[ProfileFormFieldEnum.CITY],
                tel: formValues[ProfileFormFieldEnum.TEL],
            };
            const result = await fn(basicData, providerId!);
            if (!result.error) {
                message.success('Practice experience info has updated');
                setEnable(false);
            } else {
                message.error(result.error);
            }
        } catch (e:any) {
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };

    React.useEffect(() => {
        if (initValue) {
            form.setFieldsValue(initValue);
            setHasSetSpecialitiesInFamily(!!initValue?.specialityInFamily);
            setHasSetSpecialitiesInPsychiatric(!!initValue?.specialityInHealth);
            forceUpdate({});
        }
    }, []);

    return (
        <div className={s.wrap}>
            <Form
                form={form}
                name="basic"
                className={commonS.formStyle1}
                initialValues={{ remember: true }}
                autoComplete="off"
                layout="vertical"
            >
                <Spin spinning={submiting}>
                    <div className={s.module}>
                        <h3><span>Practice Experience</span>
                            {!enable && !viewOnly &&
                            <Button
                                size="small"
                                onClick={() => {
                                    setEnable(true);
                                }}
                            >
                                Edit
                            </Button>}
                        </h3>
                        {
                            enable &&
                            <>
                                <div className={s.row}>
                                    <div className={s.rowItem}>
                                        <Form.Item
                                            style={{ width: '360px' }}
                                            label="NPI number"
                                            name={ProfileFormFieldEnum.NPI_NUMBER}
                                            rules={[
                                                { required: true, message: 'NPI number is required' },
                                                FormValidationHelper.validateLicenseNumber('The NPI must contain only letters or numbers'),
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </div>
                                    <div className={s.rowItem}>
                                        <Form.Item
                                            style={{ width: '360px' }}
                                            label="Years of experience in healthcare"
                                            name={ProfileFormFieldEnum.YEARS_OF_EXPERIENCE}
                                            rules={[{ required: true, message: 'Years of experience is required' }]}
                                        >
                                            <InputNumber controls={false} min={0} />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className={s.row}>
                                    <div className={s.colItem}>
                                        <Form.Item
                                            style={{ width: '360px' }}
                                            label="Select all your specialties in Psychiatry/Mental Health"
                                            name={ProfileFormFieldEnum.SPECIALITYINHEALTH}
                                            dependencies={[ProfileFormFieldEnum.SPECIALITYINFAMILY]}
                                            shouldUpdate
                                            rules={[{ required: (!hasSetSpecialitiesInPsychiatric && !hasSetSpecialitiesInFamily), message: 'Specialties in Psychiatry or Primary Care are required' }]}
                                        >
                                            <CheckGroupWithInput
                                                onChange={(val:unknown[]) => {
                                                    if (!val || val.length === 0) {
                                                        setHasSetSpecialitiesInPsychiatric(false);
                                                    } else {
                                                        setHasSetSpecialitiesInPsychiatric(true);
                                                    }
                                                    form.validateFields([ProfileFormFieldEnum.SPECIALITYINFAMILY]);
                                                }}
                                                options={specialitiesInPsychiatricMentalHealthOptions}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className={s.row}>
                                    <div className={s.colItem}>
                                        <Form.Item
                                            style={{ width: '360px' }}
                                            label="Select all your specialties in Primary Care"
                                            name={ProfileFormFieldEnum.SPECIALITYINFAMILY}
                                            dependencies={[ProfileFormFieldEnum.SPECIALITYINHEALTH]}
                                            shouldUpdate
                                            rules={[{ required: (!hasSetSpecialitiesInPsychiatric && !hasSetSpecialitiesInFamily), message: 'Specialties in Psychiatry or Primary Care are required ' }]}
                                        >
                                            <CheckGroupWithInput
                                                onChange={(val:unknown[]) => {
                                                    if (!val || val.length === 0) {
                                                        setHasSetSpecialitiesInFamily(false);
                                                    } else {
                                                        setHasSetSpecialitiesInFamily(true);
                                                    }
                                                    form.validateFields([ProfileFormFieldEnum.SPECIALITYINHEALTH]);
                                                }}
                                                options={specialitiesInFamilyOptions}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                {showSaveButton &&
                                <div className={s.buttonWrap}>
                                    <Button
                                        className={commonS.lightBtn}
                                        size="small"
                                        onClick={onSubmit}
                                    >
                                        Save
                                    </Button>
                                </div>
                                }
                            </>
                        }
                        {
                            !enable &&
                            <>
                                <div className={s.row}>
                                    <div
                                        style={{
                                            width: '300px',
                                        }}
                                        className={s.displayItem}
                                    >
                                        <div className={s.label}>NPI number</div>
                                        <div className={s.value}>{ form.getFieldValue('npi')}</div>
                                    </div>
                                    <div
                                        style={{
                                            width: '360px',
                                        }}
                                        className={s.displayItem}
                                    >
                                        <div className={s.label}>Number of Years in Healthcare</div>
                                        <div className={s.value}>{ form.getFieldValue('yearExp')}</div>
                                    </div>
                                </div>
                                <div className={s.row}>
                                    <div
                                        style={{
                                            width: '80%',
                                        }}
                                        className={s.displayItem}
                                    >
                                        <div className={s.label}>Your specialities in psychiatric/mental health</div>
                                        <div className={s.value}>{ form.getFieldValue('specialityInHealth')?.join(', ')}</div>
                                    </div>
                                </div>
                                <div className={s.row}>
                                    <div
                                        style={{
                                            width: '80%',
                                        }}
                                        className={s.displayItem}
                                    >
                                        <div className={s.label}>Your specialities in family practice</div>
                                        <div className={s.value}>{ form.getFieldValue('specialityInFamily')?.join(', ')}</div>
                                    </div>
                                </div>
                            </>
                        }

                    </div>
                </Spin>
            </Form>
        </div>
    );
};

export default PracticeExperiencePanel;
