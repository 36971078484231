import React from 'react';
import { Form, Button, Input, Spin, message } from 'antd';
import { FormInstance } from 'antd/lib/form';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import StateSelect from 'components/form/StateSelect';
import { providerBasicInfoUpdate } from 'api/provider';
import { opUpdateproviderBasicInfo } from 'api/operation';
import { ProfileFormFieldEnum, ProfileFormInterface, ProviderBasicInfo } from 'types/provider';

type Props = {
    editable:boolean,
    initValue?: Partial<ProfileFormInterface>,
    showSaveButton?:boolean,
    providerId?:number,
    viewOnly?:boolean,
    onChange?: (value:Partial<ProfileFormInterface>)=>void,
    onNullState?: ()=>void,
    isOperation?:boolean,
    form: FormInstance<ProfileFormInterface>,
};

const ProfilePanel = (props: Props) => {
    const { editable = true, form, viewOnly, showSaveButton, initValue, providerId, onChange, onNullState, isOperation } = props;
    const [enable, setEnable] = React.useState(!!editable);
    const [submiting, setSubmiting] = React.useState(false);
    const [,forceUpdate] = React.useState({});
    const fn = isOperation ? opUpdateproviderBasicInfo : providerBasicInfoUpdate;

    const onSubmit = async () => {
        try {
            setSubmiting(true);
            const formValues = await form.validateFields();
            setSubmiting(true);
            const basicData:Partial<ProviderBasicInfo> = {
                address: formValues[ProfileFormFieldEnum.MAILING_ADDRESS],
                specialityInFamily: formValues[ProfileFormFieldEnum.SPECIALITYINFAMILY]?.join(','),
                specialityInHealth: formValues[ProfileFormFieldEnum.SPECIALITYINHEALTH]?.join(','),
                npi: formValues[ProfileFormFieldEnum.NPI_NUMBER],
                state: formValues[ProfileFormFieldEnum.STATE],
                yearExp: formValues[ProfileFormFieldEnum.YEARS_OF_EXPERIENCE],
                zip: formValues[ProfileFormFieldEnum.ZIP_CODE],
                city: formValues[ProfileFormFieldEnum.CITY],
                tel: formValues[ProfileFormFieldEnum.TEL],
            };
            const result = await fn(basicData, providerId!);
            if (!result.error) {
                message.success('Basic profile info has updated');
                setEnable(false);
            } else {
                message.error(result.error);
            }
        } catch (e:any) {
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };

    React.useEffect(() => {
        if (initValue) {
            form.setFieldsValue(initValue);
            forceUpdate({});
        }
    }, []);

    return (
        <div className={s.wrap}>
            <Form
                form={form}
                name="basic"
                className={commonS.formStyle1}
                initialValues={{ remember: true }}
                autoComplete="off"
                layout="vertical"
            >
                <Spin spinning={submiting}>
                    <div className={s.module}>
                        <h3><span>Personal Information</span>
                            {!enable && !viewOnly &&
                            <Button
                                size="small"
                                onClick={() => {
                                    setEnable(true);
                                }}
                            >
                                Edit
                            </Button>}
                        </h3>
                        {
                            enable &&
                            <>
                                <div className={s.row}>
                                    <div className={s.rowItem}>
                                        <Form.Item
                                            style={{ width: '280px' }}
                                            label="Email"
                                            name={ProfileFormFieldEnum.EMAIL}
                                            rules={[{ required: true, message: 'Email is required' }]}
                                        >
                                            <Input disabled />
                                        </Form.Item>
                                    </div>
                                    <div className={s.rowItem}>
                                        <Form.Item
                                            style={{ width: '280px' }}
                                            label="Personal phone number"
                                            name={ProfileFormFieldEnum.TEL}
                                            rules={[{ required: true, message: 'Phone is required' }]}
                                        >
                                            <Input maxLength={30} />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className={s.row}>
                                    <div className={s.rowItem}>
                                        <Form.Item
                                            style={{ width: '360px' }}
                                            label="Mailing address"
                                            name={ProfileFormFieldEnum.MAILING_ADDRESS}
                                            rules={[{ required: true, message: 'Mailing address is required' }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </div>
                                    <div className={s.rowItem}>
                                        <Form.Item
                                            style={{ width: '120px' }}
                                            label="City"
                                            name={ProfileFormFieldEnum.CITY}
                                            rules={[{ required: true, message: 'City is required' }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </div>
                                    <div className={s.rowItem}>
                                        <Form.Item
                                            style={{ width: '160px' }}
                                            label="State"
                                            name={ProfileFormFieldEnum.STATE}
                                            rules={[{ required: true, message: 'State is required' }]}
                                        >
                                            <StateSelect />
                                        </Form.Item>
                                    </div>
                                    <div className={s.rowItem}>
                                        <Form.Item
                                            style={{ width: '160px' }}
                                            label="ZIP code"
                                            name={ProfileFormFieldEnum.ZIP_CODE}
                                            rules={[{ required: true, message: 'ZIP code is required' }]}
                                        >
                                            <Input type="number" />
                                        </Form.Item>
                                    </div>
                                </div>
                                {showSaveButton &&
                                <div className={s.buttonWrap}>
                                    <Button
                                        className={commonS.lightBtn}
                                        size="small"
                                        onClick={onSubmit}
                                    >
                                        Save
                                    </Button>
                                </div>
                                }
                            </>
                        }
                        {
                            !enable &&
                            <>
                                <div className={s.row}>
                                    <div
                                        style={{
                                            width: '300px',
                                        }}
                                        className={s.displayItem}
                                    >
                                        <div className={s.label}>Personal email</div>
                                        <div className={s.value}>{ form.getFieldValue('email')}</div>
                                    </div>
                                    <div className={s.displayItem}>
                                        <div className={s.label}>Personal phone number</div>
                                        <div className={s.value}>{ form.getFieldValue('tel')}</div>
                                    </div>
                                </div>
                                {
                                    isOperation &&
                                    <div className={s.row}>
                                        <div
                                            style={{
                                                width: '300px',
                                            }}
                                            className={s.displayItem}
                                        >
                                            <div className={s.label}>Kiwi account</div>
                                            <div className={s.value}>{ form.getFieldValue('gmail')}</div>
                                        </div>
                                    </div>
                                }

                                <div className={s.row}>
                                    <div
                                        style={{
                                            width: '300px',
                                        }}
                                        className={s.displayItem}
                                    >
                                        <div className={s.label}>Mailing Address</div>
                                        <div className={s.value}>{ form.getFieldValue('address')}</div>
                                    </div>
                                    <div
                                        style={{
                                            width: '120px',
                                        }}
                                        className={s.displayItem}
                                    >
                                        <div className={s.label}>City</div>
                                        <div className={s.value}>{ form.getFieldValue('city') || 'null'}</div>
                                    </div>
                                    <div
                                        style={{
                                            width: '300px',
                                        }}
                                        className={s.displayItem}
                                    >
                                        <div className={s.label}>State</div>
                                        <div className={s.value}>{ form.getFieldValue('state')}</div>
                                    </div>
                                    <div
                                        style={{
                                            width: '300px',
                                        }}
                                        className={s.displayItem}
                                    >
                                        <div className={s.label}>ZIP code</div>
                                        <div className={s.value}>{ form.getFieldValue('zip')}</div>
                                    </div>
                                </div>
                            </>
                        }

                    </div>
                </Spin>
            </Form>
        </div>
    );
};

export default ProfilePanel;
