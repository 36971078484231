// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--MHovW {
  position: relative;
}
.s-module__wrap--MHovW img {
  padding: 2px;
}
.s-module__unStarWrap--dfV4f {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  justify-content: space-between;
}
.s-module__unStarItem--QVyuA {
  width: 20%;
}
.s-module__staredWrap--RAKtx {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  z-index: 2;
  margin-top: -2px;
  transition: all linear 200ms;
}
.s-module__staredWrap--RAKtx img {
  max-width: 9999px;
}
.s-module__staredItem--pCpgI {
  /* width: 20%; */
}
.s-module__clickAreaWrap--pxjGL {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  z-index: 3;
}
.s-module__clickItem--Ruwg6 {
  height: 100%;
  display: inline-block;
}
.s-module__clearAreaWrap--zwXFR {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 4;
}
`, "",{"version":3,"sources":["webpack://./src/components/StarReview/s.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAFA;EAII,YAAA;AACJ;AAGA;EACE,kBAAA;EACA,OAAA;EACA,MAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,UAAA;EACA,8BAAA;AADF;AAIA;EACE,UAAA;AAFF;AAKA;EACE,kBAAA;EACA,OAAA;EACA,MAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,UAAA;EACA,gBAAA;EACA,4BAAA;AAHF;AARA;EAcI,iBAAA;AAHJ;AAOA;EALE,gBAAgB;AAClB;AAQA;EACE,kBAAA;EACA,OAAA;EACA,MAAA;EACA,SAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,wBAAA;EACA,mBAAA;EACA,UAAA;AANF;AASA;EACE,YAAA;EACA,qBAAA;AAPF;AAUA;EACE,kBAAA;EACA,OAAA;EACA,MAAA;EACA,SAAA;EACA,UAAA;AARF","sourcesContent":[".wrap {\n  position: relative;\n\n  img {\n    padding: 2px;\n  }\n}\n\n.unStarWrap {\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  z-index: 1;\n  justify-content: space-between;\n}\n\n.unStarItem {\n  width: 20%;\n}\n\n.staredWrap {\n  position: absolute;\n  left: 0;\n  top: 0;\n  height: 100%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  overflow: hidden;\n  z-index: 2;\n  margin-top: -2px;\n  transition: all linear 200ms;\n\n  img {\n    max-width: 9999px;\n  }\n}\n\n.staredItem {\n  /* width: 20%; */\n}\n\n.clickAreaWrap {\n  position: absolute;\n  left: 0;\n  top: 0;\n  bottom: 0;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: stretch;\n  align-items: center;\n  z-index: 3;\n}\n\n.clickItem {\n  height: 100%;\n  display: inline-block;\n}\n\n.clearAreaWrap {\n  position: absolute;\n  left: 0;\n  top: 0;\n  bottom: 0;\n  z-index: 4;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--MHovW`,
	"unStarWrap": `s-module__unStarWrap--dfV4f`,
	"unStarItem": `s-module__unStarItem--QVyuA`,
	"staredWrap": `s-module__staredWrap--RAKtx`,
	"staredItem": `s-module__staredItem--pCpgI`,
	"clickAreaWrap": `s-module__clickAreaWrap--pxjGL`,
	"clickItem": `s-module__clickItem--Ruwg6`,
	"clearAreaWrap": `s-module__clearAreaWrap--zwXFR`
};
export default ___CSS_LOADER_EXPORT___;
