import React, { useCallback, useEffect, useMemo, useState } from 'react';
import s from './s.module.less';
import { Button, Modal, message } from 'antd';
import { EditModuleEnum, FormFields, IProviderStore, IService, PracitceAddressForm } from 'types/practiceFront';
import ProviderStore from 'store/PracticeFront/provider';
import EditStatusStore from 'store/PracticeFront/editStatus';
import remove from 'lodash/remove';
import ServiceItem from './components/ServiceItem';
import { providerServiceDelete } from 'api/appointmentSetting';
import ErrorBlock from '../ErrorBlock';
import useRefreshPreview from 'hooks/useRefreshPreview';
import { transAddressDataToServiceOptions } from 'utils/common';

interface IProps {
    addressList: Array<PracitceAddressForm>;
    showEditingTip?:boolean;
    initFormValue: Array<IService>;
    onSubmit: (value: IService, field: EditModuleEnum, callback: () => void) => void;
    onEditStatusChange: (status: boolean, field: EditModuleEnum) => void;
    refetch: () => void;
    scrollToTheEditingModule: () => void;
    setShowEditingTip?: (status: boolean) => void;
}

const Service = ({
    addressList = [],
    scrollToTheEditingModule,
    showEditingTip,
    initFormValue,
    onSubmit,
    onEditStatusChange,
    refetch,
    setShowEditingTip,
}: IProps) => {
    const [getProvider, setProvider] = ProviderStore.useStore();
    const [getEditStatus] = EditStatusStore.useStore();
    const editStatusMap = getEditStatus('data');
    const gloablEditStatus = useMemo(() => !editStatusMap || !!Object.values(editStatusMap).find((e) => e), [editStatusMap]);
    const [, contextHolder] = message.useMessage();
    const [currentEditingIndex, setCurrentEditingIndex] = useState(-1);
    const [currentId, setCurrentId] = useState<number | undefined>();
    const [currentRemoveIndex, setCurrentRemoveIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    const [setPreview] = useRefreshPreview();
    const [addressOptions, setAddressOptions] = useState([]);

    useEffect(() => {
        if (addressList?.length > 0) {
            const addressResp = transAddressDataToServiceOptions(addressList);

            setAddressOptions(addressResp);
        }
    }, [addressList]);

    const providerData = getProvider('data') as IProviderStore;

    const handleEditStatusChange = useCallback((status: boolean, index: number) => {
        if (gloablEditStatus && status) {
            scrollToTheEditingModule?.();
            setShowEditingTip?.(true);
            message.error('Please save your changes before proceeding');
        } else {
            setShowEditingTip?.(false);
            setCurrentEditingIndex(status ? index : -1);
            onEditStatusChange(status, EditModuleEnum.SERVICE);
        }
    }, [gloablEditStatus, onEditStatusChange]);

    const submit = useCallback(async (values: IService, id?: number) => {
        onSubmit({
            ...values,
            id,
        }, EditModuleEnum.SERVICE, () => setCurrentEditingIndex(-1));
    }, [onSubmit]);

    const handleShowModal = useCallback((index: number, id?: number) => {
        if (gloablEditStatus) {
            scrollToTheEditingModule?.();
            setShowEditingTip?.(true);
            message.error('Please save your changes before proceeding');
        } else {
            setShowEditingTip?.(false);
            setCurrentId(id);
            setCurrentRemoveIndex(index);
            setOpen(true);
        }
    }, [gloablEditStatus, scrollToTheEditingModule, setShowEditingTip]);

    const handleHideModal = useCallback(() => {
        setCurrentId(undefined);
        setOpen(false);
    }, []);

    const handleRemove = useCallback(async () => {
        if (currentId) {
            const res = await providerServiceDelete(currentId);

            if (res.error) {
                message.error(res.error);
            } else {
                setPreview('flag', {});
                setOpen(false);
                refetch();
            }
        } else {
            remove(initFormValue, (e, i) => i === currentRemoveIndex);
            setProvider('data', { ...providerData, service: [...initFormValue] });
            setOpen(false);
        }
    }, [gloablEditStatus, currentId, refetch, setProvider, providerData, initFormValue, currentRemoveIndex, setPreview]);

    const handleAdd = useCallback(() => {
        if (gloablEditStatus) {
            scrollToTheEditingModule?.();
            setShowEditingTip?.(true);
            message.error('Please save your changes before proceeding');
        } else {
            setShowEditingTip?.(false);
            const newService = {
                [FormFields.COLOR_ID]: '',
                [FormFields.DESCRIPTION]: '',
                [FormFields.DURATION]: 0,
                [FormFields.NAME]: '',
                [FormFields.PERSON_ADDRESS]: '',
                [FormFields.PERSON_CITY]: '',
                [FormFields.PERSON_STATE]: '',
                [FormFields.PERSON_TYPE]: [],
                [FormFields.PERSON_ZIP]: '',
                [FormFields.SEND_FORM]: '',
                [FormFields.TELEHEALTH_TYPE]: [],
                [FormFields.TYPE]: '',
                [FormFields.PRICE]: 0,
                [FormFields.INTAKE_FORM]: '',
            };
            const newStore = {
                ...providerData,
                service: [newService, ...(providerData.service || [])],
            };

            setProvider('data', newStore);
            setCurrentEditingIndex(0);
            onEditStatusChange(true, EditModuleEnum.SERVICE);
        }
    }, [gloablEditStatus, onEditStatusChange, providerData, setProvider, scrollToTheEditingModule, setShowEditingTip]);

    return (
        <div className={s.wrap} id={EditModuleEnum.SERVICE}>
            {contextHolder}
            <Modal title="Remove this information in UniProfile?" open={open} onOk={handleRemove} onCancel={handleHideModal} okText="Remove">
                <p>The information will also be removed from UniProfile. Please note that this will be applied to your UniProfile and updated across all channels. Are you sure you want to proceed?</p>
            </Modal>
            <div className={s.header}>
                <div className={s.title}>Service</div>
                <div className={s.btns}>
                    <Button onClick={handleAdd} type="primary" style={{ marginRight: 0 }}>Add</Button>
                </div>
            </div>
            {
                showEditingTip && currentEditingIndex !== -1 && <ErrorBlock />
            }
            <div className={s.body}>
                {
                    initFormValue?.map((item, index) => {
                        const editing = index === currentEditingIndex;
                        const { id } = item;

                        return (
                            <div key={id || index} className={s.item}>
                                <ServiceItem
                                    addressList={addressOptions}
                                    id={id}
                                    index={index}
                                    isEditting={editing}
                                    data={item}
                                    onEditStatusChange={handleEditStatusChange}
                                    onSubmit={submit}
                                    onRemove={handleShowModal}
                                />
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default Service;
