import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { EnumFields } from 'types/enumerationData';
import { ServerMeta } from 'types/common';
import CommonStore from 'store/Common';
import useGetSettingData from 'hooks/useGetSettingData';

type ConditionSelectProps = {
    value?: string;
    onChange?: (val: string) => void;
    disableValues?: string[];
    hideValues?: string[];
    onlyShowValues?: ServerMeta[];
    mode?: 'multiple' | '';
} & SelectProps;

const KlarityConditionWithFeatureSelect = (props:ConditionSelectProps) => {
    const { onChange, disableValues, mode, hideValues, ...reset } = props;
    const [allItems] = useGetSettingData(EnumFields.HELLO_KLARITY_CONDITION);

    const [allOption, setAllOption] = React.useState<ServerMeta[]>([]);
    const [featureOption, setFeatureOption] = React.useState<ServerMeta[]>([]);

    const [getCommon] = CommonStore.useStore();
    const featureConditionFromStore = getCommon('featuredCondition');

    const disabledLength = disableValues?.length || 0;
    const hideLength = hideValues?.length || 0;
    React.useEffect(() => {
        if (featureConditionFromStore) {
            setFeatureOption((featureConditionFromStore as ServerMeta[]).filter((state:ServerMeta) => !hideValues?.includes(state.content) && !hideValues?.includes(state.dictKey as string)));
        }
        if (allItems) {
            setAllOption((allItems as ServerMeta[]).filter((state:ServerMeta) => !hideValues?.includes(state.content) && !hideValues?.includes(state.dictKey as string)));
        }

        //const _otherStates: Meta[] = stateOption.filter((state:Meta) => !featureStatesKey.includes(state.value as string) && !featureStatesKey.includes(state.label as string));
        //setOtherStates(_otherStates);
    }, [allItems, featureConditionFromStore, disabledLength, hideLength, hideValues]);

    return (
        <Select
            {...reset}
            onChange={onChange}
            showSearch
            virtual={false}
            mode={mode}
            options={[
                {
                    label: <span>Top states</span>,
                    title: 'Top conditions',
                    options: featureOption.map((item) => {
                        if (disableValues?.includes(item.dictKey) || disableValues?.includes(item.content as string)) {
                            return {
                                label: item.content,
                                value: item.dictKey,
                                disabled: true,
                                key: `feature${item.content}`,
                            };
                        }
                        return {
                            label: item.content,
                            value: item.dictKey,
                            key: `feature${item.content}`,
                        };
                    }),
                },
                {
                    label: <span>All states</span>,
                    title: 'All condtions',
                    options: allOption.map((item) => {
                        if (disableValues?.includes(item.dictKey) || disableValues?.includes(item.content as string)) {
                            return {
                                label: item.content,
                                value: item.dictKey,
                                disabled: true,
                            };
                        }
                        return {
                            label: item.content,
                            value: item.dictKey,
                        };
                    }),
                },
            ]}
        />
    );
};

export default KlarityConditionWithFeatureSelect;
