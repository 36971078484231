// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--qILFF .s-module__actionWrap--gkTJl .s-module__text--FcSIH {
  color: var(--primary-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/MedicationTable/s.module.less"],"names":[],"mappings":"AAAA;EAGM,2BAAA;AADN","sourcesContent":[".wrap {\n  .actionWrap {\n    .text {\n      color: var(--primary-color);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--qILFF`,
	"actionWrap": `s-module__actionWrap--gkTJl`,
	"text": `s-module__text--FcSIH`
};
export default ___CSS_LOADER_EXPORT___;
