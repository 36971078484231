import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import s from './s.module.less';
// import Cropper from 'react-easy-crop';
import { Slider } from 'antd';
import AvatarEditor from 'react-avatar-editor';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

interface IProps {
    borderRadius?: boolean;
    width?: number;
    height?: number;
    imageSrc: string;
}

const PhotoEditor = forwardRef(({
    borderRadius,
    imageSrc = '',
    width = 250,
    height = 250,
}: IProps, ref) => {
    const zoomStep = useRef(0.1);
    const rotationStep = useRef(1);
    const zoomIncreaseTime = useRef(200);
    const rotationIncreaseTime = useRef(1000 / 60);
    const zoomIncreaseRef = useRef<HTMLDivElement>(null);
    const zoomDecreaseRef = useRef<HTMLDivElement>(null);
    const rotationIncreaseRef = useRef<HTMLDivElement>(null);
    const rotationDecreaseRef = useRef<HTMLDivElement>(null);
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const editor = useRef(null);
    const zoomInterval = useRef<NodeJS.Timeout | null>(null);
    const rotationInterval = useRef<NodeJS.Timeout | null>(null);

    const getCroppedImage = useCallback(() => {
        if (editor.current) {
            // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
            // drawn on another canvas, or added to the DOM.
            const canvas = editor.current.getImage();

            // If you want the image resized to the canvas size (also a HTMLCanvasElement)
            // const canvasScaled = editor.current.getImageScaledToCanvas();

            return canvas;
        }
    }, []);

    const handleZoomChange = useCallback((value: number) => {
        return value < 1 ? 1 : value;
    }, []);
    const handleRotateChange = useCallback((value: number) => {
        let v = value;

        if (value < 0) {
            v = 0;
        } else if (value > 360) {
            v = 360;
        }
        return v;
    }, []);

    useImperativeHandle(ref, () => ({
        getImage: getCroppedImage,
    }));

    const handleKeepZoomIncrease = useCallback(() => {
        zoomInterval.current = setInterval(() => {
            setZoom((pre: number) => handleZoomChange(pre + zoomStep.current));
        }, zoomIncreaseTime.current);
    }, [handleZoomChange]);

    const handleKeepZoomDecrease = useCallback(() => {
        zoomInterval.current = setInterval(() => {
            setZoom((pre: number) => handleZoomChange(pre - zoomStep.current));
        }, zoomIncreaseTime.current);
    }, [handleZoomChange]);

    const handleKeepRotationIncrease = useCallback(() => {
        rotationInterval.current = setInterval(() => {
            setRotation((pre: number) => handleRotateChange(pre + rotationStep.current));
        }, rotationIncreaseTime.current);
    }, [handleRotateChange]);

    const handleKeepRotationDecrease = useCallback(() => {
        rotationInterval.current = setInterval(() => {
            setRotation((pre: number) => handleRotateChange(pre - rotationStep.current));
        }, rotationIncreaseTime.current);
    }, [handleRotateChange]);

    const handleStopZoom = useCallback(() => {
        if (zoomInterval.current) {
            clearInterval(zoomInterval.current as NodeJS.Timeout);
        }
    }, []);

    const handleStopRotation = useCallback(() => {
        if (rotationInterval.current) {
            clearInterval(rotationInterval.current as NodeJS.Timeout);
        }
    }, []);

    useEffect(() => {
        if (zoomIncreaseRef.current && zoomDecreaseRef.current && rotationIncreaseRef.current && rotationDecreaseRef.current) {
            const zoomIncreaseEl = zoomIncreaseRef.current;
            const zoomDecreaseEl = zoomDecreaseRef.current;
            const rotationIncreaseEl = rotationIncreaseRef.current;
            const rotationDecreaseEl = rotationDecreaseRef.current;
            zoomIncreaseEl.addEventListener('mousedown', handleKeepZoomIncrease);
            zoomDecreaseEl.addEventListener('mousedown', handleKeepZoomDecrease);
            rotationIncreaseRef.current.addEventListener('mousedown', handleKeepRotationIncrease);
            rotationDecreaseRef.current.addEventListener('mousedown', handleKeepRotationDecrease);

            zoomIncreaseEl.addEventListener('mouseup', handleStopZoom);
            zoomDecreaseEl.addEventListener('mouseup', handleStopZoom);
            rotationIncreaseEl.addEventListener('mouseup', handleStopRotation);
            rotationDecreaseEl.addEventListener('mouseup', handleStopRotation);

            return () => {
                zoomIncreaseEl.removeEventListener('mousedown', handleKeepZoomIncrease);
                zoomDecreaseEl.removeEventListener('mousedown', handleKeepZoomDecrease);
                rotationIncreaseEl.removeEventListener('mousedown', handleKeepRotationIncrease);
                rotationDecreaseEl.removeEventListener('mousedown', handleKeepRotationDecrease);

                zoomIncreaseEl.removeEventListener('mouseup', handleStopZoom);
                zoomDecreaseEl.removeEventListener('mouseup', handleStopZoom);
                rotationIncreaseEl.removeEventListener('mouseup', handleStopRotation);
                rotationDecreaseEl.removeEventListener('mouseup', handleStopRotation);
            };
        }
    }, [handleKeepRotationDecrease, handleKeepRotationIncrease, handleKeepZoomDecrease, handleKeepZoomIncrease, handleStopRotation, handleStopZoom]);

    return (
        <div className={s.wrap}>
            <div className={s.cropperBox} style={{ width: `${width}px`, height: `${height}px` }}>
                <AvatarEditor
                    crossOrigin="anonymous"
                    ref={editor}
                    image={imageSrc}
                    width={width}
                    height={height}
                    borderRadius={borderRadius ? width / 2 : 0}
                    scale={zoom}
                    rotate={rotation}
                />
            </div>
            <div className={s.actionWrap}>
                <div className={s.box}>
                    <div className={s.label}>Zoom</div>
                    <div className={s.silderBox}>
                        <div ref={zoomIncreaseRef} className={s.increase} onClick={() => setZoom(handleZoomChange(zoom + zoomStep.current))}>
                            <PlusOutlined />
                        </div>
                        <Slider className={s.slider} tooltip={{ open: true }} step={zoomStep.current} defaultValue={zoom} value={zoom} min={1} max={3} onChange={(v) => setZoom(v)} />
                        <div ref={zoomDecreaseRef} className={s.decrease} onClick={() => setZoom(handleZoomChange(zoom - zoomStep.current))}>
                            <MinusOutlined />
                        </div>
                    </div>
                </div>
                <div className={s.box}>
                    <div className={s.label}>Rotate</div>
                    <div className={s.silderBox}>
                        <div ref={rotationIncreaseRef} className={s.increase} onClick={() => setRotation(handleRotateChange(rotation + rotationStep.current))}>
                            <PlusOutlined />
                        </div>
                        <Slider className={s.slider} tooltip={{ open: true }} defaultValue={rotation} value={rotation} min={0} max={360} onChange={(v) => setRotation(v)} />
                        <div ref={rotationDecreaseRef} className={s.decrease} onClick={() => setRotation(handleRotateChange(rotation - rotationStep.current))}>
                            <MinusOutlined />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default PhotoEditor;
