import React, { useCallback, useMemo } from 'react';
import { message, Spin, Upload } from 'antd';
import s from './s.module.less';
import type { UploadChangeParam } from 'antd/es/upload';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import { uploadFileByType, uploadFile } from 'api/common';
import { file } from 'jszip';
import { getUserId } from 'utils/localstore';

interface IProps {
    url?: string;
    cls?: string;
    isError?: boolean;
    errorMessage?: string;
    accept?: string;
    index: number;
    onChange?: (url: string) => void;
    onRemove: (index: number) => void;
    height?: number;
    isOps?: boolean;
    providerId?: number;
}

const VideoUploader = ({
    url = '',
    // url = 'https://i03piccdn.sogoucdn.com/ca9fcbf66bca4e6b',
    // url = 'https://img95.699pic.com/photo/40214/1933.jpg_wh300.jpg',
    // url = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2d9hpdwkn-gVsHlBWwSRFs6VnZUES1XdxcQ&usqp=CAU',
    cls = '',
    isError,
    errorMessage = '',
    accept = ".mp4,.mov,.MP4,.MOV",
    index,
    onChange,
    height,
    isOps,
    providerId
}: IProps) => {
    const maxSize = 50 * 1024 * 1024;
    const fileInfoRef = React.useRef<UploadChangeParam<UploadFile>>();
    const [loading, setLoading] = React.useState(false);

    const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
        fileInfoRef.current = info;
        if (info.file.status === 'uploading') {
            setLoading(true);
        }
    };

    const props: UploadProps = useMemo(() => (
        {
            name: 'file',
            multiple: false,
            onChange: handleChange,
            accept,
            beforeUpload: (file) => {
                return new Promise<boolean>((resolve, reject) => {
                    const videoDom = document.createElement('video');
                    const fileReader = new FileReader();

                    fileReader.onload = (event) => {
                        if (!event || !event.target) {
                            return reject();
                        }

                        if (event.target.readyState === FileReader.DONE) {
                            const url = URL.createObjectURL(file);
                            videoDom.src = url;
                            //videoDom.src = event.target.result
                            videoDom.onloadedmetadata = () => {
                                if (videoDom.duration > 20) {
                                    message.error('Please upload a video less than 20 seconds');
                                    reject(); // Reject to prevent upload
                                } else if (videoDom.videoHeight < videoDom.videoWidth) {
                                    message.error('Please upload a video in portrait mode');
                                    reject(); // Reject to prevent upload
                                } else {
                                    resolve(true); // Resolve to allow upload
                                }
                                URL.revokeObjectURL(videoDom.src);
                            };
                        }
                    };

                    fileReader.readAsDataURL(file);
                });
            },
            customRequest: async (data) => {
                try {
                    const formData = new FormData();
                    const file: any = data.file
                    const size = file.size;

                    if (size > maxSize) {
                        message.error("Video size couldn't over 50MB")
                        return;
                    }
                    const pid = isOps ? providerId : getUserId();
                    formData.append('file', data.file);
                    formData.append('providerId', pid + '');
                    formData.append('uploadType', 'VIDEO');
                    const res = await uploadFileByType(formData);
                    if (!res.error) {
                        if (fileInfoRef.current) {
                            fileInfoRef.current.file.status = 'done';
                        }

                        if (typeof onChange === 'function') {
                            onChange(res.data?.data);
                        }
                    }
                } catch (e) {
                    console.error(e);
                } finally {
                    setLoading(false);
                }
            },
        }
    ), [accept, handleChange, onChange, index]);
    return (
        <div className={`${s.wrapper} ${cls} ${isError ? s.error : ''}`}>
            {
                loading ? (
                    <Spin spinning={loading}>
                        <div className={s.progress}>
                            Uploading...
                        </div>
                    </Spin>
                ) : (
                    <div className={s.emptyBox}>
                        <Spin spinning={loading} className={s.uploadWrap}>
                            <Upload {...props} className={s.uploadWrap}>
                                <div className={s.upWrap} style={{ height: height ? `${height}px` : 'auto' }}>
                                    <div className={s.defaultImage} />
                                    {
                                        isError && errorMessage && <p className={s.errorText}>{errorMessage}</p>}
                                    <p><span>Choose a file</span> or drag and drop video file</p>
                                    <p>MP4 or MOV up to 50MB</p>
                                </div>
                            </Upload>
                        </Spin>
                    </div>
                )
            }
        </div>
    );
};

export default VideoUploader;
