import type { BaseInputProps, ShowCountProps } from 'rc-input/lib/interface';
import React from 'react';
import s from './s.module.less';
import { Input } from 'antd';

type TextContentInput = {
    value?: string;
    onChange?: (val?: string) => void,
    header: React.ReactNode,
    footer: React.ReactNode,
    rows?: number,
    maxLength?: number,
    showCount?: boolean | ShowCountProps,
};

const TextContentInput = (Props: TextContentInput) => {
    const { onChange, value, header, footer, rows, maxLength, showCount } = Props;
    return (
        <div className={s.wrap}>
            <div className={s.header}>
                {header}
            </div>
            <div className={s.content}>
                <Input.TextArea
                    rows={rows || 5}
                    onChange={(e) => {
                        onChange && onChange(e.target.value);
                    }}
                    showCount={showCount}
                    maxLength={maxLength || 1000}
                />
            </div>
            <div className={s.footer}>
                {footer}
            </div>
        </div>
    );
};

export default TextContentInput;
