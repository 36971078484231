import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import useTimezone from 'hooks/useTimezone';

type TimeZoneSelectProps = {
    value?: string;
    onChange?: (val: string) => void,
} & SelectProps;

const TimeZoneSelect = (props:TimeZoneSelectProps) => {
    const { onChange, ...reset } = props;
    const [timeZone] = useTimezone();
    const timeZoneOption = timeZone || [];

    return (
        <Select {...reset} onChange={onChange}>
            {
                timeZoneOption.map((option) => {
                    return (
                        <Select.Option
                            key={option.value}
                            value={option.value}
                        >
                            {option.label}
                        </Select.Option>
                    );
                })
            }
        </Select>
    );
};

export default TimeZoneSelect;
