import React from 'react';
import Icon from '@ant-design/icons';
import LikeSVG from './LikeSVG';
import LikedSVG from './LikedSVG';
import UnlikeSVG from './UnlikeSVG';
import UnlikedSVG from './UnlikedSVG';

export const LikeSVGIcon = <Icon component={LikeSVG} />;
export const LikedSVGIcon = <Icon component={LikedSVG} />;
export const UnlikeSVGIcon = <Icon component={UnlikeSVG} />;
export const UnlikedSVGIcon = <Icon component={UnlikedSVG} />;
