import React from 'react';
import { Carousel } from 'antd';
import img1 from './imgs/1.png';
import img2 from './imgs/2.png';
import img3 from './imgs/3.png';
import img4 from './imgs/4.png';
import img5 from './imgs/5.png';
import s from './s.module.less';

type BannerConfigItem = {
    title: string;
    des: string;
    img: any;
};

const BannerConfig:BannerConfigItem[] = [
    {
        title: 'Your Private Practice, powered by Kiwi Health',
        des: 'Kiwi Health includes everything a private practice needs to grow immediately online. We empower you to focus on treating patients, and we’ll handle all other needs like marketing, billing and software to make your practice flourish.',
        img: img1,
    },
    {
        title: 'Multi-Channel Listing and Marketing',
        des: 'Sync your profile and availability to multiple partner platforms such as Zocdoc, Klarity, Google My Business, Yelp, Vitals, and many more with one application. Maximize your chances of being discovered by your potential patient clients.',
        img: img2,
    },
    {
        title: 'PracticeFront',
        des: 'Do you dream of having your own beautiful, modern practice website for your brand? Make your dream a reality without involving yourself in any software development.',
        img: img3,
    },
    {
        title: 'Prospect management',
        des: 'Elevate new patient acquisition with the Kiwi Prospect Management system. You can now manage patient prospects across all marketing channels in one place. No more logging in and out from different accounts. No more contemplating what to respond to a prospect. No more missing any potential clients . Turn your high-intent prospects into your patients, all in one Prospect management System.',
        img: img4,
    },
    {
        title: 'Multi-Channel Practice Calendar',
        des: 'Struggling to manage multiple calendars from your patient channels? With our Multi-Channel Practice Calendar, your patient bookings from all channels will directly ingest into one single calendar.',
        img: img5,
    },
];

export default function KiwiBanner() {
    return (
        <Carousel autoplay autoplaySpeed={5000}>
            {
                BannerConfig.map((item) => {
                    return (
                        <div className={s.container} key={item.title}>
                            <div className={s.wrap}>
                                <div className={s.title}>
                                    <div className={s.inTitle}>
                                        {item.title}
                                    </div>
                                </div>
                                <div className={s.des}>{item.des}</div>
                                <div
                                    className={s.imgWrap}
                                    style={{ backgroundImage: `url(${item.img})` }}
                                />
                            </div>
                        </div>
                    );
                })
            }
        </Carousel>
    );
}
