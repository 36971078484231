import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import useChannel from 'hooks/useChannel';
import { Meta, Channel } from 'types/common';

type ChannelSelectProps = {
    value?: number | number[];
    onChange?: (val: number | number[]) => void,
} & SelectProps;

const ChannelSelect = (props:ChannelSelectProps) => {
    const { onChange, ...reset } = props;

    const [channels, loading] = useChannel();
    const options:Meta[] = channels?.map((channel:Channel) => {
        return {
            label: channel.name,
            value: channel.id!,
        };
    }) || [];
    return (
        <Select {...reset} onChange={onChange} loading={loading}>
            {
                options.map((option:Meta) => {
                    return (
                        <Select.Option
                            key={option.value}
                            value={option.value}
                        >
                            {option.label}
                        </Select.Option>
                    );
                })
            }
        </Select>
    );
};

export default ChannelSelect;
