// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__container--AB5UA {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  color: #000;
}
.s-module__container--AB5UA .s-module__wrap--zRSs3 {
  height: 88vh;
  width: 86%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-height: 700px;
}
.s-module__container--AB5UA .s-module__title--Y5V7A {
  font-size: 30px;
  line-height: 32px;
  color: #000;
  font-weight: bold;
  flex-shrink: 0;
}
.s-module__container--AB5UA .s-module__inTitle--ALR7E {
  max-width: 540px;
  margin: 0 auto;
  margin-bottom: 48px;
}
.s-module__container--AB5UA .s-module__des--xtfjy {
  font-size: 18px;
  line-height: 24px;
  flex-shrink: 0;
  max-width: 540px;
  margin: 0 auto;
  margin-bottom: 64px;
}
.s-module__container--AB5UA .s-module__imgWrap--WPeQB {
  flex-grow: 1;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 200px;
  max-height: 500px;
}
.ant-carousel .slick-dots li.slick-active button {
  background: #00816B;
  opacity: 1;
}
.ant-carousel .slick-dots li button {
  background: #00816B;
  opacity: 0.3;
}
`, "",{"version":3,"sources":["webpack://./src/components/KiwiBanner/s.module.less"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,WAAA;EACA,WAAA;AACF;AAPA;EAQI,YAAA;EACA,UAAA;EACA,cAAA;EACA,aAAA;EACA,sBAAA;EACA,iBAAA;AAEJ;AAfA;EAgBI,eAAA;EACA,iBAAA;EACA,WAAA;EACA,iBAAA;EACA,cAAA;AAEJ;AAtBA;EAuBI,gBAAA;EACA,cAAA;EACA,mBAAA;AAEJ;AA3BA;EA6BI,eAAA;EACA,iBAAA;EACA,cAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;AACJ;AAnCA;EAqCI,YAAA;EACA,kCAAA;EACA,wBAAA;EACA,4BAAA;EACA,iBAAA;EACA,iBAAA;AACJ;AAGA;EAEI,mBAAA;EACA,UAAA;AAFJ;AADA;EAMI,mBAAA;EACA,YAAA;AAFJ","sourcesContent":[".container {\n  display: flex !important;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  width: 100%;\n  color: #000;\n  .wrap {\n    height: 88vh;\n    width: 86%;\n    margin: 0 auto;\n    display: flex;\n    flex-direction: column;\n    max-height: 700px;\n  }\n  .title {\n    font-size: 30px;\n    line-height: 32px;\n    color: #000;\n    font-weight: bold;\n    flex-shrink: 0;\n  }\n  .inTitle {\n    max-width: 540px;\n    margin: 0 auto;\n    margin-bottom: 48px;\n  }\n\n  .des {\n    font-size: 18px;\n    line-height: 24px;\n    flex-shrink: 0;\n    max-width: 540px;\n    margin: 0 auto;\n    margin-bottom: 64px;\n  }\n  .imgWrap{\n    flex-grow: 1;\n    background-position: center center;\n    background-size: contain;\n    background-repeat: no-repeat;\n    min-height: 200px;\n    max-height: 500px;\n  }\n}\n\n:global {\n  .ant-carousel .slick-dots li.slick-active button {\n    background: #00816B;\n    opacity: 1;\n  }\n  .ant-carousel .slick-dots li button {\n    background: #00816B;\n    opacity: 0.3;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `s-module__container--AB5UA`,
	"wrap": `s-module__wrap--zRSs3`,
	"title": `s-module__title--Y5V7A`,
	"inTitle": `s-module__inTitle--ALR7E`,
	"des": `s-module__des--xtfjy`,
	"imgWrap": `s-module__imgWrap--WPeQB`
};
export default ___CSS_LOADER_EXPORT___;
