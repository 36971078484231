import { Select } from 'antd';
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox';
import React, { useCallback, useEffect, useState } from 'react';
import s from './s.module.less';

interface IProps {
    fieldNames: Record<string, string>;
    options?: Record<string, string>[];
    value?: any;
    onChange?: (v: any[]) => void;
}

const Selector = ({
    fieldNames,
    options,
    value,
    onChange,
}: IProps) => {
    const [checkAll, setCheckAll] = useState(false);
    const [val, setVal] = useState<any[]>([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [searchOptionResult, setSearchOptionResult] = useState<Record<string, string>[]>([]);

    useEffect(() => {
        if (options) {
            setIndeterminate(val.length > 0 && val.length < options?.length);
            setSearchOptionResult(options);
        }
    }, [val, options]);

    useEffect(() => {
        if (value) {
            setVal(value);
        }
    }, [value]);

    useEffect(() => {
        setCheckAll(val.length === options?.length);
    }, [options, val]);

    const handleAllCheckChange = useCallback((e: CheckboxChangeEvent) => {
        const { checked } = e.target;
        setCheckAll(checked);
        if (checked && options) {
            const allValues = options.map((option) => option.dictKey);
            setVal(allValues);
            onChange?.(allValues);
        } else {
            setVal([]);
            onChange?.([]);
        }
    }, [onChange, options]);

    const handleChange = useCallback((e: any[]) => {
        setVal(e);
        onChange?.(e);
    }, [onChange]);

    const handleSearch = useCallback((e: string) => {
        const res = options?.filter((option) => option?.dictKey?.toLowerCase().includes(e.toLowerCase())) || [];

        setSearchOptionResult(res);
    }, [options]);

    return (
        <Select
            mode="multiple"
            className={s.selector}
            fieldNames={fieldNames}
            options={options}
            onChange={handleChange}
            value={val}
            onSearch={handleSearch}
            dropdownRender={(menu) => (
                <>
                    {
                        searchOptionResult && searchOptionResult.length > 0 ?
                            <Checkbox indeterminate={indeterminate} checked={checkAll} onChange={handleAllCheckChange}>Select all</Checkbox> : null
                    }
                    {menu}
                </>
            )}
        />
    );
};

export default Selector;
