import { Button, Checkbox, Form, Input } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import s from './s.module.less';
import { EMPTY_PLACEHOLDER, URL_LIMIT } from 'constants/common';
import commonS from 'styles/common.module.less';
import { EditModuleEnum, FormFields, IProviderStore, ProvderAppointment } from 'types/practiceFront';
import ErrorBlock from '../ErrorBlock';
import { Link } from 'react-router-dom';
import { PATH } from 'constants/path';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import useRefreshPreview from 'hooks/useRefreshPreview';
import { EPracticeFrontPreviewPage } from 'types/channel';
import { showNewFlag } from 'utils/provider';
import ProviderStore from 'store/PracticeFront/provider';
import useProviderNotifyEmails from 'hooks/useProviderNotifyEmails';
import FormValidationHelper from 'utils/validation';
import SubscriptionStore from 'store/Subscription';
import { CouponType } from 'types/provider';

interface IProps {
    isKlarityUser?: boolean;
    emailNotify?: string | null;
    appointmentNewFlag: boolean | null;
    showEditingTip?: boolean;
    isEditing: boolean;
    gloablEditStatus: boolean;
    initFormValue: ProvderAppointment;
    isFree: boolean;
    scrollToTheEditingModule: () => void;
    setTabKey: (key: string) => void;
    setShowEditingTip: (field, value: boolean) => void;
    onEditStatusChange: (status: boolean, field: EditModuleEnum) => void;
    onSubmit: (value: ProvderAppointment, field: EditModuleEnum, callback: () => void | Function) => void;
}

const AppointmentLink = ({
    isKlarityUser,
    appointmentNewFlag,
    showEditingTip,
    isEditing,
    gloablEditStatus,
    initFormValue,
    isFree,
    setTabKey,
    scrollToTheEditingModule,
    setShowEditingTip,
    onEditStatusChange,
    onSubmit,
}: IProps) => {
    const payPlan = SubscriptionStore.get('currentPlan');
    const [getProvider, setProvider] = ProviderStore.useStore();
    const [formInstance] = Form.useForm();
    const [useDirectLink, setUseDirectLink] = useState(false);
    const [setPreview] = useRefreshPreview();
    const providerData = getProvider('data') as IProviderStore;
    const [notifyEmails] = useProviderNotifyEmails();
    const [showEditButton, setShowEditButton] = useState(false);

    useEffect(() => {
        setShowEditButton(!isKlarityUser);
    }, [isKlarityUser]);

    const hasNotifyEmails = notifyEmails && notifyEmails.length > 0;

    const handleEditingChange = useCallback((status: boolean) => {
        if (gloablEditStatus && status) {
            if (gloablEditStatus) {
                // setTabKey('2');
                // setTimeout(() => {
                //     scrollToTheEditingModule();
                // }, 100);
            }
        } else {
            setShowEditingTip?.('data', false);
        }
        onEditStatusChange(status, EditModuleEnum.APPOINTMENT_LINK);
    }, [gloablEditStatus, onEditStatusChange, scrollToTheEditingModule, setShowEditingTip, setTabKey]);

    useEffect(() => {
        if (initFormValue) {
            formInstance.setFieldsValue(initFormValue);
        }
    }, [initFormValue, formInstance]);

    useEffect(() => {
        // https://www.notion.so/klarityhealth/3d4bdaf1dea943b28fbeeb22799cabc7?v=97936f8fa5b84b3a8503127fd5cbb108&p=d864f485654d4f1dbba84b49caada4bd&pm=s
        // headway用户默认选中
        if (initFormValue.useIntegrationUrl) {
            setUseDirectLink(true);
        } else if (payPlan?.couponType === CouponType.HEADWAY) {
            setUseDirectLink(true);
            if (!initFormValue[FormFields.APPOINTMENT_LINK]) {
                handleEditingChange(true);
            }
        } else {
            setUseDirectLink(false);
        }
    }, [initFormValue, payPlan, useDirectLink]);

    const setUlToIframe = useCallback(async () => {
        if (!isFree && initFormValue && initFormValue[FormFields.APPOINTMENT_LINK]) {
            const appointmentLink = initFormValue[FormFields.APPOINTMENT_LINK];
            setPreview('currentPage', appointmentLink);
        } else if (initFormValue?.[FormFields.USE_INTEGRATION_URL]) {
            setPreview('currentPage', EPracticeFrontPreviewPage.DIRECT_BOOKING);
        } else {
            setPreview('currentPage', EPracticeFrontPreviewPage.BOOKING);
        }
    }, [initFormValue, isFree]);

    useEffect(() => {
        setUlToIframe();
    }, [initFormValue, setUlToIframe]);

    const handleSubmit = useCallback(() => {
        if (!isFree) {
            formInstance.validateFields().then((values) => {
                let { appointmentIntegrationUrl } = values;

                if (!useDirectLink) {
                    appointmentIntegrationUrl = '';
                } else if (values?.appointmentIntegrationUrl && values.appointmentIntegrationUrl?.indexOf('https://') !== 0) {
                    appointmentIntegrationUrl = `https://${values.appointmentIntegrationUrl}`;
                }
                setProvider('data', {
                    ...providerData,
                    appointmentIntegrationUrl: appointmentIntegrationUrl || '',
                });
                onSubmit({
                    useIntegrationUrl: appointmentIntegrationUrl ? useDirectLink : false,
                    appointmentIntegrationUrl: appointmentIntegrationUrl || '',
                }, EditModuleEnum.APPOINTMENT_LINK, () => {
                    handleEditingChange(false);

                    return () => { };
                });
            }).catch((e) => {
                console.error(e);
            });
        } else {
            handleEditingChange(false);
        }
    }, [isFree, formInstance, useDirectLink, onSubmit, handleEditingChange]);

    const handleCheckChange = useCallback((e: CheckboxChangeEvent) => {
        setProvider('data', {
            ...providerData,
            useIntegrationUrl: e.target.checked,
        });
        if (e.target.checked) {
            // directbooking/Xiaocong_Liu/51
            setPreview('currentPage', EPracticeFrontPreviewPage.DIRECT_BOOKING);
        } else {
            setPreview('currentPage', EPracticeFrontPreviewPage.BOOKING);
            if (!isFree) {
                onSubmit({
                    useIntegrationUrl: false,
                    appointmentIntegrationUrl: '',
                }, EditModuleEnum.APPOINTMENT_LINK, () => handleEditingChange(false));
            }
        }
    }, [handleEditingChange, onSubmit, providerData, isFree]);

    const bookingLinkRender = useMemo(() => {
        if (payPlan?.couponType === CouponType.HEADWAY && initFormValue[FormFields.APPOINTMENT_LINK]) {
            return <a className={s.previewALink} href={initFormValue[FormFields.APPOINTMENT_LINK]}>{initFormValue[FormFields.APPOINTMENT_LINK]}</a>;
        }

        return initFormValue[FormFields.APPOINTMENT_LINK] || EMPTY_PLACEHOLDER;
    }, [initFormValue, payPlan]);

    const checkboxDIsabled = useMemo(() => {
        // 新的逻辑，如果是headway用户，则不能取消选中，也就意味着不能为空。
        // https://www.notion.so/klarityhealth/3d4bdaf1dea943b28fbeeb22799cabc7?v=97936f8fa5b84b3a8503127fd5cbb108&p=d864f485654d4f1dbba84b49caada4bd&pm=s
        if (payPlan?.couponType === CouponType.HEADWAY || isKlarityUser) {
            return true;
        }

        return isEditing;
    }, [isEditing, payPlan?.couponType, isKlarityUser]);

    const cancelButtonDisabled = useMemo(() => {
        if (payPlan?.couponType === CouponType.HEADWAY && !initFormValue[FormFields.APPOINTMENT_LINK]) {
            return true;
        }
        return false;
    }, [initFormValue, payPlan]);

    return (
        <div>
            <div className={s.wrap}>
                <div className={`${s.mask} ${useDirectLink && s.disable}`}>
                    <div className={s.header}>
                        <div className={`${s.titleBox} ${isEditing && s.editingTitle}`}>
                            <div className={s.title}>Appointment request form</div>
                            <div className={s.tagBox}>
                                <div className={s.recommended}>Recommended</div>
                                {isFree && <div className={s.paidFunc}>Paid</div>}
                            </div>
                        </div>
                    </div>
                    <div className={s.desc}>Take greater control of your availability by allowing visitors to send a request to you before booking an appointment.</div>
                    <div className={s.paidTip}>
                        <div className={s.starIcon} />
                        <div className={s.TipContent}>
                            <div className={s.tipText}>You will receive and see new potential clients’ information and messages in <Link target="_blank" to={`${PATH.DASHBOARD}/${PATH.PROSPECTS}`}>My Prospects</Link> tab.</div>
                        </div>
                    </div>
                    <div>
                        <div className={s.label}>
                            Prospect email notification preferences
                        </div>
                        <div className={s.value}>{hasNotifyEmails ? notifyEmails.join(', ') : EMPTY_PLACEHOLDER}</div>
                    </div>
                </div>
            </div>

            <Checkbox className={s.checkbox} disabled={checkboxDIsabled} checked={useDirectLink} onChange={handleCheckChange}>I want to use a direct booking link</Checkbox>
            <div id="bookingLinkCheckbox" />

            {
                useDirectLink &&
                <div className={s.wrap}>
                    <div className={s.header}>
                        <div className={`${s.titleBox}`}>
                            <div className={s.title}>Direct booking integration</div>
                            <div className={s.tagBox}>
                                {
                                    // showNewFlag(appointmentNewFlag) && <div className={s.newFeature}>New</div>
                                }
                                {isFree && <div className={s.paidFunc}>Paid</div>}
                            </div>
                        </div>
                        {
                            showEditButton ? (
                                <div className={s.btns}>
                                    {
                                        isEditing ?
                                            <>
                                                <Button disabled={cancelButtonDisabled} onClick={() => handleEditingChange(false)}>Cancel</Button>
                                                <Button type="primary" onClick={handleSubmit}>Save changes</Button>
                                            </>
                                            : <Button disabled={isFree} onClick={() => handleEditingChange(true)}>Edit</Button>
                                    }
                                </div>
                            ) : ''

                        }
                    </div>
                    {
                        showEditingTip && isEditing && <ErrorBlock />
                    }
                    <div className={s.desc}>
                        {
                            isKlarityUser ?
                                'Users will be directed to your HelloKlarity profile page to book an appointment.' :
                                'If you already have a scheduling link, insert it here to allow visitors to directly book an appointment with you.'
                        }
                    </div>
                    <div className={s.content}>
                        {
                            isEditing ?
                                <Form
                                    form={formInstance}
                                    name="appointmentLink"
                                    className={commonS.formStyle1}
                                    autoComplete="off"
                                    layout="vertical"
                                >
                                    <div className={s.itemBox}>
                                        <div className={s.item}>
                                            <Form.Item
                                                label="Scheduling link"
                                                name={FormFields.APPOINTMENT_LINK}
                                                validateFirst
                                                rules={[
                                                    // https://www.notion.so/klarityhealth/a49e9f8c18434682b0f7602609154fcf?v=137a6f564e784baf80b728d49b537307&p=553e01e8aa3e4c6fadb7e79e54770144&pm=s
                                                    // { required: true, message: 'Appointment link is a required field' },
                                                    FormValidationHelper.validateHeadway('Please put the direct booking link from Headway', payPlan?.couponType),
                                                ]}
                                            >
                                                <Input addonBefore="https://" disabled={isFree} maxLength={URL_LIMIT} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Form> :
                                <div>
                                    <div className={s.label}>
                                        Scheduling link
                                        {
                                            payPlan?.couponType === CouponType.HEADWAY && initFormValue[FormFields.APPOINTMENT_LINK] && <a className={s.previewTip}>(Click the link below for a preview)</a>
                                        }
                                    </div>
                                    <div className={s.value}>
                                        {bookingLinkRender}
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            }
        </div>
    );
};

export default AppointmentLink;
