import React from 'react';
import Pdf from 'assets/common/pdf.png';
import s from './s.module.less';

type Props = {
    src?: string;
};

const LicenseImg = (props:Props) => {
    const { src } = props;
    if (src?.toLocaleLowerCase().endsWith('.pdf')) {
        return (
            <div className={s.pdfWrap}>
                <img src={Pdf} alt="license in PDF" />
                <a href={src} target="_blank" rel="noopener noreferrer">View PDF</a>
            </div>
        );
    }
    return (
        <img src={src} alt="license" />
    );
};

export default LicenseImg;
