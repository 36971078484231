import React, { useCallback, useState, useEffect } from 'react';
import { getBillingPortalLink } from 'api/subscription';
import { checkIsPaymentFailed } from 'api/ehr';
import { Spin, message } from 'antd';
import s from './s.module.less';

const PaymentStatus: React.FC = () => {
    const [isLoading, setLoading] = useState(false);
    const [isShowTips, setIsShowTips] = useState(false);

    const handleUpgradePayment = useCallback(async () => {
        setLoading(true);
        try {
            const result = await getBillingPortalLink();
            if (!result.error) {
                const link = result.data.data;
                if (link) {
                    window.open(link, '_blank');
                } else {
                    message.error('Customer billing portal is for paid user only.');
                }
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    }, []);

    const paymentTipRender = async () => {
        try {
            const { data } = await checkIsPaymentFailed();
            setIsShowTips(data?.data);
        } catch (e) {

        }
    };

    useEffect(() => {
        paymentTipRender();
    }, []);

    return (
        <Spin spinning={isLoading}>
            {isShowTips && (
                <div className={s.updatePaymentTip}>
                    <div className={s.tipInfo}>
                        <div className={s.tipIcon} />
                        <div className={s.tipContent}>
                            <div className={s.tipTitle}>
                                Your recent payment was declined
                            </div>
                            <div className={s.tipDesc}>
                                To avoid service interruption, please update your payment
                                information immediately. Thank you for your quick attention.
                            </div>
                        </div>
                    </div>
                    <div className={s.upgradeText} onClick={handleUpgradePayment}>
                        Update payment
                    </div>
                </div>
            )}
        </Spin>
    );
};

export default PaymentStatus;
