// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--UZi4L {
  background: #fff;
  height: auto;
}
.s-module__wrap--UZi4L h3 {
  color: var(--gray-800);
  font-size: 20px;
  line-height: 44px;
  font-weight: 500;
  border-bottom: 1px solid var(--bottom-line-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.s-module__wrap--UZi4L h3 .s-module__icon--xbF48 {
  color: var(--primary-color);
  font-size: 24px;
  font-weight: 100;
  margin-right: 8px;
  cursor: pointer;
  opacity: 1;
  transition: all linear 200ms;
}
.s-module__wrap--UZi4L h3 .s-module__icon--xbF48:hover {
  opacity: 0.7;
}
.s-module__wrap--UZi4L .s-module__toggle--B5KD4 {
  transition: all linear 300ms;
  transform: scaleY(1);
  transform-origin: top;
}
.s-module__wrap--UZi4L .s-module__row--SqeeK {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
}
.s-module__wrap--UZi4L .s-module__row--SqeeK .s-module__displayItem--Rq0SV {
  margin-bottom: 12px;
}
.s-module__wrap--UZi4L .s-module__row--SqeeK .s-module__displayItem--Rq0SV .s-module__label--cbRrz {
  color: #6B7280;
  font-size: 14px;
  line-height: 20px;
}
.s-module__wrap--UZi4L .s-module__rowItem--r7aSx {
  margin-right: 36px;
}
.s-module__wrap--UZi4L .s-module__colItem--vdCHr {
  display: flex;
  flex-direction: column;
}
.s-module__folded--XQf0k {
  padding-bottom: 12px;
  height: 68px;
  overflow-y: hidden;
}
.s-module__folded--XQf0k h3 {
  border-bottom: 0;
}
.s-module__folded--XQf0k .s-module__toggle--B5KD4 {
  transform: scaleY(0);
}
`, "",{"version":3,"sources":["webpack://./src/components/onboarding/PracticeExperiencePanel/s.module.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;AACF;AAHA;EAII,sBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,iDAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AAEJ;AAfA;EAeM,2BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;EACA,UAAA;EACA,4BAAA;AAGN;AAxBA;EAwBM,YAAA;AAGN;AA3BA;EA4BI,4BAAA;EACA,oBAAA;EACA,qBAAA;AAEJ;AAhCA;EAkCI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;AACJ;AAtCA;EA4CM,mBAAA;AAHN;AAzCA;EAwCQ,cAAA;EACA,eAAA;EACA,iBAAA;AAIR;AA9CA;EAiDI,kBAAA;AAAJ;AAjDA;EAqDI,aAAA;EACA,sBAAA;AADJ;AAKA;EACE,oBAAA;EACA,YAAA;EACA,kBAAA;AAHF;AAAA;EAKI,gBAAA;AAFJ;AAHA;EAQI,oBAAA;AAFJ","sourcesContent":[".wrap {\n  background: #fff;\n  height: auto;\n  h3 {\n    color: var(--gray-800);\n    font-size: 20px;\n    line-height: 44px;\n    font-weight: 500;\n    border-bottom: 1px solid var(--bottom-line-color);\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 12px;\n    .icon{\n      color: var(--primary-color);\n      font-size: 24px;\n      font-weight: 100;\n      margin-right: 8px;\n      cursor: pointer;\n      opacity: 1;\n      transition: all linear 200ms;\n    }\n    .icon:hover{\n      opacity: 0.7;\n    }\n  }\n  .toggle{\n    transition: all linear 300ms;\n    transform: scaleY(1);\n    transform-origin: top;\n  }\n\n  .row {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    margin-top: 16px;\n    .displayItem{\n      .label {\n        color: #6B7280;\n        font-size: 14px;\n        line-height: 20px;\n      }\n      margin-bottom: 12px;\n    }\n  }\n\n  .rowItem {\n    margin-right: 36px;\n  }\n\n  .colItem {\n    display: flex;\n    flex-direction: column;\n  }\n}\n\n.folded {\n  padding-bottom: 12px;\n  height: 68px;\n  overflow-y: hidden;\n  h3 {\n    border-bottom: 0;\n  }\n  .toggle{\n    transform: scaleY(0);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--UZi4L`,
	"icon": `s-module__icon--xbF48`,
	"toggle": `s-module__toggle--B5KD4`,
	"row": `s-module__row--SqeeK`,
	"displayItem": `s-module__displayItem--Rq0SV`,
	"label": `s-module__label--cbRrz`,
	"rowItem": `s-module__rowItem--r7aSx`,
	"colItem": `s-module__colItem--vdCHr`,
	"folded": `s-module__folded--XQf0k`
};
export default ___CSS_LOADER_EXPORT___;
