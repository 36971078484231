import { ELicenseType, License, Physician } from 'types/common';
import { Provider, QualificationFormFieldEnumInterface, ProfileFormInterface, ProfileFormFieldEnum, QualificationFormFieldEnum, UniprofileFormDataShadow, UniprofileFormData, FormLicenseShadow, ServiceExtShadow, ServiceExt } from 'types/provider';

import dayjs from 'dayjs';
import { FormPhysician, FormLicense } from 'types/form';
import { formatDate, removeHMSInUTCTime } from 'utils/common';
import { OTHERS_KEY, NURSE_LICENSE_TYPES } from 'constants/meta';
import { NOT_SET } from 'constants/common';

type FormData = {
    basic:Partial<ProfileFormInterface>,
    qualifications: Partial<QualificationFormFieldEnumInterface>[]
};

export const transferFormPhysicianToServerData = (data:FormPhysician):Physician => {
    return {
        ...data,
        expDate: formatDate(data.expDate),
        effectiveDate: formatDate(data.effectiveDate),
        birthdayDate: formatDate(data.birthdayDate),
    };
};

export const transferServerPhysicianToFormData = (data:Physician):FormPhysician => {
    return {
        ...data,
        expDate: data.expDate ? dayjs(removeHMSInUTCTime(data.expDate)) : undefined,
        effectiveDate: dayjs(removeHMSInUTCTime(data.effectiveDate)),
        birthdayDate: data.birthdayDate ? dayjs(removeHMSInUTCTime(data.birthdayDate)) : undefined,
    };
};

export const transferFormLicenseTypeToServerData = (licenseType?: Record<string, string | Record<string, string>>):string => {
    let licenseTypeStr = '';
    const licenseTypeArr:string[] = [];
    if (licenseType) {
        const systemLicenceTypes = NURSE_LICENSE_TYPES?.map((item:any) => item.value) || [];
        Object.keys(licenseType).forEach((key) => {
            if (key === OTHERS_KEY) {
                const data = licenseType[key] as Record<string, string>;
                Object.keys(data).forEach((otherKey) => {
                    const url = data[otherKey] as string;
                    licenseTypeArr.push(`${otherKey}=${url}`);
                });
            } else if (systemLicenceTypes.includes(key)) {
                const url = licenseType[key] as string;
                licenseTypeArr.push(`${key}=${url}`);
            }
        });
    }
    if (licenseTypeArr.length > 0) {
        licenseTypeStr = licenseTypeArr.join(',');
    }
    return licenseTypeStr;
};

export const transferServerLicenseTypeToFormData = (licenseType?:string):Record<string, string | Record<string, string>> | undefined => {
    if (!licenseType) {
        return {};
    }
    const systemValues = NURSE_LICENSE_TYPES?.map((item:any) => item.value) || [];
    const licenseTypeArr = licenseType.split(',');
    const licenseTypeObject:Record<string, string> = {};
    for (let i = 0; i < licenseTypeArr.length; i++) {
        const licenseTypeKeyVal:string[] = licenseTypeArr[i].split('=');
        if (licenseTypeKeyVal.length === 2) {
            licenseTypeObject[licenseTypeKeyVal[0]] = licenseTypeKeyVal[1];
        }
    }
    const defaultValues = licenseTypeObject ? Object.keys(licenseTypeObject) : [];
    const defaultSelectValues = defaultValues.filter((val) => systemValues.includes(val));
    const othersValue = defaultValues.filter(Boolean).filter((val) => !systemValues.includes(val));
    const newLicenseType:Record<string, string | Record<string, string>> = {

    };
    defaultSelectValues.forEach((key) => {
        newLicenseType[key] = licenseTypeObject[key];
    });

    if (othersValue && othersValue.length > 0) {
        const other:Record<string, string> = {};
        othersValue.forEach((key) => {
            other[key] = licenseTypeObject[key];
        });
        newLicenseType[OTHERS_KEY] = other;
    }
    return newLicenseType;
};

export const transferFormLicenseToServerData = (data:FormLicense):License => {
    return {
        ...data,
        schedule: data.schedule?.join(',') || '',
        expireDate: formatDate(data.expireDate),
        licenseType: transferFormLicenseTypeToServerData(data.licenseType),
    };
};

export const transferServerLicenseToFormData = (data:License):FormLicense => {
    return {
        ...data,
        expireDate: data.expireDate ? dayjs(removeHMSInUTCTime(data.expireDate)) : undefined,
        schedule: data.schedule?.split(',') || [],
        licenseType: transferServerLicenseTypeToFormData(data.licenseType),
    };
};

export const LicenseData = {
    serverToForm: transferServerLicenseToFormData,
    formToServer: transferFormLicenseToServerData,
};

export const PhysicianData = {
    serverToForm: transferServerPhysicianToFormData,
    formToServer: transferFormPhysicianToServerData,
};

export const transferProviderToFormData = (data:Provider):FormData => {
    const licenses = data.licenseList || [];
    const physicians = data.physicianData || [];
    const states:string[] = [];
    licenses.forEach((license:License) => {
        if (!states.includes(license.state)) {
            states.push(license.state);
        }
    });

    physicians.forEach((physician:Physician) => {
        if (!states.includes(physician.state)) {
            states.push(physician.state);
        }
    });
    const qualifications:Partial<QualificationFormFieldEnumInterface>[] = [];
    states.forEach((state:string) => {
        const qualification:Partial<QualificationFormFieldEnumInterface> = {};
        const deas = licenses.filter((item) => item.state === state && item.type === ELicenseType.DEA);
        if (deas && deas.length > 0) {
            const deasList = deas.map((item) => LicenseData.serverToForm(item));
            qualification[QualificationFormFieldEnum.DEA_LICENSES] = { licenses: deasList };
        }

        const nurce = licenses.find((item) => item.state === state && item.type === ELicenseType.NURSE);
        const pa = licenses.find((item) => item.state === state && item.type === ELicenseType.PA);
        if (nurce || pa) {
            qualification[QualificationFormFieldEnum.NURSE_AND_PHYSICIAN_ASSISTANT_LICENSES] = {};
            if (nurce) {
                qualification[QualificationFormFieldEnum.NURSE_AND_PHYSICIAN_ASSISTANT_LICENSES]!.nurse = LicenseData.serverToForm(nurce);
            }
            if (pa) {
                qualification[QualificationFormFieldEnum.NURSE_AND_PHYSICIAN_ASSISTANT_LICENSES]!.pa = LicenseData.serverToForm(pa);
            }
        }

        const csc = licenses.filter((item) => item.state === state && item.type === ELicenseType.CONTROLLED_SUBSTAN_CECERTIFICATION);
        if (csc && csc.length > 0) {
            const licenseList = csc.map((item) => LicenseData.serverToForm(item));
            qualification[QualificationFormFieldEnum.CONTROLLED_SUBSTANCE_CERTIFICATION] = { licenses: licenseList };
        }

        const cscs = licenses.filter((item) => item.state === state && item.type === ELicenseType.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES);
        if (cscs && cscs.length > 0) {
            const licenseList = cscs.map((item) => LicenseData.serverToForm(item));
            qualification[QualificationFormFieldEnum.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES] = { licenses: licenseList };
        }

        const physician = physicians.find((item) => item.state === state);
        if (physician) {
            qualification[QualificationFormFieldEnum.COLLABORATING_PHYSICIAN] = PhysicianData.serverToForm(physician);
        }

        qualification[QualificationFormFieldEnum.STATE] = state;

        qualifications.push(qualification);
    });

    return {
        basic: {
            [ProfileFormFieldEnum.EMAIL]: data.email,
            [ProfileFormFieldEnum.GMAIL]: data.gmail,
            [ProfileFormFieldEnum.TEL]: data.tel,
            [ProfileFormFieldEnum.MAILING_ADDRESS]: data.address,
            [ProfileFormFieldEnum.ZIP_CODE]: data.zip,
            [ProfileFormFieldEnum.CITY]: data.city,
            [ProfileFormFieldEnum.STATE]: data.state,
            [ProfileFormFieldEnum.NPI_NUMBER]: data.npi,
            [ProfileFormFieldEnum.YEARS_OF_EXPERIENCE]: data.yearExp,
            [ProfileFormFieldEnum.SPECIALITYINHEALTH]: data.specialityInHealth ? data.specialityInHealth?.trim()?.split(',') : undefined,
            [ProfileFormFieldEnum.SPECIALITYINFAMILY]: data.specialityInFamily ? data.specialityInFamily?.trim()?.split(',') : undefined,
        },
        qualifications,
    };
};

const isSameString = (str1?:string, str2?:string):boolean => {
    if (!str1 && !str2) {
        return true;
    }
    // if (typeof str1 !== 'string' || typeof str2 !== 'string') {
    //     return false;
    // }
    return `${str1}` === `${str2}`;
};

const isSameNumber = (str1?:number, str2?:number):boolean => {
    if (!str1 && !str2) {
        return true;
    }
    return `${str1}` === `${str2}`;
};

export const isSameStringArray = (arr1?:string[], arr2?:string[]):boolean => {
    if (!arr1 && !arr2) {
        return true;
    }
    if (!arr1 || !arr2) {
        return false;
    }
    if (arr1.length !== arr2.length) {
        return false;
    }
    for (let i = 0; i < arr1.length; i++) {
        if (!arr2.includes(arr1[i])) {
            return false;
        }
    }
    return true;
};

export const getDiffByString = (val1?: string, val2?: string):string | undefined => {
    if (isSameString(val1, val2)) {
        return undefined;
    }
    return val2 || NOT_SET;
};

export const getDiffByNumber = (val1?: number, val2?: number):number | undefined => {
    if (isSameNumber(val1, val2)) {
        return undefined;
    }
    return val2 || 0;
};

export const getDiffByStringArray = (val1?: string[], val2?: string[]):string[] | undefined => {
    if (isSameStringArray(val1, val2)) {
        return undefined;
    }
    return val2 || [];
};

export const getShadowDataFromTwoData = (newData:UniprofileFormData, oldData?:UniprofileFormData):UniprofileFormDataShadow | undefined => {
    if (!newData || !oldData) {
        return undefined;
    }
    const ret:UniprofileFormDataShadow = { ...newData,
        licenses: {
            [ELicenseType.DEA]: [...(newData?.licenses?.[ELicenseType.DEA] || [])],
            [ELicenseType.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES]: [...(newData?.licenses?.[ELicenseType.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES] || [])],
            [ELicenseType.CONTROLLED_SUBSTAN_CECERTIFICATION]: [...(newData?.licenses?.[ELicenseType.CONTROLLED_SUBSTAN_CECERTIFICATION] || [])],
            [ELicenseType.PRACTICE]: [...(newData?.licenses?.[ELicenseType.PRACTICE] || [])],
        },
    };
    if (oldData.profile && newData.profile) {
        //profile
        const profile1 = newData.profile;
        const profile2 = oldData.profile;

        const stringValKeys = [
            'title',
            'intro',
            'primaryLanguage',
            'officeAddress',
            'officeCity',
            'officeState',
            'officeZip',
            'payout',
            'payment',
            'photo',
            'website',
            'middleName',
            'headLine',
            'firstName',
            'lastName',
            'payout',
            'payment',
            'yearExp',
            'tel',
            'appointmentIntegrationUrl',
        ];
        const stringArrayValKeys = [
            'foreignLanguage',
            'photoList',
            'additionalPhotoList',
            'licenses',
            'credential',
            'specialityInHealth',
            'specialityInFamily',
        ];
        Object.keys(profile1).forEach((key) => {
            if (stringValKeys.includes(key)) {
                try {
                    //@ts-ignore
                    const newVal = getDiffByString(profile1[key] as string, profile2[key] as string);

                    if (newVal) {
                        //@ts-ignore
                        ret.profile[`${key}New`] = newVal;
                    }
                } catch (e) {
                    console.log(e);
                }
            }
            if (stringArrayValKeys.includes(key)) {
                try {
                    //@ts-ignore
                    const newVal = getDiffByStringArray(profile1[key] as string[], profile2[key] as string[]);
                    if (newVal) {
                        //@ts-ignore
                        ret.profile[`${key}New`] = newVal;
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        });
    }

    if (oldData.practice && newData.practice) {
        //practice
        const practice1 = newData.practice;
        const practice2 = oldData.practice;

        const stringValKeys = [
            'treatmentPhilosophy',
            'treatmentApproach',
            'practiceName',
            'practiceAddress',
            'practiceCity',
            'practiceState',
            'practiceZip',
            'practiceEmail',
            'practicePhone',
            'practiceWebsite',
            'companyType',
            'practiceDesc',
            'practiceLogo',
            'taxIdType',
            'taxId',
        ];
        const stringArrayValKeys = [
            'specialityInFamily',
            'specialityInHealth',
            'specialtyList',
            'conditionTreatedList',
        ];
        Object.keys(practice1).forEach((key) => {
            if (stringValKeys.includes(key)) {
                try {
                    //@ts-ignore
                    const newVal = getDiffByString(practice1[key] as string, practice2[key] as string);
                    if (newVal) {
                        //@ts-ignore
                        ret.practice[`${key}New`] = newVal;
                    }
                } catch (e) {
                    console.log(e);
                }
            }
            if (stringArrayValKeys.includes(key)) {
                try {
                    //@ts-ignore
                    const newVal = getDiffByStringArray(practice1[key] as string[], practice2[key] as string[]);
                    if (newVal) {
                        //@ts-ignore
                        ret.practice[`${key}New`] = newVal;
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        });
    }

    if (oldData.insurance || newData.insurance) {
        const insurance1 = newData.insurance;
        const insurance2 = oldData.insurance;
        if (!insurance1 && insurance2) {
            //spe case
            ret.insurance = {
                ...insurance2,
                insuranceListNew: insurance2.insuranceList,
                otherInsuranceListNew: insurance2.otherInsuranceList,
                supportPayTypeNew: insurance2.supportPayType,
            };
        } else {
            const stringValKeys = [
                'otherInsuranceList',
                'supportPayType',
            ];
            const stringArrayValKeys = [
                'insuranceList',
            ];
            Object.keys(insurance1).forEach((key) => {
                if (stringValKeys.includes(key)) {
                    try {
                        //@ts-ignore
                        const newVal = getDiffByString(insurance1[key] as string, insurance2[key] as string);
                        if (newVal) {
                            //@ts-ignore
                            ret.insurance[`${key}New`] = newVal;
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }
                if (stringArrayValKeys.includes(key)) {
                    try {
                        //@ts-ignore
                        const newVal = getDiffByStringArray(insurance1[key] as string[], insurance2[key] as string[]);
                        if (newVal) {
                            //@ts-ignore
                            ret.insurance[`${key}New`] = newVal;
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }
            });
        }
    }

    if (oldData.malpracticeInsuranceCertificate || newData.malpracticeInsuranceCertificate) {
        const malpracticeInsuranceCertificate1 = oldData.malpracticeInsuranceCertificate;
        const malpracticeInsuranceCertificate2 = newData.malpracticeInsuranceCertificate;
        if (!malpracticeInsuranceCertificate1 && malpracticeInsuranceCertificate2) {
            ret.malpracticeInsuranceCertificate = {
                ...malpracticeInsuranceCertificate2,
                isNewAdd: true,
            };
        } else if (malpracticeInsuranceCertificate1 && !malpracticeInsuranceCertificate2) {
            ret.malpracticeInsuranceCertificate = {
                ...malpracticeInsuranceCertificate1,
                isDeleted: true,
            };
        } else {
            const stringValKeys = [
                'type',
                'secondType',
                'state',
                'licenseNumber',
                'expireDate',
                'url',
                'certificatedTitle',
                'classTitleNew',
            ];
            const numberValKeys = [
                'hourCompleted',
            ];
            const stringArrayValKeys = [
                'schedule',
            ];
            Object.keys(malpracticeInsuranceCertificate1!).forEach((key) => {
                if (stringValKeys.includes(key)) {
                    try {
                        //@ts-ignore
                        const newVal = getDiffByString(malpracticeInsuranceCertificate1[key] as string, malpracticeInsuranceCertificate2[key] as string);
                        if (newVal) {
                            //@ts-ignore
                            ret.malpracticeInsuranceCertificate[`${key}New`] = newVal;
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }
                if (numberValKeys.includes(key)) {
                    try {
                        //@ts-ignore
                        const newVal = getDiffByNumber(malpracticeInsuranceCertificate1[key] as number, malpracticeInsuranceCertificate2[key] as number);
                        if (newVal) {
                            //@ts-ignore
                            ret.malpracticeInsuranceCertificate[`${key}New`] = newVal;
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }
                if (stringArrayValKeys.includes(key)) {
                    try {
                        //@ts-ignore
                        const newVal = getDiffByStringArray(malpracticeInsuranceCertificate1[key] as string[], malpracticeInsuranceCertificate2[key] as string[]);
                        if (newVal) {
                            //@ts-ignore
                            ret.malpracticeInsuranceCertificate[`${key}New`] = newVal;
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }
            });
        }
    }
    if (oldData.licenses || newData.licenses) {
        if (!ret.licenses) {
            ret.licenses = {
                [ELicenseType.DEA]: [],
                [ELicenseType.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES]: [],
                [ELicenseType.CONTROLLED_SUBSTAN_CECERTIFICATION]: [],
                [ELicenseType.PRACTICE]: [],
            };
        }
        //ret.licenses[ELicenseType.DEA] = [];
        //ret.licenses[ELicenseType.PRACTICE] = [];
        //dea
        const changeDeaIds:number[] = (ret.licenses[ELicenseType.DEA] || []).map((item) => item.id);
        const changeDeaLicense:FormLicenseShadow[] = ret.licenses[ELicenseType.DEA] || [];
        const oldDeaLicense:FormLicenseShadow[] = oldData.licenses?.[ELicenseType.DEA] || [];
        changeDeaLicense.forEach((item1) => {
            const find = oldDeaLicense.find((item2) => {
                return item1.id === item2.id;
            });
            //diff from find and org item
            if (!find) {
                item1.isNewAdd = true;
            } else {
                const stringValKeys = [
                    'type',
                    'secondType',
                    'state',
                    'licenseNumber',
                    'url',
                    'certificatedTitle',
                    'classTitleNew',
                ];
                const numberValKeys = [
                    'hourCompleted',
                ];
                const stringArrayValKeys = [
                    'schedule',
                ];
                Object.keys(item1!).forEach((key) => {
                    if (stringValKeys.includes(key)) {
                        try {
                            //@ts-ignore
                            const newVal = getDiffByString(item1[key] as string, find[key] as string);
                            if (newVal) {
                                //@ts-ignore
                                item1[`${key}New`] = newVal;
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    }
                    if (numberValKeys.includes(key)) {
                        try {
                            //@ts-ignore
                            const newVal = getDiffByNumber(item1[key] as number, find[key] as number);
                            if (newVal) {
                                //@ts-ignore
                                item1[`${key}New`] = newVal;
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    }
                    if (stringArrayValKeys.includes(key)) {
                        try {
                            //@ts-ignore
                            const newVal = getDiffByStringArray(item1[key] as string[], find[key] as string[]);
                            if (newVal) {
                                //@ts-ignore
                                radd[`${key}New`] = newVal;
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    }
                });
            }
        });

        oldDeaLicense.forEach((item1) => {
            const isDeleted = !changeDeaIds.includes(item1.id);
            if (isDeleted) {
                const add:FormLicenseShadow = { ...item1, isDeleted: true };
                ret.licenses![ELicenseType.DEA]!.push(add);
            }
        });

        //end dea

        //practice
        const changePracticeIds:number[] = (ret.licenses[ELicenseType.PRACTICE] || []).map((item) => item.id!);
        const changePracticeLicense:FormLicenseShadow[] = ret.licenses[ELicenseType.PRACTICE] || [];
        const oldPracticeLicense:FormLicenseShadow[] = oldData.licenses?.[ELicenseType.PRACTICE] || [];
        changePracticeLicense.forEach((item1) => {
            const find = oldPracticeLicense.find((item2) => {
                return item1.id === item2.id;
            });
            //diff from find and org item
            if (!find) {
                item1.isNewAdd = true;
            } else {
                const stringValKeys = [
                    'type',
                    'secondType',
                    'state',
                    'licenseNumber',
                    'url',
                    'certificatedTitle',
                    'classTitleNew',
                ];
                const numberValKeys = [
                    'hourCompleted',
                ];
                const stringArrayValKeys = [
                    'schedule',
                ];
                Object.keys(item1!).forEach((key) => {
                    if (stringValKeys.includes(key)) {
                        try {
                            //@ts-ignore
                            const newVal = getDiffByString(item1[key] as string, find[key] as string);
                            if (newVal) {
                                //@ts-ignore
                                item1[`${key}New`] = newVal;
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    }
                    if (numberValKeys.includes(key)) {
                        try {
                            //@ts-ignore
                            const newVal = getDiffByNumber(item1[key] as number, find[key] as number);
                            if (newVal) {
                                //@ts-ignore
                                item1[`${key}New`] = newVal;
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    }
                    if (stringArrayValKeys.includes(key)) {
                        try {
                            //@ts-ignore
                            const newVal = getDiffByStringArray(item1[key] as string[], find[key] as string[]);
                            if (newVal) {
                                //@ts-ignore
                                radd[`${key}New`] = newVal;
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    }
                });
            }
        });

        oldPracticeLicense.forEach((item1) => {
            const isDeleted = !changePracticeIds.includes(item1.id);
            if (isDeleted) {
                const add:FormLicenseShadow = { ...item1, isDeleted: true };
                ret.licenses![ELicenseType.PRACTICE]!.push(add);
            }
        });

        //end practice
    }

    if ((oldData?.services || newData?.services) && false) {
        ret.services = [];
        const changeIds:number[] = (newData?.services || []).map((item) => item.id!);
        const changeItems:ServiceExt[] = newData?.services || [];
        const oldItems:ServiceExt[] = oldData?.services || [];
        changeItems.forEach((item1) => {
            const add:ServiceExtShadow = { ...item1 };
            const find = oldItems.find((item2) => {
                return item1.id === item2.id;
            });
            //diff from find and org item
            if (!find) {
                add.isNewAdd = true;
            } else {
                const stringValKeys = [
                    'description',
                    'name',
                    'personAddress',
                    'personCity',
                    'personState',
                    'personType',
                    'personZip',

                ];
                const numberValKeys = [
                    'duration',
                    'price',
                    'lowestPrice',
                    'highestPrice',
                ];
                Object.keys(item1!).forEach((key) => {
                    if (stringValKeys.includes(key)) {
                        try {
                            //@ts-ignore
                            const newVal = getDiffByString(item1[key] as string, find[key] as string);
                            if (newVal) {
                                //@ts-ignore
                                add[`${key}New`] = newVal;
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    }
                    if (numberValKeys.includes(key)) {
                        try {
                            //@ts-ignore
                            const newVal = getDiffByNumber(item1[key] as number, find[key] as number);
                            if (newVal) {
                                //@ts-ignore
                                add[`${key}New`] = newVal;
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    }
                });
            }
            ret.services.push(add);
        });

        oldItems.forEach((item1) => {
            const isDeleted = !changeIds.includes(item1.id!);
            if (isDeleted) {
                const add:ServiceExtShadow = { ...item1, isDeleted: true };
                ret.services.push(add);
            }
        });

        //end dea
    }
    return ret;
};
