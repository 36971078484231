import React, { useMemo } from 'react';
import s from './s.module.less';

interface IProps {
    status: string;
}

const PaymentStatusTag = ({
    status,
}: IProps) => {
    const tagConf = useMemo(() => {
        let text = '';
        let cls = '';

        // 兼容后端数据返回key或者content
        switch (status) {
            case 'Paid':
            case 'paid':
                text = 'Successful';
                cls = 'success';
                break;
            case 'Payment failed':
            case 'failed':
                text = 'Failed';
                cls = 'faild';
                break;
            case 'no_needed':
            case 'Free':
                text = 'No payment needed';
                cls = 'noneed';
                break;
            default:
                text = 'No payment needed';
                cls = 'noneed';
        }

        return {
            cls,
            text,
        };
    }, [status]);
    return (
        <div className={`${s.status} ${s[tagConf.cls]}`}>{tagConf.text}</div>
    );
};

export default PaymentStatusTag;
