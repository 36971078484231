// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--V0kUG {
  background: var(--main-bg-color);
  padding: 48px;
  min-height: 100vh;
}
.s-module__wrap--V0kUG .s-module__header--z53Ad {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.s-module__wrap--V0kUG .s-module__header--z53Ad .s-module__breadcrumb--CJoZF {
  margin-bottom: 8px;
}
.s-module__wrap--V0kUG .s-module__header--z53Ad .s-module__breadcrumb--CJoZF .ant-breadcrumb-separator {
  margin-inline: 23px;
}
.s-module__wrap--V0kUG .s-module__header--z53Ad .s-module__title--aSKii {
  font-weight: 700;
  font-size: 30px;
  color: var(--gray-900);
}
.s-module__wrap--V0kUG .s-module__header--z53Ad .s-module__buttons--KNm3e button + button {
  margin-left: 16px;
}
.s-module__wrap--V0kUG .s-module__footer--JYaIp button + button {
  margin-left: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/PreviewWrapper/s.module.less"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,aAAA;EACA,iBAAA;AACF;AAJA;EAMI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AACJ;AAVA;EAgBM,kBAAA;AAHN;AAbA;EAaQ,mBAAA;AAGR;AAhBA;EAoBM,gBAAA;EACA,eAAA;EACA,sBAAA;AADN;AArBA;EA2BQ,iBAAA;AAHR;AAxBA;EAkCM,iBAAA;AAPN","sourcesContent":[".wrap {\n  background: var(--main-bg-color);\n  padding: 48px;\n  min-height: 100vh;\n\n  .header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 16px;\n\n    .breadcrumb {\n      :global(.ant-breadcrumb-separator) {\n        margin-inline: 23px;\n      }\n\n      margin-bottom: 8px;\n    }\n\n    .title {\n      font-weight: 700;\n      font-size: 30px;\n      color: var(--gray-900);\n    }\n\n    .buttons {\n      button+button {\n        margin-left: 16px;\n      }\n    }\n  }\n\n  .footer {\n    button+button {\n      margin-left: 16px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--V0kUG`,
	"header": `s-module__header--z53Ad`,
	"breadcrumb": `s-module__breadcrumb--CJoZF`,
	"title": `s-module__title--aSKii`,
	"buttons": `s-module__buttons--KNm3e`,
	"footer": `s-module__footer--JYaIp`
};
export default ___CSS_LOADER_EXPORT___;
