import React, { useCallback, useMemo, useState } from 'react';
import s from './s.module.less';
import { Button, Popover, Spin, message } from 'antd';
import { TSubscriptionSubType, TSubscriptionType, TSubscriptionItemFromServer } from 'types/subscription';
import { getPayLink } from 'api/subscription';
import { isFreeUser, isPaidFailUserByInfo } from 'utils/provider';
import { Email } from 'constants/contact';
import Question from 'assets/common/Question.svg';
import { isTrialUser } from 'constants/subscription';

interface IProps {
    id?: number;
    currentPlan?: TSubscriptionItemFromServer;
    type: TSubscriptionType,
    subType?: TSubscriptionSubType,
    name: string;
    featureList?: Array<{ name: string, desc?: string }>;
    highlight?: string;
    price: number;
    myPlanLevel: number;
}

const KlarityPlanCard = ({
    myPlanLevel,
    id,
    currentPlan,
    type,
    name = '',
    featureList = [],
    highlight,
    price = 0,
    subType = TSubscriptionSubType.MONTHLY_BILLING,
}: IProps) => {
    const [loading, setLoading] = useState(false);

    const handleContactOPS = () => {
        const link = `mailto:${Email.support}`;
        window.open(link, '_self');
    };

    const handleSubscript = async () => {
        if (!id) {
            message.error('Plan not set');
            return;
        }
        if (type === TSubscriptionType.KLARITY_STARTER) {
            //if click type is free just contact us
            const link = `mailto:${Email.support}`;
            window.open(link, '_self');
            return;
        }
        setLoading(true);
        const result = await getPayLink(id);
        if (!result.error) {
            const link = result.data.data;
            if (link) {
                window.open(link, '_self');
            } else {
                message.error('Pay link not found');
            }
        }

        setLoading(false);
    };

    const billingType = useMemo(() => {
        if (type === TSubscriptionType.KLARITY_STARTER) {
            return {
                billedType: '',
                period: '',
            };
        } else {
            let res = {
                billedType: 'Billed monthly',
                period: 'month',
            };
            switch (subType) {
                case TSubscriptionSubType.QUARTERLY_BILLING:
                    res = {
                        billedType: 'Billed quarterly',
                        period: 'quarter',
                    };
                    break;
                case TSubscriptionSubType.YEARLY_BILLING:
                    res = {
                        billedType: 'Billed yearly',
                        period: 'year',
                    };
                    break;
                default: res = {
                    billedType: 'Billed monthly',
                    period: 'month',
                };
            }

            return res;
        }
    }, [subType, type]);

    const buttonRender = () => {
        if (myPlanLevel === 1 && type === TSubscriptionType.KLARITY_STARTER) {
            return <Button className={s.actionButton} disabled>Current plan</Button>;
        } else if (myPlanLevel === 2) {
            if (type === TSubscriptionType.KLARITY_STARTER) {
                return <Button type="primary" className={s.actionButton} onClick={handleContactOPS}>Downgrade</Button>;
            }
            if (type === TSubscriptionType.KLARITY_PLUS) {
                return <Button className={s.actionButton} disabled>Current plan</Button>;
            }
            if (type === TSubscriptionType.KLARITY_PRO) {
                return <Button type="primary" className={s.actionButton} onClick={handleContactOPS}>Upgrade</Button>;
            }
        } else if (myPlanLevel === 3) {
            if (type === TSubscriptionType.KLARITY_STARTER) {
                return <Button type="primary" className={s.actionButton} onClick={handleContactOPS}>Downgrade</Button>;
            }
            if (type === TSubscriptionType.KLARITY_PLUS) {
                return <Button type="primary" className={s.actionButton} onClick={handleContactOPS}>Downgrade</Button>;
            }
            if (type === TSubscriptionType.KLARITY_PRO) {
                return <Button className={s.actionButton} disabled>Current plan</Button>;
            }
        } else {
            return <Button type="primary" className={s.actionButton} onClick={handleSubscript}>Upgrade</Button>;
        }
    };

    return (
        <Spin spinning={loading}>
            <div className={s.wrap}>
                <div className={s.priceWrap}>
                    <div className={s.titleBox}>
                        <div className={s.title}>{name}</div>
                        {highlight && <div className={s.tag}>{highlight}</div>}
                    </div>
                    <div className={s.priceBox}>
                        {
                            type !== TSubscriptionType.FREE &&
                            <>
                                <span className={s.price}>${price}</span>
                                <span className={s.type}>/{billingType.period}</span>
                            </>
                        }
                    </div>
                    <div className={s.billedType}>{billingType.billedType}</div>
                    {buttonRender()}
                </div>
                <div className={s.featureListBox}>
                    <div className={s.featureTitle}>What’s included</div>
                    <div className={s.featureList}>
                        {
                            (featureList || []).map((feature, index) => {
                                return (
                                    <div key={index} className={s.featureItem}>
                                        <div className={s.featureText}>{feature?.name}</div>
                                        {feature?.desc &&
                                            <Popover content={<div className={s.featureDesc}>{feature?.desc}</div>} title={null}>
                                                <img src={Question} />
                                            </Popover>}
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </Spin>
    );
};

export default KlarityPlanCard;
