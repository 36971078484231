import React, { useEffect, useState } from 'react';
import { getPFStatusData, setPFTheme } from 'api/practiceFront';
import s from './s.module.less';
import cx from 'classnames';
import { Spin } from 'antd';
import useRefreshPreview from 'hooks/useRefreshPreview';

type TTheme = {
    name: string
};

const THEME_ARR: TTheme[] = [
    {
        name: 'Default',
    },
    {
        name: 'LightBlue',
    },
    {
        name: 'Cyan',
    },
    {
        name: 'Green',
    },
];

const ThemeInfo = () => {
    const [theme, setTheme] = useState<string>(THEME_ARR[0].name);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [setPreview] = useRefreshPreview();
    const getInfo = async () => {
        try {
            setIsLoading(true);
            const res = await getPFStatusData();
            if (!res?.error) {
                setTheme(res.data?.data?.theme || THEME_ARR[0].name);
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    const handleChooseTheme = async (item: TTheme) => {
        try {
            setIsLoading(true);
            const res = await setPFTheme(item.name);
            if (res?.data?.data) {
                setTheme(item.name);
                setPreview('flag', {});
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getInfo();
    }, []);
    return (
        <div>
            <Spin spinning={isLoading}>
                <h3 className={s.title}>Theme</h3>
                <p className={s.desc}>Choose the theme of your website</p>
                <div className={s.list}>
                    {THEME_ARR.map((item: TTheme) => {
                        return (
                            <div
                                className={cx(
                                    s.item,
                                    s[item.name],
                                    theme === item.name ? s.active : '',
                                )}
                                onClick={() => handleChooseTheme(item)}
                            />
                        );
                    })}
                </div>
            </Spin>
        </div>
    );
};

export default ThemeInfo;
