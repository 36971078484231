// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--Vop72 {
  padding: 30px 24px;
  border: 1px solid var(--gray);
  border-radius: 10px;
  font-family: 'Inter';
  font-style: normal;
}
.s-module__wrap--Vop72 .s-module__header--YW88T {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
}
.s-module__wrap--Vop72 .s-module__header--YW88T .s-module__title--aksj1 {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: var(--gray-700);
}
.s-module__wrap--Vop72 .s-module__header--YW88T .s-module__btns--t6YyT button {
  margin-left: 8px;
}
.s-module__wrap--Vop72 .s-module__body--B3oY1 .s-module__item--h3hsx {
  margin-bottom: 16px;
}
.s-module__wrap--Vop72 .s-module__noncontent--dlVzC {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray-500);
}
`, "",{"version":3,"sources":["webpack://./src/components/PracticeFrontFromComponents/Education/s.module.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,6BAAA;EACA,mBAAA;EACA,oBAAA;EACA,kBAAA;AACJ;AANA;EAQQ,mBAAA;EACA,aAAA;EACA,8BAAA;AACR;AAXA;EAaY,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AACZ;AAjBA;EAqBgB,gBAAA;AADhB;AApBA;EA4BY,mBAAA;AALZ;AAvBA;EAiCQ,oBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AAPR","sourcesContent":[".wrap {\n    padding: 30px 24px;\n    border: 1px solid var(--gray);\n    border-radius: 10px;\n    font-family: 'Inter';\n    font-style: normal;\n\n    .header {\n        margin-bottom: 16px;\n        display: flex;\n        justify-content: space-between;\n\n        .title {\n            font-weight: 500;\n            font-size: 20px;\n            line-height: 20px;\n            color: var(--gray-700);\n        }\n    \n        .btns {\n            button {\n                margin-left: 8px;\n            }\n        }\n    }\n\n    .body {\n        .item {\n            margin-bottom: 16px;\n        }\n    }\n\n    .noncontent {\n        font-family: 'Inter';\n        font-style: normal;\n        font-weight: 400;\n        font-size: 14px;\n        line-height: 20px;\n        color: var(--gray-500);\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--Vop72`,
	"header": `s-module__header--YW88T`,
	"title": `s-module__title--aksj1`,
	"btns": `s-module__btns--t6YyT`,
	"body": `s-module__body--B3oY1`,
	"item": `s-module__item--h3hsx`,
	"noncontent": `s-module__noncontent--dlVzC`
};
export default ___CSS_LOADER_EXPORT___;
