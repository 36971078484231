// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--NGR2L {
  padding: 24px;
}
.s-module__wrap--NGR2L .s-module__title--a9Q4E {
  padding-top: 24px;
  margin-bottom: 24px;
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: var(--black);
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/TermsNConditions/s.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AAFA;EAIQ,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;AACR","sourcesContent":[".wrap {\n    padding: 24px;\n\n    .title {\n        padding-top: 24px;\n        margin-bottom: 24px;\n        font-family: Inter;\n        font-size: 24px;\n        font-weight: 600;\n        line-height: 32px;\n        color: var(--black);\n        text-align: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--NGR2L`,
	"title": `s-module__title--a9Q4E`
};
export default ___CSS_LOADER_EXPORT___;
