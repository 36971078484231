import React from 'react';
import { Select } from 'antd';
import s from './s.module.less';
import { TimeItemType } from 'types/appointmentSetting';

type TimeRangerPickerProps = {
    value?: TimeItemType,
    onChange?: (val: TimeItemType) => void, //start and end time
};

type TimeOption = {
    label:string,
    value:number[],
};

const TimePicker = (props:TimeRangerPickerProps) => {
    const { onChange, value } = props;
    const [start, setStart] = React.useState('');
    const [end, setEnd] = React.useState('');
    const time = [0, 15, 30, 45];
    const options:TimeOption[] = [];

    React.useEffect(() => {
        if (value) {
            const { fromHour, fromMinute, toHour, toMinute } = value;
            if (fromHour !== undefined && fromMinute !== undefined && toHour !== undefined && toMinute !== undefined) {
                setStart(`${fromHour},${fromMinute}`);
                setEnd(`${toHour},${toMinute}`);
            }
        }
    }, [start, end, value]);

    for (let i = 0; i < 12; i++) {
        for (let j = 0; j < time.length; j++) {
            const label = `${i >= 10 ? i : `0${i}`}:${j === 0 ? '00' : time[j]} AM`;
            options.push({
                label,
                value: [i, time[j]],
            });
        }
    }
    for (let j = 0; j < time.length; j++) {
        const label = `12:${j === 0 ? '00' : time[j]} PM`;
        options.push({
            label,
            value: [12, time[j]],
        });
    }
    for (let i = 1; i < 12; i++) {
        for (let j = 0; j < time.length; j++) {
            const label = `${i >= 10 ? i : `0${i}`}:${j === 0 ? '00' : time[j]} PM`;
            options.push({
                label,
                value: [12 + i, time[j]],
            });
        }
    }

    return (
        <div className={s.wrap}>
            <div className={s.timePicker}>
                <Select
                    onChange={(val) => {
                        setStart(val);
                        const startVal = (val && val.split(',')) || [];
                        const endVal = (end && end.split(',')) || [];
                        let useEnd = end;
                        let needChange = false;
                        if (!useEnd) {
                            needChange = true;
                        }
                        if (endVal.length > 0 && startVal.length === 2) {
                            if (parseInt(endVal[0], 10) < parseInt(startVal[0], 10)) {
                                needChange = true;
                            } else if (parseInt(endVal[0], 10) === parseInt(startVal[0], 10)) {
                                if (parseInt(endVal[1], 10) <= parseInt(startVal[1], 10)) {
                                    needChange = true;
                                }
                            }
                        }
                        if (needChange && startVal.length === 2) {
                            const newEndValues = [0, 0];
                            if (startVal[0] === '23' && startVal[1] === '30') {
                                newEndValues[0] = 23;
                                newEndValues[1] = 45;
                            } else if (parseInt(startVal[1], 10) === 0) {
                                newEndValues[0] = parseInt(startVal[0], 10);
                                newEndValues[1] = 30;
                            } else if (parseInt(startVal[1], 10) === 15) {
                                newEndValues[0] = parseInt(startVal[0], 10);
                                newEndValues[1] = 45;
                            } else if (parseInt(startVal[1], 10) === 30) {
                                newEndValues[0] = parseInt(startVal[0], 10) + 1;
                                newEndValues[1] = 0;
                            } else if (parseInt(startVal[1], 10) === 45) {
                                newEndValues[0] = parseInt(startVal[0], 10) + 1;
                                newEndValues[1] = 15;
                            }
                            setEnd(newEndValues.join(','));
                            useEnd = newEndValues.join(',');
                            const newEndVal = (useEnd && useEnd.split(',')) || [];
                            if (startVal.length > 0 && newEndVal.length > 0) {
                                onChange?.({
                                    fromHour: parseInt(startVal[0], 10),
                                    fromMinute: parseInt(startVal[1], 10),
                                    toHour: parseInt(newEndVal[0], 10),
                                    toMinute: parseInt(newEndVal[1], 10),
                                });
                            }
                            return;
                        }
                        if (startVal.length > 0 && endVal.length > 0) {
                            onChange?.({
                                fromHour: parseInt(startVal[0], 10),
                                fromMinute: parseInt(startVal[1], 10),
                                toHour: parseInt(endVal[0], 10),
                                toMinute: parseInt(endVal[1], 10),
                            });
                        }
                    }}
                    className={s.timeSelect}
                    value={start}
                >
                    {
                        options.slice(0, -1).map((option) => {
                            return (
                                <Select.Option
                                    key={option.label}
                                    value={option.value.join(',')}
                                >
                                    {option.label}
                                </Select.Option>
                            );
                        })
                    }
                </Select>
                <span className="ml-4 mr-4">to</span>
                <Select
                    onChange={(val) => {
                        setEnd(val);
                        const startVal = (start && start.split(',')) || [];
                        const endVal = (val && val.split(',')) || [];
                        if (startVal.length > 0 && endVal.length > 0) {
                            onChange?.({
                                fromHour: parseInt(startVal[0], 10),
                                fromMinute: parseInt(startVal[1], 10),
                                toHour: parseInt(endVal[0], 10),
                                toMinute: parseInt(endVal[1], 10),
                            });
                        }
                    }}
                    className={s.timeSelect}
                    value={end}
                >
                    {
                        options.map((option) => {
                            let disable = false;
                            if (start) {
                                const startValues = start.split(',');
                                if (parseInt(startValues[0], 10) > option.value[0]) {
                                    disable = true;
                                } else if (parseInt(startValues[0], 10) === option.value[0]) {
                                    disable = parseInt(startValues[1], 10) >= option.value[1];
                                }
                            }
                            return (
                                <Select.Option
                                    key={option.label}
                                    value={option.value.join(',')}
                                    disabled={disable}
                                >
                                    {option.label}
                                </Select.Option>
                            );
                        })
                    }
                </Select>
            </div>
        </div>

    );
};

export default TimePicker;
