import React, { useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { Button } from 'antd';
import { ISpecialtiesApproaches } from 'types/helloKlarity';
// import { saveSpecialtyHighlight } from 'api/operation';

interface IProps {
    providerId?: number;
    setSpecialtiesIsEdting?: (status: boolean) => void;
    specialtyHighlightList: string[];
    initFormValue: ISpecialtiesApproaches,
    isEditing?: boolean;
    refetch?: () => void;
    onSaveSuccess?: (value: string[]) => void;
}

const SpecialtiesConditions = ({
    // providerId,
    setSpecialtiesIsEdting,
    specialtyHighlightList,
    isEditing,
    initFormValue,
    // refetch,
    onSaveSuccess,
}: IProps) => {
    const {
        specialtyList = [],
        conditionTreatedList = [],
    } = initFormValue || {};

    const [highlights, setHighlights] = useState<string[]>([]);
    // const [loading, setLoading] = useState(false);

    useEffect(() => {
        setHighlights(specialtyHighlightList);
    }, [specialtyHighlightList]);

    const handleHighlight = useCallback((v: string) => {
        if (isEditing) {
            const newSet = new Set(highlights);
            const hasHighlight = newSet.has(v);

            if (hasHighlight) {
                newSet.delete(v);
            } else {
                newSet.add(v);
            }

            setHighlights(Array.from(newSet));
        }
    }, [highlights, isEditing]);

    const handleSave = useCallback(() => {
        onSaveSuccess?.(highlights);
        setSpecialtiesIsEdting?.(false);
        // setLoading(true);
        // try {
        //     const res = await saveSpecialtyHighlight({
        //         providerId,
        //         specialtyHighlight: value.join(','),
        //     });

        //     if (res && !res.error) {
        //         setSpecialtiesIsEdting?.(false);
        //         refetch?.();
        //         onSaveSuccess?.(highlights);
        //     } else {
        //         message.error(res.error);
        //     }
        // } catch (e) {
        //     console.error(e);
        // }
        // setLoading(false);
    }, [highlights, onSaveSuccess, setSpecialtiesIsEdting]);

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <div className={s.title}>Specialties & Conditions</div>
                <div className={s.btns}>
                    {
                        isEditing ?
                            <>
                                <Button onClick={() => setSpecialtiesIsEdting?.(false)}>Cancel</Button>
                                <Button type="primary" onClick={handleSave}>Save highlights</Button>
                            </>
                            : <Button onClick={() => setSpecialtiesIsEdting?.(true)}>Add highlight</Button>
                    }
                </div>
            </div>
            <div className={s.content}>
                <div className={s.row}>
                    <div
                        className={s.displayItem}
                    >
                        <div className={`${s.label} ${s.required}`}>Specialties</div>
                        <div className={s.value}>
                            {
                                specialtyList && specialtyList.length > 0 ?
                                    specialtyList?.map((item, i) => {
                                        const highlight = highlights.includes(item);
                                        return (
                                            <div className={`${s.conditionTag} ${isEditing ? s.clickable : ''} ${highlight ? s.highlight : ''}`} key={i} onClick={() => handleHighlight(item)}>{item}</div>
                                        );
                                    }) :
                                    <div className={s.noncontent}>Nothing here yet</div>
                            }
                        </div>
                    </div>
                </div>
                <div className={s.row}>
                    <div
                        className={s.displayItem}
                    >
                        <div className={`${s.label} ${s.required}`}>Conditions treated</div>
                        <div className={s.value}>
                            {
                                conditionTreatedList && conditionTreatedList.length > 0 ?
                                    conditionTreatedList?.map((item, i) => <div className={s.conditionTag} key={i}>{item}</div>) :
                                    <div className={s.noncontent}>Nothing here yet</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpecialtiesConditions;
