import React, { useCallback, useEffect, useMemo, useState } from 'react';
import s from './s.module.less';
import { Button, Checkbox, DatePicker, Form, Input, Select } from 'antd';
import { EditModuleEnum, FormFields, IEducation } from 'types/practiceFront';
import dayjs from 'utils/dayjs';
import commonS from 'styles/common.module.less';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields } from 'types/enumerationData';
import PhotoList from 'components/PhotoList';

interface IProps {
    readonly?: boolean;
    id?: number,
    index: number,
    data: IEducation,
    isEditting: boolean;
    onEditStatusChange: (status: boolean, index: number) => void;
    onSubmit: (value: IEducation, id?: number) => void;
    onRemove: (index: number, id?: number) => void;
}

const EducationItem = ({
    readonly,
    id,
    index,
    data,
    isEditting,
    onEditStatusChange,
    onSubmit,
    onRemove,
}: IProps) => {
    const [degreeOptions] = useGetSettingData(EnumFields.DEGREE);
    const [, forceUpdate] = useState({});
    const [formInstance] = Form.useForm<IEducation>();
    const formName = `${EditModuleEnum.EDUCATION}_${index}`;

    useEffect(() => {
        if (data) {
            formInstance.setFieldsValue(data);
        }
    }, [data, formInstance]);

    const {
        schoolName,
        major,
        degree,
        startDate,
        endDate,
        specialties,
        // schoolLogo,
        // certificates,
        currently,
    } = data;

    const formatDate = useCallback((day: dayjs.Dayjs) => day?.format('YYYY-MM'), []);
    const handleSubmit = useCallback(() => {
        formInstance.validateFields().then((values) => {
            onSubmit(values, id);
        }).catch((e) => {
            console.error(e);
        });
    }, [formInstance, id, onSubmit]);

    const currentEducation = formInstance.getFieldValue(FormFields.IS_PURSUING_DEGREE);

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <div className={s.label}>Education {index + 1}</div>
                {
                    !readonly &&
                    <div className={s.btns}>
                        {
                            isEditting ?
                                <>
                                    <Button onClick={() => onEditStatusChange(false, index)}>Cancel</Button>
                                    <Button type="primary" onClick={handleSubmit}>Save changes</Button>
                                </>
                                :
                                <>
                                    <Button onClick={() => onRemove(index, id)}>Remove</Button>
                                    <Button onClick={() => onEditStatusChange(true, index)}>Edit</Button>
                                </>
                        }
                    </div>
                }
            </div>
            <div className={s.intro}>
                {
                    !isEditting ?
                        <>
                            <div className={s.name}>{schoolName}</div>
                            <div className={s.title}>{degree} of {major}{specialties ? ` - ${specialties}` : ''}</div>
                            {
                                startDate &&
                                <div className={s.timeloc}>
                                    {
                                        currently ?
                                            <>
                                                <span>{formatDate(startDate)}</span>
                                                <span>-</span>
                                                <span>Present</span>
                                            </>
                                            :
                                            <>
                                                <span>{formatDate(startDate)}</span>
                                                <span>-</span>
                                                <span>{formatDate(endDate!)}</span>
                                            </>
                                    }
                                </div>
                            }
                        </>
                        :
                        <Form
                            form={formInstance}
                            name={formName}
                            className={commonS.formStyle1}
                            autoComplete="off"
                            layout="vertical"
                        >
                            <div className={s.itemBox}>
                                <div className={s.item}>
                                    <Form.Item
                                        label="School name"
                                        name={FormFields.SCHOOL_NAME}
                                        rules={[
                                            { required: true, message: 'school name is a required field' },
                                        ]}
                                    >
                                        <Input type="text" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.itemBox}>
                                <div className={s.item}>
                                    <Form.Item
                                        label="Major"
                                        name={FormFields.MAJOR}
                                        rules={[
                                            { required: true, message: 'major is a required field' },
                                        ]}
                                    >
                                        <Input type="text" placeholder="Ex. Nurse practitioner" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.itemBox}>
                                <div className={s.item}>
                                    <Form.Item
                                        label="Degree"
                                        name={FormFields.DEGREE}
                                        rules={[
                                            { required: true, message: 'degree is a required field' },
                                        ]}
                                    >
                                        <Select fieldNames={{ label: 'content', value: 'dictKey' }} options={degreeOptions} />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.itemBox}>
                                <div className={s.item}>
                                    <Form.Item
                                        label="Specialties"
                                        name={FormFields.SPECIALTIES}
                                        rules={[
                                            { required: false, message: 'specialties is a required field' },
                                        ]}
                                    >
                                        <Input type="text" placeholder="Ex. Family practice; use “,” to separate if there are multiple specialties" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.itemBox}>
                                <div className={s.item}>
                                    <Form.Item
                                        label="Certificates"
                                        name={FormFields.CERTIFICATES}
                                    >
                                        <Input type="text" placeholder="Ex. ANP-BC; use “,” to separate if there are multiple certificates" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.itemBox}>
                                <div className={s.item}>
                                    <Form.Item
                                        name={FormFields.IS_PURSUING_DEGREE}
                                        valuePropName="checked"
                                    >
                                        <Checkbox onChange={(e) => {
                                            if (e.target.checked) {
                                                formInstance.setFieldValue(FormFields.END_DATE, undefined);
                                                formInstance.validateFields();
                                            }
                                            forceUpdate({});
                                        }}
                                        >I am currently pursuing this degree
                                        </Checkbox>
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.itemBox}>
                                <div className={s.item}>
                                    <Form.Item
                                        label="Start Date"
                                        name={FormFields.START_DATE}
                                        rules={[
                                            { required: true, message: 'start date a required field' },
                                            {
                                                validator: (rules, value) => {
                                                    const endDateValue = formInstance.getFieldValue(FormFields.END_DATE);

                                                    if (value && !endDateValue && !currentEducation) {
                                                        return Promise.reject(
                                                            new Error('End date is required'),
                                                        );
                                                    }

                                                    if (endDateValue && endDateValue.isBefore(value)) {
                                                        return Promise.reject(
                                                            new Error('start date cannot be later than the end date'),
                                                        );
                                                    }
                                                    return Promise.resolve(true);
                                                },
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            onChange={() => {
                                                formInstance.validateFields();
                                                forceUpdate({});
                                            }}
                                            allowClear
                                            className={s.datepicker}
                                            picker="month"
                                            disabledDate={(current) => {
                                                const endDateValue = formInstance.getFieldValue(FormFields.END_DATE);
                                                if (!endDateValue || currentEducation) {
                                                    return false;
                                                }
                                                return current.valueOf() > endDateValue.valueOf();
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            {
                                !currentEducation &&
                                <div className={s.itemBox}>
                                    <div className={s.item}>
                                        <Form.Item
                                            label="End Date"
                                            name={FormFields.END_DATE}
                                            rules={[
                                                {
                                                    validator: (rules, value) => {
                                                        const startDateValue = formInstance.getFieldValue(FormFields.START_DATE);

                                                        if (value && !startDateValue) {
                                                            return Promise.reject(
                                                                new Error('Start date is required'),
                                                            );
                                                        }

                                                        if (startDateValue && value && value.isBefore(startDateValue)) {
                                                            return Promise.reject(
                                                                new Error('start date cannot be later than the end date'),
                                                            );
                                                        }

                                                        return Promise.resolve(true);
                                                    },
                                                },
                                            ]}
                                        >
                                            <DatePicker
                                                onChange={() => {
                                                    formInstance.validateFields();
                                                    forceUpdate({});
                                                }}
                                                allowClear
                                                className={s.datepicker}
                                                picker="month"
                                                disabledDate={(current) => {
                                                    const startDateValue = formInstance.getFieldValue(FormFields.START_DATE);
                                                    if (!startDateValue) {
                                                        return false;
                                                    }
                                                    return current.valueOf() < startDateValue.valueOf();
                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            }
                            <div className={s.itemBox}>
                                <div className={s.item}>
                                    <Form.Item
                                        label="School logo"
                                        name={FormFields.SCHOOL_LOGO}
                                        valuePropName="data"
                                    >
                                        <PhotoList min={1} enable responseType="string" />
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                }
            </div>
        </div>
    );
};

export default EducationItem;
