import { Meta } from 'types/common';

export const LICENSES:Meta[] = [
    { label: 'A-GNP', value: 'A-GNP' },
    { label: 'ACNPC-AG', value: 'ACNPC-AG' },
    { label: 'CPNP-AC', value: 'CPNP-AC' },
    { label: 'FNP', value: 'FNP' },
    { label: 'NPC', value: 'NPC' },
    { label: 'PA', value: 'PA' },
    { label: 'PA-C', value: 'PA-C' },
    { label: 'PMHNP', value: 'PMHNP' },
    { label: 'PNP', value: 'PNP' },
    { label: 'WHNP', value: 'WHNP' },
];

export const TREATEDS:Meta[] = [
    { label: 'ADHD', value: 'ADHD' },
    { label: 'Anxiety', value: 'Anxiety' },
    { label: 'Depression', value: 'Depression' },
    { label: 'Insomnia', value: 'Insomnia' },
    { label: 'Mood Disorders', value: 'Mood Disorders' },
];

export const SCHEDULE:Meta[] = [
    { label: 'Schedule I', value: 'Schedule I' },
    { label: 'Schedule II', value: 'Schedule II' },
    { label: 'Schedule III', value: 'Schedule III' },
    { label: 'Schedule IV', value: 'Schedule IV' },
    { label: 'Schedule V', value: 'Schedule V' },
];

export const NURSE_LICENSE_TYPES:Meta[] = [
    { label: 'AGANCNP-BC', value: 'AGANCNP-BC' },
    { label: 'AGCPNC-BC', value: 'AGCPNC-BC' },
    { label: 'APRN', value: 'APRN' },
    { label: 'ARNP', value: 'ARNP' },
    { label: 'CCRN', value: 'CCRN' },
    { label: 'CPNP-AC', value: 'CPNP-AC' },
    { label: 'FNP', value: 'FNP' },
    { label: 'NNP-BC', value: 'NNP-BC' },
    { label: 'NPC', value: 'NPC' },
    { label: 'PMHNP-BC', value: 'PMHNP-BC' },
    { label: 'PPCNP-BC', value: 'PPCNP-BC' },
    { label: 'WHNP-BC', value: 'WHNP-BC' },
];

export const OTHERS_KEY = 'others';

export const PATIENT_TYPES:Meta[] = [
    {
        label: 'Children (5-13 years old)',
        value: 'Children',
    },
    {
        label: 'Adolescents (13-18 years old)',
        value: 'Adolescents',
    },
    {
        label: 'Adults (18-65 years old)',
        value: 'Adults',
    },
    {
        label: 'Seniors (65+ years old)',
        value: 'Seniors',
    },
    {
        label: 'Couples and Family',
        value: 'Couples and Family',
    },
    {
        label: 'Group',
        value: 'Group',
    },
];

export const INTAKE_FORM_OPTIONS: Meta[] = [
    {
        label: 'Create a new intake form',
        value: '0',
    },
    {
        label: 'Initial visit intake form',
        value: 'Initial visit intake form',
    },
    {
        label: 'Follow-ups intake form',
        value: 'Follow-ups intake form',
    },
    {
        label: 'Refills intake form',
        value: 'Refills intake form',
    },
];

export const STATE:Meta[] = [
    { label: 'Alabama', value: 'AL' },
    { label: 'Alaska', value: 'AK' },
    { label: 'Arizona', value: 'AZ' },
    { label: 'Arkansas', value: 'AR' },
    { label: 'California', value: 'CA' },
    { label: 'Colorado', value: 'CO' },
    { label: 'Connecticut', value: 'CT' },
    { label: 'Delaware', value: 'DE' },
    { label: 'District of Columbia', value: 'DC' },
    { label: 'Florida', value: 'FL' },
    { label: 'Georgia', value: 'GA' },
    { label: 'Hawaii', value: 'HI' },
    { label: 'Idaho', value: 'ID' },
    { label: 'Illinois', value: 'IL' },
    { label: 'Indiana', value: 'IN' },
    { label: 'Iowa', value: 'IA' },
    { label: 'Kansas', value: 'KS' },
    { label: 'Kentucky', value: 'KY' },
    { label: 'Louisiana', value: 'LA' },
    { label: 'Maine', value: 'ME' },
    { label: 'Maryland', value: 'MD' },
    { label: 'Massachusetts', value: 'MA' },
    { label: 'Michigan', value: 'MI' },
    { label: 'Minnesota', value: 'MN' },
    { label: 'Mississippi', value: 'MS' },
    { label: 'Missouri', value: 'MO' },
    { label: 'Montana', value: 'MT' },
    { label: 'Nebraska', value: 'NE' },
    { label: 'Nevada', value: 'NV' },
    { label: 'New Hampshire', value: 'NH' },
    { label: 'New Jersey', value: 'NJ' },
    { label: 'New Mexico', value: 'NM' },
    { label: 'New York', value: 'NY' },
    { label: 'North Carolina', value: 'NC' },
    { label: 'North Dakota', value: 'ND' },
    { label: 'Ohio', value: 'OH' },
    { label: 'Oklahoma', value: 'OK' },
    { label: 'Oregon', value: 'OR' },
    { label: 'Pennsylvania', value: 'PA' },
    { label: 'Rhode Island', value: 'RI' },
    { label: 'South Carolina', value: 'SC' },
    { label: 'South Dakota', value: 'SD' },
    { label: 'Tennessee', value: 'TN' },
    { label: 'Texas', value: 'TX' },
    { label: 'Utah', value: 'UT' },
    { label: 'Vermont', value: 'VT' },
    { label: 'Virginia', value: 'VA' },
    { label: 'Washington', value: 'WA' },
    { label: 'West Virginia', value: 'WV' },
    { label: 'Wisconsin', value: 'WI' },
    { label: 'Wyoming', value: 'WY' },
];

export const COMPANY_TYPE: Meta[] = [
    {
        label: 'Sole proprietorship',
        value: '1',
    },
    {
        label: 'S Corporation',
        value: '2',
    },
    {
        label: 'Corporation',
        value: '3',
    },
    {
        label: 'Limited liability company  (LLC)',
        value: '4',
    },
    {
        label: 'Partnership',
        value: '5',
    },
];

export const EMPLOYMENT_TYPE: Meta[] = [
    {
        label: 'Full-time',
        value: '1',
    },
    {
        label: 'Part-time',
        value: '2',
    },
    {
        label: 'Per Diem',
        value: '3',
    },
    {
        label: 'Contract',
        value: '4',
    },
    {
        label: 'Temporary',
        value: '5',
    },
    {
        label: 'Self-employed',
        value: '6',
    },
    {
        label: 'Internship',
        value: '7',
    },
    {
        label: 'Residency',
        value: '8',
    },
];

export const DEGREE: Meta[] = [
    {
        label: "Bachelor's",
        value: '1',
    },
    {
        label: 'Master’s',
        value: '2',
    },
    {
        label: 'Doctorate',
        value: '3',
    },
];

export const FOREIGN_LANGUAGE = [
    {
        label: 'Spanish',
        value: 'Spanish',
    },
    {
        label: 'Mandarin',
        value: 'Mandarin',
    },
    {
        label: 'Tagalog',
        value: 'Tagalog',
    },
    {
        label: 'Vietnamese',
        value: 'Vietnamese',
    },
    {
        label: 'French',
        value: 'French',
    },
    {
        label: 'Arabic',
        value: 'Arabic',
    },
    {
        label: 'Korean',
        value: 'Korean',
    },
    {
        label: 'Japanese',
        value: 'Japanese',
    },
];
