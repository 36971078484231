import React, { useEffect, useState } from 'react';
import s from './s.module.less';
import { Swiper, SwiperSlide } from 'swiper/react';
import CloudImage from 'assets/uniprofileResume/cloud.svg';
import ProfileImage from 'assets/uniprofileResume/profileInroImage.png';
import EducationImage from 'assets/uniprofileResume/educationInroImage.png';
import { Input, Progress } from 'antd';
import mockProviderData from 'data/mockProviderData';
import { getCheckFieldsResult } from 'utils/uniprofile';
import { StepValue } from 'pages/UniprofileResume/types';
import { UniprofileFormData } from 'types/provider';
// import stepPercentageStore from 'store/UniprofileResume/stepPercentage';
import uniprofileResumeStore from 'store/UniprofileResume';

export const steps: Array<keyof UniprofileFormData> = [
    'profile',
    'experienceList',
    'educationList',
    'practice',
    'addtional',
    'licenses',
    'services',
];

// interface IProps {
//     resumeData?: UniprofileFormData
// }

const AnalysisResult = () => {
    const [getResumeInfo] = uniprofileResumeStore.useStore();
    const resumeData = getResumeInfo('data');
    // const [,setPercentageStore] = stepPercentageStore.useStore();
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        if (resumeData) {
            const percentageArray: Record<string, number>[] = steps.map((fieldName) => {
                const a = getCheckFieldsResult(resumeData, fieldName);

                return {
                    [fieldName]: a.percent,
                };
            });

            // const percenageStoreData = percentageArray.reduce((r, c) => {
            //     const [k, v] = Object.entries(c)[0];
            //     r[k] = v;
            //     return r;
            // }, {});

            // setPercentageStore('data', percenageStoreData);

            const calcu = percentageArray.reduce((r:number, c: Record<string, number>) => {
                const numberVal = Object.values(c)[0];
                const result = Number.isNaN(r) ? 0 : r;
                const current = Number.isNaN(numberVal) ? 0 : numberVal;

                return result + current;
            }, 0) / percentageArray.length;

            setPercentage(Math.ceil(calcu * 100));
        }
    }, [resumeData]);
    return (
        <div className={s.wrap}>
            <div className={s.title}>Your UniProfile is {percentage}% Complete!</div>
            <div className={s.progressBar}>
                <Progress type="circle" percent={percentage} />
            </div>
            <div className={s.desc}>{'We\'ve used your resume to fill out as much of your UniProfile as possible.'}</div>
            {/* <div className={s.line} />
            <div className={s.title}>Add existing website link to enhance your profile</div>
            <div className={s.websiteAddionBox}>
                <div className={s.image} />
                <div className={s.contentWrap}>
                    <div className={s.desc}>
                        <p>Is your resume missing some details? No problem! Just add a link to your professional website. Ava, our AI, will use it to enhance your UniProfile.</p>
                        <p>For optimal compatibility, ensure your website was created before April 2023.</p>
                        <p>Note: In case of any discrepancies, your resume information will take precedence.</p>
                    </div>
                    <Input addonBefore="https://" disabled />
                </div>
            </div> */}
        </div>
    );
};

export default AnalysisResult;
