import React, { useState } from 'react';
import { Form, Button, DatePicker, Input, message, Tag } from 'antd';
import { License } from 'types/common';
import { FormLicense } from 'types/form';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import FileUpload from 'components/form/FileUpload';
import { providerLicenseUpdate } from 'api/provider';
import { opLicenseUpdate } from 'api/operation';
import { formatDate } from 'utils/common';
import { FormLicenseShadow, FormMutipleLicense } from 'types/provider';
import { LicenseData } from 'data/provider';
import LicenseImg from 'components/LicenseImg';
import StateSelect from 'components/form/StateSelect';
import useDeleteLicense from 'hooks/useDeleteLicense';
import { renderChangUI } from 'types/changeHighLight';
import { SNAPSHOT_COLOR } from 'constants/common';

type Props = {
    inited?:boolean,
    initValue?: FormMutipleLicense,
    state?:string,
    providerId?:number,
    onChange?: (value:FormMutipleLicense)=>void
    viewOnly?:boolean,
    onNullState?: ()=>void,
    isOperation?:boolean,
    onEditableChange?: (editing: boolean) => void,
    doVerifyFlag?: {},
    onVerified?: ()=>void,
    refetch?: () => void,
};

const PracticeLicense = (props: Props) => {
    const { inited = false, initValue, providerId, onChange, viewOnly, onNullState, onEditableChange, isOperation, doVerifyFlag, onVerified, refetch } = props;
    const [submiting, setSubmiting] = React.useState(false);

    //enable and show modal is little function cover..

    const [enable, setEnable] = React.useState(!isOperation);
    const [hasInit, setHasInit] = React.useState(false);

    //mean already filled and display modal
    const [form] = Form.useForm<FormMutipleLicense>();
    const [,forceUpdate] = React.useState({});
    const [hasValues, setHasValues] = React.useState(false);
    const [onRemove, handleRemove] = useDeleteLicense({
        form,
        isOperation,
        filedName: 'licenses',
    });

    const onSubmit = async () => {
        try {
            setSubmiting(true);
            await handleRemove();
            const formValues = await form.validateFields();
            const licenseArrary = formValues.licenses || [];
            const addData:Partial<FormLicense>[] = [];
            for (let i = 0; i < licenseArrary.length; i++) {
                const data = licenseArrary[i];
                const licenseData:Partial<License> = {
                    id: data.id,
                    type: data.type,
                    secondType: data.secondType,
                    state: data.state,
                    licenseNumber: data.licenseNumber,
                    expireDate: formatDate(data.expireDate),
                    schedule: data.schedule?.join(',') || '',
                    url: data.url,
                };
                const fn = isOperation ? opLicenseUpdate : providerLicenseUpdate;
                // eslint-disable-next-line no-await-in-loop
                const result = await fn(licenseData, providerId!);
                if (result.error) {
                    message.error(result.error);
                    return;
                } else {
                    addData.push(LicenseData.serverToForm(licenseData as License));
                }
            }
            message.success('Practice license info has updated');
            onEditableChange?.(false);
            setEnable(false);
            setHasValues(true);
            refetch?.();

            if (onChange) {
                onChange({
                    licenses: addData,
                });
            }
        } catch (e:any) {
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };

    const renderEditButtons = () => {
        if (viewOnly) {
            return null;
        }
        return !enable ? (
            <Button
                onClick={() => {
                    setEnable(true);
                    onEditableChange?.(true);
                }}
            >
                Edit
            </Button>
        ) : (
            <div className={s.editButtons}>
                {
                    (hasValues || isOperation) &&
                    <Button
                        onClick={
                            () => {
                                setEnable(false);
                                onEditableChange?.(false);
                                if (initValue && initValue?.licenses?.length! > 0) {
                                    form.setFieldsValue(initValue);
                                }
                            }
                        }
                    >
                        Cancel
                    </Button>
                }
                <Button
                    style={{ marginLeft: 8 }}
                    type="primary"
                    onClick={onSubmit}
                    loading={submiting}
                >
                    Save changes
                </Button>
            </div>
        );
    };
    const length = initValue?.licenses?.length || 0;
    const licenses = initValue?.licenses;
    React.useEffect(() => {
        if (hasValues && length === 0) {
            return;
        }
        if (licenses?.length! > 0) {
            //setEnable(true);
            form.setFieldsValue({ ...{ licenses } });
            setHasValues(true);
            if (!hasValues) {
                setEnable(false);
                onEditableChange?.(false);
            }
            forceUpdate({});
        } else if (!isOperation) {
            setEnable(true);
            onEditableChange?.(true);
        }
    }, [licenses, isOperation, hasValues, form, length]);

    React.useEffect(() => {
        const doSubmit = async () => {
            try {
                setSubmiting(true);
                const formValues = await form.validateFields();
                const licenseArrary = formValues.licenses || [];
                const addData:Partial<FormLicense>[] = [];
                for (let i = 0; i < licenseArrary.length; i++) {
                    const data = licenseArrary[i];
                    const licenseData:Partial<License> = {
                        type: data.type,
                        secondType: data.secondType,
                        state: data.state,
                        licenseNumber: data.licenseNumber,
                        expireDate: formatDate(data.expireDate),
                        schedule: data.schedule?.join(',') || '',
                        url: data.url,
                        id: data.id,
                    };
                    const fn = isOperation ? opLicenseUpdate : providerLicenseUpdate;
                    // eslint-disable-next-line no-await-in-loop
                    const result = await fn(licenseData, providerId!);
                    if (result.error) {
                        message.error(result.error);
                        return;
                    } else {
                        addData.push(LicenseData.serverToForm(licenseData as License));
                    }
                }
                message.success('Practice license info has updated');
                setHasValues(true);
                if (onChange) {
                    onChange({
                        licenses: addData,
                    });
                }
                onVerified?.();
            } catch (e:any) {
                if (e.errorFields) {
                    //form error
                    return;
                }
                const msg = e?.toString?.() || 'data error';
                message.error(msg);
            } finally {
                setSubmiting(false);
            }
        };
        if (doVerifyFlag && onVerified) {
            if (enable) {
                doSubmit();
            } else {
                onVerified?.();
            }
        }
    }, [doVerifyFlag]);

    const licensesArray:FormLicense[] = form.getFieldValue('licenses')?.filter((item:FormLicense) => !!(item && item.licenseNumber));

    return (
        <div className={s.module}>
            <h3>
                <span>Practice licences</span>
                { renderEditButtons() }
            </h3>
            {
                enable &&
                <Form
                    form={form}
                    name="basic"
                    className={commonS.formStyle1}
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.List name="licenses" initialValue={[{}]}>
                        {(fields, { add, remove }) => {
                            const licensesLength = fields.length;
                            return (
                                <div id="practiceLicense">
                                    {fields.map((field, inx) => {
                                        return (
                                            <Form.Item
                                                noStyle
                                                key={field.key}
                                            >
                                                <div>
                                                    <div className={s.subModule}>
                                                        <h3>{`License ${inx + 1}`}</h3>
                                                        <Form.Item name={[field.name, 'id']}>
                                                            <Input type="hidden" />
                                                        </Form.Item>
                                                        <div className={s.row}>
                                                            <div className={s.rowItem}>
                                                                <Form.Item
                                                                    style={{ width: '320px' }}
                                                                    label="State for licenses"
                                                                    name={[field.name, 'state']}
                                                                    validateFirst
                                                                    rules={[
                                                                        { required: true, message: 'State is required' },
                                                                    ]}
                                                                >
                                                                    <StateSelect />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div className={s.rowItem}>
                                                                <Form.Item
                                                                    style={{ width: '360px' }}
                                                                    label="License number"
                                                                    name={[field.name, 'licenseNumber']}
                                                                    validateFirst
                                                                    rules={[
                                                                        { required: true, message: 'License number is required' },
                                                                    ]}
                                                                >
                                                                    <Input maxLength={25} placeholder="What is the license number?  " />
                                                                </Form.Item>
                                                            </div>
                                                            <div className={s.rowItem}>
                                                                <Form.Item
                                                                    style={{ width: '360px' }}
                                                                    label="Expiration date"
                                                                    name={[field.name, 'expireDate']}
                                                                    rules={[{ required: true, message: 'Expiration date is required' }]}
                                                                >
                                                                    <DatePicker
                                                                        disabledDate={(date) => {
                                                                            const now = (new Date()).getTime();
                                                                            if (!date) {
                                                                                return false;
                                                                            }
                                                                            return date?.valueOf() < now;
                                                                        }}
                                                                        style={{ width: '100%' }}
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div className={s.rowItem}>
                                                                <Form.Item
                                                                    style={{ width: '320px' }}
                                                                    label="License name"
                                                                    name={[field.name, 'type']}
                                                                    rules={[{ required: true, message: 'License name is required' }]}
                                                                >
                                                                    <Input placeholder="Ex, Nurse practitioner," />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div className={s.rowItem}>
                                                                <Form.Item
                                                                    style={{ width: '320px' }}
                                                                    label="License type"
                                                                    name={[field.name, 'secondType']}
                                                                    rules={[{ required: true, message: 'Licenses type is required' }]}
                                                                >
                                                                    <Input placeholder="Ex, AGCPNC-BC, NPC" />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div className={s.rowItem}>
                                                                <Form.Item
                                                                    style={{ width: '500px' }}
                                                                    label="Upload the copy of the license"
                                                                    name={[field.name, 'url']}
                                                                    rules={[{ required: true, message: 'License copy is required' }]}
                                                                >
                                                                    <FileUpload />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        {field.name !== 0 &&
                                                        <div className={s.removeWrap}>
                                                            <Button
                                                                onClick={() => {
                                                                    onRemove(field.name, () => remove(field.name));
                                                                }}
                                                                danger
                                                                size="small"
                                                            >Remove
                                                            </Button>
                                                        </div> }
                                                    </div>
                                                    {field.name === licensesLength - 1 &&
                                                    <div
                                                        onClick={() => {
                                                            add();
                                                            const wrap = document.getElementById('practiceLicense');
                                                            setTimeout(() => {
                                                                wrap?.parentElement?.parentElement?.scroll({
                                                                    top: 1000000,
                                                                    behavior: 'smooth',
                                                                });
                                                            }, 200);
                                                        }}
                                                        className={s.add}
                                                    >
                                                        + Add another practice license
                                                    </div>
                                                    }
                                                </div>
                                            </Form.Item>);
                                    })}
                                </div>);
                        }}
                    </Form.List>
                </Form>
            }
            {
                !enable && (
                    <div>
                        {
                            licensesArray?.map((item:FormLicense | FormLicenseShadow, inx) => {
                                let borderColor = '#DAD8D8';
                                if ((item as FormLicenseShadow).isNewAdd) {
                                    borderColor = '#87d068';
                                }
                                if ((item as FormLicenseShadow).isDeleted) {
                                    borderColor = 'red';
                                }
                                return (
                                    <div
                                        style={{
                                            borderColor,
                                        }}
                                        className={s.subModule}
                                        key={`${item.licenseNumber}_${inx}`}
                                    >
                                        {
                                            (item as FormLicenseShadow).isNewAdd &&
                                            <Tag className="changed" color="#87d068">New Add</Tag>
                                        }
                                        {
                                            (item as FormLicenseShadow).isDeleted &&
                                            <Tag className="changed" color="red">Is Deleted</Tag>
                                        }
                                        <div className={s.row}>
                                            <div
                                                style={{
                                                    width: '300px',
                                                }}
                                                className={s.displayItem}
                                            >
                                                <div className={s.label}>License state</div>
                                                <div className={s.value}>{ item.state}</div>
                                                {renderChangUI('state', item, !!isOperation)}
                                            </div>
                                        </div>
                                        <div className={s.row}>
                                            <div
                                                style={{
                                                    width: '300px',
                                                }}
                                                className={s.displayItem}
                                            >
                                                <div className={s.label}>Practice license number</div>
                                                <div className={s.value}>{ item.licenseNumber}</div>
                                                {renderChangUI('licenseNumber', item, !!isOperation)}
                                            </div>
                                            <div className={s.displayItem}>
                                                <div className={s.label}>Expiration date</div>
                                                <div className={s.value}>{ item.expireDate?.format('MM-DD-YYYY')}</div>
                                            </div>
                                        </div>
                                        <div className={s.row}>
                                            <div
                                                style={{
                                                    width: '300px',
                                                }}
                                                className={s.displayItem}
                                            >
                                                <div className={s.label}>License name</div>
                                                <div className={s.value}>{ item.type}</div>
                                                {renderChangUI('type', item, !!isOperation)}
                                            </div>
                                            <div className={s.displayItem}>
                                                <div className={s.label}>License type</div>
                                                <div className={s.value}>{ item.secondType}</div>
                                                {renderChangUI('secondType', item, !!isOperation)}
                                            </div>
                                        </div>
                                        <div className={s.row}>
                                            <div
                                                style={{
                                                    width: '400px',
                                                }}
                                                className={s.displayItem}
                                            >
                                                <div className={s.label}>Uploaded license</div>
                                                <div className={s.value}>
                                                    <LicenseImg src={item.url} />
                                                </div>
                                                {renderChangUI('url', item, !!isOperation, (val:string) => { return <div style={{ border: `1px solid ${SNAPSHOT_COLOR}`, display: 'inline-block', marginTop: '16px' }}><LicenseImg src={val} /></div>; })}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                )
            }
        </div>
    );
};

export default PracticeLicense;
