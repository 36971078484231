import React from 'react';
import s from './s.module.less';
import PaymentInfo from '../PaymentInfo';
import SubscriptionStore from 'store/Subscription';
import useSubscriptionPlan from 'hooks/useSubscriptionPlan';
import { Spin } from 'antd';
import { TSubscriptionItemFromServer, TSubscriptionType } from 'types/subscription';
import useGetProviderPlanDetail from 'hooks/useGetProviderPlanDetail';
import Subscription from 'components/Subscription';

const SubscriptionInfo = () => {
    const [plansData, loadingSubscriptionPlan] = useSubscriptionPlan();
    const subscriptionPlan = plansData.kiwiPlan;
    const [deital, detailLoading] = useGetProviderPlanDetail();
    const [getSubscription] = SubscriptionStore.useStore();
    const currentPlanFromServer: TSubscriptionItemFromServer | undefined = getSubscription('currentPlan');

    return (
        <Spin spinning={!!loadingSubscriptionPlan || !!detailLoading}>
            <div className={s.wrap}>
                <PaymentInfo currentPlan={currentPlanFromServer} deital={deital} />
                <Subscription hideTitle wrapClass={s.infoWrap} />
            </div>
        </Spin>
    );
};

export default SubscriptionInfo;
