import React, { useCallback, useEffect } from 'react';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { Button, Form, Input } from 'antd';
import { EditModuleEnum, FormFields } from 'types/practiceFront';
import { CouponType, ProviderPractice } from 'types/provider';
import ErrorBlock from '../ErrorBlock';
import FormValidationHelper from 'utils/validation';

interface IProps {
    isEditing: boolean;
    showEditingTip?: boolean;
    initFormValue: ProviderPractice;
    gloablEditStatus: boolean;
    onSubmit: (value: ProviderPractice, field: EditModuleEnum, callback: () => void) => void;
    setShowEditingTip: (field, value: boolean) => void;
    onEditStatusChange: (status: boolean, field: EditModuleEnum) => void;
    setTabKey: (key: string) => void;
    scrollToTheEditingModule: () => void;
}

const Contact = ({
    isEditing,
    gloablEditStatus,
    showEditingTip,
    initFormValue,
    onSubmit,
    setShowEditingTip,
    onEditStatusChange,
    setTabKey,
    scrollToTheEditingModule,
}: IProps) => {
    const [contactForm] = Form.useForm<ProviderPractice>();
    const { vfdPhoneLine } = initFormValue;
    // 如果用户有vfdPhoneLine， 那么Practice phone number就显示为vfdPhoneLine
    const practicePhoneNumber = initFormValue.vfdPhoneLine || initFormValue.practicePhone;

    useEffect(() => {
        if (initFormValue) {
            contactForm.setFieldsValue({
                ...initFormValue,
                practicePhone: practicePhoneNumber,
            });
        }
    }, [initFormValue, contactForm, practicePhoneNumber]);

    const handleEditingChange = useCallback((status: boolean) => {
        if (gloablEditStatus && status) {
            if (gloablEditStatus) {
                setTabKey('1');
                setTimeout(() => {
                    scrollToTheEditingModule();
                }, 100);
            }
            // setShowEditingTip?.('data', true);
            // message.error('Please save your changes before proceeding');
        } else {
            setShowEditingTip?.('data', false);
        }
        onEditStatusChange(status, EditModuleEnum.CONTACT);
    }, [gloablEditStatus, onEditStatusChange, scrollToTheEditingModule, setShowEditingTip, setTabKey]);

    const handleSubmit = () => {
        contactForm.validateFields().then((values) => {
            onSubmit({
                ...values,
                id: initFormValue.id,
            }, EditModuleEnum.CONTACT, () => handleEditingChange(false));
        }).catch((e) => {
            console.error(e);
        });
    };

    return (
        <div className={s.wrap} id={EditModuleEnum.CONTACT}>
            <div className={s.header}>
                <div className={s.title}>Practice Phone Number</div>
                {
                    !vfdPhoneLine && (
                        <div className={s.btns}>
                            {
                                isEditing ?
                                    <>
                                        <Button onClick={() => handleEditingChange(false)}>Cancel</Button>
                                        <Button type="primary" onClick={handleSubmit}>Save changes</Button>
                                    </>
                                    : <Button onClick={() => handleEditingChange(true)}>Edit</Button>
                            }
                        </div>
                    )
                }
            </div>
            {
                vfdPhoneLine && (
                    <div className={s.tip}>
                        <div className={s.starIcon} />
                        <div className={s.TipContent}>
                            <div className={s.tipText}>Your practice phone number has been automatically updated to the virtual phone line number.</div>
                        </div>
                    </div>
                )
            }
            {
                showEditingTip && isEditing && <ErrorBlock />
            }
            <div className={s.body}>
                {
                    !isEditing ?
                        (
                            <div className={s.viewWrap}>
                                <div className={s.viewItem}>
                                    <div className={s.label}>Practice phone number</div>
                                    <div className={s.value}>{practicePhoneNumber || 'No phone number'}</div>
                                </div>
                            </div>
                        )
                        :
                        (
                            <div className={s.formWrap}>
                                <Form
                                    form={contactForm}
                                    name={EditModuleEnum.CONTACT}
                                    className={commonS.formStyle1}
                                    autoComplete="off"
                                    layout="vertical"
                                >
                                    <div className={s.itemBox}>
                                        <div className={s.item}>
                                            <Form.Item
                                                label="Practice phone number"
                                                name={FormFields.PRACTICE_PHONE}
                                                validateFirst
                                                rules={[
                                                    { required: true, message: 'practice phone number is a required field' },
                                                    FormValidationHelper.validatePhoneNumber('Phone number must contain only number or -'),
                                                ]}
                                            >
                                                <Input maxLength={30} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        )
                }
            </div>
        </div>
    );
};

export default Contact;
