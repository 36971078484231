import React from 'react';
import dayjs from 'utils/dayjs';
import { DatePicker, Select, Checkbox } from 'antd';
import s from './s.module.less';

const { RangePicker } = DatePicker;

type TimeRangerPickerProps = {
    value?: dayjs.Dayjs[],
    onChange?: (val: dayjs.Dayjs[]) => void, //start and end time
    supportAllDay?: boolean
};

type TimeOption = {
    label:string,
    value:number[],
};

const TimeRangerPicker = (props:TimeRangerPickerProps) => {
    const { onChange, value, supportAllDay } = props;
    const [allDayMode, setAllDayMode] = React.useState(false);
    const [start, setStart] = React.useState('');
    const [end, setEnd] = React.useState('');
    const [date, setDate] = React.useState<dayjs.Dayjs | undefined>(undefined);
    const [startDate, setStartDate] = React.useState<dayjs.Dayjs | undefined>(undefined);
    const [endDate, setEndDate] = React.useState<dayjs.Dayjs | undefined>(undefined);
    const time = [0, 15, 30, 45];
    const options:TimeOption[] = [];

    const doChange = (startDatetime:dayjs.Dayjs, endDatetime:dayjs.Dayjs) => {
        if (!startDatetime || !endDatetime) {
            return;
        }
        onChange?.([startDatetime, endDatetime]);
    };

    React.useEffect(() => {
        if (value && value.length === 2 && !start && !end && !date) {
            const startValue = value[0];
            const endValue = value[1];
            setDate(dayjs(new Date(startValue.year(), startValue.month(), startValue.date())));
            const startHour = startValue.hour();
            const startMin = startValue.minute();
            const endHour = endValue.hour();
            let endMin = endValue.minute();
            if (endMin > 45) {
                endMin = 45;
            }
            setStart(`${startHour},${startMin}`);
            setEnd(`${endHour},${endMin}`);
        }
    }, [start, end, date, value]);

    for (let i = 0; i < 12; i++) {
        for (let j = 0; j < time.length; j++) {
            const label = `${i >= 10 ? i : `0${i}`}:${j === 0 ? '00' : time[j]} AM`;
            options.push({
                label,
                value: [i, time[j]],
            });
        }
    }
    for (let j = 0; j < time.length; j++) {
        const label = `12:${j === 0 ? '00' : time[j]} PM`;
        options.push({
            label,
            value: [12, time[j]],
        });
    }
    for (let i = 1; i < 12; i++) {
        for (let j = 0; j < time.length; j++) {
            const label = `${i >= 10 ? i : `0${i}`}:${j === 0 ? '00' : time[j]} PM`;
            options.push({
                label,
                value: [12 + i, time[j]],
            });
        }
    }
    return (
        <div className={s.wrap}>
            <div className={s.timePicker}>
                {allDayMode ?
                    <RangePicker
                        onChange={(val) => {
                            if (!val || !val[0] || !val[1]) {
                                return;
                            }
                            const sDate = val[0].hour(0).minute(0).second(0);
                            const eDate = val[1].hour(23).minute(59).second(59);
                            setStartDate(sDate);
                            setEndDate(eDate);
                            doChange(sDate, eDate);
                        }}
                        format="dddd, MMMM DD"
                        className={s.dateSelectAllDay}
                        placeholder={['Select start date', 'Select end date']}
                    />
                    :
                    <>
                        <DatePicker
                            value={date}
                            disabledDate={(current) => {
                                return current && current < dayjs().startOf('day');
                            }}
                            onChange={(val) => {
                                setDate(val || undefined);
                                const startVal = (start && start.split(',')) || [];
                                const endVal = (end && end.split(',')) || [];
                                if (val && startVal.length > 0 && endVal.length > 0) {
                                    const startTime = dayjs(new Date(val.year(), val.month(), val.date(), parseInt(startVal[0], 10), parseInt(startVal[1], 10)));
                                    const endTime = dayjs(new Date(val.year(), val.month(), val.date(), parseInt(endVal[0], 10), parseInt(endVal[1], 10)));
                                    doChange(startTime, endTime);
                                }
                            }}
                            format="dddd, MMMM DD"
                            className={s.dateSelect}
                        />
                        <Select
                            className={s.timeSelect}
                            onChange={(val) => {
                                setStart(val);
                                const startVal = (val && val.split(',')) || [];
                                const endVal = (end && end.split(',')) || [];
                                if (endVal.length > 0 && startVal.length === 2) {
                                    //exist end
                                    let needChange = false;
                                    if (parseInt(endVal[0], 10) < parseInt(startVal[0], 10)) {
                                        needChange = true;
                                    } else if (parseInt(endVal[0], 10) === parseInt(startVal[0], 10)) {
                                        if (parseInt(endVal[1], 10) <= parseInt(startVal[1], 10)) {
                                            needChange = true;
                                        }
                                    }
                                    if (needChange) {
                                        const newEndValues = [0, 0];
                                        if (startVal[0] === '23' && startVal[1] === '30') {
                                            newEndValues[0] = 23;
                                            newEndValues[1] = 45;
                                        } else if (parseInt(startVal[1], 10) === 0) {
                                            newEndValues[0] = parseInt(startVal[0], 10);
                                            newEndValues[1] = 30;
                                        } else if (parseInt(startVal[1], 10) === 15) {
                                            newEndValues[0] = parseInt(startVal[0], 10);
                                            newEndValues[1] = 45;
                                        } else if (parseInt(startVal[1], 10) === 30) {
                                            newEndValues[0] = parseInt(startVal[0], 10) + 1;
                                            newEndValues[1] = 0;
                                        } else if (parseInt(startVal[1], 10) === 45) {
                                            newEndValues[0] = parseInt(startVal[0], 10) + 1;
                                            newEndValues[1] = 15;
                                        }
                                        setEnd(newEndValues.join(','));

                                        if (date && startVal.length > 0 && newEndValues.length > 0) {
                                            const startTime = dayjs(new Date(date.year(), date.month(), date.date(), parseInt(startVal[0], 10), parseInt(startVal[1], 10)));
                                            const endTime = dayjs(new Date(date.year(), date.month(), date.date(), newEndValues[0], newEndValues[1]));
                                            doChange(startTime, endTime);
                                        }
                                        return;
                                    }
                                }
                                if (date && startVal.length > 0 && endVal.length > 0) {
                                    const startTime = dayjs(new Date(date.year(), date.month(), date.date(), parseInt(startVal[0], 10), parseInt(startVal[1], 10)));
                                    const endTime = dayjs(new Date(date.year(), date.month(), date.date(), parseInt(endVal[0], 10), parseInt(endVal[1], 10)));
                                    doChange(startTime, endTime);
                                }
                            }}
                            value={start}
                        >
                            {
                                options.slice(0, -1).map((option) => {
                                    // let disable = false;
                                    // if (end) {
                                    //     const endValues = end.split(',');
                                    //     if (parseInt(endValues[0], 10) < option.value[0]) {
                                    //         disable = true;
                                    //     } else if (parseInt(endValues[0], 10) === option.value[0]) {
                                    //         disable = parseInt(endValues[1], 10) <= option.value[1];
                                    //     }
                                    // }
                                    return (
                                        <Select.Option
                                            key={option.label}
                                            value={option.value.join(',')}
                                        >
                                            {option.label}
                                        </Select.Option>
                                    );
                                })
                            }
                        </Select>
                        <Select
                            onChange={(val) => {
                                setEnd(val);
                                const startVal = (start && start.split(',')) || [];
                                const endVal = (val && val.split(',')) || [];
                                if (date && startVal.length > 0 && endVal.length > 0) {
                                    const startTime = dayjs(new Date(date.year(), date.month(), date.date(), parseInt(startVal[0], 10), parseInt(startVal[1], 10)));
                                    const endTime = dayjs(new Date(date.year(), date.month(), date.date(), parseInt(endVal[0], 10), parseInt(endVal[1], 10)));
                                    doChange(startTime, endTime);
                                }
                            }}
                            value={end}
                            className={s.timeSelect}
                        >
                            {
                                options.map((option) => {
                                    let disable = false;
                                    if (start) {
                                        const startValues = start.split(',');
                                        if (parseInt(startValues[0], 10) > option.value[0]) {
                                            disable = true;
                                        } else if (parseInt(startValues[0], 10) === option.value[0]) {
                                            disable = parseInt(startValues[1], 10) >= option.value[1];
                                        }
                                    }
                                    return (
                                        <Select.Option
                                            key={option.label}
                                            value={option.value.join(',')}
                                            disabled={disable}
                                        >
                                            {option.label}
                                        </Select.Option>
                                    );
                                })
                            }
                        </Select>
                    </>
                }
            </div>
            {supportAllDay &&
                <div className={s.allDayCheck}>
                    <Checkbox onChange={(e) => {
                        setAllDayMode(e.target.checked);
                    }}
                    >All day
                    </Checkbox>
                </div>
            }
        </div>
    );
};

export default TimeRangerPicker;
