import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { Meta } from 'types/common';

type GenderSelectProps = {
    value?: string;
    onChange?: (val: string) => void,
    disableValues?:string[]
} & SelectProps;

const GenderSelect = (props:GenderSelectProps) => {
    const { onChange, disableValues, ...reset } = props;
    const genderOption:Meta[] = ['Female', 'Male', 'Non-binary', 'Transgender', 'A gender not listed here', 'Prefer not to say'].map((str:string) => {
        return {
            label: str,
            value: str,
        };
    });
    return (
        <Select {...reset} onChange={onChange} showSearch>
            {
                genderOption.map((option:Meta) => {
                    return (
                        <Select.Option
                            disabled={!!(disableValues?.includes(option.value as string) && reset.value !== option.value)}
                            key={option.value}
                            value={option.value}
                        >
                            {option.label}
                        </Select.Option>
                    );
                })
            }
        </Select>
    );
};

export default GenderSelect;
