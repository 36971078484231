// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__status--uRiCy {
  padding: 2px 10px;
  display: inline-block;
  background: #DBDBDB;
  border-radius: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: var(--gray-800);
  white-space: nowrap;
}
.s-module__status--uRiCy.s-module__approved--KwUfd {
  background-color: var(--green-100);
  color: var(--green-800);
}
.s-module__status--uRiCy.s-module__followup--Lz1DU {
  background-color: var(--purple-100);
  color: var(--purple-800);
}
.s-module__status--uRiCy.s-module__rejected--xX4_Y {
  background-color: var(--red-100);
  color: var(--red-800);
}
.s-module__status--uRiCy.s-module__pending--Zkytr {
  background-color: var(--yellow-100);
  color: var(--yellow-800);
}
.s-module__status--uRiCy.s-module__golive--_bT0k {
  background-color: var(--blue-100);
  color: var(--blue-800);
}
`, "",{"version":3,"sources":["webpack://./src/components/ProfileAuditStatusTag/s.module.less"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,qBAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AACI;EACI,kCAAA;EACA,uBAAA;AACR;AAEI;EACI,mCAAA;EACA,wBAAA;AAAR;AAGI;EACI,gCAAA;EACA,qBAAA;AADR;AAII;EACI,mCAAA;EACA,wBAAA;AAFR;AAKI;EACI,iCAAA;EACA,sBAAA;AAHR","sourcesContent":[".status {\n    padding: 2px 10px;\n    display: inline-block;\n    background: #DBDBDB;\n    border-radius: 10px;\n    font-weight: 500;\n    font-size: 12px;\n    line-height: 16px;\n    text-align: center;\n    color: var(--gray-800);\n    white-space: nowrap;\n\n    &.approved {\n        background-color: var(--green-100);\n        color: var(--green-800);\n    }\n\n    &.followup {\n        background-color: var(--purple-100);\n        color: var(--purple-800);\n    }\n\n    &.rejected {\n        background-color: var(--red-100);\n        color: var(--red-800);\n    }\n\n    &.pending {\n        background-color: var(--yellow-100);\n        color: var(--yellow-800);\n    }\n\n    &.golive {\n        background-color: var(--blue-100);\n        color: var(--blue-800);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": `s-module__status--uRiCy`,
	"approved": `s-module__approved--KwUfd`,
	"followup": `s-module__followup--Lz1DU`,
	"rejected": `s-module__rejected--xX4_Y`,
	"pending": `s-module__pending--Zkytr`,
	"golive": `s-module__golive--_bT0k`
};
export default ___CSS_LOADER_EXPORT___;
