import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { Meta, Channel } from 'types/common';
import useMyListedChannels from 'hooks/useMyListedChannels';

type MyChannelSelectProps = {
    value?: number | number[];
    onChange?: (val: number | number[]) => void,
    showOthers?:boolean,
} & SelectProps;

const MyChannelSelect = (props:MyChannelSelectProps) => {
    const { onChange, showOthers, value, ...reset } = props;

    const [channels, loading] = useMyListedChannels();
    const options:Meta[] = channels?.map((channel:Channel) => {
        return {
            label: channel.name,
            value: channel.id!,
        };
    }) || [];
    return (
        <Select value={value} {...reset} onChange={onChange} loading={loading}>
            {
                options.map((option:Meta) => {
                    return (
                        <Select.Option
                            key={option.value}
                            value={option.value}
                        >
                            {option.label}
                        </Select.Option>
                    );
                })
            }
            {
                showOthers && (
                    <Select.Option
                        key="others"
                        value={-1}
                    >
                        Others
                    </Select.Option>
                )
            }
        </Select>
    );
};

export default MyChannelSelect;
