import React, { useCallback, useMemo, useState } from 'react';
import s from './s.module.less';
import { Button, Checkbox, Form, Input, Select } from 'antd';
import { FormFields, PracitceAddressForm } from 'types/practiceFront';
import StateSelect from 'components/form/StateSelect';
import commonS from 'styles/common.module.less';
import useSettings from 'hooks/useSettings';
import { EnumFields } from 'types/enumerationData';
import { addressPublicType } from 'utils/address';

interface IProps extends PracitceAddressForm {
    showRemove: boolean;
    id?: number;
    index: number;
    isEditing: boolean;
    onRemove: (id: number) => void
    onEditingStatusChange: (status: boolean, id?: number) => void;
    onSubmit: (formData: PracitceAddressForm, addressIsChanged: boolean, callbacl: () => void, id?: number) => void;
}

const AddressForm = (props: IProps) => {
    const [practiceAddressForm] = Form.useForm<PracitceAddressForm>();
    const [setting] = useSettings();
    const addressTypeOptions = setting[EnumFields.ADDRESS_PUBLIC_TYPE];
    const [addressIsChanged, setAddressIsChanged] = useState(false);
    const {
        showRemove,
        id,
        index,
        isEditing,
        practiceCity,
        practiceZip,
        practiceState,
        practiceAddress,
        isDefault,
        asMailing,
        publicType,
        onRemove,
        onEditingStatusChange,
        onSubmit,
    } = props;

    const finalAddress = useMemo(() => {
        const addressValue = practiceAddress || '';
        const cityValue = practiceCity ? `${practiceCity}` : '';
        const stateValue = practiceState ? `, ${practiceState}` : '';
        const zipCodeValue = practiceZip ? ` ${practiceZip}` : '';

        return (
            <>
                { addressValue && <p>{addressValue}</p> }
                <p>{cityValue}{stateValue}{zipCodeValue}</p>
            </>
        );
    }, [practiceAddress, practiceCity, practiceState, practiceZip]);

    const handleSubmit = useCallback(() => {
        practiceAddressForm.validateFields().then((values) => {
            onSubmit(values, addressIsChanged, () => {
                setAddressIsChanged(false);
            }, id);
        }).catch((e) => {
            console.error(e);
        });
    }, [addressIsChanged, id, onSubmit, practiceAddressForm]);
    return (
        <div className={s.wrap}>
            <div className={s.row}>
                <div className={s.addressBox}>
                    <div className={s.header}>
                        <div className={s.labelBox}>
                            <div className={s.label}>Practice address {index + 1}</div>
                            <div className={s.tags}>
                                { !isEditing && isDefault && <div className={s.defaultTag}>Default</div> }
                                { !isEditing && asMailing && <div className={s.mailingTag}>Mailing address</div> }
                                { publicType && <div className={s.publicTypeTag}>{addressPublicType(publicType)}</div> }
                            </div>
                        </div>
                        <div className={s.btns}>
                            {
                                isEditing ?
                                    <>
                                        <Button
                                            size="small"
                                            onClick={() => {
                                                onEditingStatusChange(false, id);
                                                setAddressIsChanged(false);
                                            }}
                                        >Cancel
                                        </Button>
                                        <Button size="small" type="primary" onClick={handleSubmit}>Save changes</Button>
                                    </> :
                                    <>
                                        {showRemove && <Button size="small" onClick={() => onRemove(id as number)}>remove</Button>}
                                        <Button size="small" onClick={() => onEditingStatusChange(true, id)}>Edit</Button>
                                    </>
                            }
                        </div>
                    </div>
                    {
                        !isEditing &&
                        <div className={s.intro}>
                            {finalAddress}
                        </div>
                    }
                </div>
            </div>
            {
                isEditing && (
                    <Form
                        scrollToFirstError
                        form={practiceAddressForm}
                        name="formName"
                        className={commonS.formStyle1}
                        initialValues={props}
                        autoComplete="off"
                        layout="vertical"
                    >
                        <div className={s.additionalBox}>
                            <div className={s.labelBox}>
                                { showRemove && <Button onClick={() => onRemove(id)}>Remove</Button>}
                            </div>
                            <div className={s.additionalForm}>
                                <div className={s.formBody}>
                                    <div className={s.row}>
                                        <div
                                            className={s.displayItem}
                                        >
                                            <Form.Item
                                                label="Address"
                                                name={FormFields.PRACTICE_ADDRESS}
                                                validateFirst
                                                rules={[
                                                    { required: true, message: 'Address is a required field' },
                                                ]}
                                            >
                                                <Input type="text" onChange={() => setAddressIsChanged(true)} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className={s.row}>
                                        <div
                                            className={s.displayItem}
                                        >
                                            <Form.Item
                                                label="City"
                                                name={FormFields.PRACTICE_CITY}
                                                validateFirst
                                                rules={[
                                                    { required: true, message: 'City is a required field' },
                                                ]}
                                            >
                                                <Input type="text" onChange={() => setAddressIsChanged(true)} />
                                            </Form.Item>
                                        </div>
                                        <div
                                            className={s.displayItem}
                                        >
                                            <Form.Item
                                                label="State"
                                                name={FormFields.PRACTICE_STATE}
                                                validateFirst
                                                rules={[
                                                    { required: true, message: 'State is a required field' },
                                                ]}
                                            >
                                                <StateSelect onChange={() => setAddressIsChanged(true)} />
                                            </Form.Item>
                                        </div>
                                        <div
                                            className={s.displayItem}
                                        >
                                            <Form.Item
                                                label="ZIP Code"
                                                name={FormFields.PRACTICE_ZIP}
                                                validateFirst
                                                rules={[
                                                    { required: true, message: 'Zip code is a required field' },
                                                ]}
                                            >
                                                <Input type="text" onChange={() => setAddressIsChanged(true)} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className={s.row}>
                                        <div
                                            className={s.displayItem}
                                            style={{ marginBottom: '-24px' }}
                                        >
                                            <Form.Item
                                                label="Address type"
                                                name={FormFields.ADDRESS_PUBLIC_TYPE}
                                                rules={[
                                                    { required: true, message: 'Address type is a required field' },
                                                ]}
                                            >
                                                <Select options={addressTypeOptions} onChange={() => setAddressIsChanged(true)} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className={s.row}>
                                        <div
                                            className={s.displayItem}
                                            style={{ marginBottom: '-24px' }}
                                        >
                                            <Form.Item
                                                name={FormFields.IS_DEFAULT}
                                                valuePropName="checked"
                                            >
                                                <Checkbox>Make this my default practice address</Checkbox>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className={s.row}>
                                        <div
                                            className={s.displayItem}
                                        >
                                            <Form.Item
                                                name={FormFields.AS_MAILING}
                                                valuePropName="checked"
                                            >
                                                <Checkbox>Set as a mailing address</Checkbox>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )
            }
        </div>
    );
};

export default AddressForm;
