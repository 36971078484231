import React from 'react';
import { UpOutlined } from '@ant-design/icons';
import s from './s.module.less';

interface IProps {
    title: string | React.ReactElement,
    defaultHide?:boolean,
    children: React.ReactElement
    contentCls?: string;
    showVal?: boolean; //if have true | false, means control outside
    onShowStatusChange?: (show:boolean)=>void
}

const SliderPanel = ({
    title,
    defaultHide,
    children,
    contentCls = '',
    showVal,
    onShowStatusChange,
}: IProps) => {
    const ref = React.useRef<any>();
    const [show, setShow] = React.useState(!defaultHide);
    const [height, setHeight] = React.useState('auto');
    const [hasHeight, setHasHeight] = React.useState(false);
    React.useEffect(() => {
        if (height === 'auto') {
            const contentHeight = ref.current.clientHeight;
            if (contentHeight !== 0) {
                //sometimes contentheight is 0 if using defaultHide, looks not need this useEffect
                setHeight(contentHeight);
                setHasHeight(true);
            }
        }
    }, []);

    React.useEffect(() => {
        if (showVal !== undefined) {
            setShow(showVal);
        }
    }, [showVal]);

    return (
        <div className={show ? `${s.wrap} ${s.wrapShow}` : s.wrap}>
            <div className={s.title}>
                <div className={s.text}>{title}</div>
                <span
                    onClick={() => {
                        setShow(!show);
                        if (onShowStatusChange) {
                            onShowStatusChange(!show);
                        }
                    }}
                    className={s.icon}
                >
                    <UpOutlined />
                </span>
            </div>
            <div
                ref={ref}
                style={{
                    // eslint-disable-next-line no-nested-ternary
                    height: show ? (hasHeight ? `${height}px` : 'auto') : '0px',
                }}
                className={`${s.content} ${contentCls}`}
            >
                {children}
            </div>
        </div>
    );
};

export default SliderPanel;
