// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/campain/blackfriday2024/homepage_banner.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("assets/common/thin_arrow.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--oIDsK {
  margin-top: 16px;
  position: relative;
  border-radius: 10px;
  background: #fff url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center;
  background-size: cover;
  width: 100%;
}
.s-module__wrap--oIDsK .s-module__previousBtn--alQwX {
  position: absolute;
  left: 0;
  top: 50%;
  width: 22px;
  height: 22px;
  background: #fff url(${___CSS_LOADER_URL_REPLACEMENT_1___}) no-repeat center center;
  background-size: 80%;
  border-radius: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  z-index: 10;
}
.s-module__wrap--oIDsK .s-module__nextBtn--zaUlE {
  position: absolute;
  top: 50%;
  right: 0;
  width: 22px;
  height: 22px;
  background: #fff url(${___CSS_LOADER_URL_REPLACEMENT_1___}) no-repeat center center;
  background-size: 80%;
  border-radius: 50%;
  transform: translate(0, -50%) rotateZ(180deg);
  cursor: pointer;
  z-index: 10;
}
.s-module__pagination--OGjgq {
  bottom: 0px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/ProviderCampainHeader/s.module.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,gFAAA;EACA,sBAAA;EACA,WAAA;AACJ;AAPA;EAaQ,kBAAA;EACA,OAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;EACA,gFAAA;EACA,oBAAA;EACA,kBAAA;EACA,6BAAA;EACA,eAAA;EACA,WAAA;AAHR;AApBA;EA2BQ,kBAAA;EACA,QAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;EACA,gFAAA;EACA,oBAAA;EACA,kBAAA;EACA,6CAAA;EACA,eAAA;EACA,WAAA;AAJR;AAQA;EACI,sBAAA;AANJ","sourcesContent":[".wrap {\n    margin-top: 16px;\n    position: relative;\n    border-radius: 10px;\n    background: #fff url('assets/campain/blackfriday2024/homepage_banner.png') no-repeat center center;\n    background-size: cover;\n    width: 100%;\n\n    .leftWrap {\n        // width: 500px;\n    }\n\n    .previousBtn {\n        position: absolute;\n        left: 0;\n        top: 50%;\n        width: 22px;\n        height: 22px;\n        background: #fff url('assets/common/thin_arrow.svg') no-repeat center center;\n        background-size: 80%;\n        border-radius: 50%;\n        transform: translate(0, -50%);\n        cursor: pointer;\n        z-index: 10;\n    }\n\n    .nextBtn {\n        position: absolute;\n        top: 50%;\n        right: 0;\n        width: 22px;\n        height: 22px;\n        background: #fff url('assets/common/thin_arrow.svg') no-repeat center center;\n        background-size: 80%;\n        border-radius: 50%;\n        transform: translate(0, -50%) rotateZ(180deg);\n        cursor: pointer;\n        z-index: 10;\n    }\n}\n\n.pagination {\n    bottom: 0px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--oIDsK`,
	"previousBtn": `s-module__previousBtn--alQwX`,
	"nextBtn": `s-module__nextBtn--zaUlE`,
	"pagination": `s-module__pagination--OGjgq`
};
export default ___CSS_LOADER_EXPORT___;
