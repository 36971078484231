import React, { useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { Modal, Radio, RadioChangeEvent, Space, message } from 'antd';
import GlobalLayerStore from 'store/GlobalLayer';
import { EType } from 'types/helloKlarity';
import { IBPMappingItem } from 'types/operation';
import { updateHelloKlarityPatientRating } from 'api/operation';

interface IProps {
    row?: IBPMappingItem | null;
    type: EType;
    onOk: (value: number) => void;
    onCancel: () => void;
}

const PatientRatingUpdateModal = ({
    row,
    onOk,
    onCancel,
}: IProps) => {
    const [getGlobalLayer] = GlobalLayerStore.useStore();
    const [value, setValue] = useState(2);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (row) {
            setValue(row.patientRating);
        }
    }, [row]);

    const update = useCallback(async (rowId: number, rating: number) => {
        if (rowId) {
            setLoading(true);
            try {
                const res = await updateHelloKlarityPatientRating({
                    id: rowId,
                    patientRating: rating,
                });

                if (res && !res.error) {
                    onOk(rating);
                } else {
                    message.error(res?.error);
                }
            } catch (e) {
                console.error(e);
            }

            setLoading(false);
        }
    }, [onOk]);

    const handleOk = useCallback(() => {
        if (row) {
            update(row.id, value);
        }
    }, [row, update, value]);

    const onChange = useCallback((e: RadioChangeEvent) => {
        setValue(e.target.value);
    }, []);

    return (
        <Modal
            title="Update patient rating"
            width={312}
            open={getGlobalLayer('showProviderMappingPatientRatingModal')}
            okText="Save"
            onOk={handleOk}
            onCancel={onCancel}
            confirmLoading={loading}
        >
            <Radio.Group onChange={onChange} value={value}>
                <Space direction="vertical">
                    <Radio value={2}>
                        <div className={s.radioItem}>
                            <div className={s.great} />Great
                        </div>
                    </Radio>
                    <Radio value={0}>
                        <div className={s.radioItem}>
                            <div className={s.average} />Average
                        </div>
                    </Radio>
                    <Radio value={-2}>
                        <div className={s.radioItem}>
                            <div className={s.poor} />Poor
                        </div>
                    </Radio>
                </Space>
            </Radio.Group>
        </Modal>
    );
};

export default PatientRatingUpdateModal;
