import React, { useCallback, useEffect, useState } from 'react';
// import s from './s.module.less';
import { Input, Modal, message } from 'antd';
import GlobalLayerStore from 'store/GlobalLayer';
import { EType } from 'types/helloKlarity';
import { updateHelloKlarityProviderSlug } from 'api/operation';
import { IBPMappingItem } from 'types/operation';

interface IProps {
    row?: IBPMappingItem | null;
    type: EType;
    onOk: (value: string) => void;
    onCancel: () => void;
}

const SlugUpdateModal = ({
    row,
    onOk,
    onCancel,
}: IProps) => {
    const [getGlobalLayer] = GlobalLayerStore.useStore();
    const [value, setValue] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (row) {
            setValue(row.providerNameSlug);
        }
    }, [row]);

    const update = useCallback(async (rowId: number, slugValue: string) => {
        if (rowId && slugValue) {
            setLoading(true);
            try {
                const res = await updateHelloKlarityProviderSlug({
                    id: rowId,
                    providerNameSlug: slugValue,
                });

                if (res && !res.error) {
                    onOk(slugValue);
                } else {
                    message.error(res?.error);
                }
            } catch (e) {
                console.error(e);
            }

            setLoading(false);
        }
    }, [onOk]);

    const handleOk = useCallback(() => {
        if (row) {
            update(row.id, value);
        }
    }, [row, update, value]);

    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    }, []);

    return (
        <Modal
            title="Update slug"
            width={312}
            open={getGlobalLayer('showProviderMappingUpdateSlugModal')}
            okText="Save"
            onOk={handleOk}
            onCancel={onCancel}
            confirmLoading={loading}
        >
            <Input onChange={onChange} value={value} />
        </Modal>
    );
};

export default SlugUpdateModal;
