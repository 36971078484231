import React from 'react';
import s from './s.module.less';
import { AdditionFieldEnum, AddtionalList } from 'types/channelApplicationProvider';

interface IProps {
    initFormValue: {
        associationAdditionalList: Array<AddtionalList>;
        awardAdditionalList: Array<AddtionalList>;
        publicationAdditionalList: Array<AddtionalList>;
    }
}

const Recognition = ({
    initFormValue,
}: IProps) => {
    const { associationAdditionalList = [], awardAdditionalList = [], publicationAdditionalList = [] } = initFormValue || {};
    return (
        <div className={s.wrap}>
            <div className={s.title}>Associations, awards, and other resources</div>
            <div className={s.box}>
                <div className={s.subTitle}>Associations</div>
                {
                    (associationAdditionalList && associationAdditionalList.length > 0) ? (
                        <ul>
                            {
                                associationAdditionalList.map((e: AddtionalList, i: number) => <li key={i}>{e[AdditionFieldEnum.NAME]}</li>)
                            }
                        </ul>
                    ) :
                        <div className={s.noncontent}>Nothing here yet</div>
                }
            </div>
            <div className={s.box}>
                <div className={s.subTitle}>Awards</div>
                {
                    (awardAdditionalList && awardAdditionalList.length > 0) ? (
                        <ul>
                            {
                                awardAdditionalList.map((e: AddtionalList, i: number) => <li key={i}>{e[AdditionFieldEnum.NAME]}</li>)
                            }
                        </ul>
                    ) :
                        <div className={s.noncontent}>Nothing here yet</div>
                }
            </div>
            <div className={s.box}>
                <div className={s.subTitle}>Published paper/additional resources</div>
                {
                    (publicationAdditionalList && publicationAdditionalList.length > 0) ? (
                        <ul>
                            {
                                publicationAdditionalList.map((e: AddtionalList, i: number) => (
                                    <li key={i}>
                                        <div>{e[AdditionFieldEnum.NAME]}</div>
                                        <div>{e[AdditionFieldEnum.AUTHORS]}</div>
                                        { e[AdditionFieldEnum.WEBSITE] && <div>{e[AdditionFieldEnum.WEBSITE]}</div> }
                                    </li>
                                ))
                            }
                        </ul>
                    ) :
                        <div className={s.noncontent}>Nothing here yet</div>
                }
            </div>
        </div>
    );
};

export default Recognition;
