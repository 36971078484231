import React from 'react';
import s from './s.module.less';
import 'swiper/css';

interface IProps {
    photos: string[]
}

const ProviderPhotos = ({
    photos,
}: IProps) => {
    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <div className={s.title}>Photos</div>
            </div>
            <div className={s.body}>
                {
                    photos?.map((url, i) => {
                        return (
                            <div key={i} className={s.sliderImg}>
                                <div className={s.inner}>
                                    <img src={url} />
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default ProviderPhotos;
