import React from 'react';
import { Form, DatePicker, Input, Checkbox, Radio } from 'antd';
import { SCHEDULE } from 'constants/meta';
import { FormLicense } from 'types/form';
import { FormInstance } from 'antd/lib/form';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import FileUpload from 'components/form/FileUpload';
import FormValidationHelper from 'utils/validation';

type Props = {
    editable?:boolean,
    form: FormInstance<FormLicense>
};

const PhysiciansAssistantLicense = (props: Props) => {
    const { form } = props;
    return (
        <div className={s.wrap}>
            <p className={s.tips}>
                Get started by filling in the information below to create your new project.
            </p>
            <Form
                form={form}
                name="basic"
                className={commonS.formStyle1}
                initialValues={{ remember: true }}
                autoComplete="off"
                layout="vertical"
            >
                <div className={s.module}>
                    <div className={s.row}>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '320px' }}
                                label="My liscense type is"
                                name={['secondType']}
                                rules={[{ required: true, message: 'PA license type is required' }]}
                            >
                                <Radio.Group>
                                    <Radio value="1">Physician assistant</Radio>
                                    <Radio value="2">Physician assistant certified</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '360px' }}
                                label="PA license number"
                                name={['licenseNumber']}
                                validateFirst
                                rules={[
                                    { required: true, message: 'PA license number is required' },
                                    FormValidationHelper.validateLicenseNumber('License number must contain only letters or numbers'),
                                ]}
                            >
                                <Input maxLength={25} placeholder="What is the PA license number?  " />
                            </Form.Item>
                        </div>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '360px' }}
                                label="Expiration date"
                                name={['expireDate']}
                                rules={[{ required: true, message: 'Expiration date is required' }]}
                            >
                                <DatePicker
                                    disabledDate={(date) => {
                                        const now = (new Date()).getTime();
                                        if (!date) {
                                            return false;
                                        }
                                        return date?.valueOf() < now;
                                    }}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    {/* <div className={s.rowItem}>
                        <Form.Item
                            style={{ width: '500px' }}
                            label="Select schedules this license cover"
                            name={['schedule']}
                            rules={[{ required: true, message: 'Schedules is required' }]}
                        >
                            <Checkbox.Group
                                options={SCHEDULE}
                            />
                        </Form.Item>
                    </div> */}
                    <div className={s.rowItem}>
                        <Form.Item
                            style={{ width: '500px' }}
                            label="Upload the copy of the license"
                            name={['url']}
                            rules={[{ required: true, message: 'License copy is required' }]}
                        >
                            <FileUpload accept="image/*,.pdf" />
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default PhysiciansAssistantLicense;
