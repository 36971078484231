import React, { useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { Modal, Radio, RadioChangeEvent, Space, message } from 'antd';
import GlobalLayerStore from 'store/GlobalLayer';
import { EType } from 'types/helloKlarity';
import { EProviderMappingStatusValue, IBPMappingItem } from 'types/operation';
import { updateHelloKlarityMappingStatus } from 'api/operation';

interface IProps {
    hideOptions?: boolean;
    row?: IBPMappingItem | null;
    type?: EType;
    onOk: (value: EProviderMappingStatusValue) => void;
    onCancel: () => void;
}

const MappingModal = ({
    hideOptions,
    row,
    type = EType.HELLOW_KLARITY,
    onOk,
    onCancel,
}: IProps) => {
    const [getGlobalLayer] = GlobalLayerStore.useStore();
    const unmapPlatformName = type === EType.HELLOW_KLARITY ? 'Airtable' : 'Kiwi Health';
    const platformName = type === EType.HELLOW_KLARITY ? 'Kiwi Health' : 'Airtable';
    const [value, setValue] = useState(EProviderMappingStatusValue.EHR_MAPPED);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setValue(type === EType.AIRTABLE ? EProviderMappingStatusValue.AIR_TABLE_MAPPING : EProviderMappingStatusValue.EHR_MAPPED);
    }, [type]);

    const update = useCallback(async (rowId: number, mappingValue: EProviderMappingStatusValue) => {
        if (rowId && mappingValue) {
            setLoading(true);
            try {
                const res = await updateHelloKlarityMappingStatus({
                    id: rowId,
                    mappingStatus: mappingValue,
                });

                if (res && !res.error) {
                    onOk(mappingValue);
                } else {
                    message.error(res?.error);
                }
            } catch (e) {
                console.error(e);
            }

            setLoading(false);
        }
    }, [onOk]);

    const handleOk = useCallback(() => {
        if (row) {
            update(row.id, value);
        }
    }, [row, update, value]);

    const onChange = useCallback((e: RadioChangeEvent) => {
        setValue(e.target.value);
    }, []);
    return (
        <Modal
            title={`Map the provider profile page to ${platformName} data? `}
            open={getGlobalLayer('showProviderMappingModal')}
            okText="Save"
            onOk={handleOk}
            onCancel={onCancel}
            confirmLoading={loading}
        >
            <p>{`This provider also exists in ${unmapPlatformName}. Are you sure you want to unmap the provider from ${unmapPlatformName}, and populate provider profile page with ${platformName} data?`}</p>
            {
                !hideOptions && (
                    <Radio.Group onChange={onChange} value={value}>
                        <Space direction="vertical">
                            <Radio value={EProviderMappingStatusValue.EHR_MAPPED}>
                                <div className={s.radio}>
                                    Map Kiwi Health UniProfile data<span className={s.recommended}>Recommended</span>
                                </div>
                            </Radio>
                            <Radio value={EProviderMappingStatusValue.AIR_TABLE_MAPPING}>
                                <div className={s.radio}>
                                    Map Airtable data
                                </div>
                            </Radio>
                        </Space>
                    </Radio.Group>
                )
            }
        </Modal>
    );
};

export default MappingModal;
