import React, { useCallback, useEffect, useMemo } from 'react';
import s from './s.module.less';
import { TSubscriptionItemFromServer, TSubscriptionType, TSubscriptionSubType, EStripeStatus } from 'types/subscription';
import dayjs from 'utils/dayjs';
import BillingPortalButton from 'components/BillingPortalButton';
import { Button, Tag, message } from 'antd';
import { IPaymentInfo } from 'types/payment';
import { isTrialUser } from 'constants/subscription';
import { isFreeUserByInfo, isPaidFailUserByInfo, shouldShowKlarityPlanUI } from 'utils/provider';
import { getProviderAgreement } from 'api/subscription';
import { closeManageSubscriptionModal, openUniprofileAgreementSignModal } from 'utils/globalLayerControl';
import { HomeInfo } from 'types/provider';

interface IAgreementContractData {
    contractSignUrl?: string;
    contractVersion?: string;
}

interface IProps {
    currentPlan?: TSubscriptionItemFromServer;
    deital?: IPaymentInfo
    homeInfo?: HomeInfo
}

const PaymentInfo = ({
    currentPlan,
    deital,
    homeInfo,
}: IProps) => {
    const showKlarityPlanUI = shouldShowKlarityPlanUI(homeInfo);
    const [agreementContractData, setAgreementContractData] = React.useState<IAgreementContractData>();
    const { type = TSubscriptionType.FREE, originStatus, subType = TSubscriptionSubType.MONTHLY_BILLING, price = 0, createTime = new Date().getTime().toString() } = currentPlan || {};
    const { premiumListedCount = 0, standardListedCount = 0 } = deital || {};
    const isTrial = isTrialUser(currentPlan);
    const isFree = isFreeUserByInfo(currentPlan);
    const isPaidFail = isPaidFailUserByInfo(currentPlan);

    //means not postdue or unpaid
    const isEmptyPlan = (currentPlan?.type === TSubscriptionType.FREE && !currentPlan?.originStatus) || currentPlan?.originStatus === EStripeStatus.CANCELED;

    const getAgreementInfo = useCallback(async () => {
        try {
            const res = await getProviderAgreement();

            if (res && !res.error) {
                setAgreementContractData(res.data.data);
            } else {
                message.error(res?.error);
            }
        } catch (e) {
            console.error(e);
        }
    }, []);

    useEffect(() => {
        getAgreementInfo();
    }, [getAgreementInfo]);

    const handleViewAgreement = useCallback((link?: string) => {
        if (link) {
            window.open(link, '_blank');
        }
    }, []);

    const showAgreementModal = useCallback(() => {
        closeManageSubscriptionModal();
        openUniprofileAgreementSignModal('Confirm agreement of service', false);
    }, []);

    const renderPlanType = useMemo(() => {
        if (showKlarityPlanUI) {
            return (
                <div className={s.planType}>
                    <span>{currentPlan?.type} Plan</span>
                </div>
            );
        }
        if (isTrial) {
            return <div className={s.planType}> Growth Plan <span className={s.freeTrial}>Free Trial</span></div>;
        }

        if (isFree && !isTrial && !isPaidFail) {
            return (
                <div className={s.planType}>
                    <span>{TSubscriptionType.FREE} Plan</span>
                </div>
            );
        }

        if (isPaidFail && !isTrial) {
            return (
                <div className={s.planType}>
                    <span>{type || TSubscriptionType.FREE} Plan</span>
                    {
                        originStatus &&
                        <span>
                            {
                                originStatus === EStripeStatus.ACTIVE ||
                                    originStatus === EStripeStatus.TRIALING
                                    ? null :
                                    <Tag color="#f50">{originStatus?.replace('_', ' ').toUpperCase()}</Tag>
                            }
                        </span>
                    }
                </div>
            );
        }

        return <span> {currentPlan?.type} Plan</span>;
    }, [currentPlan, isFree, isPaidFail, isTrial, originStatus, type]);

    const renderAgreementText = useMemo(() => {
        if (agreementContractData && agreementContractData.contractSignUrl) {
            return (
                <div className={s.agreementLinkBox}>
                    <div className={s.agreementLinkLabel}>Plan agreement:</div>
                    <div className={s.links}>
                        <Button type="link" className={s.agreementLink} onClick={() => handleViewAgreement(agreementContractData.contractSignUrl)}>
                            {agreementContractData.contractVersion}
                        </Button>
                    </div>
                </div>
            );
        } else if (!isTrial && !isFree) {
            return (
                <div className={s.agreementLinkBox}>
                    <div className={s.agreementLinkLabel}>Plan agreement:</div>
                    <div className={s.links}>
                        <Button type="link" className={s.signAgreement} onClick={() => showAgreementModal()}>
                            review and confirm agreement
                        </Button>
                    </div>
                </div>
            );
        }

        return null;
    }, [agreementContractData, handleViewAgreement, isFree, isTrial, showAgreementModal]);
    return (
        <div className={s.wrap}>
            <div className={s.listInfo}>
                <div className={s.planTitle}>
                    You are with
                    {renderPlanType}
                </div>
                {
                    renderAgreementText
                }
                <div className={s.listBox}>
                    <div className={s.infoList}>Since you joined</div>
                    <div className={s.list}>
                        <div className={s.listItem}>You are listed on {Number(premiumListedCount) + Number(standardListedCount)} channels</div>
                        {/* <div className={s.listItem}>Received {prospectCount} prospects </div>
                        <div className={s.listItem}>{appointmentCount} appointment inquiries from Practice front</div> */}
                    </div>
                </div>
            </div>
            {
                !isEmptyPlan &&
                <div className={s.paymentInfo}>
                    <div className={s.paymentInfoTitle}>Billing & Payment</div>
                    <div className={s.infoBox}>
                        <div className={s.infoItem}>
                            <span>Price</span>
                            <span>{isTrial ? 'Free Trial' : `$${price}/mo`}</span>
                        </div>
                        <div className={s.infoItem}>
                            <span>Billing period</span>
                            <span>{subType.split(' ')[0]}</span>
                        </div>
                        <div className={s.infoItem}>
                            <span>Renewal date</span>
                            <span>{dayjs(createTime).format('MMM DD, YY')}</span>
                        </div>
                    </div>
                    <BillingPortalButton block disabled={isTrial || (isFree && !isPaidFail)} />
                </div>
            }
        </div>
    );
};

export default PaymentInfo;
