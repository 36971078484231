// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__uploadText--bvFTl {
  color: var(--gray-600);
}
.s-module__uploadText--bvFTl span {
  color: var(--primary-color);
}
.s-module__uploadHint--GQ7uG {
  color: var(--gray-500);
}
.s-module__iconWrap--qRB_Y {
  text-align: center;
  padding: 0px 0 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.s-module__preview--yfrjz {
  width: 300px;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.s-module__icon--EYltE {
  width: 36px;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.s-module__uploadName--KdN1C {
  color: var(--gray-500);
  font-size: 12px;
  line-height: 22px;
  margin-top: 8px;
}
.s-module__uploadName--KdN1C .s-module__remove--Ap3j5 {
  color: var(--primary-color);
  font-size: 14px;
  line-height: 20px;
  margin-left: 8px;
  cursor: pointer;
}
.s-module__wrap--WTfnP .ant-upload-list-text .ant-upload-list-item-container {
  display: none;
}
.s-module__wrap--WTfnP .ant-upload-list-text .ant-upload-list-item-container:last-child {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/components/form/FileUpload/s.module.less"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;AAFA;EAII,2BAAA;AACJ;AAGA;EACE,sBAAA;AADF;AAIA;EACE,kBAAA;EACA,qBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAFF;AAKA;EACE,YAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,uBAAA;EACA,yBAAA;EACA,qBAAA;AAHF;AAMA;EACE,WAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,uBAAA;EACA,yBAAA;EACA,qBAAA;AAJF;AAQA;EACE,sBAAA;EACA,eAAA;EACA,iBAAA;EACA,eAAA;AANF;AAEA;EAMI,2BAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;AALJ;AASA;EAGM,aAAA;AATN;AAMA;EAOM,cAAA;AAVN","sourcesContent":[".uploadText {\n  color: var(--gray-600);\n\n  span {\n    color: var(--primary-color);\n  }\n}\n\n.uploadHint {\n  color: var(--gray-500);\n}\n\n.iconWrap {\n  text-align: center;\n  padding: 0px 0 12px 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.preview {\n  width: 300px;\n  user-drag: none;\n  user-select: none;\n  -moz-user-select: none;\n  -webkit-user-drag: none;\n  -webkit-user-select: none;\n  -ms-user-select: none;\n}\n\n.icon {\n  width: 36px;\n  user-drag: none;\n  user-select: none;\n  -moz-user-select: none;\n  -webkit-user-drag: none;\n  -webkit-user-select: none;\n  -ms-user-select: none;\n}\n\n\n.uploadName {\n  color: var(--gray-500);\n  font-size: 12px;\n  line-height: 22px;\n  margin-top: 8px;\n  .remove{\n    color: var(--primary-color);\n    font-size: 14px;\n    line-height: 20px;\n    margin-left: 8px;\n    cursor: pointer;\n  }\n}\n\n.wrap {\n  :global {\n    .ant-upload-list-text .ant-upload-list-item-container {\n      display: none;\n    }\n\n    .ant-upload-list-text .ant-upload-list-item-container:last-child {\n      display: block;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploadText": `s-module__uploadText--bvFTl`,
	"uploadHint": `s-module__uploadHint--GQ7uG`,
	"iconWrap": `s-module__iconWrap--qRB_Y`,
	"preview": `s-module__preview--yfrjz`,
	"icon": `s-module__icon--EYltE`,
	"uploadName": `s-module__uploadName--KdN1C`,
	"remove": `s-module__remove--Ap3j5`,
	"wrap": `s-module__wrap--WTfnP`
};
export default ___CSS_LOADER_EXPORT___;
