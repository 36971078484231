export const CarouselLabels = {
    STATE_SELECT: '{StateSelect}',
    CONDITION_SELECT: '{ConditionSelect}',
    STATE: '{State}',
    CONDITION: '{Condition}',
};

export const MAX_CAROUSEL_LENGTH = 3;

export const CarouselDefaults = {
    CONDITION: {
        title: 'Providers for {ConditionSelect} treatment in {StateSelect}',
        description: 'Connect digitally or in-person with top providers for {Condition} treatment in {State}. No subscription or membership required, no hidden fees. Book your appointment today.',
    },
    STATE: {
        title: 'Providers near me in {StateSelect}',
        description: 'Connect digitally or in-person with top providers in {State}. No subscription or membership required, no hidden fees. Book your appointment today.',
    },
};

export const CAROUSELS_DEFAULT_IMAGE = 'https://saas-prod-us.s3.us-east-2.amazonaws.com/prod/c068e78f-0724-43d8-83ae-065d537c7ac8.png';
