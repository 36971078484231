import React, { useCallback, useMemo, useState } from 'react';
import s from './s.module.less';
import { Button, Modal, message } from 'antd';
import { IWorkExperience, EditModuleEnum, FormFields, IProviderStore } from 'types/practiceFront';
import dayjs from 'utils/dayjs';
import ExperienceItem from './components/ExperienceItem';
import ProviderStore from 'store/PracticeFront/provider';
import { removeExperience } from 'api/practiceFront';
import EditStatusStore from 'store/PracticeFront/editStatus';
import remove from 'lodash/remove';
import ErrorBlock from '../ErrorBlock';
import useRefreshPreview from 'hooks/useRefreshPreview';

interface IProps {
    readonly?: boolean;
    showEditingTip?: boolean;
    initFormValue: Array<IWorkExperience>;
    onSubmit: (value: IWorkExperience, field: EditModuleEnum, callback: () => void) => void;
    onEditStatusChange: (status: boolean, field: EditModuleEnum) => void;
    refetch: () => void;
    scrollToTheEditingModule?: () => void;
    setShowEditingTip?: (status: boolean) => void;
}

const WorkExperience = ({
    readonly,
    setShowEditingTip,
    scrollToTheEditingModule,
    showEditingTip,
    initFormValue,
    onSubmit,
    onEditStatusChange,
    refetch,
}: IProps) => {
    const [getProvider, setProvider] = ProviderStore.useStore();
    const [getEditStatus] = EditStatusStore.useStore();
    const editStatusMap = getEditStatus('data');
    const gloablEditStatus = useMemo(() => !editStatusMap || !!Object.values(editStatusMap).find((e) => e), [editStatusMap]);
    const [, contextHolder] = message.useMessage();
    const [currentEditingIndex, setCurrentEditingIndex] = useState(-1);
    const [currentId, setCurrentId] = useState<number | undefined>();
    const [currentRemoveIndex, setCurrentRemoveIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    const [setPreview] = useRefreshPreview();

    const providerData = getProvider('data') as IProviderStore;

    const handleEditStatusChange = useCallback((status: boolean, index: number) => {
        if (gloablEditStatus && status) {
            scrollToTheEditingModule?.();
            setShowEditingTip?.(true);
            message.error('Please save your changes before proceeding');
        } else {
            setShowEditingTip?.(false);
            setCurrentEditingIndex(status ? index : -1);
            onEditStatusChange(status, EditModuleEnum.WORKEXPERIENCE);
        }
    }, [gloablEditStatus, onEditStatusChange, scrollToTheEditingModule, setShowEditingTip]);

    const submit = useCallback(async (values: IWorkExperience, id?: number) => {
        onSubmit({
            ...values,
            endDate: values.currently ? dayjs(undefined) : values.endDate,
            id,
        }, EditModuleEnum.WORKEXPERIENCE, () => setCurrentEditingIndex(-1));
    }, [onSubmit]);

    const handleShowModal = useCallback((index: number, id?: number) => {
        if (gloablEditStatus) {
            scrollToTheEditingModule?.();
            setShowEditingTip?.(true);
            message.error('Please save your changes before proceeding');
        } else {
            setShowEditingTip?.(false);
            setCurrentId(id);
            setCurrentRemoveIndex(index);
            setOpen(true);
        }
    }, [gloablEditStatus, scrollToTheEditingModule, setShowEditingTip]);

    const handleHideModal = useCallback(() => {
        setCurrentId(undefined);
        setOpen(false);
    }, []);

    const handleRemove = useCallback(async () => {
        if (currentId) {
            const res = await removeExperience(currentId);

            if (res.error) {
                message.error(res.error);
            } else {
                setPreview('flag', {});
                setOpen(false);
                refetch();
            }
        } else {
            remove(initFormValue, (e, i) => i === currentRemoveIndex);
            setProvider('data', { ...providerData, experience: [...initFormValue] });
            setOpen(false);
        }
    }, [currentId, refetch, setProvider, providerData, initFormValue, currentRemoveIndex, setPreview]);

    const handleAdd = useCallback(() => {
        if (gloablEditStatus) {
            scrollToTheEditingModule?.();
            setShowEditingTip?.(true);
            message.error('Please save your changes before proceeding');
        } else {
            setShowEditingTip?.(false);
            const newExp = {
                [FormFields.ORGANIZATION_NAME]: '',
                [FormFields.TITLE]: '',
                [FormFields.IS_CURRENTLY_WORK]: false,
                [FormFields.START_DATE]: dayjs(undefined),
                [FormFields.END_DATE]: dayjs(undefined),
                [FormFields.EMPLOYMENT_TYPE]: '',
                [FormFields.HOSPITAL_LOCATION]: '',
                [FormFields.DESCRIPTION]: '',
                [FormFields.LOGO]: '',
            };
            const newStore = {
                ...providerData,
                experience: [newExp, ...(providerData.experience || [])],
            };

            setProvider('data', newStore);
            setCurrentEditingIndex(0);
            onEditStatusChange(true, EditModuleEnum.WORKEXPERIENCE);
        }
    }, [gloablEditStatus, onEditStatusChange, providerData, scrollToTheEditingModule, setProvider, setShowEditingTip]);

    return (
        <div className={s.wrap} id={EditModuleEnum.WORKEXPERIENCE}>
            {contextHolder}
            <Modal title="Remove this information in UniProfile?" open={open} onOk={handleRemove} onCancel={handleHideModal} okText="Remove">
                <p>The information will also be removed from UniProfile. Please note that this will be applied to your UniProfile and updated across all channels. Are you sure you want to proceed?</p>
            </Modal>
            <div className={s.header}>
                <div className={s.title}>Work experience</div>
                {
                    !readonly &&
                    <div className={s.btns}>
                        <Button type="primary" onClick={handleAdd}>Add</Button>
                    </div>
                }
            </div>
            {
                showEditingTip && currentEditingIndex !== -1 && <ErrorBlock />
            }
            <div className={s.body}>
                {
                    initFormValue && initFormValue.length > 0 ?
                        initFormValue?.map((item, index) => {
                            const editing = index === currentEditingIndex;
                            const { id } = item;

                            return (
                                <div key={id || index} className={s.item}>
                                    <ExperienceItem
                                        readonly={readonly}
                                        id={id}
                                        index={index}
                                        isEditting={editing}
                                        data={item}
                                        onEditStatusChange={handleEditStatusChange}
                                        onSubmit={submit}
                                        onRemove={handleShowModal}
                                    />
                                </div>
                            );
                        })
                        :
                        <div className={s.noncontent}>Nothing here yet</div>
                }
            </div>
        </div>
    );
};

export default WorkExperience;
