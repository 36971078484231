import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { Color } from 'types/calendar';
import useKiwiPage from 'hooks/useKiwiPage';
import { TPage } from 'types/kiwiHealth';
import { EStatus } from 'types/common';

type KiwiChannelSelectProps = {
    useAdminApi?: boolean;
    value?: number;
    onChange?: (val: number) => void,
    colorOptions?: Color[],
    disableValues?: number[],
} & SelectProps;

const KiwiChannelSelect = (props:KiwiChannelSelectProps) => {
    const { onChange, colorOptions, value, disableValues, useAdminApi, ...reset } = props;
    const [itemList, loading, refresh] = useKiwiPage(true);
    const displayItems = itemList?.filter((p:TPage) => { return p.status === EStatus.Active; }) || [];

    return (
        <Select {...reset} value={value} onChange={onChange} loading={loading} placeholder="Select a page">
            {
                displayItems.map((option:TPage) => {
                    let disabled = disableValues && option.id && (disableValues.includes(option?.id));
                    if (value === option.id) {
                        disabled = false;
                    }
                    return (
                        <Select.Option
                            key={option.id}
                            value={option.id}
                            disabled={disabled}
                        >
                            <div>{option.name}</div>
                        </Select.Option>
                    );
                })
            }
        </Select>
    );
};

export default KiwiChannelSelect;
