import React from 'react';
import { Form, DatePicker, Input, Checkbox } from 'antd';
import { NURSE_LICENSE_TYPES, OTHERS_KEY, SCHEDULE } from 'constants/meta';
import { FormInstance } from 'antd/lib/form';
import { FormLicense } from 'types/form';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import FileUpload from 'components/form/FileUpload';
import { Meta } from 'types/common';
import FormValidationHelper from 'utils/validation';

type Props = {
    editable?:boolean,
    disableNumberAndDate?: boolean,
    form: FormInstance<FormLicense>,
};

const NurseLicense = (props: Props) => {
    const { form, disableNumberAndDate } = props;
    const [selectTypes, setSelectTypes] = React.useState<string[]>([]);
    const [checkOthers, setCheckOthers] = React.useState(false);
    const [inputVal, setInputVal] = React.useState('');

    const optionsValues = NURSE_LICENSE_TYPES?.map((item:any) => item.value) || [];
    const boxValues = [...selectTypes, checkOthers && OTHERS_KEY].filter(Boolean).filter((val) => optionsValues.includes(val));

    const inputArray = inputVal?.split(',').filter(Boolean) || [];
    const licenseTypesOption = [...NURSE_LICENSE_TYPES];

    const [otherLicenseMap, setOtherLicenseMap] = React.useState<Record<string, string>>({});

    const onLicenseTypeChange = (check:boolean, val: string) => {
        if (val === OTHERS_KEY) {
            setCheckOthers(check);
            if (!check) {
                setInputVal('');
                const newLicenseType = { ...form.getFieldValue('licenseType') };
                delete newLicenseType[OTHERS_KEY];
                form.setFieldValue(['licenseType'], (checkOthers || selectTypes.length) > 0 ? newLicenseType : undefined);
            }
            return;
        }
        if (check && !selectTypes.includes(val)) {
            setSelectTypes([...selectTypes, val]);
        } else if (!check && selectTypes.includes(val)) {
            const newLicenseType = { ...form.getFieldValue('licenseType') };
            delete newLicenseType[val];
            setSelectTypes(selectTypes.filter((v) => v !== val));
            form.setFieldValue(['licenseType'], (checkOthers || selectTypes.length) > 0 ? newLicenseType : undefined);
        }
    };

    React.useEffect(():void => {
        //must set before modal show
        const licenseType = form.getFieldValue('licenseType');
        if (!licenseType) {
            return;
        }
        const systemValues = NURSE_LICENSE_TYPES?.map((item:any) => item.value) || [];
        const defaultValues = licenseType ? Object.keys(licenseType) : [];
        const defaultSelectValues = defaultValues.filter((val) => systemValues.includes(val));
        const others = licenseType[OTHERS_KEY];
        if (others && Object.keys(others).length > 0) {
            setCheckOthers(true);
            setInputVal(Object.keys(others).join(','));
            setOtherLicenseMap(others);
        }
        setSelectTypes(defaultSelectValues);
    }, []);

    return (
        <div className={s.wrap}>
            <p className={s.tips}>
                Add a nurse practitioner license and upload all your license type documents.
            </p>
            <Form
                form={form}
                name="basic"
                className={commonS.formStyle1}
                initialValues={{ remember: true }}
                autoComplete="off"
                layout="vertical"
            >
                <div className={s.module}>
                    <div className={s.row}>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '360px' }}
                                label="Nurse practitioner license number"
                                name={['licenseNumber']}
                                validateFirst
                                rules={[
                                    { required: true, message: 'Nurse practitioner license number is required' },
                                    FormValidationHelper.validateLicenseNumber('License number must contain only letters or numbers'),
                                ]}
                            >
                                <Input disabled={disableNumberAndDate} maxLength={25} placeholder="What is the nurse practitioner license number?  " />
                            </Form.Item>
                        </div>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '360px' }}
                                label="Expiration date"
                                name={['expireDate']}
                                rules={[{ required: true, message: 'Expiration date is required' }]}
                            >
                                <DatePicker
                                    disabled={disableNumberAndDate}
                                    disabledDate={(date) => {
                                        const now = (new Date()).getTime();
                                        if (!date) {
                                            return false;
                                        }
                                        return date?.valueOf() < now;
                                    }}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    {/* <div className={s.rowItem}>
                        <Form.Item
                            style={{ width: '500px' }}
                            label="Select schedules this license cover"
                            name={['schedule']}
                            rules={[{ required: true, message: 'Schedules is required' }]}
                        >
                            <Checkbox.Group
                                options={SCHEDULE}
                            />
                        </Form.Item>
                    </div> */}
                    <div className={s.rowItem}>
                        <Form.Item
                            style={{ width: '360px' }}
                            label="Select all license type you have"
                            name={['licenseType']}
                            rules={[{ required: true, message: 'licenses is required' },
                                {
                                    validator: async (_, value) => {
                                        if (Object.keys(value).length === 0) {
                                            throw new Error('licenses is required');
                                        }
                                    },
                                },
                            ]}
                        >
                            {
                                licenseTypesOption.map((meta:Meta) => {
                                    return (
                                        <div key={meta.value}>
                                            <Checkbox checked={!!selectTypes.includes(meta.value as string)} onChange={(e) => { onLicenseTypeChange(e.target.checked, `${meta.value}`); }}>{meta.label}</Checkbox>
                                            {boxValues.includes(`${meta.value}`) &&
                                            <div className={s.rowItem}>
                                                <Form.Item
                                                    style={{ width: '500px' }}
                                                    label="Upload the copy of the license"
                                                    name={['licenseType', meta.value]}
                                                    rules={[{ required: true, message: 'License copy is required' }]}
                                                >
                                                    <FileUpload accept="image/*,.pdf" />
                                                </Form.Item>
                                            </div>
                                            }
                                        </div>
                                    );
                                })
                            }
                            <div key={OTHERS_KEY}>
                                <Checkbox checked={checkOthers} onChange={(e) => { onLicenseTypeChange(e.target.checked, OTHERS_KEY); }}>Other, not listed here</Checkbox>
                            </div>
                            <Input
                                value={inputVal}
                                onChange={(e) => {
                                    setInputVal(e.target.value);
                                    form.setFieldValue(['licenseType', OTHERS_KEY], {});
                                }}
                                disabled={!checkOthers}
                                style={{ marginTop: 4 }}
                                placeholder="Add your other licenses and seperate with “,”"
                            />
                            {
                                inputArray.map((val) => {
                                    return (
                                        <div className={s.rowItem}>
                                            <Form.Item
                                                style={{ width: '500px', marginTop: 16 }}
                                                label="Upload the copy of the license"
                                                name={['licenseType', OTHERS_KEY, val]}
                                                initialValue={otherLicenseMap[val]}
                                                rules={[{ required: true, message: 'License copy is required' }]}
                                            >
                                                <FileUpload accept="image/*,.pdf" />
                                            </Form.Item>
                                        </div>
                                    );
                                })
                            }
                        </Form.Item>

                    </div>
                </div>
            </Form>
        </div>
    );
};

export default NurseLicense;
