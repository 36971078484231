import React, { useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { Input } from 'antd';

interface IProps {
    options?: string[],
    value?: string,
    onChange?: (v: string) => void;
}

const HeadlinesEditor = ({
    options,
    value,
    onChange,
}: IProps) => {
    const [val, setVal] = useState('');

    useEffect(() => {
        if (value) {
            setVal(value);
        }
    }, [value]);

    const handleClick = useCallback((v: string) => {
        if (v !== val) {
            setVal(v);
            onChange?.(v);
        }
    }, [onChange, val]);

    const handleTextChange = useCallback((e) => {
        const v = e.target.value;
        setVal(v);
        onChange?.(v);
    }, []);

    return (
        <div className={s.wrap}>
            {
                options && options.length > 0 ? (
                    <>
                    <div className={s.optionsBox}>
                        {
                            options.map((e, index) => {
                                return (
                                    <div className={`${s.option} ${val === e ? s.selected: ''}`} key={index} onClick={() => handleClick(e)}>
                                        <div className={s.text}>{e}</div>
                                    </div>
                                );
                            })
                        }
                    </div>
                    <Input.TextArea value={val} className={s.textArea} showCount maxLength={100} onChange={handleTextChange} />
                    </>
                ) :
                <Input type="text" value={val} onChange={handleTextChange} />
            }
        </div>
    );
};

export default HeadlinesEditor;
