// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/common/tipWarningIcon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--oEAy1 .s-module__tip--aet0J {
  margin-bottom: 24px;
  padding: 16px 18px;
  background-color: var(--yellow-50);
  border-radius: 6px;
  display: flex;
}
.s-module__wrap--oEAy1 .s-module__tip--aet0J .s-module__tipIcon--fM0XS {
  margin-right: 12px;
  width: 28px;
  height: 28px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center no-repeat;
  background-size: 100%;
}
.s-module__wrap--oEAy1 .s-module__tip--aet0J .s-module__tipContent--0WiJl .s-module__title--Rrlaz {
  margin-bottom: 8px;
  font-size: 16px;
  color: var(--yellow-800);
}
.s-module__wrap--oEAy1 .s-module__tip--aet0J .s-module__tipContent--0WiJl .s-module__text--JpjUH {
  font-size: 16px;
  color: var(--yellow-700);
}
`, "",{"version":3,"sources":["webpack://./src/components/PracticeAddressRemovement/s.module.less"],"names":[],"mappings":"AAAA;EAEQ,mBAAA;EACA,kBAAA;EACA,kCAAA;EACA,kBAAA;EACA,aAAA;AAAR;AANA;EASY,kBAAA;EACA,WAAA;EACA,YAAA;EACA,oEAAA;EACA,qBAAA;AAAZ;AAbA;EAkBgB,kBAAA;EACA,eAAA;EACA,wBAAA;AAFhB;AAlBA;EAuBgB,eAAA;EACA,wBAAA;AAFhB","sourcesContent":[".wrap {\n    .tip {\n        margin-bottom: 24px;\n        padding: 16px 18px;\n        background-color: var(--yellow-50);\n        border-radius: 6px;\n        display: flex;\n\n        .tipIcon {\n            margin-right: 12px;\n            width: 28px;\n            height: 28px;\n            background: url('assets/common/tipWarningIcon.svg') center no-repeat;\n            background-size: 100%;\n        }\n\n        .tipContent {\n            .title {\n                margin-bottom: 8px;\n                font-size: 16px;\n                color: var(--yellow-800);\n            }\n            .text {\n                font-size: 16px;\n                color: var(--yellow-700);\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--oEAy1`,
	"tip": `s-module__tip--aet0J`,
	"tipIcon": `s-module__tipIcon--fM0XS`,
	"tipContent": `s-module__tipContent--0WiJl`,
	"title": `s-module__title--Rrlaz`,
	"text": `s-module__text--JpjUH`
};
export default ___CSS_LOADER_EXPORT___;
