import { EditModuleEnum } from 'types/practiceFront';

const ModuleTabIndex = {
    [EditModuleEnum.BASICINFO]: 1,
    [EditModuleEnum.INSURANCE]: 1,
    [EditModuleEnum.MYPHILOSOPHY]: 1,
    [EditModuleEnum.SEPCIALITIESAPPROACH]: 1,
    [EditModuleEnum.WORKEXPERIENCE]: 1,
    [EditModuleEnum.EDUCATION]: 1,
    [EditModuleEnum.SERVICE]: 1,
    [EditModuleEnum.ABOUTME]: 1,
    [EditModuleEnum.CONTACT]: 2,
    [EditModuleEnum.PRACTICE_ADDRESS]: 2,
    [EditModuleEnum.LOCAL_SEO]: 2,
    [EditModuleEnum.APPOINTMENT_LINK]: 3,
};

export default ModuleTabIndex;
