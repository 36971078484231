import { Button, Modal } from 'antd';
import InfoNote from 'components/InfoNote';
import Notes from 'components/Notes';
import React, { useCallback, useEffect, useState } from 'react';
import commonS from 'styles/common.module.less';
import s from './s.module.less';
import VideoUploader from 'components/VideoUploader';
import RightDemo from '../../imgs/correct.png';
import ErrorDemo from '../../imgs/error.png';
import RightDemo2 from '../../imgs/correct2.png';
import ErrorDemo2 from '../../imgs/error2.png';
import VideoPreview from 'components/VideoPreview';
import { PROGRAM_YOUTUBE_LINK } from 'constants/common';
import { uploadFileByType } from 'api/common';
import { getUserId } from 'utils/localstore';
import { VIDEO_UPLOAD_DEMO } from 'constants/common';

interface IProps {
    show: boolean,
    onCancel: () => void,
    onSuccess: (url: string, cover: string) => void,
    isOps?: boolean;
    providerId?: number;
}

const UploadVideoModal = (props: IProps) => {
    const { show, onCancel, onSuccess, isOps, providerId } = props;
    const [uploadedVideoUrl, setUploadedVideoUrl] = React.useState("");
    const [file, setFile] = useState<File | null>(null);
    const [uploading, setUploading] = useState(false);

    const handleUpload = async () => {
        //upload photo
        setUploading(true);
        try {
            const pid = isOps ? providerId : getUserId();
            const formData = new FormData();
            formData.append('file', file!);
            formData.append('providerId', pid + '');
            formData.append('uploadType', 'IMAGE');
            const res = await uploadFileByType(formData);
            if (!res.error) {
                const photoUrl = res.data?.data
                if (photoUrl) {
                    onSuccess(uploadedVideoUrl, photoUrl);
                    setUploadedVideoUrl("");
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            setUploading(false);
        }
    }

    return (
        <Modal
            open={show}
            title='Videos'
            destroyOnClose
            width={900}
            onCancel={() => {
                setUploadedVideoUrl("");
                onCancel();
            }}
            className={commonS.modalFixHeightWrap}
            footer={
                <div className={s.modalFooter}>
                    <Button
                        onClick={() => {
                            setUploadedVideoUrl("");
                            onCancel();
                        }}
                        className={s.cancelBtn}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={!file || !uploadedVideoUrl}
                        type='primary'
                        loading={uploading}
                        className={s.saveBtn}
                        onClick={handleUpload}
                    >
                        Upload
                    </Button>
                </div>
            }
        >
            <div className={s.modalWrap}>
                <Notes
                    hideNote
                    des={
                        "💡 Not sure where to start? Watch the example video for ideas."
                    }
                    buttonElement={
                        <Button
                            className={s.watchBtn}
                            onClick={() => {
                                window.open(VIDEO_UPLOAD_DEMO, '_blank');
                            }}
                        >
                            Watch example videos
                        </Button>
                    }
                ></Notes>
                <div className={s.mContent}>
                    <div className={s.left}>
                        {
                            !uploadedVideoUrl &&
                            <VideoUploader
                                isOps={isOps}
                                providerId={providerId}
                                index={1}
                                cls={s.videoUploader}
                                onRemove={() => { }}
                                onChange={(url: string) => {
                                    setUploadedVideoUrl(url);
                                }}
                                height={500}
                            />
                        }
                        {
                            uploadedVideoUrl &&
                            <VideoPreview
                                url={uploadedVideoUrl}
                                onCoverImageFileChange={(file: File | null) => {
                                    setFile(file);
                                }}
                                onUrlChange={(url: string) => {
                                    setUploadedVideoUrl(url);
                                }}
                            />
                        }

                    </div>
                    <div className={s.right}>
                        <h4>
                            Upload your 15-second intro video
                        </h4>
                        <div className={s.des}>
                            Introduce yourself in a 15-second video to make a memorable first impression with potential clients. Focus on clarity, professionalism, and highlighting your unique expertise. Leave the last 5 seconds for a warm closing to create a connection.
                        </div>
                        <h4>Tips for an engaging introduction video:</h4>
                        <div className={s.label}>
                            Content: Be yourself—smile and speak naturally
                        </div>
                        <div className={s.text}>
                            Start with a warm greeting, and avoid reading from a script. A simple, friendly introduction works best—15 seconds is enough to create a connection.
                        </div>
                        <div className={s.label}>
                            Lighting: Use natural or soft front-facing light
                        </div>
                        <div className={s.text}>
                            Position yourself facing a window or soft light source to ensure your face is well-lit without shadows. Avoid bright backlighting or dim environments.
                        </div>
                        <div className={s.demoWrap}>
                            <div className={s.dLeft}>
                                <img src={RightDemo} />
                            </div>
                            <div className={s.dRight}>
                                <img src={ErrorDemo} />
                            </div>
                        </div>
                        <div className={s.label}>
                            Camera: Hold your phone vertically in portrait mode
                        </div>
                        <div className={s.text}>
                            Record your video in vertical (portrait) orientation. Landscape (horizontal) videos will not be accepted. Make sure your face is centered and stable in the frame.
                        </div>
                        <div className={s.demoWrap}>
                            <div className={s.dLeft}>
                                <img src={RightDemo2} />
                            </div>
                            <div className={s.dRight}>
                                <img src={ErrorDemo2} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default UploadVideoModal;
