import React, { useMemo } from 'react';
import s from './s.module.less';
import { IChannelListChannel } from 'types/practiceFront';

interface IProps {
    initFormValue: Array<IChannelListChannel>;
}

const ChannelPlatforms = ({
    initFormValue,
}: IProps) => {
    const listData = useMemo(() => {
        const data = (initFormValue || []).map((e) => e.name);
        if (data?.length > 5) {
            return [...data.slice(0, 5), '...'];
        }

        return data;
    }, [initFormValue]);
    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <div className={s.title}>Channels & Platforms</div>
            </div>
            <div className={s.tip}>
                <div className={s.icon} />
                <div className={s.tipText}>This is a non-editable section. The information is auto-populated based on your Channel information.</div>
            </div>
            <div className={s.body}>
                <div className={s.viewWrap}>
                    <div className={s.viewItem}>
                        <div className={s.label}>Channels</div>
                        <div className={s.value}>
                            {
                                listData && listData.length > 0 ?
                                    <ul>
                                        {
                                            listData.map((e) => <li key={e}>{e}</li>)
                                        }
                                    </ul> :
                                    <div className={s.noncontent}>No channels</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChannelPlatforms;
