import React from 'react';
import { Spin, Button, Input, Modal, message } from 'antd';
import s from './s.module.less';
import { SelectValue, CityState } from 'types/common';
import { EMetaType } from '../../types';
import { lookupLocation } from 'api/googleGeo';
import RemoveIcon from './Remove.svg';
import DebounceSelect from '../DebounceSelect';
import { EMetaTypeDesMap } from '../../constants';
import { isFreeUser } from 'utils/provider';
import { openSubscriptionModal } from 'utils/globalLayerControl';
import { providerSeoCityUpdate, providerSeoCountryUpdate, providerSeoLandmarkUpdate, providerSeoZipUpdate } from 'api/provider';
import { updateProviderSeoCity, updateProviderSeoCountry, updateProviderSeoLandmark, updateProviderSeoZip } from 'api/operation';

interface IProps {
    type: EMetaType;
    onUpdated: (val:string[]) => void;
    onPendingUpdate?: (val:string[]) => void;
    values?: string[];
    state: string;
    isOps?: boolean;
    providerId?: number,
    onEditStatusChange: (status: boolean) => void;
}

const MetaArea = ({
    values = [],
    type,
    onUpdated,
    onPendingUpdate,
    isOps,
    providerId,
    state,
    onEditStatusChange,
}: IProps) => {
    const [loading, setLoading] = React.useState(false);
    const [pendingValue, setPendingValue] = React.useState<string[]>([]);
    const [pendingAddValue, setPendingAddValue] = React.useState<SelectValue[]>([]);
    const [showAddModal, setShowAddModal] = React.useState(false);
    const [isEditing, setIsEditing] = React.useState(false);
    const [orgValues, setOrgValues] = React.useState<string[]>([]);
    const isFree = isFreeUser();

    React.useEffect(() => {
        setPendingValue([...values]);
        setOrgValues([...values]);
    }, [values]);

    const fetchAddressList = (address: string, callback:(res:SelectValue[])=>void):void => {
        lookupLocation(address, (cityState:CityState[], status) => {
            if (status === 'OK' && cityState?.length > 0) {
                callback(cityState?.map(
                    (cState:CityState) => ({
                        label: cState.city,
                        value: cState.city,
                    }),
                ));
            } else {
                callback([{ label: 'Not data', value: '', disabled: true }]);
            }
        }, isOps);
    };

    const handleSubmitOps = async () => {
        if (!providerId) {
            message.error('Provider id is not found');
            return;
        }
        let fun = updateProviderSeoCity;
        if (type === EMetaType.LANDMARKS) {
            fun = updateProviderSeoLandmark;
        }
        if (type === EMetaType.COUNTIES) {
            fun = updateProviderSeoCountry;
        }
        if (type === EMetaType.ZIP) {
            fun = updateProviderSeoZip;
        }
        setLoading(true);
        const result = await fun(pendingValue.slice(), providerId, state);
        setLoading(false);
        if (!result.error) {
            message.success('Information updated successfully');
            setIsEditing(false);
            setOrgValues([...pendingValue]);
            onPendingUpdate?.([...pendingValue]);
            onUpdated?.([...pendingValue]);
        }
    };

    const handleSubmit = async () => {
        let fun = providerSeoCityUpdate;
        if (type === EMetaType.LANDMARKS) {
            fun = providerSeoLandmarkUpdate;
        }
        if (type === EMetaType.COUNTIES) {
            fun = providerSeoCountryUpdate;
        }
        if (type === EMetaType.ZIP) {
            fun = providerSeoZipUpdate;
        }
        setLoading(true);
        const result = await fun(pendingValue.slice(), state);
        setLoading(false);
        if (!result.error) {
            message.success('Information updated successfully');
            setIsEditing(false);
            setOrgValues([...pendingValue]);
            onPendingUpdate?.([...pendingValue]);
            onUpdated?.([...pendingValue]);
        }
    };

    return (
        <div className={s.wrap}>
            {
                isEditing && (
                    <h4 className={s.edit}>
                        <span>Service areas by {EMetaTypeDesMap[type].toLowerCase()} <span className={s.high}>*</span></span>
                        <div>
                            <Button
                                onClick={() => {
                                    setIsEditing(false);
                                    onPendingUpdate?.([...values]);
                                    setPendingValue([...values]);
                                    onEditStatusChange(false);
                                }}
                                size="small"
                                style={{ marginRight: 8 }}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    if (isFree && !isOps) {
                                        openSubscriptionModal();
                                        return;
                                    }
                                    if (isOps) {
                                        handleSubmitOps();
                                    } else {
                                        handleSubmit();
                                    }
                                }}
                                size="small"
                                type="primary"
                                loading={loading}
                            >
                                Save changes
                            </Button>
                        </div>
                    </h4>
                )
            }
            {
                !isEditing && (
                    <h4 className={s.notEdit}>
                        <span>Service areas by {EMetaTypeDesMap[type].toLowerCase()}</span>
                        <Button
                            onClick={() => {
                                setIsEditing(true);
                                onPendingUpdate?.([...orgValues]);
                                onEditStatusChange(true);
                            }}
                            size="small"
                        >
                            Edit
                        </Button>
                    </h4>
                )
            }
            {
                isEditing && (
                    <>
                        <div className={s.preview}>
                            {
                                loading && <Spin size="small" />
                            }
                            {
                                pendingValue?.map((ad:string) => {
                                    return (
                                        <div className={s.pItem} key={ad}>{ad} <img
                                            onClick={() => {
                                                const newPendingValue = pendingValue.filter((item) => item !== ad);
                                                setPendingValue(newPendingValue);
                                                onPendingUpdate?.(newPendingValue);
                                            //sentMessage(isEditing, newPendingValue);
                                            }}
                                            src={RemoveIcon}
                                        />
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <div className={s.btn} onClick={() => { setShowAddModal(true); }}>
                            + Add more {EMetaTypeDesMap[type]?.toLowerCase()}
                        </div>
                    </>
                )
            }

            {
                !isEditing && (
                    <div className={s.previewDisplay}>
                        <ul>
                            {
                                orgValues?.map((ad:string) => {
                                    return <li key={ad}>{ad}</li>;
                                })
                            }
                        </ul>
                    </div>
                )
            }

            <Modal
                width="550px"
                className={s.modal}
                title={`Add more ${EMetaTypeDesMap[type].toLowerCase()} for local SEO`}
                footer={null}
                open={showAddModal}
                onCancel={() => {
                    setPendingAddValue([]);
                    setShowAddModal(false);
                }}
            >
                <div className={s.mWrap}>
                    <div className={s.label}>Service areas</div>
                    {
                        type === EMetaType.CITIES && (
                            <DebounceSelect
                                mode="multiple"
                                value={pendingAddValue}
                                placeholder="Input city"
                                // eslint-disable-next-line react/jsx-no-bind
                                fetchOptions={fetchAddressList}
                                onChange={(newValue) => {
                                    setPendingAddValue(newValue as SelectValue[]);
                                }}
                                style={{ width: '100%' }}
                            />
                        )
                    }
                    {
                        type !== EMetaType.CITIES && (
                            <Input
                                value={pendingAddValue?.length > 0 ? pendingAddValue[0].value : ''}
                                placeholder={`Input ${EMetaTypeDesMap[type].toLowerCase()}`}
                                onChange={(e) => {
                                    setPendingAddValue([{ label: e.target.value, value: e.target.value }]);
                                }}
                                style={{ width: '100%' }}
                            />
                        )
                    }

                    <div className={s.btnWrap}>
                        <Button
                            onClick={() => {
                                setPendingAddValue([]);
                                setShowAddModal(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={pendingAddValue.length === 0}
                            style={{ marginLeft: 16 }}
                            type="primary"
                            onClick={() => {
                                const addStr:string[] = pendingAddValue?.map((item) => item.value);
                                const newPendingValue = [...pendingValue, ...addStr];
                                const uniqueArray = newPendingValue.filter((value, index, self) => {
                                    return self.indexOf(value) === index;
                                });
                                setPendingValue(uniqueArray);
                                onPendingUpdate?.(uniqueArray);
                                setPendingAddValue([]);
                                setShowAddModal(false);
                                //sentMessage(isEditing, uniqueArray);
                            }}
                        >
                            Add
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default MetaArea;
