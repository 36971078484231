// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/common/correctIcon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrapper--a4x32 {
  padding-top: 4px;
}
.s-module__wrapper--a4x32 .s-module__photoList--GkKsa {
  display: flex;
  flex-wrap: wrap;
}
.s-module__wrapper--a4x32 .s-module__photoList--GkKsa .s-module__photo--h3p4F {
  position: relative;
  margin-right: 16px;
  margin-bottom: 16px;
}
.s-module__wrapper--a4x32 .s-module__photoList--GkKsa .s-module__placeholder--cDGDC {
  width: 245px;
  height: 245px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  border: 2px dashed var(--gray-300);
  border-radius: 6px;
  -o-object-fit: scale-down;
     object-fit: scale-down;
}
.s-module__wrapper--a4x32 .s-module__photoList--GkKsa .s-module__selected--IZqSE::after {
  content: '';
  width: 32px;
  height: 32px;
  display: block;
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 8;
  background: var(--white) url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center no-repeat;
  background-size: cover;
  border-radius: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/components/PhotoList/s.module.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAFA;EAGQ,aAAA;EACA,eAAA;AAER;AANA;EAOY,kBAAA;EACA,kBAAA;EACA,mBAAA;AAEZ;AAXA;EAaY,YAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,oBAAA;EACA,kBAAA;EACA,kCAAA;EACA,kBAAA;EACA,yBAAA;KAAA,sBAAA;AACZ;AAGY;EACI,WAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;EACA,UAAA;EACA,iFAAA;EACA,sBAAA;EACA,kBAAA;AADhB","sourcesContent":[".wrapper {\n    padding-top: 4px;\n    .photoList {\n        display: flex;\n        flex-wrap: wrap;\n\n        .photo {\n            position: relative;\n            margin-right: 16px;\n            margin-bottom: 16px;\n        }\n\n        .placeholder {\n            width: 245px;\n            height: 245px;\n            display: flex;\n            justify-content: center;\n            align-items: center;\n            font-family: 'Inter';\n            font-style: normal;\n            border: 2px dashed var(--gray-300);\n            border-radius: 6px;\n            object-fit: scale-down;\n        }\n\n        .selected {\n            &::after {\n                content: '';\n                width: 32px;\n                height: 32px;\n                display: block;\n                position: absolute;\n                top: 12px;\n                left: 12px;\n                z-index: 8;\n                background: var(--white) url('assets/common/correctIcon.svg') center no-repeat;\n                background-size: cover;\n                border-radius: 50%;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `s-module__wrapper--a4x32`,
	"photoList": `s-module__photoList--GkKsa`,
	"photo": `s-module__photo--h3p4F`,
	"placeholder": `s-module__placeholder--cDGDC`,
	"selected": `s-module__selected--IZqSE`
};
export default ___CSS_LOADER_EXPORT___;
