import React, { useCallback, useEffect, useMemo } from 'react';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { Button, Form } from 'antd';
import { IMyPhilosophy, EditModuleEnum, FormFields } from 'types/practiceFront';
import RichText from 'components/RichText';
import { removeHtmlTag } from 'utils/common';
import { TreatmentPhilosophyTextLength } from 'constants/common';
import ErrorBlock from '../ErrorBlock';
import TreatmentApproach from 'components/ApplicationFormComponents/Practice/component/TreatmentApproch';

interface IProps {
    readonly?: boolean;
    showEditingTip?: boolean;
    isEditing?: boolean;
    initFormValue: IMyPhilosophy;
    onEditStatusChange: (status: boolean, field: EditModuleEnum) => void;
    onSubmit: (value: IMyPhilosophy, field: EditModuleEnum) => void;
}

const MyPhilosophy = ({
    readonly,
    showEditingTip,
    isEditing,
    initFormValue,
    onEditStatusChange,
    onSubmit,
}: IProps) => {
    const [treatmentPhilosophyForm] = Form.useForm<IMyPhilosophy>();

    const { treatmentApproach } = initFormValue;

    useEffect(() => {
        if (initFormValue) {
            treatmentPhilosophyForm.setFieldsValue(initFormValue);
        }
    }, [initFormValue, treatmentPhilosophyForm]);

    const handleSubmit = useCallback(() => {
        treatmentPhilosophyForm.validateFields().then((values) => {
            onSubmit(values, EditModuleEnum.MYPHILOSOPHY);
        }).catch((e) => {
            console.error(e);
        });
    }, [treatmentPhilosophyForm, onSubmit]);

    const renderReviewTreatmentApproach = useMemo(() => {
        return treatmentApproach?.split('•').filter((e) => !!e).map((text, index) => <li key={index}>{text}</li>);
    }, [treatmentApproach]);

    return (
        <div className={s.wrap} id={EditModuleEnum.MYPHILOSOPHY}>
            {/* <a></a> */}
            <div className={s.header}>
                <div className={s.title}>Treatment methodology</div>
                {
                    !readonly &&
                    <div className={s.btns}>
                        {
                            isEditing ?
                                <>
                                    <Button onClick={() => onEditStatusChange(false, EditModuleEnum.MYPHILOSOPHY)}>Cancel</Button>
                                    <Button type="primary" onClick={handleSubmit}>Save changes</Button>
                                </>
                                : <Button onClick={() => onEditStatusChange(true, EditModuleEnum.MYPHILOSOPHY)}>Edit</Button>
                        }
                    </div>
                }
            </div>
            {
                showEditingTip && isEditing && <ErrorBlock />
            }
            <div className={s.body}>
                {
                    !isEditing ?
                        (
                            <div className={s.viewWrap}>
                                <div className={s.viewItem}>
                                    <div className={s.label}>Treatment philosophy</div>
                                    { initFormValue?.treatmentPhilosophy ? <div className={s.value} dangerouslySetInnerHTML={{ __html: initFormValue?.treatmentPhilosophy }} /> : 'Nothing here yet' }
                                </div>
                                <div className={s.viewItem}>
                                    <div className={s.label}>Treatment approach</div>
                                    <div className={s.value}>
                                        { initFormValue?.treatmentApproach ? renderReviewTreatmentApproach : 'Nothing here yet'}
                                    </div>
                                </div>
                            </div>
                        )
                        :
                        (
                            <div className={s.formWrap}>
                                <Form
                                    form={treatmentPhilosophyForm}
                                    name={EditModuleEnum.MYPHILOSOPHY}
                                    className={commonS.formStyle1}
                                    autoComplete="off"
                                    layout="vertical"
                                >
                                    <div className={s.itemBox}>
                                        <div className={s.item}>
                                            <Form.Item
                                                label="Treatment philosophy"
                                                name={FormFields.TREATMENT_PHILOSOPHY}
                                                rules={[
                                                    {
                                                        validator: (rule, value) => {
                                                            if (removeHtmlTag(value).length > TreatmentPhilosophyTextLength) {
                                                                return Promise.reject(`Maximum ${TreatmentPhilosophyTextLength} characters`);
                                                            } else {
                                                                return Promise.resolve();
                                                            }
                                                        },
                                                    },
                                                ]}
                                            >
                                                <RichText className={s.textarea} maxLength={TreatmentPhilosophyTextLength} placeholder="Ex. How do you treat patients? What values to you the most as a provider? What do you believe in?" />
                                            </Form.Item>
                                            <Form.Item
                                                label="Treatment approach"
                                                name={FormFields.TREATMENT_APPROACH}
                                            >
                                                <TreatmentApproach />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        )
                }
            </div>
        </div>
    );
};

export default MyPhilosophy;
