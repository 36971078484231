// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--PbWtC {
  padding: 40px;
  border-radius: 16px;
  background: #fff;
}
.s-module__wrap--PbWtC .s-module__title--PfH5V {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  cursor: pointer;
}
.s-module__wrap--PbWtC .s-module__icon--ZCafW {
  transition: all linear 200ms;
}
.s-module__wrap--PbWtC .s-module__title--PfH5V:hover .s-module__icon--ZCafW {
  opacity: 0.3;
}
.s-module__wrap--PbWtC .s-module__content--hQtEe {
  transition: all linear 200ms;
  overflow: hidden;
  padding-top: 0;
  opacity: 0;
}
.s-module__wrap--PbWtC .s-module__content--hQtEe p {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 16px;
}
.s-module__wrapShow--D5Zr9 .s-module__content--hQtEe {
  padding-top: 56px;
  opacity: 1;
}
.s-module__wrapShow--D5Zr9 .s-module__icon--ZCafW {
  transform: rotate(180deg);
}
`, "",{"version":3,"sources":["webpack://./src/components/SliderPanel/s.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,gBAAA;AACJ;AAJA;EAMQ,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,eAAA;AACR;AAdA;EAiBQ,4BAAA;AAAR;AAjBA;EAqBQ,YAAA;AADR;AApBA;EAyBQ,4BAAA;EACA,gBAAA;EACA,cAAA;EACA,UAAA;AAFR;AA1BA;EA8BY,eAAA;EACA,iBAAA;EACA,mBAAA;AADZ;AAMA;EAEQ,iBAAA;EACA,UAAA;AALR;AAEA;EAMQ,yBAAA;AALR","sourcesContent":[".wrap {\n    padding: 40px;\n    border-radius: 16px;\n    background: #fff;\n\n    .title {\n        display: flex;\n        flex-direction: row;\n        align-items: center;\n        justify-content: space-between;\n        font-size: 20px;\n        line-height: 28px;\n        font-weight: bold;\n        cursor: pointer;\n    }\n\n    .icon {\n        transition: all linear 200ms;\n    }\n\n    .title:hover .icon {\n        opacity: 0.3;\n    }\n\n    .content {\n        transition: all linear 200ms;\n        overflow: hidden;\n        padding-top: 0;\n        opacity: 0;\n        p {\n            font-size: 16px;\n            line-height: 28px;\n            margin-bottom: 16px;\n        }\n    }\n}\n\n.wrapShow{\n    .content {\n        padding-top: 56px;\n        opacity: 1;\n    }\n    .icon {\n        transform: rotate(180deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--PbWtC`,
	"title": `s-module__title--PfH5V`,
	"icon": `s-module__icon--ZCafW`,
	"content": `s-module__content--hQtEe`,
	"wrapShow": `s-module__wrapShow--D5Zr9`
};
export default ___CSS_LOADER_EXPORT___;
