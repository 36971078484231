import React from 'react';
import { Button, Spin, Upload } from 'antd';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import s from './s.module.less';
import Papa from 'papaparse';
// import useMyListedChannels from 'hooks/useMyListedChannels';
import type { UploadChangeParam } from 'antd/es/upload';
import { Prospect } from 'types/common';
import useChannel from 'hooks/useChannel';
import UploadIcon from './upload.svg';

const { Dragger } = Upload;

type Props = {
    onUpdate: (data?: Partial<Prospect>[]) => void;
};

type ECSVType = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'first name': string,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'last name': string,
    'phone': string,
    'email': string,
    'channel': string
};

const SecondStep = (props:Props) => {
    const { onUpdate } = props;
    const [showUploadList, setShowUploadList] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const fileInfoRef = React.useRef<UploadChangeParam<UploadFile>>();
    const [fileList, setFileList] = React.useState<UploadFile<any>[]>([]);
    const [fileName, setFileName] = React.useState<string>('');
    const [channels, loadingChannel] = useChannel();

    const parseCsvFile = (file?: File) => {
        if (!file) {
            return;
        }
        Papa.parse(file, {
            complete: (result:any) => {
                // result.data 包含解析后的 CSV 数据
                const pendingData:ECSVType[] = result.data;
                // {
                //     "first name": "xiaocong",
                //     "last name": "lou",
                //     "phone": "98073491",
                //     "email": "evanliu.it@gmail.com",
                //     "channel": "others"
                //   },
                const prospectList:Partial<Prospect>[] = pendingData?.map((item:ECSVType) => {
                    const channelItem = channels?.find((channel) => channel.name?.toLowerCase() === item.channel?.toLowerCase());
                    const ret:Partial<Prospect> = {
                        firstName: item['first name'],
                        lastName: item['last name'],
                        tel: item.phone,
                        email: item.email,
                        channel: item.channel,
                    };
                    if (channelItem) {
                        ret.channelId = channelItem.id;
                    } else {
                        ret.channel = 'Others';
                        ret.channelId = -1;
                    }
                    return ret;
                });
                onUpdate?.(prospectList);
            },
            header: true, // 如果 CSV 文件包含表头，则设置为 true
        });
    };

    const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
        fileInfoRef.current = info;
        let fList = [...info.fileList];

        // 限制文件数量
        fList = fList.slice(-1);
        // 读取文件并解析
        if (fList.length > 0) {
            const file = fList[0].originFileObj;
            setFileName(file?.name || '');
            parseCsvFile(file);
        }

        setFileList(fList);
    };

    const uploadProps: UploadProps = {
        name: 'file',
        multiple: false,
        onChange: handleChange,
        accept: '.csv, application/vnd.ms-excel, text/csv',
        showUploadList,
        fileList,
        onDrop() {
            //console.log('Dropped files', e.dataTransfer.files);
        },
        customRequest: async (data) => {
            //https://github.com/react-component/upload#customrequest
            // try {
            //     const formData = new FormData();
            //     formData.append('file', data.file);
            //     const res:any = {};
            //     if (!res.error) {
            //         if (fileInfoRef.current) {
            //             fileInfoRef.current.file.status = 'done';
            //         }
            //         setShowUploadList(true);
            //         const url = res.data.data;
            //         const isPdf = fileInfoRef.current?.file?.name?.toLowerCase().endsWith('pdf');
            //     }
            // } catch (e) {
            //     setShowUploadList(false);
            //     console.error(e);
            // } finally {
            //     setLoading(false);
            // }
        },
    };

    return (
        <div className={s.wrap}>
            <h3>Upload the patient.csv file </h3>
            <div className={s.uploadWrap}>
                {!fileName &&
                <Dragger {...uploadProps}>
                    <Spin spinning={loading}>
                        <p className={s.iconWrap}>
                            <img src={UploadIcon} className={s.icon} />
                        </p>
                        <p className={s.uploadText}>
                            <span>Upload a file</span> or drag and drop
                        </p>
                        <p className={s.uploadHint}>
                            .csv up to 10MB
                        </p>
                    </Spin>
                </Dragger>
                }
                { fileName &&
                <div className={s.preview}>
                    <div className={s.successInfo}>Upload successful!</div>
                    <div className={s.detail}>
                        <div className={s.detailInner}>
                            <div className={s.name}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M9 12H15M9 16H15M17 21H7C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H12.5858C12.851 3 13.1054 3.10536 13.2929 3.29289L18.7071 8.70711C18.8946 8.89464 19 9.149 19 9.41421V19C19 20.1046 18.1046 21 17 21Z" stroke="#111827" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span>
                                    {fileName}
                                </span>
                            </div>
                            <Button
                                onClick={() => {
                                    setFileList([]);
                                    setFileName('');
                                    onUpdate?.(undefined);
                                }}
                                danger
                            >Remove
                            </Button>
                        </div>
                    </div>

                </div>}
            </div>
        </div>
    );
};

export default SecondStep;
