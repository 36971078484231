import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Breadcrumb, Button, message, Modal } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import s from './s.module.less';
import useListToChannel from 'hooks/useListToChannel';
import { useRequest } from 'ahooks';
import { getProviderFormDetail } from 'api/applicationForm';
import ProviderStore from 'store/Provider';
import SingleServiceTypeChannelModal from 'components/SingleServiceTypeChannelModal';
import { NoticeType } from 'antd/es/message/interface';
import { useNavigate } from 'react-router-dom';
import { getChannelDetailById } from 'api/channel';
import { SINGLE_TYPE_SINGLE_SERVICE } from 'constants/common';

interface IProps {
    channelId: number;
    logo: string;
    name: string;
}

const PreviewHeader = ({
    channelId,
    logo,
    name,
}: IProps) => {
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    // 如果某个channel不允许重复，但是目前还没有重复service的时候。
    const [submitWithoutSingleServiceSelect, setSubmitWithoutSingleServiceSelect] = useState(false);
    const [open, setOpen] = useState(false);
    const [getUser] = ProviderStore.useStore();
    const user = getUser('data');
    const pramas = useRef({
        allowAuth: false,
        channelId,
        providerId: user?.id,
        step: 'REVIEW',
    });
    const { data: providerData } = useRequest(getProviderFormDetail, {
        defaultParams: [pramas.current],
    });

    const { data: ChannelData } = useRequest(getChannelDetailById, {
        defaultParams: [channelId.toString()],
    });

    const providerServiceList = useMemo(() => providerData?.data?.data?.providerServiceList, [providerData]);

    const onShowMessage = useCallback((type: NoticeType, content: string) => {
        messageApi.open({
            type,
            content,
        });
    }, [messageApi]);

    const onShowSelectionModal = useCallback((status: boolean) => {
        setOpen(status);
    }, []);

    const handleServiceListFeedback = useCallback((status: boolean) => {
        setSubmitWithoutSingleServiceSelect(status);
    }, []);

    const {
        handleChannelConfirmSubmit,
        handleModalOk,
        singleServiceTypeIds,
        handleSingleServiceTypeSelected,
    } = useListToChannel({
        services: providerServiceList,
        channelId: Number(channelId),
        onShowMessage,
        onShowSelectionModal,
        submitWithoutSingleServiceSelect,
        IgnoreMultipleServiceSelect: ChannelData?.data?.data?.serviceConfig !== SINGLE_TYPE_SINGLE_SERVICE,
    });

    const handleListToChannel = useCallback(() => {
        handleChannelConfirmSubmit?.();
    }, [handleChannelConfirmSubmit]);

    const handleCloseModal = useCallback(() => {
        setOpen(false);
        handleSingleServiceTypeSelected([]);
    }, [handleSingleServiceTypeSelected]);

    const handleHistoryBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    return (
        <div className={s.header}>
            {contextHolder}
            <Modal
                centered
                open={open}
                onOk={handleModalOk}
                onCancel={handleCloseModal}
                width={1000}
            >
                <SingleServiceTypeChannelModal
                    logo={logo}
                    name={name}
                    checkedIds={singleServiceTypeIds}
                    onServiceListFeedback={handleServiceListFeedback}
                    onChange={handleSingleServiceTypeSelected}
                />
            </Modal>
            <div>
                <Breadcrumb
                    separator=">"
                    className={s.breadcrumb}
                >
                    <Breadcrumb.Item><HomeOutlined /></Breadcrumb.Item>
                    <Breadcrumb.Item>{name}</Breadcrumb.Item>
                    <Breadcrumb.Item>{name} profile preview</Breadcrumb.Item>
                </Breadcrumb>
                <div className={s.title}>{name} profile preview</div>
            </div>
            <div className={s.buttons}>
                <Button type="primary" ghost onClick={handleHistoryBack}>Back</Button>
                <Button type="primary" onClick={handleListToChannel}>List me on this channel</Button>
            </div>
        </div>
    );
};

export default PreviewHeader;
