// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__tipsWrap--cXXvN .s-module__googleBtn--d7xrv {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.s-module__tipsWrap--cXXvN .s-module__googleBtn--d7xrv span {
  margin-left: 8px;
}
.s-module__tipsWrap--cXXvN h3 {
  color: var(--gray-800, #1F2937);
  font-size: 16px;
  font-weight: 700;
  line-height: 32px;
  margin-top: 24px;
}
.s-module__tipsWrap--cXXvN .s-module__info--vkugG {
  color: var(--gray-600, #4B5563);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
}
.s-module__tipsWrap--cXXvN .s-module__imgWrap--Xeq_p img {
  width: 580px;
}
.s-module__tipsWrap--cXXvN .s-module__contact--uaJPP {
  color: var(--gray-500);
  font-weight: 500;
  font-size: 14px;
  margin-top: 36px;
  margin-bottom: 24px;
}
.s-module__tipsWrap--cXXvN .s-module__contact--uaJPP a {
  color: var(--primary-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/UploadPatientListModal/components/FirstStep/s.module.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,mBAAA;EACA,mBAAA;AAAJ;AAJA;EAOM,gBAAA;AAAN;AAPA;EAYI,+BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAFJ;AAdA;EAoBI,+BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AAHJ;AArBA;EA6BM,YAAA;AALN;AAxBA;EAkCI,sBAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AAPJ;AA/BA;EAyCM,2BAAA;AAPN","sourcesContent":[".tipsWrap {\n  .googleBtn {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n\n    span {\n      margin-left: 8px;\n    }\n  }\n\n  h3 {\n    color: var(--gray-800, #1F2937);\n    font-size: 16px;\n    font-weight: 700;\n    line-height: 32px;\n    margin-top: 24px;\n  }\n\n  .info {\n    color: var(--gray-600, #4B5563);\n    font-size: 14px;\n    font-weight: 500;\n    line-height: 20px;\n    margin-bottom: 8px;\n  }\n\n  .imgWrap {\n    img {\n      width: 580px;\n    }\n  }\n\n  .contact {\n    color: var(--gray-500);\n    font-weight: 500;\n    font-size: 14px;\n    margin-top: 36px;\n    margin-bottom: 24px;\n\n    a {\n      color: var(--primary-color);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tipsWrap": `s-module__tipsWrap--cXXvN`,
	"googleBtn": `s-module__googleBtn--d7xrv`,
	"info": `s-module__info--vkugG`,
	"imgWrap": `s-module__imgWrap--Xeq_p`,
	"contact": `s-module__contact--uaJPP`
};
export default ___CSS_LOADER_EXPORT___;
