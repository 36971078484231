import React, { useCallback, useMemo } from 'react';
import { Spin, Upload } from 'antd';
import s from './s.module.less';
import type { UploadChangeParam } from 'antd/es/upload';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import { uploadFile } from 'api/common';

interface IProps {
    url?: string;
    cls?: string;
    isError?: boolean;
    errorMessage?: string;
    accept?:string;
    index: number;
    onChange?: (url: string, index: number) => void;
    onRemove: (index: number) => void;
}

const PhotoUploader = ({
    url = '',
    // url = 'https://i03piccdn.sogoucdn.com/ca9fcbf66bca4e6b',
    // url = 'https://img95.699pic.com/photo/40214/1933.jpg_wh300.jpg',
    // url = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2d9hpdwkn-gVsHlBWwSRFs6VnZUES1XdxcQ&usqp=CAU',
    cls = '',
    isError,
    errorMessage = '',
    accept = 'image/*',
    index,
    onChange,
    onRemove,
}: IProps) => {
    const fileInfoRef = React.useRef<UploadChangeParam<UploadFile>>();
    const [loading, setLoading] = React.useState(false);

    const handleChange: UploadProps['onChange'] = useCallback((info: UploadChangeParam<UploadFile>) => {
        fileInfoRef.current = info;
        if (info.file.status === 'uploading') {
            setLoading(true);
        }
    }, []);

    const handleRemove = useCallback(() => {
        if (typeof onRemove === 'function') {
            onRemove(index);
        }
    }, [onRemove, index]);

    const props: UploadProps = useMemo(() => (
        {
            name: 'file',
            multiple: false,
            onChange: handleChange,
            accept,
            customRequest: async (data) => {
                try {
                    const formData = new FormData();
                    formData.append('file', data.file);
                    const res = await uploadFile(formData);
                    if (!res.error) {
                        if (fileInfoRef.current) {
                            fileInfoRef.current.file.status = 'done';
                        }

                        if (typeof onChange === 'function') {
                            onChange(res.data?.data, index);
                        }
                    }
                } catch (e) {
                    console.error(e);
                } finally {
                    setLoading(false);
                }
            },
        }
    ), [accept, handleChange, onChange, index]);
    return (
        <div className={`${s.wrapper} ${cls} ${isError ? s.error : ''}`}>
            {
                url ? (
                    <div className={s.box}>
                        <img src={url} />
                        <div className={s.delete} onClick={handleRemove} />
                    </div>
                ) : (
                    <div className={s.emptyBox}>
                        <Spin spinning={loading}>
                            <Upload {...props}>
                                <div className={s.defaultImage} />
                                {
                                    isError && errorMessage && <p className={s.errorText}>{errorMessage}</p> }
                                <p><span>Upload a file</span> or drag and drop</p>
                                <p>PNG, JPG, GIF up to 10MB</p>
                            </Upload>
                        </Spin>
                    </div>
                )
            }
        </div>
    );
};

export default PhotoUploader;
