// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--hFx71 {
  padding: 30px 24px;
  border: 1px solid var(--gray);
  border-radius: 10px;
  font-family: 'Inter';
  font-style: normal;
}
.s-module__wrap--hFx71 .s-module__header--iSMX1 {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.s-module__wrap--hFx71 .s-module__header--iSMX1 .s-module__title--iWOuk {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: var(--gray-700);
}
`, "",{"version":3,"sources":["webpack://./src/components/PracticeFrontFromComponents/PracticeAddress/s.module.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,6BAAA;EACA,mBAAA;EACA,oBAAA;EACA,kBAAA;AACJ;AANA;EAQQ,mBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AACR;AAZA;EAcY,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AACZ","sourcesContent":[".wrap {\n    padding: 30px 24px;\n    border: 1px solid var(--gray);\n    border-radius: 10px;\n    font-family: 'Inter';\n    font-style: normal;\n\n    .header {\n        margin-bottom: 16px;\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n\n        .title {\n            font-weight: 500;\n            font-size: 20px;\n            line-height: 20px;\n            color: var(--gray-700);\n        }\n    \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--hFx71`,
	"header": `s-module__header--iSMX1`,
	"title": `s-module__title--iWOuk`
};
export default ___CSS_LOADER_EXPORT___;
