// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--Yodar {
  border-radius: 6px;
  background: var(--blue-50, #EFF6FF);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: var(--blue-800, #1E40AF);
  /* text-sm/leading-5/font-medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 16px;
}
.s-module__wrap--Yodar .s-module__icon--LtTuY {
  margin-right: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/InfoNote/s.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mCAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,+BAAA;EACA,kCAAkC;EAClC,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;AACF;AAdA;EAgBI,kBAAA;AACJ","sourcesContent":[".wrap {\n  border-radius: 6px;\n  background: var(--blue-50, #EFF6FF);\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  color: var(--blue-800, #1E40AF);\n  /* text-sm/leading-5/font-medium */\n  font-family: Inter;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 20px;\n  padding: 16px;\n\n  .icon {\n    margin-right: 12px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--Yodar`,
	"icon": `s-module__icon--LtTuY`
};
export default ___CSS_LOADER_EXPORT___;
