// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--AkdLs {
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 82px;
  background-color: var(--white);
}
.s-module__wrap--AkdLs .s-module__bar--arA3U {
  margin-right: 8px;
  flex: 1;
  height: 4px;
  background-color: var(--gray-300);
  border-radius: 10px;
}
.s-module__wrap--AkdLs .s-module__bar--arA3U:last-child {
  margin-right: 0;
}
.s-module__wrap--AkdLs .s-module__bar--arA3U.s-module__actived--JSEy4 {
  background-color: var(--main-green);
}
@media only screen and (max-width: 600px) {
  .s-module__wrap--AkdLs {
    bottom: auto;
    top: 60px;
    padding: 0 16px;
  }
  .s-module__wrap--AkdLs .s-module__bar--arA3U {
    height: 8px;
    border-radius: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ProgressBar/s.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,eAAA;EACA,WAAA;EACA,OAAA;EACA,YAAA;EACA,8BAAA;AACJ;AARA;EAUQ,iBAAA;EACA,OAAA;EACA,WAAA;EACA,iCAAA;EACA,mBAAA;AACR;AACQ;EACI,eAAA;AACZ;AAEQ;EACI,mCAAA;AAAZ;AAKA;EACI;IACI,YAAA;IACA,SAAA;IACA,eAAA;EAHN;EAAE;IAMQ,WAAA;IACA,gBAAA;EAHV;AACF","sourcesContent":[".wrap {\n    display: flex;\n    align-items: center;\n    position: fixed;\n    width: 100%;\n    left: 0;\n    bottom: 82px;\n    background-color: var(--white);\n\n    .bar {\n        margin-right: 8px;\n        flex: 1;\n        height: 4px;\n        background-color: var(--gray-300);\n        border-radius: 10px;\n\n        &:last-child {\n            margin-right: 0;\n        }\n\n        &.actived {\n            background-color: var(--main-green);\n        }\n    }\n}\n\n@media only screen and (max-width: 600px) {\n    .wrap {\n        bottom: auto;\n        top: 60px;\n        padding: 0 16px;\n\n        .bar {\n            height: 8px;\n            border-radius: 0;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--AkdLs`,
	"bar": `s-module__bar--arA3U`,
	"actived": `s-module__actived--JSEy4`
};
export default ___CSS_LOADER_EXPORT___;
