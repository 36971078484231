export const type = {
    basic: 'Basic',
    essential: 'Essential',
    plus: 'Plus',
};
export const CCCTypeList = [
    {
        type: type.basic,
        skuCode: 'ccc-basic',
    },
    {
        type: type.essential,
        skuCode: 'ccc-essential',
    },
    {
        type: type.plus,
        skuCode: 'ccc-plus',
    },
];
