import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { Meta } from 'types/common';
import { DURATION_LIST } from 'constants/common';

type DurationFormSelectProps = {
    value?: string;
    onChange?: (val: string) => void,
} & SelectProps;

const DurationSelect = (props:DurationFormSelectProps) => {
    const { onChange, ...reset } = props;

    return (
        <Select {...reset} onChange={onChange} showSearch>
            {
                DURATION_LIST.map((option:Meta) => {
                    return (
                        <Select.Option
                            key={option.value}
                            value={option.value}
                        >
                            {option.label} mins
                        </Select.Option>
                    );
                })
            }
        </Select>
    );
};

export default DurationSelect;
