import React, { useCallback, useMemo } from 'react';
import s from './s.module.less';
import { Button, Popover } from 'antd';
import SubscriptionButton from 'components/SubscriptionButton';
import { GROUP_PRICE_CONTACT_LINK } from 'constants/subscription';
import { TFunStatus, TSubscriptionItem } from 'types/subscription';
import { TSubscriptionType } from 'types/subscription';
import Check from './Check.svg';
import Question from './Question.svg';
import NotSupportIcon from 'assets/common/not_support_icon.svg';
import useSubscriptionPlan from 'hooks/useSubscriptionPlan';
import ProviderChannelsStore from 'store/ProviderChannles';
import { Channel } from 'types/common';
import SubscriptionStore from 'store/Subscription';
import { Email } from 'constants/contact';

const HIGH_LIGHT_SUB_TYPE = TSubscriptionType.KLARITY_PLUS;

interface IProps {
    selectedPanId?: number;
    onPlanSelect?: (plan: TSubscriptionItem, planId: number) => void;
}

const KlaritySubscription = ({
    selectedPanId,
    onPlanSelect,
}: IProps) => {
    const [getSubscription] = SubscriptionStore.useStore();
    const [tabInx, setTabInx] = React.useState(2);
    const [plansData, loadingSubscriptionPlan, refresh] = useSubscriptionPlan();
    const subscriptionPlan = plansData.klarityPlan;
    const currentPlan = subscriptionPlan && subscriptionPlan.find((item) => !!item.isCurrent);
    const [getMyChannels] = ProviderChannelsStore.useStore();
    const myChannels = getMyChannels('data');

    const myPlan = getSubscription('currentPlan');

    let tabInxClass = s.tabBg1;
    if (tabInx === 2) {
        tabInxClass = s.tabBg2;
    }
    if (tabInx === 3) {
        tabInxClass = s.tabBg3;
    }
    const funList: TFunStatus[] = useMemo(() => {
        let freeChannle = ['WebMD Standard', 'NextDoor'];
        if (myChannels?.freeList && myChannels?.freeList.length > 0) {
            freeChannle = myChannels.freeList.splice(0, 2).map((e: Channel) => e.name);
        }

        const channel1 = freeChannle[0];
        const channel2 = freeChannle[1] ? `and ${freeChannle[1]}` : '';

        const funs = subscriptionPlan[0]?.funList || [];
        return funs.map((f: TFunStatus, i: number) => {
            if (i === 0) {
                return {
                    ...f,
                    des: `${freeChannle.length} basic channels are included in the Free Plan, including ${channel1} ${channel2}`,
                };
            }
            return f;
        });
    }, [myChannels, subscriptionPlan]);

    const supportIconRender = useCallback((isSupport?: boolean, supportText?: string) => {
        if (!supportText) {
            if (isSupport) {
                return <img src={Check} />;
            }
            return <img src={NotSupportIcon} />;
        }
    }, []);

    const handleContactOPS = useCallback(() => {
        const link = `mailto:${Email.support}`;
        window.open(link, '_self');
    }, []);

    return (
        <div className={s.wrap}>
            <h2>Select the ideal plan tailored to your practice</h2>
            <div className={s.tabOut}>
                <div className={s.tabWrap}>
                    <div className={`${s.tabBg} ${tabInxClass}`} />
                    <div
                        onClick={() => {
                            setTabInx(1);
                        }}
                        className={tabInx === 1 ? `${s.tabItem} ${s.tabItemActive}` : s.tabItem}
                    >
                        Monthly billing
                    </div>
                    <div
                        onClick={() => {
                            setTabInx(2);
                        }}
                        className={tabInx === 2 ? `${s.tabItem} ${s.tabItemActive}` : s.tabItem}
                    >
                        Quarterly billing <span className={s.high}>(Save 15%)</span>
                    </div>
                    <div
                        onClick={() => {
                            setTabInx(3);
                        }}
                        className={tabInx === 3 ? `${s.tabItem} ${s.tabItemActive}` : s.tabItem}
                    >Yearly <span className={s.high}>(Save 20%)</span>
                    </div>
                </div>
            </div>
            <div className={s.content}>
                <div className={s.contentInner}>
                    <div className={s.planWrap}>
                        <div className={s.planTitleWrap}>
                            <div className={s.funTitle} />
                            {
                                subscriptionPlan.map((item) => {
                                    const originalMonthlyPrice = item.originalMonthlyPrice || 0;
                                    const priceDesc = item.priceDesc || '';
                                    let price: number | string = item.monthlyPrice;
                                    let planId = item.monthlyPlanId;
                                    let interval = 'monthly';
                                    let totalPrice = {
                                        Plus: 85,
                                        Pro: 230,
                                    };
                                    if (tabInx === 2) {
                                        price = item.quarterlyPrice;
                                        planId = item.quarterlyPlanId;
                                        interval = 'quarterly';
                                        totalPrice = {
                                            Plus: 215,
                                            Pro: 700,
                                        };
                                    }
                                    if (tabInx === 3) {
                                        price = item.yearlyPrice;
                                        planId = item.yearlyPlanId;
                                        interval = 'yearly';
                                        totalPrice = {
                                            Plus: 585,
                                            Pro: 1920,
                                        };
                                    }
                                    let className = s.planTitle;
                                    if (item.type === HIGH_LIGHT_SUB_TYPE) {
                                        className = `${s.planTitle} ${s.planTitleHighLight}`;
                                    }
                                    if (loadingSubscriptionPlan) {
                                        price = '';
                                    }
                                    let { isCurrent } = item;

                                    if (myPlan?.status === 'paid' && item.type === myPlan?.type) {
                                        isCurrent = true;
                                    }

                                    // starter的时候plus和pro都是直接购买的，
                                    // 否则plus的时候，一个downgrad一个upgrade，都需要联系OPS
                                    // pro的时候，2个downgrade，都需要联系OPS
                                    if (myPlan && myPlan.type && myPlan.type !== TSubscriptionType.KLARITY_STARTER) {
                                        if (item.type !== myPlan?.type) {
                                            item.contactOnly = true;
                                        }
                                    }

                                    let text = 'Upgrade';
                                    if (currentPlan) {
                                        if (currentPlan.monthlyPrice > item.monthlyPrice) {
                                            text = 'Downgrade';
                                        }
                                    }
                                    return (
                                        <div className={className} key={`${item.name}`}>
                                            <div className={s.name}>
                                                {item.name} <span className={s.highlight}>{item.highlight}</span>
                                            </div>
                                            <div className={s.des}>{item.des}</div>
                                            <div className={s.price}>
                                                {
                                                    !item.contactOnly &&
                                                    <>
                                                        <div className={s.priceBox}>
                                                            {
                                                                originalMonthlyPrice !== 0 && originalMonthlyPrice !== price &&
                                                                <span className={s.originalPrice}>
                                                                    {`$${originalMonthlyPrice}`}
                                                                </span>
                                                            }
                                                            {
                                                                price === 0 ?
                                                                    <span className={s.priceVal}>
                                                                        Free
                                                                    </span> :
                                                                    <span className={s.priceVal}>
                                                                        ${price}{price !== 0 && <span className={s.label}>/mo</span>}
                                                                    </span>
                                                            }
                                                        </div>
                                                        {
                                                            tabInx !== 1 || priceDesc ? <span className={s.priceDesc}>{priceDesc || `$${totalPrice[item.type]} billed ${interval}`}</span> : <div className={s.placeholder} />
                                                        }
                                                    </>
                                                }
                                                {
                                                    item.contactOnly &&
                                                    <span>
                                                        Let&apos;s talk
                                                    </span>
                                                }
                                            </div>
                                            <div className={s.priceTips}>
                                                {item.priceTips || ''}
                                            </div>
                                            <div className={s.btnWrap}>
                                                {
                                                    isCurrent &&
                                                    <Button disabled block>
                                                        Current
                                                    </Button>
                                                }
                                                {
                                                    !item.contactOnly && !isCurrent &&
                                                    <SubscriptionButton
                                                        selectedPanId={selectedPanId}
                                                        planId={planId}
                                                        loading={!!loadingSubscriptionPlan}
                                                        type="primary"
                                                        block
                                                        disabled={false}
                                                        text={text}
                                                        plan={item}
                                                        onPlanSelect={onPlanSelect}
                                                    />
                                                }
                                                {
                                                    !!item.contactOnly && !isCurrent &&
                                                    <Button
                                                        onClick={handleContactOPS}
                                                        loading={!!loadingSubscriptionPlan}
                                                        type="primary"
                                                        block
                                                    >Contact us
                                                    </Button>
                                                }
                                            </div>
                                        </div>);
                                })
                            }
                        </div>
                        {
                            funList.map((fun: TFunStatus) => {
                                return (
                                    <div className={s.planContentWrap} key={fun.name}>
                                        <div className={s.funContent}>
                                            {fun.name}
                                            <Popover placement="topLeft" content={<div className={s.funContentPop}>{fun.des}</div>} title={null}>
                                                <img src={Question} />
                                            </Popover>
                                        </div>
                                        {
                                            subscriptionPlan.map((item) => {
                                                const target = item.funList?.find((i: TFunStatus) => i.name === fun.name);
                                                const isSupport = target?.support;
                                                const supportText = target?.supportText;
                                                let className = s.planContent;
                                                if (item.type === HIGH_LIGHT_SUB_TYPE) {
                                                    className = `${s.planContent} ${s.planContentHighLight}`;
                                                }
                                                return (
                                                    <div className={className} key={`${item.name}`}>
                                                        {supportIconRender(isSupport, supportText)}
                                                        {
                                                            typeof supportText === 'string' ?
                                                                <span>{supportText}</span> :
                                                                <ul>
                                                                    {
                                                                        supportText?.map((text, index) => <li key={index}><span>{text}</span></li>)
                                                                    }
                                                                </ul>
                                                        }
                                                    </div>);
                                            })
                                        }
                                    </div>
                                );
                            })
                        }

                    </div>
                </div>
            </div>
        </div>
    );
};

export default KlaritySubscription;
