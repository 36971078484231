import React, { useCallback, useEffect, useRef, useState } from 'react';
import s from './s.module.less';
import { Button, Checkbox, DatePicker, Form, Input, Select } from 'antd';
import { EditModuleEnum, FormFields, IWorkExperience } from 'types/practiceFront';
import dayjs from 'utils/dayjs';
import commonS from 'styles/common.module.less';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields } from 'types/enumerationData';
import PhotoList from 'components/PhotoList';

interface IProps {
    readonly?: boolean;
    id?: number,
    index: number,
    data: IWorkExperience,
    isEditting: boolean;
    onEditStatusChange: (status: boolean, index: number) => void;
    onSubmit: (value: IWorkExperience, id?: number) => void;
    onRemove: (index: number, id?: number) => void;
}

const ExperienceItem = ({
    readonly,
    id,
    index,
    data,
    isEditting,
    onEditStatusChange,
    onSubmit,
    onRemove,
}: IProps) => {
    const [showViewAllButton, setShowViewAllButton] = useState(false);
    const ellipsisContentRef = useRef<HTMLDivElement>(null);
    const normalContentRef = useRef<HTMLDivElement>(null);
    const [employmentTypeOptions] = useGetSettingData(EnumFields.EMPLOYMENT_TYPE);
    const [, forceUpdate] = useState({});
    const [formInstance] = Form.useForm<IWorkExperience>();
    const formName = `${EditModuleEnum.WORKEXPERIENCE}_${index}`;

    useEffect(() => {
        if (data) {
            formInstance.setFieldsValue(data);
        }
    }, [data, formInstance]);

    const {
        currently,
        description,
        endDate,
        hospitalLocation,
        organizationName,
        startDate,
        title,
    } = data;

    useEffect(() => {
        if (readonly && ellipsisContentRef.current && normalContentRef.current) {
            const { clientHeight: ellipsisClientHeight } = ellipsisContentRef.current;
            const { clientHeight } = normalContentRef.current;

            setShowViewAllButton(ellipsisClientHeight < clientHeight);
        }
    }, [description, readonly]);

    const handleShowAllClick = useCallback(() => {
        if (ellipsisContentRef.current) {
            ellipsisContentRef.current.style.display = 'block';
            setShowViewAllButton(false);
        }
    }, []);

    const formatDate = useCallback((day: dayjs.Dayjs) => day?.format('YYYY-MM'), []);
    const handleSubmit = useCallback(() => {
        formInstance.validateFields().then((values) => {
            onSubmit(values, id);
        }).catch((e) => {
            console.error(e);
        });
    }, [formInstance, id, onSubmit]);

    const location = hospitalLocation && (
        <>
            <span>•</span>
            <span>{hospitalLocation}</span>
        </>
    );

    const currentWork = formInstance.getFieldValue(FormFields.IS_CURRENTLY_WORK);

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <div className={s.label}>Work experience {index + 1}</div>
                {
                    !readonly &&
                    <div className={s.btns}>
                        {
                            isEditting ?
                                <>
                                    <Button onClick={() => onEditStatusChange(false, index)}>Cancel</Button>
                                    <Button type="primary" onClick={handleSubmit}>Save changes</Button>
                                </>
                                :
                                <>
                                    <Button onClick={() => onRemove(index, id)}>Remove</Button>
                                    <Button onClick={() => onEditStatusChange(true, index)}>Edit</Button>
                                </>
                        }
                    </div>
                }
            </div>
            <div className={s.intro}>
                {
                    !isEditting ?
                        <>
                            <div className={s.name}>{organizationName}</div>
                            <div className={s.title}>{title}</div>
                            <div className={s.timeloc}>
                                <span>{!startDate ? '' : formatDate(startDate)} - {currently ? 'Present' : formatDate(endDate)}</span>
                                {
                                    location
                                }
                            </div>
                            {
                                readonly ?
                                    <div className={s.descBox}>

                                        <div ref={ellipsisContentRef} className={s.desc}>{description}</div>
                                        {
                                            showViewAllButton &&
                                            <div className={s.more} onClick={handleShowAllClick}>
                                                <a> View all </a>
                                            </div>
                                        }
                                        <div ref={normalContentRef} className={s.hiddenContent}>{description}</div>
                                    </div> :
                                    <div className={s.desc}>{description}</div>
                            }
                        </>
                        :
                        <Form
                            form={formInstance}
                            name={formName}
                            className={commonS.formStyle1}
                            autoComplete="off"
                            layout="vertical"
                        >
                            <div className={s.itemBox}>
                                <div className={s.item}>
                                    <Form.Item
                                        label="Organization name"
                                        name={FormFields.ORGANIZATION_NAME}
                                        validateFirst
                                        rules={[
                                            { required: true, message: 'organization name is a required field' },
                                        ]}
                                    >
                                        <Input type="text" placeholder="Ex. Hospital or clinic or practice name" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.itemBox}>
                                <div className={s.item}>
                                    <Form.Item
                                        label="Title"
                                        name={FormFields.TITLE}
                                        validateFirst
                                        rules={[
                                            { required: true, message: 'title is a required field' },
                                        ]}
                                    >
                                        <Input type="text" placeholder="Ex. Nurse practitioner" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.itemBox}>
                                <div className={s.item}>
                                    <Form.Item
                                        name={FormFields.IS_CURRENTLY_WORK}
                                        valuePropName="checked"
                                    >
                                        <Checkbox
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    formInstance.setFieldValue(FormFields.END_DATE, undefined);
                                                    formInstance.validateFields();
                                                }
                                                forceUpdate({});
                                            }}
                                        >
                                            I am currently working in this role
                                        </Checkbox>
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.itemBox}>
                                <div className={s.item}>
                                    <Form.Item
                                        label="Start Date"
                                        name={FormFields.START_DATE}
                                        rules={[
                                            { required: true, message: 'start date a required field' },
                                            {
                                                validator: (rules, value) => {
                                                    const endDateValue = formInstance.getFieldValue(FormFields.END_DATE);

                                                    if (value && !endDateValue && !currentWork) {
                                                        return Promise.reject(
                                                            new Error('End date is required'),
                                                        );
                                                    }

                                                    if (endDateValue && endDateValue.isBefore(value)) {
                                                        return Promise.reject(
                                                            new Error('start date cannot be later than the end date'),
                                                        );
                                                    }
                                                    return Promise.resolve(true);
                                                },
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            onChange={() => {
                                                formInstance.validateFields();
                                                forceUpdate({});
                                            }}
                                            allowClear
                                            className={s.datepicker}
                                            picker="month"
                                            disabledDate={(current) => {
                                                const endDateValue = formInstance.getFieldValue(FormFields.END_DATE);
                                                if (!endDateValue || currentWork) {
                                                    return false;
                                                }
                                                return current.valueOf() > endDateValue.valueOf();
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            {
                                !currentWork &&
                                <div className={s.itemBox}>
                                    <div className={s.item}>
                                        <Form.Item
                                            label="End Date"
                                            name={FormFields.END_DATE}
                                            rules={[
                                                {
                                                    validator: (rules, value) => {
                                                        const startDateValue = formInstance.getFieldValue(FormFields.START_DATE);

                                                        if (value && !startDateValue) {
                                                            return Promise.reject(
                                                                new Error('Start date is required'),
                                                            );
                                                        }

                                                        if (startDateValue && value && value.isBefore(startDateValue)) {
                                                            return Promise.reject(
                                                                new Error('start date cannot be later than the end date'),
                                                            );
                                                        }

                                                        return Promise.resolve(true);
                                                    },
                                                },
                                            ]}
                                        >
                                            <DatePicker
                                                onChange={() => {
                                                    formInstance.validateFields();
                                                    forceUpdate({});
                                                }}
                                                allowClear
                                                className={s.datepicker}
                                                picker="month"
                                                disabledDate={(current) => {
                                                    const startDateValue = formInstance.getFieldValue(FormFields.START_DATE);
                                                    if (!startDateValue) {
                                                        return false;
                                                    }
                                                    return current.valueOf() < startDateValue.valueOf();
                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            }
                            <div className={s.itemBox}>
                                <div className={s.item}>
                                    <Form.Item
                                        label="Employment type"
                                        name={FormFields.EMPLOYMENT_TYPE}
                                    >
                                        <Select fieldNames={{ label: 'content', value: 'dictKey' }} options={employmentTypeOptions} />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.itemBox}>
                                <div className={s.item}>
                                    <Form.Item
                                        label="Location"
                                        name={FormFields.HOSPITAL_LOCATION}
                                        validateFirst
                                        rules={[
                                            { required: true, message: 'Location is a required field' },
                                        ]}
                                    >
                                        <Input type="text" placeholder="Ex. City and state" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.itemBox}>
                                <div className={s.item}>
                                    <div className={s.formItemLabel}>Description</div>
                                    <div className={s.des}>Write a few sentences to describe what you have done and accomplished in this role</div>
                                    <Form.Item
                                        name={FormFields.DESCRIPTION}
                                    >
                                        <Input.TextArea className={s.textarea} showCount maxLength={1000} />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.itemBox}>
                                <div className={s.item}>
                                    <Form.Item
                                        label="Logo"
                                        name={FormFields.LOGO}
                                        valuePropName="data"
                                    >
                                        <PhotoList min={1} enable responseType="string" />
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                }
            </div>
        </div>
    );
};

export default ExperienceItem;
