// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--BZmRH h3 {
  color: var(--gray-800, #1F2937);
  font-size: 16px;
  font-weight: 700;
  line-height: 32px;
  margin-top: 24px;
}
.s-module__wrap--BZmRH .s-module__des--T3Eht {
  color: #4B5563;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 4px;
}
.s-module__wrap--BZmRH .s-module__des--T3Eht li {
  list-style-type: disc;
  list-style-position: inside;
}
.s-module__wrap--BZmRH .s-module__des--T3Eht .s-module__highlight--BKHGI {
  font-weight: bold;
  color: var(--primary-color);
  min-width: 12px;
  display: inline-block;
  text-align: center;
}
.s-module__wrap--BZmRH .s-module__selectItem--PlF26 {
  border-radius: 10px;
  border: 1px solid #DAD8D8;
  background: #F9FAFB;
  padding: 16px 24px;
  width: 80%;
  max-width: 850px;
  margin-bottom: 16px;
}
.s-module__wrap--BZmRH .s-module__selectItemIn--ClC87 {
  display: flex;
  flex-direction: column;
}
.s-module__wrap--BZmRH .s-module__forSelect--TpBvL {
  margin-top: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/components/UploadPatientListModal/components/ThirdStep/s.module.less"],"names":[],"mappings":"AAAA;EAEI,+BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAAJ;AANA;EAUI,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;AADJ;AAbA;EAiBM,qBAAA;EACA,2BAAA;AADN;AAjBA;EAuBM,iBAAA;EACA,2BAAA;EACA,eAAA;EACA,qBAAA;EACA,kBAAA;AAHN;AAxBA;EAgCI,mBAAA;EACA,yBAAA;EACA,mBAAA;EACA,kBAAA;EACA,UAAA;EACA,gBAAA;EACA,mBAAA;AALJ;AAjCA;EA0CI,aAAA;EACA,sBAAA;AANJ;AArCA;EA8CI,gBAAA;AANJ","sourcesContent":[".wrap {\n  h3 {\n    color: var(--gray-800, #1F2937);\n    font-size: 16px;\n    font-weight: 700;\n    line-height: 32px;\n    margin-top: 24px;\n  }\n\n  .des {\n    color: #4B5563;\n    font-size: 14px;\n    font-weight: 500;\n    line-height: 24px;\n    margin-top: 4px;\n\n    li {\n      list-style-type: disc;\n      list-style-position: inside;\n    }\n\n\n    .highlight {\n      font-weight: bold;\n      color: var(--primary-color);\n      min-width: 12px;\n      display: inline-block;\n      text-align: center;\n    }\n  }\n\n  .selectItem {\n    border-radius: 10px;\n    border: 1px solid #DAD8D8;\n    background: #F9FAFB;\n    padding: 16px 24px;\n    width: 80%;\n    max-width: 850px;\n    margin-bottom: 16px;\n  }\n\n  .selectItemIn {\n    display: flex;\n    flex-direction: column;\n  }\n  .forSelect{\n    margin-top: 24px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--BZmRH`,
	"des": `s-module__des--T3Eht`,
	"highlight": `s-module__highlight--BKHGI`,
	"selectItem": `s-module__selectItem--PlF26`,
	"selectItemIn": `s-module__selectItemIn--ClC87`,
	"forSelect": `s-module__forSelect--TpBvL`
};
export default ___CSS_LOADER_EXPORT___;
