import React from 'react';
import s from './s.module.less';

type Props = {
    onClickDirectUpload?: ()=>void,
    onClickManualInput?: ()=>void
};
const PatientUploadSelectPanel = (props: Props) => {
    const { onClickDirectUpload, onClickManualInput } = props;

    return (
        <div className={s.wrap}>
            <div
                onClick={onClickDirectUpload}
                className={s.item}
            >
                <div className={s.icon}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <path d="M3 10.5H21M3 14.5H21M12 10.5V18.5M5 18.5H19C20.1046 18.5 21 17.6046 21 16.5V8.5C21 7.39543 20.1046 6.5 19 6.5H5C3.89543 6.5 3 7.39543 3 8.5V16.5C3 17.6046 3.89543 18.5 5 18.5Z" stroke="#00816B" strokeWidth="2" />
                    </svg>
                </div>
                <div className={s.right}>
                    <div className={s.title}>
                        Direct upload
                    </div>
                    <div className={s.des}>
                        Upload a CSV file with patients’ information
                    </div>
                </div>
            </div>

            <div
                onClick={onClickManualInput}
                className={s.item}
            >
                <div className={s.icon}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <path d="M20.2677 4.23223L20.9748 3.52513V3.52513L20.2677 4.23223ZM6.5 21.5355V22.5355C6.76522 22.5355 7.01957 22.4301 7.20711 22.2426L6.5 21.5355ZM3 21.5355H2C2 22.0878 2.44772 22.5355 3 22.5355V21.5355ZM3 17.9644L2.29289 17.2573C2.10536 17.4448 2 17.6992 2 17.9644H3ZM17.4393 4.93934C18.0251 4.35355 18.9748 4.35355 19.5606 4.93934L20.9748 3.52513C19.608 2.15829 17.3919 2.15829 16.0251 3.52513L17.4393 4.93934ZM19.5606 4.93934C20.1464 5.52513 20.1464 6.47487 19.5606 7.06066L20.9748 8.47487C22.3417 7.10804 22.3417 4.89196 20.9748 3.52513L19.5606 4.93934ZM19.5606 7.06066L5.79289 20.8284L7.20711 22.2426L20.9748 8.47487L19.5606 7.06066ZM6.5 20.5355H3V22.5355H6.5V20.5355ZM16.0251 3.52513L2.29289 17.2573L3.70711 18.6715L17.4393 4.93934L16.0251 3.52513ZM2 17.9644V21.5355H4V17.9644H2ZM14.5251 6.43934L18.0606 9.97487L19.4748 8.56066L15.9393 5.02513L14.5251 6.43934Z" fill="#00816B" />
                    </svg>
                </div>
                <div className={s.right}>
                    <div className={s.title}>
                        Manual input
                    </div>
                    <div className={s.des}>
                        Enter patients’ information manually
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PatientUploadSelectPanel;
