import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import s from './s.module.less';

type Props = {
    content: string; //markdownContent
};
const MarkdownRenderer = (props: Props) => {
    const { content } = props;
    return (
        <div className={s.wrap}>
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
        </div>
    );
};

export default MarkdownRenderer;
