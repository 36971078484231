import { message, Spin, Upload } from 'antd';
import React, { useCallback, useEffect } from 'react';
import s from './s.module.less';
import type { UploadProps } from 'antd';
import { RcFile } from 'antd/es/upload';
import { uploadProviderFile } from 'api/common';
import { getPrivateFilePreview } from 'api/ehr';
import remove from 'lodash/remove';

interface IProps {
    onChange?: (data: Record<string, string>) => void,
    value?: string[],
}

const Uploader = ({
    onChange,
    value,
}: IProps) => {
    const [uploadloading, setUploadLoading] = React.useState(false);
    const [docList, setDocList] = React.useState<Record<string, string>[]>([]);
    const [previewLoading, setPreviewLoading] = React.useState(false);

    useEffect(() => {
        onChange?.(docList.reduce((p, c) => {
            p[c.key] = c.filename;
            return p;
        }, {}));
    }, [docList]);

    const onUploadSuccess = useCallback((key: string, filename: string) => {
        const doc = {
            key,
            filename,
        };
        setDocList([...docList, doc]);
    }, [docList]);

    const handleLinkClick = useCallback(async (v?: string) => {
        if (v) {
            setPreviewLoading(true);
            try {
                const res = await getPrivateFilePreview(v);

                if (res && !res.error && res.data?.data?.singedUrl) {
                    window.open(res.data.data.singedUrl, '_blank');
                } else if (!res.data?.data?.singedUrl) {
                    message.error('there is no file to preview.');
                } else {
                    message.error(res?.error);
                }
            } catch (e) {
                console.error(e);
            }
            setPreviewLoading(false);
        }
    }, []);

    const handleRemoveLink = useCallback((v: string) => {
        remove(docList, (n) => n.key === v);

        setDocList([...docList]);
    }, [docList]);

    const props: UploadProps = {
        name: 'file',
        action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
        headers: {
            authorization: 'authorization-text',
        },
        showUploadList: false,
        multiple: false,
        accept: '.png,.jpg,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        beforeUpload(file) {
            return new Promise((resolve, reject) => {
                const isLt10M = file.size / 1024 / 1024 <= 10;
                if (!isLt10M) {
                    message.error('File must smaller than 10MB!');
                    reject(false);
                } else {
                    resolve(true);
                }
            });
        },
        customRequest: async (data) => {
            setUploadLoading(true);
            try {
                const formData = new FormData();
                formData.append('file', data.file);
                formData.append('uploadType', 'REVIEW_DISPUTE');
                const res = await uploadProviderFile(formData);
                if (!res.error) {
                    // const documentId = res.data?.data;
                    onUploadSuccess(res.data?.data, data.file.name);
                    // setDocList([...docList, res.data?.data]);
                    // setUploadedFile(data.file as RcFile);
                    // setAvaDocumentId(documentId);
                }
            } catch (e) {
                console.error(e);
            } finally {
                setUploadLoading(false);
            }
        },
    };
    return (
        <div className={s.uploaderWrap}>
            {
                docList.length < 3 && (
                    <Spin spinning={uploadloading}>
                        <div className={s.upload}>
                            <Upload.Dragger {...props}>
                                <div className={s.uploadBox}>
                                    <div className={s.uploadIcon} />
                                    <p><span>Upload a file </span>or drag and drop</p>
                                    <div className={s.desc}>PNG, JPG, PDF, or .doc up to 10MB</div>
                                </div>
                            </Upload.Dragger>
                        </div>
                    </Spin>
                )
            }
            <div className={s.list}>
                {
                    docList.map((doc) => {
                        return (
                            <div className={s.doc}>
                                <span className={s.text} onClick={() => handleLinkClick(doc.key)}>{doc.filename}</span>
                                <div className={s.closeIcon} onClick={() => handleRemoveLink(doc.key)} />
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default Uploader;
