import React, { useCallback, useEffect, useState } from 'react';
import PhotoUploader from 'components/PhotoUploader';
import s from './s.module.less';
import { TMedication } from 'types/common';
import CommonTable from 'components/CommonTable';
import { ColumnType, TableProps } from 'antd/es/table';
import { Button, Input, InputNumber } from 'antd';

interface IProps {
    data: TMedication[],
    canRemove?: boolean,
    canEdit?: boolean,
    onRemove?: (id: number) => void,
    onQuantityChange?: (medicationId: number, quantity: number) => void,
    medicationQuantityMap?: Record<string, number>,
}

const MedicationTable = ({
    data = [],
    canRemove,
    canEdit,
    onRemove,
    onQuantityChange,
    medicationQuantityMap,
}: IProps) => {
    const columns: ColumnType<TMedication>[] = [
        {
            title: 'Medication name',
            dataIndex: 'medicationName',
            className: s.tableColumn,
            render: (name: string) => {
                return <div>{name}</div>
            },
        },
        {
            title: 'Quantity',
            className: s.tableColumn,
            width: '120px',
            render: (medication: TMedication) => {
                const key = '_' + medication.id
                return <div>
                    <InputNumber
                        onChange={(value) => {
                            onQuantityChange?.(medication.id, value || 1)
                        }
                        }
                        value={medicationQuantityMap?.[key] || 1}
                        defaultValue={1} min={1} />
                </div>
            },
        },
        {
            title: 'BRAND',
            dataIndex: 'isBranded',
            className: s.tableColumn,
            width: '120px',
            render: (isBranded: boolean) => {
                return <div>{isBranded ? 'Branded' : 'Non-Branded'}</div>
            },
        },
        {
            title: 'DOSAGE',
            dataIndex: 'dosage',
            className: s.tableColumn,
            width: '120px',
            render: (dosage: string) => {
                return <div>{dosage}</div>
            },
        },
        {
            title: 'PRICE',
            dataIndex: 'price',
            className: s.tableColumn,
            width: '120px',
            render: (price: string) => {
                return <div>{price}</div>
            },
        },
        {
            title: 'PHARMACYNAME',
            dataIndex: 'fulfillmentPharmacyName',
            className: s.tableColumn,
            width: '120px',
            render: (pharmacyName: string) => {
                return <div>{pharmacyName}</div>
            },
        },
        {
            title: '',
            dataIndex: '',
            className: s.tableColumn,
            width: '120px',
            fixed: 'right',
            render: (item: TMedication) => {
                return (
                    <div className={s.actionWrap}>
                        <Button
                            onClick={() => {
                                onRemove?.(item.id)
                            }}
                            className={s.text} type='text'>Remove</Button>
                    </div>
                )
            },
        },
    ]
    return (
        <div className={s.wrap}>
            <div className={s.tableWrap}>
                <CommonTable
                    bordered
                    rowKey="id"
                    columns={columns}
                    data={data || []}
                    pagination={false}
                />
            </div>
        </div>
    );
};

export default MedicationTable;
