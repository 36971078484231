import React from 'react';
import s from './s.module.less';
import { Button, Drawer, message, Spin } from 'antd';
import useFetchAuditDetailData from 'pages/operation/ListToChannelDetailPage/hooks/useFetchAuditDetailData';
import Detail from 'pages/operation/ListToChannelDetailPage/components/Detail';
import { getProviderNameDisplay } from 'utils/provider';
import copy from 'copy-to-clipboard';
import { UniprofileFormData } from 'types/provider';
import useAirtableProviderDetail from 'hooks/useAirtableProviderDetail';
import { TProfileLinkInfo } from 'types/common';
import { EnumFields, SettingEnum } from 'types/enumerationData';
import CommonTable from 'components/CommonTable';
import useGetSettingData from 'hooks/useGetSettingData';

type IProps = {
    providerId?: string,
    open?: boolean,
    onClose?: () => void,
    isOps?: boolean,
    isAirtableUser?: boolean,
};

type TTableItem = {
    state: string,
    link: string,
    vfdLink: string,
}

const ProfileViewDrawer = (props: IProps) => {
    const { open, onClose, providerId, isOps = true, isAirtableUser } = props;
    const [allStates] = useGetSettingData(EnumFields.STATE);
    const { data, loading, refetch } = useFetchAuditDetailData({
        channelId: -1,
        providerId: (!isAirtableUser && providerId) ? parseInt(providerId!, 10) : undefined,
    });

    const { data: airtableData, loading: airtableLoading, refetch: airtableRefetch } = useAirtableProviderDetail(isAirtableUser ? providerId : '', true);

    // const [displayEhrProviderData, setDisplayEhrProviderData] = React.useState<UniprofileFormData>();
    // const [displayAirtableProviderData, setDisplayAirtableProviderData] = React.useState();

    const isLoading = airtableLoading || loading;
    let title = '';
    if (!isLoading && isAirtableUser) {
        title = `${getProviderNameDisplay(airtableData as any)} uniProfile`;
    } else if (!isLoading) {
        title = `${getProviderNameDisplay(data?.profile as any)} uniProfile`;
    }

    const hasProfileLinks = airtableData?.profileLinkInfo && airtableData?.profileLinkInfo?.length > 0;

    const tableData: TTableItem[] = [];
    if (hasProfileLinks) {
        const addLink: string[] = [];
        const profileLinkInfo = airtableData?.profileLinkInfo;
        profileLinkInfo?.forEach((info: TProfileLinkInfo) => {
            try {
                const url = new URL(info.profileLink!);
                const search = url.searchParams;
                const state: string = (search && search.get('state')) || 'General';
                const linkUrl = url.origin + url.pathname;


                const targetState = (allStates as SettingEnum[])?.find((e: SettingEnum) => {
                    return state?.replace(/-/g, ' ').toLowerCase() === e.dictKey?.toLowerCase()
                        || state?.replace(/-/g, ' ').toLowerCase() === (e.content as string)?.toLowerCase()
                });
                if (!addLink.includes(url.href)) {
                    tableData.push({
                        state: targetState ? targetState.content : state,
                        link: url.href,
                        vfdLink: info.vfdLink!,
                    });
                    addLink.push(url.href);
                }
                if (!addLink.includes(linkUrl)) {
                    tableData.push({
                        state: 'General',
                        link: linkUrl,
                        vfdLink: info.vfdLink!,
                    });
                    addLink.push(linkUrl);
                }
            } catch (ex) {

            }

        });
    }
    const columns = [
        {
            title: 'STATE',
            dataIndex: 'state',
            width: '200px',
        },
        {
            title: 'PPP Link',
            dataIndex: 'link',
            key: 'link',
            render: (link?: string) => {
                return (
                    <a className={s.pppLink} href={link} target="_blank" rel="noreferrer">{link}</a>
                )
            }
        },
        {
            title: 'ACTION',
            width: '120px',
            render: (data?: TTableItem) => {
                return <span
                    className={s.copyText}
                    onClick={() => {
                        copy(data?.link || '', { format: 'text/plain' });
                        message.success('Copied to the clipboard');
                    }}>Copy</span>
            },
        },
        {
            title: 'VFD LINK',
            width: '120px',
            dataIndex: 'vfdLink',
            key: 'vfdLink',
            render: (link?: string) => {
                return <span
                    onClick={() => {
                        if (link) {
                            window.open(link, '_blank');
                        } else {
                            message.error('Vfd link not found')
                        }
                    }}
                    className={s.copyText}>Open</span>
            },
        },
    ];

    return (
        <Drawer
            width={900}
            title={title}
            onClose={onClose}
            open={open}
            className={s.drawer}
        >
            <div className={s.wrap}>
                {
                    isAirtableUser &&
                    <Spin spinning={isLoading}>
                        <div className={s.userWrap}>
                            <h3>Account information</h3>
                            <div className={s.rowWrap}>
                                <div className={s.col} style={{ width: '80%' }}>
                                    <div className={s.label}>Klarity PPP</div>
                                    {
                                        !hasProfileLinks && airtableData?.profileLinks?.map((link: string) => {
                                            return (
                                                <div className={s.value} key={link}>
                                                    <span className={s.link}>{link}</span>
                                                    <span
                                                        onClick={() => {
                                                            copy(link || '', { format: 'text/plain' });
                                                            message.success('Copied to the clipboard');
                                                        }}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                            <path d="M6.66634 5.83333V12.5C6.66634 13.4205 7.41253 14.1667 8.33301 14.1667H13.333M6.66634 5.83333V4.16667C6.66634 3.24619 7.41253 2.5 8.33301 2.5H12.1545C12.3755 2.5 12.5875 2.5878 12.7438 2.74408L16.4223 6.42259C16.5785 6.57887 16.6663 6.79083 16.6663 7.01184V12.5C16.6663 13.4205 15.9201 14.1667 14.9997 14.1667H13.333M6.66634 5.83333H5.33301C4.22844 5.83333 3.33301 6.72876 3.33301 7.83333V15.8333C3.33301 16.7538 4.0792 17.5 4.99967 17.5H11.333C12.4376 17.5 13.333 16.6046 13.333 15.5V14.1667" stroke="#00816B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </span>
                                                </div>
                                            );
                                        })
                                    }
                                    {
                                        hasProfileLinks &&
                                        <div className={s.value}>
                                            <CommonTable
                                                bordered
                                                rowKey="link"
                                                columns={columns}
                                                data={tableData || []}
                                                pagination={false}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </Spin>
                }
                {
                    !isAirtableUser &&
                    <Spin spinning={isLoading}>
                        <Detail
                            isDrawer
                            providerId={parseInt(providerId!, 10)!}
                            formData={data}
                            channelId={-1}
                            refetch={refetch}
                            onSubmit={() => { }}
                            onShowMessage={() => { }}
                            onApprove={() => { }}
                        />
                    </Spin>
                }
            </div>
        </Drawer>

    );
};

export default ProfileViewDrawer;
