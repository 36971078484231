// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--Jc6rV {
  padding: 30px 24px;
  border: 1px solid var(--gray);
  border-radius: 10px;
  font-family: 'Inter';
  font-style: normal;
}
.s-module__wrap--Jc6rV .s-module__header--SzeZU {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
}
.s-module__wrap--Jc6rV .s-module__header--SzeZU .s-module__title--hgHIV {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: var(--gray-700);
}
.s-module__wrap--Jc6rV .s-module__body--GZWZ5 {
  display: flex;
  flex-wrap: wrap;
}
.s-module__wrap--Jc6rV .s-module__body--GZWZ5 .s-module__sliderImg--TZxZM {
  width: 50%;
  padding: 0 10px;
  margin: 10px 0;
}
.s-module__wrap--Jc6rV .s-module__body--GZWZ5 .s-module__sliderImg--TZxZM .s-module__inner--ls1lc {
  height: auto;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5.15px;
  border: 2px dashed var(--gray-300);
}
.s-module__wrap--Jc6rV .s-module__body--GZWZ5 .s-module__sliderImg--TZxZM .s-module__inner--ls1lc img {
  -o-object-fit: cover;
     object-fit: cover;
}
`, "",{"version":3,"sources":["webpack://./src/components/HelloKlarityFormComponents/Photos/s.module.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,6BAAA;EACA,mBAAA;EACA,oBAAA;EACA,kBAAA;AACJ;AANA;EAQQ,mBAAA;EACA,aAAA;EACA,8BAAA;AACR;AAXA;EAaY,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AACZ;AAjBA;EAqBQ,aAAA;EACA,eAAA;AADR;AArBA;EAwBY,UAAA;EACA,eAAA;EACA,cAAA;AAAZ;AA1BA;EA6BgB,YAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,qBAAA;EACA,kCAAA;AAAhB;AAnCA;EAsCoB,oBAAA;KAAA,iBAAA;AAApB","sourcesContent":[".wrap {\n    padding: 30px 24px;\n    border: 1px solid var(--gray);\n    border-radius: 10px;\n    font-family: 'Inter';\n    font-style: normal;\n\n    .header {\n        margin-bottom: 16px;\n        display: flex;\n        justify-content: space-between;\n\n        .title {\n            font-weight: 500;\n            font-size: 20px;\n            line-height: 20px;\n            color: var(--gray-700);\n        }\n    }\n\n    .body {\n        display: flex;\n        flex-wrap: wrap;\n        .sliderImg {\n            width: 50%;\n            padding: 0 10px;\n            margin:  10px 0;\n\n            .inner {\n                height: auto;\n                aspect-ratio: 1;\n                display: flex;\n                justify-content: center;\n                align-items: center;\n                border-radius: 5.15px;\n                border: 2px dashed var(--gray-300);\n\n                img {\n                    object-fit: cover;\n                }\n            }\n\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--Jc6rV`,
	"header": `s-module__header--SzeZU`,
	"title": `s-module__title--hgHIV`,
	"body": `s-module__body--GZWZ5`,
	"sliderImg": `s-module__sliderImg--TZxZM`,
	"inner": `s-module__inner--ls1lc`
};
export default ___CSS_LOADER_EXPORT___;
