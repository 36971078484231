// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__labelDes--KCjM8 {
  color: var(--gray-500, #6B7280);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
.s-module__wrap--QaMZa .s-module__mSelectAdd--x2Awa {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
  line-height: 38px;
}
.s-module__wrap--QaMZa .s-module__mSelectAdd--x2Awa .ant-select-selection-item {
  color: #447DB2;
  font-weight: 500;
  border-radius: 2px;
  border: 1px solid var(--Neutral-4, #F0F0F0);
  background: var(--blue-100, #DBEAFE);
}
.s-module__wrap--QaMZa .s-module__emptyTips--ZjeaL {
  color: var(--White-gray, #6B7280);
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 8px;
  margin-bottom: 4px;
}
.s-module__mWrap--Pzktz .s-module__mItem--wmokc {
  border-radius: 10px;
  border: 1px solid #DAD8D8;
  background: #F9FAFB;
  padding: 16px;
  margin-bottom: 24px;
}
.s-module__mWrap--Pzktz .s-module__add--uUpJt {
  color: var(--primary-color);
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/MedicationSelect/s.module.less"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACF;AAEA;EAEI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;AADJ;AAPA;EAYQ,cAAA;EACA,gBAAA;EACA,kBAAA;EACA,2CAAA;EACA,oCAAA;AAFR;AAdA;EAsBI,iCAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;EACA,kBAAA;AALJ;AASA;EAEI,mBAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;AARJ;AAEA;EAUI,2BAAA;EACA,eAAA;AATJ","sourcesContent":[".labelDes {\n  color: var(--gray-500, #6B7280);\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 20px;\n}\n\n.wrap {\n  .mSelectAdd {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    margin-top: 8px;\n    margin-bottom: 8px;\n    line-height: 38px;\n\n    :global {\n      .ant-select-selection-item {\n        color: #447DB2;\n        font-weight: 500;\n        border-radius: 2px;\n        border: 1px solid var(--Neutral-4, #F0F0F0);\n        background: var(--blue-100, #DBEAFE);\n      }\n    }\n  }\n\n  .emptyTips {\n    color: var(--White-gray, #6B7280);\n    font-size: 14px;\n    font-weight: 500;\n    line-height: 24px;\n    margin-top: 8px;\n    margin-bottom: 4px;\n  }\n}\n\n.mWrap {\n  .mItem {\n    border-radius: 10px;\n    border: 1px solid #DAD8D8;\n    background: #F9FAFB;\n    padding: 16px;\n    margin-bottom: 24px;\n  }\n\n  .add {\n    color: var(--primary-color);\n    cursor: pointer;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelDes": `s-module__labelDes--KCjM8`,
	"wrap": `s-module__wrap--QaMZa`,
	"mSelectAdd": `s-module__mSelectAdd--x2Awa`,
	"emptyTips": `s-module__emptyTips--ZjeaL`,
	"mWrap": `s-module__mWrap--Pzktz`,
	"mItem": `s-module__mItem--wmokc`,
	"add": `s-module__add--uUpJt`
};
export default ___CSS_LOADER_EXPORT___;
