import React, { useCallback, useState } from 'react';
import s from './s.module.less';
import uniprofileAIResume from 'assets/uniprofile/uploadResumeRobot.png';
import UploadUniprofile from '../UploadUniprofilePage';
import { Radio } from 'antd';

interface IProps {
    onUploadSuccess: (documentId?: string | true) => void;
    type?: number | null;
    onClick: (v: number) => void;
}

const UniprofileResume = ({
    onUploadSuccess,
    type,
    onClick,
}: IProps) => {
    const handleClick = useCallback((v: number) => {
        onClick(v);
        onUploadSuccess(v === 2 ? true : '');
    }, [onClick]);

    const handleUploadSuccess = useCallback((documentId?: string) => {
        onUploadSuccess(documentId);
    }, []);

    return (
        <div className={s.wrap}>
            <div className={`${s.left} ${type === 2 ? s.minWidth : ''}`}>
                <div className={s.top}>
                    <img className={s.avaImage} src={uniprofileAIResume} />
                    <div className={s.chatBlock}>
                        <div className={s.chatText}>
                            Hi there! I'm <div className={s.avaLogo}>Ava</div>, your AI assistant. I'm here to help you set up your UniProfile quickly and easily. I can read your resume, help improve your writing, and even suggest the best prices for your services. Let’s get started!
                        </div>
                    </div>
                </div>
                <div className={s.title}>Do you have a resume?</div>
                <div className={s.subTitle}><div className={s.avaLogo}>Ava</div> can use it to pre-fill your UniProfile and save you up to 20 minutes!</div>
                <div className={s.desc}>Both PDF and Word documents are accepted, but PDF will give you better performance</div>
                <div className={s.typeSelection}>
                    <div className={`${s.typeOption} ${type === 1 && s.selected}`} onClick={() => handleClick(1)}>
                        <div className={s.optionInner}>
                            <div className={s.checkbox} />
                            <div className={s.textContent}>
                                Yes, I have a resume ready to upload
                            </div>
                        </div>
                    </div>
                    <div className={`${s.typeOption} ${type === 2 && s.selected}`} onClick={() => handleClick(2)}>
                        <div className={s.optionInner}>
                            <div className={s.checkbox} />
                            <div className={s.textContent}>
                                No, I don’t have a resume yet
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                type === 1 && (
                    <div className={s.right}>
                        <div className={s.title}>Upload your resume here</div>
                        <UploadUniprofile onUploadSuccess={handleUploadSuccess} />
                    </div>
                )
            }
        </div>
    );
};

export default UniprofileResume;
