import React from 'react';

const MenuHelloKlaritySVG = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
            fill="currentColor"
        />
        <path
            d="M8.69531 16V8H10.3867V11.5273H10.4922L13.3711 8H15.3984L12.4297 11.582L15.4336 16H13.4102L11.2188 12.7109L10.3867 13.7266V16H8.69531Z"
            fill="currentColor"
        />
    </svg>
);

export default MenuHelloKlaritySVG;
