// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--yoAgW {
  font-size: 14px;
  line-height: 20px;
}
.s-module__wrap--yoAgW img {
  max-width: 250px;
  height: auto;
  margin: 12px;
}
.s-module__wrap--yoAgW a {
  font-weight: 500;
}
.s-module__wrap--yoAgW ol {
  margin-top: 16px;
}
.s-module__wrap--yoAgW ol > li {
  margin-bottom: 36px;
}
.s-module__wrap--yoAgW h3,
.s-module__wrap--yoAgW h4,
.s-module__wrap--yoAgW h2 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
  margin-top: 8px;
}
.s-module__wrap--yoAgW ol > li p,
.s-module__wrap--yoAgW ol > li h2 {
  font-size: 16px !important;
  font-weight: 500 !important;
  margin-bottom: 8px;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/components/MarkdownRenderer/s.module.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,iBAAA;AACF;AAHA;EAII,gBAAA;EACA,YAAA;EACA,YAAA;AAEJ;AARA;EASI,gBAAA;AAEJ;AAXA;EAYI,gBAAA;AAEJ;AAdA;EAeI,mBAAA;AAEJ;AAjBA;;;EAkBI,eAAA;EACA,gBAAA;EAEA,kBAAA;EACA,eAAA;AAIJ;AA1BA;;EA2BM,0BAAA;EACA,2BAAA;EACA,kBAAA;EACA,gBAAA;AAGN","sourcesContent":[".wrap {\n  font-size: 14px;\n  line-height: 20px;\n  img {\n    max-width: 250px;\n    height: auto;\n    margin: 12px;\n  }\n  a {\n    font-weight: 500;\n  }\n  ol{\n    margin-top: 16px;\n  }\n  ol > li {\n    margin-bottom: 36px;\n  }\n  h3,h4,h2{\n    font-size: 18px;\n    font-weight: 500;\n    margin-top: 16px;\n    margin-bottom: 8px;\n    margin-top: 8px;\n  \n  }\n  ol > li {\n    p,h2 {\n      font-size: 16px !important;\n      font-weight: 500 !important;\n      margin-bottom: 8px;\n      text-align: left;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--yoAgW`
};
export default ___CSS_LOADER_EXPORT___;
