export enum ServiceFormFieldEnum {
    COLOR_ID = 'colorId',
    DESCRIPTION = 'description',
    DURATION = 'duration',
    NAME = 'name',
    TYPE = 'type',
    PERSONADDRESS = 'personAddress',
    PERSONCITY = 'personCity',
    PERSONSTATE = 'personState',
    PERSONTYPE = 'personType',
    PERSONZIP = 'personZip',
    PROVIDER_ID = 'providerId',
    SENDFORM = 'sendForm',
    TELEHEALTHTYPE = 'telehealthType',
    PRICE = 'price',
    ADDRESS_ID = 'addressId',
    ENABLE_SLIDING_SCALE = 'enableSlidingScale',
    LOWEST_PRICE = 'lowestPrice',
    HIGHEST_PRICE = 'highestPrice',
}

export interface ServiceModal {
    [ServiceFormFieldEnum.COLOR_ID]: string
    [ServiceFormFieldEnum.DESCRIPTION]: string
    [ServiceFormFieldEnum.DURATION]: number
    [ServiceFormFieldEnum.NAME]: string
    [ServiceFormFieldEnum.PERSONADDRESS]: string
    [ServiceFormFieldEnum.PERSONCITY]: string
    [ServiceFormFieldEnum.PERSONSTATE]: string
    [ServiceFormFieldEnum.PERSONTYPE]: string[]
    [ServiceFormFieldEnum.PERSONZIP]: string
    [ServiceFormFieldEnum.PROVIDER_ID]: number
    [ServiceFormFieldEnum.SENDFORM]: string
    [ServiceFormFieldEnum.TELEHEALTHTYPE]: string[]
    [ServiceFormFieldEnum.TYPE]: string
    [ServiceFormFieldEnum.PRICE]: number
    [ServiceFormFieldEnum.ADDRESS_ID]: number | null
    [ServiceFormFieldEnum.ENABLE_SLIDING_SCALE]?:boolean;
    [ServiceFormFieldEnum.LOWEST_PRICE]?: number;
    [ServiceFormFieldEnum.HIGHEST_PRICE]?: number;
}
