import React, { useMemo } from 'react';
import s from './s.module.less';
import { IService } from 'types/practiceFront';
import { ProviderPractice } from 'types/provider';

interface IProps {
    initFormValue: Array<IService>;
    contact?: ProviderPractice;
}

const MeetingTypes = ({
    initFormValue,
    contact,
}: IProps) => {
    const serviceTypes = useMemo(() => {
        const hasTelehealthType = initFormValue?.find((item:IService) => !!item.telehealthType);
        const hasInPersonType:any = initFormValue?.find((item:IService) => !!(item.personType && (item.personAddress || item.personCity || item.personState)));

        const address = hasInPersonType?.personAddress ? `${hasInPersonType?.personAddress},` : '';
        const state = hasInPersonType?.personState ? `${hasInPersonType?.personState},` : '';
        const city = hasInPersonType?.personCity ? `${hasInPersonType?.personCity}` : '';

        // if (!contact?.practiceAddress || !contact?.practiceCity || !contact?.practiceState) {
        //     //logic for practice front
        //     hasInPersonType = false;
        // }

        return {
            telehealth: hasTelehealthType?.telehealthType && hasTelehealthType.telehealthType?.length > 0,
            // inperson: hasInPersonType?.personType && hasInPersonType.personType.length > 0 ? `${address}${state}${city}` : false,
            inperson: hasInPersonType ? `${address}${state}${city}` : false,
        };
    }, [initFormValue]);

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <div className={s.title}>Meeting types</div>
            </div>
            <div className={s.tip}>
                <div className={s.icon} />
                <div className={s.tipText}>This is a non-editable section. The meeting types is auto-populated based on your initial visits information.</div>
            </div>
            <div className={s.body}>
                <div className={s.viewWrap}>
                    <div className={s.viewItem}>
                        <div className={s.label}>Meeting types</div>
                        <div className={s.value}>
                            <div className={s.typeBox}>
                                {
                                    serviceTypes.inperson ?
                                        (
                                            <>
                                                <div className={s.correctIcon} />
                                                <div className={s.infoBox}>
                                                    <div className={s.serviceTypeName}>In-person visits</div>
                                                    <div className={s.label}>Office address</div>
                                                    <div className={s.value}>{serviceTypes.inperson}</div>
                                                </div>
                                            </>
                                        ) :
                                        (
                                            <>
                                                <div className={s.wrongIcon} />
                                                <div className={s.infoBox}>
                                                    <div className={s.serviceTypeName}>In-person visits</div>
                                                </div>
                                            </>
                                        )
                                }
                            </div>
                            <div className={s.typeBox}>
                                <div className={`${serviceTypes.telehealth ? s.correctIcon : s.wrongIcon}`} />
                                <div className={s.infoBox}>
                                    <div className={s.serviceTypeName}>Video visits</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MeetingTypes;
