import { EnumFields, SettingEnum } from 'types/enumerationData';
import { TFunStatus, TSubscriptionItem, TSubscriptionItemFromServer, TSubscriptionType } from 'types/subscription';

const funList: TFunStatus[] = [
    {
        name: 'Free channels',
        des: 'Get listed on selected standard and premium channels to reach more patients. Kiwi covers the cost of any premium channels included in your plan.',
        support: true,
    },
    {
        name: 'Premium channel discount',
        des: 'Kiwi’s exclusive discount on premium channel subscriptions.',
        support: false,
    },
    {
        name: 'Practice front',
        des: 'A Kiwi Health profile page built with cutting-edge medical SEO technology, expanding your reach across multiple locations and boosting your online presence.',
        support: true,
    },
    {
        name: 'Virtual front desk',
        des: 'HIPAA-trained virtual receptionist specialized in answering incoming patient calls and booking appointments while you are busy.',
        support: false,
    },
    {
        name: 'Klarity success coach',
        des: 'Work one-on-one with a dedicated Klarity support specialist to develop a personalized strategy to maximize your online presence, attract more patients, and achieve your practice goals.',
        support: false,
    },
];

const conbindFunList = (changeItems: Partial<TFunStatus>[]): TFunStatus[] => {
    return funList.slice().map((item, index) => {
        if (changeItems.length <= index) {
            return { ...item };
        }
        return { ...item, ...changeItems[index] };
    });
};

export const SUBSCRIPTION_PLANS: TSubscriptionItem[] = [
    {
        name: 'Starter',
        des: 'Your essential online presence & 150 mins of virtual receptionists — a $329/month value.',
        priceDesc: 'included in Klarity membership',
        monthlyPrice: 0,
        originalMonthlyPrice: 0,
        quarterlyPrice: 0,
        yearlyPrice: 0,
        contactOnly: false,
        type: TSubscriptionType.KLARITY_STARTER,
        subType: undefined,
        isCurrent: false,
        funList: conbindFunList(
            [
                {
                    support: true,
                    supportText: [
                        '10 selected standard channels',
                        'Psychology Today',
                        'Mental Health Match',
                    ],
                },
                { support: false },
                { support: true },
                {
                    support: true,
                    supportText: '150 mins call-time',
                },
                { support: false },
            ],
        ),
    },
    {
        name: 'Plus',
        highlight: 'Best value',
        des: 'Good for part-time & new full-time healthcare providers',
        monthlyPrice: 49,
        originalMonthlyPrice: 85,
        quarterlyPrice: 42,
        yearlyPrice: 40,
        contactOnly: false,
        type: TSubscriptionType.KLARITY_PLUS,
        subType: undefined,
        isCurrent: false,
        funList: conbindFunList(
            [
                {
                    support: true,
                    supportText: [
                        'All standard channels (25+)',
                        'Psychology Today',
                        'Mental Health Match',
                        'All Counseling',
                    ],
                },
                { support: true },
                { support: true },
                {
                    support: true,
                    supportText: '250 mins call-time',
                },
                { support: true },
            ],
        ),
    },
    {
        name: 'Pro',
        highlight: '',
        des: 'Boost your practice with 25+ listings, premium channels, expert guidance, and 7 hours of virtual receptionists — a $500+/month value.',
        // priceTips: 'Early Bird Offer! Price set to rise soon!',
        monthlyPrice: 99,
        originalMonthlyPrice: 230,
        quarterlyPrice: 84,
        yearlyPrice: 80,
        contactOnly: false,
        type: TSubscriptionType.KLARITY_PRO,
        subType: undefined,
        isCurrent: false,
        funList: conbindFunList(
            [
                {
                    support: true,
                    supportText: [
                        'All standard channels (25+)',
                        'Psychology Today',
                        'Mental Health Match',
                        'All Counseling',
                        'WebMD premium',
                        'Vitals',
                    ],
                },
                { support: true },
                { support: true },
                {
                    support: true,
                    supportText: '500 mins call-time',
                },
                { support: true },
            ],
        ),
    },
];

export const GROUP_SUBSCRIPTION_PLAN: TSubscriptionItem = {
    name: 'Group Practice',
    highlight: '',
    des: 'Solutions for group practices that are looking for effective marketing',
    monthlyPrice: 0,
    quarterlyPrice: 0,
    yearlyPrice: 0,
    contactOnly: true,
    type: TSubscriptionType.GROUP_PRACTICE,
    subType: undefined,
    isCurrent: false,
    funList: conbindFunList(
        [
            { support: true },
            { support: true, supportText: [] },
            { support: true, supportText: [] },
            { support: true },
            { support: true },
        ],
    ),
};

export const SUBSCRIPTION_DETAIL_LINK = 'https://www.kiwihealth.com/pricing';

export const KIWI_CONTACT_LINK = 'https://www.kiwihealth.com/#contact';

export const GROUP_PRICE_CONTACT_LINK = 'https://calendly.com/klarity-and-kiwi/30-minutes-with-lysander?preview_source=et_card&month=2024-08';

export const PROVIDER_PAY_PLAN_TYPE: SettingEnum[] = [
    {
        dictKey: TSubscriptionType.FREE,
        content: TSubscriptionType.FREE,
        type: EnumFields.PROVIDER_PAY_PLAN_TYPE,
        id: 1,
    },
    {
        dictKey: TSubscriptionType.ESSENTIAL,
        content: TSubscriptionType.ESSENTIAL,
        type: EnumFields.PROVIDER_PAY_PLAN_TYPE,
        id: 2,
    },
    {
        dictKey: TSubscriptionType.GROWTH,
        content: TSubscriptionType.GROWTH,
        type: EnumFields.PROVIDER_PAY_PLAN_TYPE,
        id: 3,
    },
    {
        dictKey: TSubscriptionType.GROUP_PRACTICE,
        content: TSubscriptionType.GROUP_PRACTICE,
        type: EnumFields.PROVIDER_PAY_PLAN_TYPE,
        id: 4,
    },
];

//map for growth yearly plan: https://docs.google.com/spreadsheets/d/1Oo82LvSi0TFiEw1VdYKUMnoAA8OPfljCnankhfouvFc/edit#gid=1343487863
export const TRIAL_PRICE_ID: string = 'price_1O5MOyLWCWKPYTPjGPO7bTw8';

export const isTrialUser = (currentSubscription?: TSubscriptionItemFromServer): boolean => {
    if (currentSubscription?.priceId === TRIAL_PRICE_ID) {
        return true;
    }
    return false;
};
