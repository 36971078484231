import SmileyIcon from 'assets/survey/smileyIcon.svg';
import MessagesIcon from 'assets/survey/messagesIcon.svg';
import ChooseIcon from 'assets/survey/chooseIcon.svg';

export const SurveyQuestions = [
    {
        isAnonymous: 0,
        isOverallRating: 1,
        configBackend: JSON.stringify({
            title: 'This question asks patients to rate their overall satisfaction with your service',
            icon: SmileyIcon,
            valueType: 'string',
            tips: 'Rating',
            titleFiledEditorType: 'textarea',
            titleMaxLength: 300,
            isAnonymous: 0,
            isOverallRating: 1,
            isRequired: 1,
        }),
        configFrontend: JSON.stringify({
            editor: 'rating',
            optionsFieldName: 'elementListValues',
        }),
        elementListValues: [
            {
                id: 100,
                emoji: '',
                name: 'Terrible',
                value: '1',
                priority: 10,
            },
            {
                id: 101,
                emoji: '',
                name: 'Bad',
                value: '2',
                priority: 20,
            },
            {
                id: 102,
                emoji: '',
                name: 'Okay',
                value: '3',
                priority: 30,
            },
            {
                id: 103,
                emoji: '',
                name: 'Good',
                value: '4',
                priority: 40,
            },
            {
                id: 104,
                emoji: '',
                name: 'Amazing',
                value: '5',
                priority: 50,
            },
        ],
    },
    {
        isAnonymous: 0,
        isOverallRating: 0,
        configBackend: JSON.stringify({
            title: 'This question asks patients to provide feedback on your service',
            icon: MessagesIcon,
            valueType: 'string',
            tips: 'Feedback',
            titleFiledEditorType: 'textarea',
            subTextEditorType: 'textarea',
            titleMaxLength: 300,
            subTextMaxLength: 300,
            isAnonymous: 0,
            isOverallRating: 0,
            isRequired: 1,
        }),
        configFrontend: JSON.stringify({
            editor: 'textarea',
            props: {
                maxLength: 1000,
                showCount: true,
                placeholder: 'Share your thoughts here...',
            },
            styles: {
                height: '300px',
            },
        }),
    },
    {
        isAnonymous: 1,
        isOverallRating: 0,
        configBackend: JSON.stringify({
            title: 'This question asks patients’ permission to contact them for follow-up',
            icon: ChooseIcon,
            valueType: 'string',
            tips: 'Yes/No',
            titleFiledEditorType: 'textarea',
            subTextEditorType: 'textarea',
            titleMaxLength: 300,
            subTextMaxLength: 300,
            isAnonymous: 1,
            isOverallRating: 0,
            isRequired: 1,
        }),
        configFrontend: JSON.stringify({
            editor: 'radioButton',
        }),
    },
];
