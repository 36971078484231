import React, { useCallback, useRef, useState } from 'react';
import { PracitceAddressForm, EditModuleEnum, IProviderStore, IService } from 'types/practiceFront';
import { ProviderPractice } from 'types/provider';
import { Button, Modal, Spin, message } from 'antd';
import s from './s.module.less';
import AddressForm from './components/AddressForm';
import { addPracticeAddress, deletePracticeAddress, updatePracticeAddress } from 'api/practiceFront';
import ProviderStore from 'store/PracticeFront/provider';
import { updateProviderServiceAddress, getAllServiceByPracticeAddressId } from 'api/applicationForm';
import { getUserId } from 'utils/localstore';
import { IServiceAddressUpdateInput, PracticeAddressUpdateType } from 'types/applicationForm';
import PracticeAddressRemovement from 'components/PracticeAddressRemovement';

interface IProps {
    isEditing: boolean;
    initFormValue: ProviderPractice;
    gloablEditStatus: boolean;
    setTabKey: (key: string) => void;
    // onSubmit: (value: ProviderPractice, field: EditModuleEnum | string, callback: () => void) => void;
    setShowEditingTip: (field, value: boolean) => void;
    onEditStatusChange: (status: boolean, field: EditModuleEnum) => void;
    scrollToTheEditingModule: () => void;
    refetch: () => void;
}

const PracticeAddress = ({
    gloablEditStatus,
    // isEditing,
    initFormValue,
    // onSubmit,
    // setTabKey,
    setShowEditingTip,
    onEditStatusChange,
    scrollToTheEditingModule,
    refetch,
}: IProps) => {
    const [getProvider, setProvider] = ProviderStore.useStore();
    const [editingAddressId, setEditingAddressId] = useState<number | undefined>(-1);
    const [open, setOpen] = useState(false);
    const [removeId, setRemoveId] = useState<number | null>(null);
    const providerData = getProvider('data') as IProviderStore;

    const [addressEditType, setAddressEditType] = useState<PracticeAddressUpdateType>(PracticeAddressUpdateType.UPDATE);
    const [showAddressUpdateModal, setShowAddressUpdateModal] = useState(false);
    const [fetchServiceLoading, setFetchServiceLoading] = useState<boolean>(false);
    const [serviceListData, setServiceListData] = useState<Array<IService>>([]);
    const [updateServiceAddressData, setUpdateServiceAddressData] = useState<Array<IServiceAddressUpdateInput>>([]);
    const formListRemoveFn = useRef<null | Function>(null);

    const handleEditingChange = useCallback((status: boolean, id?: number) => {
        if (gloablEditStatus && status) {
            if (gloablEditStatus) {
                setTimeout(() => {
                    scrollToTheEditingModule();
                }, 100);
            }
            // setShowEditingTip?.('data', true);
            // message.error('Please save your changes before proceeding');
        } else {
            setShowEditingTip?.('data', false);
            setEditingAddressId(status ? id : -1);
        }
        onEditStatusChange(status, EditModuleEnum.PRACTICE_ADDRESS);
    }, [gloablEditStatus, onEditStatusChange, scrollToTheEditingModule, setShowEditingTip]);

    const fetchServiceList = useCallback(async (providerID: number, addressId: number) => {
        setFetchServiceLoading(true);
        let res = {
            error: '',
        };
        try {
            res = await getAllServiceByPracticeAddressId(providerID, addressId);
        } catch (e) {
            console.error(e);
        }
        setFetchServiceLoading(false);
        return res;
    }, []);

    const handleBeforeRemove = useCallback(async (removeCallback: () => void, addressId: number, type: PracticeAddressUpdateType) => {
        const userId = getUserId();

        if (userId && addressId) {
            const res = await fetchServiceList(userId, addressId);

            if (!res.error) {
                if (res?.data?.data?.length > 0) {
                    setServiceListData(res.data.data);
                    setAddressEditType(type);
                    setOpen(false);
                    setShowAddressUpdateModal(true);
                    formListRemoveFn.current = removeCallback;
                } else {
                    removeCallback();
                }
            } else {
                message.error(res.error);
            }
        }
    }, [fetchServiceList]);

    const handleConfirmRemove = useCallback(async () => {
        if (typeof formListRemoveFn.current === 'function') {
            await (formListRemoveFn.current)();

            const res = await updateProviderServiceAddress(updateServiceAddressData);

            if (res && !res.error) {
                setShowAddressUpdateModal(false);
            } else {
                message.error(res.error);
            }
        }
    }, [updateServiceAddressData]);

    const handleRemove = useCallback(async (id: number) => {
        setRemoveId(id);
        setOpen(true);
    }, []);

    const conformRemove = useCallback(async () => {
        if (removeId) {
            handleBeforeRemove(async () => {
                const res = await deletePracticeAddress(removeId);

                setOpen(false);

                if (!res.error) {
                    setRemoveId(null);
                    refetch?.();
                } else {
                    message.error(res.error);
                }
            }, removeId, PracticeAddressUpdateType.REMOVE);
        } else {
            setOpen(false);
            refetch();
        }
    }, [handleBeforeRemove, refetch, removeId]);

    const handleServiceAddressChange = useCallback(async (data: Array<IServiceAddressUpdateInput>) => {
        setUpdateServiceAddressData(data);
    }, []);

    const handleHideAddressUpdateModal = useCallback(() => {
        setShowAddressUpdateModal(false);
    }, []);

    const handleSubmit = useCallback(async (formData: PracitceAddressForm, addressIsChanged: boolean, callback, id?: number) => {
        if (id) {
            const updateAddress = async () => {
                const res = await updatePracticeAddress({
                    ...formData,
                    id,
                });

                if (!res?.error) {
                    setEditingAddressId(-1);
                    onEditStatusChange(false, EditModuleEnum.PRACTICE_ADDRESS);
                    refetch?.();
                    callback?.();
                } else {
                    message.error(res.error);
                }
            };
            if (addressIsChanged) {
                handleBeforeRemove(updateAddress, id, PracticeAddressUpdateType.UPDATE);
            } else {
                updateAddress();
            }
        } else {
            const res = await addPracticeAddress(formData);
            if (!res?.error) {
                setEditingAddressId(-1);
                onEditStatusChange(false, EditModuleEnum.PRACTICE_ADDRESS);
                refetch?.();
            } else {
                message.error(res.error);
            }
        }
    }, [handleBeforeRemove, onEditStatusChange, refetch]);

    const handleAddNewAddress = useCallback(() => {
        if (gloablEditStatus) {
            setTimeout(() => {
                scrollToTheEditingModule();
            }, 100);
            // setShowEditingTip?.('data', true);
            // message.error('Please save your changes before proceeding');
            onEditStatusChange(true, EditModuleEnum.CONTACT);
        } else if (initFormValue?.addressList?.find((item) => !item.id)) {
            message.error('There is an unsaved new data, please save it and then add new address');
        } else {
            setShowEditingTip?.('data', false);
            const newAddress: PracitceAddressForm = {
                isDefault: false,
                asMailing: false,
                practiceAddress: '',
                practiceCity: '',
                practiceState: '',
                practiceZip: '',
            };

            const orignAddressList = initFormValue?.addressList || [];

            const newAddressList = [newAddress, ...orignAddressList];
            const newStore = {
                ...providerData,
                contact: {
                    ...(initFormValue || {}),
                    addressList: newAddressList,
                },
            };

            setProvider('data', newStore);
            setEditingAddressId(undefined);
            onEditStatusChange(true, EditModuleEnum.PRACTICE_ADDRESS);
        }
    }, [gloablEditStatus, initFormValue, onEditStatusChange, providerData, scrollToTheEditingModule, setProvider, setShowEditingTip]);

    return (
        <div className={s.wrap}>
            <Spin spinning={fetchServiceLoading}>
                <Modal title="Remove this address in UniProfile?" open={open} onOk={conformRemove} onCancel={() => setOpen(false)} okText="Remove">
                    <p>The address will also be removed from UniProfile. Please note that this will be applied to your UniProfile and updated across all channels. Are you sure you want to proceed?</p>
                </Modal>
                <Modal
                    title={`${addressEditType === PracticeAddressUpdateType.REMOVE ? 'Remove the address' : 'Update the address'}`}
                    centered
                    open={showAddressUpdateModal}
                    okText={`${addressEditType === PracticeAddressUpdateType.REMOVE ? 'Remove' : 'Update'}`}
                    onOk={handleConfirmRemove}
                    onCancel={(handleHideAddressUpdateModal)}
                    width="50%"
                >
                    <div className={s.ServiceModalContent}>
                        <PracticeAddressRemovement
                            serviceList={serviceListData}
                            onChange={handleServiceAddressChange}
                            type={addressEditType}
                        />
                    </div>
                </Modal>
                <div className={s.header}>
                    <div className={s.title}>Practice address</div>
                    <Button type="default" onClick={handleAddNewAddress}>Add</Button>
                </div>
                {initFormValue?.addressList?.map((item: PracitceAddressForm, index: number) => {
                    const isEditing = editingAddressId === item.id;
                    const showRemove = !!(initFormValue?.addressList && initFormValue?.addressList.length > 1);
                    return (
                        <AddressForm
                            showRemove={showRemove}
                            key={item.id}
                            {...item}
                            index={index}
                            isEditing={isEditing}
                            onEditingStatusChange={handleEditingChange}
                            onRemove={handleRemove}
                            onSubmit={handleSubmit}
                        />
                    );
                })}
            </Spin>
        </div>
    );
};

export default PracticeAddress;
