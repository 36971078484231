import React, { useCallback, useEffect, useMemo, useState } from 'react';
import s from './s.module.less';
import { transAddressDataToServiceOptions } from 'utils/common';
import ServiceItem from './components/ServiceItem';
import { IService, PracitceAddressForm } from 'types/practiceFront';
import { IServiceAddressUpdateInput, PracticeAddressUpdateType } from 'types/applicationForm';
import { getRemovementPopupProviderPracticeAddressList } from 'api/applicationForm';
import { getRemovementPopupProviderPracticeAddressList as getOpsRemovementPopupProviderPracticeAddressList } from 'api/operation';
import { message } from 'antd';

interface IProps {
    providerId?: number;
    isOps?: boolean;
    serviceList: Array<IService>;
    type: PracticeAddressUpdateType;
    onChange: (updateData: Array<IServiceAddressUpdateInput>) => void
}

const ServiceRemovement = ({
    providerId,
    isOps,
    serviceList = [],
    type = PracticeAddressUpdateType.UPDATE,
    onChange,
}: IProps) => {
    const [addressOptions, setAddressOptions] = useState<Array<{ value: number, label: string, isDefault: boolean }>>([]);
    const [updateData, setUpdateData] = useState<Array<IServiceAddressUpdateInput>>([]);
    const [addressList, setAddressList] = useState<Array<PracitceAddressForm>>([]);

    useEffect(() => {
        if (serviceList) {
            setUpdateData(serviceList.map((e:IService) => {
                return {
                    id: e.id as number,
                    addressId: e.addressId,
                };
            }));
        }
    }, [serviceList]);

    const getAddressList = useCallback(async () => {
        try {
            let res;
            if (isOps && providerId) {
                res = await getOpsRemovementPopupProviderPracticeAddressList(providerId);
            } else {
                res = await getRemovementPopupProviderPracticeAddressList();
            }

            if (!res.error) {
                setAddressList(res.data?.data);
            } else {
                message.error(res.error);
            }
        } catch (e) {
            message.error(e);
        }
    }, [isOps, providerId]);

    useEffect(() => {
        getAddressList();
    }, [getAddressList]);

    useEffect(() => {
        if (addressList.length > 0) {
            const addressResp = transAddressDataToServiceOptions(addressList);

            setAddressOptions(addressResp);
        }
    }, [addressList]);

    const handleUpdate = (serviceId: number, addressId: number) => {
        const newUpdateData = updateData.map((e:IServiceAddressUpdateInput) => {
            if (e.id === serviceId) {
                return {
                    id: e.id,
                    addressId,
                };
            }

            return e;
        });

        setUpdateData(newUpdateData);

        onChange(newUpdateData);
    };

    const titleRender = useMemo(() => {
        if (type === PracticeAddressUpdateType.REMOVE) {
            return `${serviceList.length} services using this address will be affected.`;
        }

        return `${serviceList.length} services will be affected from this updating`;
    }, [serviceList.length, type]);

    const descRender = useMemo(() => {
        if (type === PracticeAddressUpdateType.REMOVE) {
            return 'If you delete this address, any services currently linked to it will automatically switch to your default practice address. Please review and adjust the associated services\' addresses if necessary.';
        }

        return `<p>If you update an address, any services currently linked to it will automatically update. Please review and adjust the associated services\' addresses if necessary.</p>
         <p>If you didn't make an update, no service will be changed.</p>`;
    }, [type]);

    const serviceListRender = useMemo(() => {
        return serviceList.map((service, index) => {
            const { id, addressId } = service;

            return <ServiceItem type={type} key={id} index={index} addressId={addressId} addressList={addressOptions} data={service} onUpdate={handleUpdate} />;
        });
    }, [addressOptions, handleUpdate, serviceList, type]);

    return (
        <div className={s.wrap}>
            <div className={s.tip}>
                <div className={s.tipIcon} />
                <div className={s.tipContent}>
                    <div className={s.title}>{titleRender}</div>
                    <div className={s.text} dangerouslySetInnerHTML={{ __html: descRender }} />
                </div>
            </div>
            {serviceListRender}
        </div>
    );
};

export default ServiceRemovement;
