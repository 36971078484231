import React, { useEffect, useState } from 'react';
import { Form, Spin, Radio, DatePicker, Popover, InputNumber, Button, Input, message, Space } from 'antd';
import dayjs from 'dayjs';
import { formatDate } from 'utils/common';
import { Physician } from 'types/common';
import { FormPhysician } from 'types/form';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { providerPhysicianUpdate } from 'api/provider';
import { deletePhysician as deletePhysicianAdmin, opPhysicianUpdate } from 'api/operation';
import { deletePhysician } from 'api/channel';
import StateSelect from 'components/form/StateSelect';
import FileUpload from 'components/form/FileUpload';
import FormValidationHelper from 'utils/validation';
import { PhysicianData } from 'data/provider';
import LicenseImg from 'components/LicenseImg';
import { EMPTY_PLACEHOLDER, REQUIRED_FIELD } from 'constants/common';

type Props = {
    initValue?: FormPhysician[],
    state?: string,
    providerId?: number
    onChange?: (value: Partial<FormPhysician>[]) => void,
    viewOnly?: boolean,
    onNullState?: () => void,
    isOperation?: boolean,
    inited?: boolean,
    onEditableChange?: (editing: boolean) => void,
    isEditing?: boolean,
    refetch?: () => void,
};

export type FormMutiplePhysician = {
    physicians?: Partial<FormPhysician>[]
};

const CollaboratingPhysician = (props: Props) => {
    const { initValue, viewOnly, providerId, onChange, onEditableChange, isEditing, isOperation, refetch } = props;
    const [enable, setEnable] = React.useState(false);
    const [, forceUpdate] = React.useState({});
    const [form] = Form.useForm<FormMutiplePhysician>();
    const [submiting, setSubmiting] = React.useState(false);
    const [pendingRemove, setPendingRemove] = React.useState<number[]>([]);

    useEffect(() => {
        setEnable(!!isEditing)
    }, [isEditing])

    const handleRemove = async () => {
        let deleteApi = deletePhysician;
        if (isOperation) {
            deleteApi = deletePhysicianAdmin;
        }

        const requests = pendingRemove.map((id) => deleteApi(id));

        try {
            const res = await Promise.all(requests);

            if (res.every((e) => !e.error)) {
                setPendingRemove([]);
            } else {
                message.error('Some data was not deleted successfully. Please try again');
            }
        } catch (e) {
            console.error(e);
        }
    };

    const onRemove = async (index: number, removeCallback: () => void) => {
        const filed = form.getFieldValue('physicians')?.[index];

        if (filed && filed.id) {
            const newPendingRemove = Array.from(new Set([
                ...pendingRemove,
                filed.id,
            ]));
            setPendingRemove(newPendingRemove);
        }

        removeCallback?.();
    };

    const onSubmit = async () => {
        try {
            setSubmiting(true);
            await handleRemove();
            const formValues = await form.validateFields();

            const physiciansArrary = formValues.physicians || [];

            const addData: Partial<FormPhysician>[] = [];
            for (let i = 0; i < physiciansArrary.length; i++) {
                const data = physiciansArrary[i];

                const physicianData: Partial<Physician> = `${data.choice}` === '3' ? {
                    id: data.id,
                    address: data.address,
                    effectiveDate: data?.effectiveDate ? formatDate(data.effectiveDate) : '',
                    email: data.email,
                    expDate: data.expDate ? formatDate(data.expDate) : '',
                    birthdayDate: data.birthdayDate ? formatDate(data.birthdayDate) : '',
                    licenseState: data.licenseState,
                    deaNumber: data.deaNumber,
                    name: data.name,
                    npi: data.npi,
                    choice: data.choice,
                    physicianState: data.physicianState,
                    specialities: data.specialities,
                    state: data.state,
                    tel: data.tel,
                    url: data.url,
                    yearExp: data.yearExp,
                    zip: data.zip,
                } : {
                    id: data.id,
                    state: data.state,
                    choice: data.choice,
                    email: '',
                };

                const fn = isOperation ? opPhysicianUpdate : providerPhysicianUpdate;
                // eslint-disable-next-line no-await-in-loop
                const result = await fn(physicianData, providerId!);
                if (result.error) {
                    message.error(result.error);
                    return;
                } else {
                    addData.push(PhysicianData.serverToForm(physicianData as Physician));
                }
            }
            message.success('Collaborating physician info has updated');
            onEditableChange?.(false);
            setEnable(false);
            if (onChange) {
                onChange(addData);
            }
            refetch?.();
        } catch (e: any) {
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };

    React.useEffect(() => {
        if (initValue && initValue?.length > 0) {
            form.setFieldsValue({ physicians: initValue });
            forceUpdate({});
        }
    }, [initValue]);

    const physicianArray: FormPhysician[] = form.getFieldValue('physicians')?.filter((item:FormPhysician) => !!(item && item.state));

    const hasValue = !!(physicianArray?.length! > 0);
    return (
        <div className={s.wrap}>
            <Form
                form={form}
                name="basic"
                className={commonS.formStyle1}
                initialValues={{ remember: true }}
                autoComplete="off"
                layout="vertical"
            >
                <Spin spinning={submiting}>
                    <div className={s.module}>
                        <h3>
                            <div className={s.left}>
                                <span>Collaborating Physician</span>
                            </div>
                            {
                                !viewOnly && !enable &&
                                <Button
                                    size="small"
                                    onClick={() => {
                                        onEditableChange?.(true);
                                        setEnable(true);
                                    }}
                                >
                                    Edit
                                </Button>
                            }
                            {
                                enable && !viewOnly &&
                                <div className={s.btnWrap}>
                                    <Button
                                        type="primary"
                                        size="small"
                                        onClick={onSubmit}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        size="small"
                                        onClick={() => {
                                            form.setFieldsValue({ physicians: initValue && initValue?.length > 0 ? initValue : [{}] });
                                            onEditableChange?.(false);
                                            setEnable(false);
                                        }}
                                        style={{ marginLeft: 16 }}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            }
                        </h3>

                        {
                            enable &&
                            <>
                                {/* <Form.Item
                                    label="Collaborating physician needed"
                                    name="collaboratingPhysicianNeeded"
                                    rules={[{ required: true, message: 'This field is required' }]}
                                >
                                    <Radio.Group>
                                        <Space direction="vertical">
                                            <Radio value={1}>Yes, I have collaborating physicians for my practice</Radio>
                                            <Radio value={2}>No, I don’t need one for my practice.</Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item> */}
                                <Form.List name="physicians" initialValue={[{}]}>
                                    {(fields, { add, remove }) => {
                                        const { length } = fields;
                                        return (
                                            <div id="physicians">
                                                {fields.map((field) => {
                                                    const showMore = form.getFieldValue(['physicians', field.name, 'choice']) === 3;
                                                    const currentBitrhday = form.getFieldValue(['physicians', field.name, 'birthdayDate']);
                                                    return (
                                                        <Form.Item
                                                            key={field.key}
                                                            noStyle
                                                        >
                                                            <div className={s.subModule}>
                                                                <div className={s.row}>
                                                                    <div className={s.rowItem}>
                                                                        <Form.Item
                                                                            style={{ width: '360px' }}
                                                                            label="State"
                                                                            name={[field.name, 'state']}
                                                                            validateFirst
                                                                            rules={[
                                                                                { required: true, message: 'State is required' },
                                                                            ]}
                                                                        >
                                                                            <StateSelect />
                                                                        </Form.Item>
                                                                    </div>
                                                                </div>
                                                                <div className={s.row}>
                                                                    <div className={s.rowItem}>
                                                                        <Form.Item
                                                                            style={{ width: '500px' }}
                                                                            label=""
                                                                            name={[field.name, 'choice']}
                                                                            rules={[{ required: true, message: 'Field is required' }]}
                                                                        >
                                                                            <Radio.Group onChange={() => { forceUpdate({}); }}>
                                                                                <Radio value={1}>No, it’s not requried in this state.</Radio>
                                                                                {/* <Radio value={2}>No, I will need a collabrating physician in the future.</Radio> */}
                                                                                <Radio value={3}>Yes, I have a collaborating physician.</Radio>
                                                                            </Radio.Group>
                                                                        </Form.Item>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    showMore &&
                                                                    <div className={s.addModle}>
                                                                        <h4>Please add information of your collaborating physician:</h4>
                                                                        <div className={s.row}>
                                                                            <div className={s.rowItem}>
                                                                                <Form.Item
                                                                                    style={{ width: '320px' }}
                                                                                    label="Physician’s name"
                                                                                    name={[field.name, 'name']}
                                                                                    rules={[{ required: true, message: 'Physician name is required' }]}
                                                                                >
                                                                                    <Input placeholder="Collaborating physician’s name" />
                                                                                </Form.Item>
                                                                            </div>
                                                                            <div className={s.rowItem}>
                                                                                <Form.Item
                                                                                    style={{ width: '180px' }}
                                                                                    label="Practice phone number"
                                                                                    name={[field.name, 'tel']}
                                                                                    rules={[
                                                                                        {
                                                                                            required: true, message: 'Practice phone number is required',
                                                                                        },
                                                                                        FormValidationHelper.validatePhoneNumber('Phone number must contain only number or -'),
                                                                                    ]}
                                                                                >
                                                                                    <Input maxLength={30} />
                                                                                </Form.Item>
                                                                            </div>
                                                                            <div className={s.rowItem}>
                                                                                <Form.Item
                                                                                    style={{ width: '200px' }}
                                                                                    label="Physician’s email"
                                                                                    name={[field.name, 'email']}
                                                                                    rules={[{ required: true, message: 'Physician’s email is required' }]}
                                                                                >
                                                                                    <Input />
                                                                                </Form.Item>
                                                                            </div>
                                                                        </div>
                                                                        <div className={s.row}>
                                                                            <div className={s.rowItem}>
                                                                                <Form.Item
                                                                                    style={{ width: '320px' }}
                                                                                    label="Physician’s address"
                                                                                    name={[field.name, 'address']}
                                                                                    rules={[{ required: true, message: 'Address is required' }]}
                                                                                >
                                                                                    <Input />
                                                                                </Form.Item>
                                                                            </div>
                                                                            <div className={s.rowItem}>
                                                                                <Form.Item
                                                                                    style={{ width: '180px' }}
                                                                                    label="State"
                                                                                    name={[field.name, 'physicianState']}
                                                                                    rules={[{ required: true, message: 'State is required' }]}
                                                                                >
                                                                                    <StateSelect />
                                                                                </Form.Item>
                                                                            </div>
                                                                            <div className={s.rowItem}>
                                                                                <Form.Item
                                                                                    style={{ width: '200px' }}
                                                                                    label="ZIP code"
                                                                                    name={[field.name, 'zip']}
                                                                                    rules={[{ required: true, message: 'ZIP code is required' }]}
                                                                                >
                                                                                    <Input />
                                                                                </Form.Item>
                                                                            </div>
                                                                        </div>
                                                                        <div className={s.row}>
                                                                            <div className={s.rowItem}>
                                                                                <Form.Item
                                                                                    style={{ width: '200px' }}
                                                                                    label="Date of Birth"
                                                                                    name={[field.name, 'birthdayDate']}
                                                                                >
                                                                                    <DatePicker
                                                                                        disabledDate={(current) => {
                                                                                            const day18Ago = dayjs().subtract(18, 'year');
                                                                                            return current.valueOf() > day18Ago.valueOf();
                                                                                        }}
                                                                                        defaultPickerValue={currentBitrhday ? undefined : dayjs().subtract(18, 'year')}
                                                                                        style={{ width: '100%' }}
                                                                                    />
                                                                                </Form.Item>
                                                                            </div>
                                                                        </div>
                                                                        <div className={s.row}>
                                                                            <div className={s.rowItem}>
                                                                                <Form.Item
                                                                                    style={{ width: '320px' }}
                                                                                    label="Physician’s NPI number"
                                                                                    name={[field.name, 'npi']}
                                                                                    validateFirst
                                                                                    rules={[
                                                                                        {
                                                                                            required: true, message: 'NPI is required',
                                                                                        },
                                                                                        FormValidationHelper.validateLicenseNumber('The NPI must contain only letters or numbers'),
                                                                                    ]}
                                                                                >
                                                                                    <Input />
                                                                                </Form.Item>
                                                                            </div>
                                                                            <div className={s.rowItem}>
                                                                                <Form.Item
                                                                                    style={{ width: '180px' }}
                                                                                    label="Licensed states"
                                                                                    name={[field.name, 'licenseState']}
                                                                                    rules={[{ required: true, message: 'License state is required' }]}
                                                                                >
                                                                                    <StateSelect />
                                                                                </Form.Item>
                                                                            </div>
                                                                            <div className={s.rowItem}>
                                                                                <Form.Item
                                                                                    style={{ width: '200px' }}
                                                                                    label="Physician’s specialities"
                                                                                    name={[field.name, 'specialities']}
                                                                                    rules={[{ required: true, message: 'Specialities is required' }]}
                                                                                >
                                                                                    <Input placeholder="Specialities and seperate with “,”" />
                                                                                </Form.Item>
                                                                            </div>
                                                                        </div>
                                                                        <div className={s.row}>
                                                                            <div className={s.rowItem}>
                                                                                <Form.Item
                                                                                    style={{ width: '320px' }}
                                                                                    label="Effective date of the agreement"
                                                                                    name={[field.name, 'effectiveDate']}
                                                                                    rules={[{ required: true, message: 'Effective date is required' }]}
                                                                                >
                                                                                    <DatePicker style={{ width: '100%' }} />
                                                                                </Form.Item>
                                                                            </div>
                                                                            <div className={s.rowItem}>
                                                                                <Form.Item
                                                                                    style={{ width: '320px' }}
                                                                                    label="Expiration date of the agreement"
                                                                                    name={[field.name, 'expDate']}
                                                                                >
                                                                                    <DatePicker
                                                                                        disabledDate={(date) => {
                                                                                            const now = (new Date()).getTime();
                                                                                            if (!date) {
                                                                                                return false;
                                                                                            }
                                                                                            return date?.valueOf() < now;
                                                                                        }}
                                                                                        style={{ width: '100%' }}
                                                                                    />
                                                                                </Form.Item>
                                                                            </div>
                                                                        </div>
                                                                        <div className={s.row}>
                                                                            <div className={s.rowItem}>
                                                                                <Form.Item
                                                                                    style={{ width: '500px' }}
                                                                                    label="Agreement upload"
                                                                                    name={[field.name, 'url']}
                                                                                    rules={[{ required: true, message: 'Agreement is required' }]}
                                                                                >
                                                                                    <FileUpload />
                                                                                </Form.Item>
                                                                            </div>
                                                                        </div>
                                                                        <div className={s.row}>
                                                                            <div className={s.rowItem}>
                                                                                <Form.Item
                                                                                    style={{ width: '500px' }}
                                                                                    label={
                                                                                        <div className={s.labelTitle}>
                                                                                            Collaborating physician’s DEA number
                                                                                            <Popover
                                                                                                placement="bottomLeft"
                                                                                                content={<div className={s.toolWrap}>A DEA number is required for your collaborating physician to prescribe controlled medicines in Kiwi EHR when you purchase e-Prescription + EPCS for them. You can add the DEA number here or later in your UniProfile under Collaborating Physicians.</div>}
                                                                                                trigger="hover"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                                                                                    <path d="M5.99582 6.98047C6.36261 6.20216 7.35231 5.64453 8.51566 5.64453C9.9913 5.64453 11.1875 6.54171 11.1875 7.64844C11.1875 8.58322 10.3341 9.36851 9.17977 9.58995C8.81747 9.65946 8.51566 9.9514 8.51566 10.3203M8.51562 12.3242H8.5223M14.5273 8.98438C14.5273 12.3046 11.8358 14.9961 8.51562 14.9961C5.19544 14.9961 2.50391 12.3046 2.50391 8.98438C2.50391 5.66419 5.19544 2.97266 8.51562 2.97266C11.8358 2.97266 14.5273 5.66419 14.5273 8.98438Z" stroke="#6B7280" strokeWidth="1.33594" strokeLinecap="round" strokeLinejoin="round" />
                                                                                                </svg>
                                                                                            </Popover>
                                                                                        </div>
                                                                                    }
                                                                                    name={[field.name, 'deaNumber']}
                                                                                    validateFirst
                                                                                >
                                                                                    <Input />
                                                                                </Form.Item>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {field.name !== 0 &&
                                                                    <div className={s.removeWrap}>
                                                                        <Button
                                                                            onClick={() => {
                                                                                onRemove(field.name, () => remove(field.name));
                                                                            }}
                                                                            danger
                                                                            size="small"
                                                                        >Remove
                                                                        </Button>
                                                                    </div>}
                                                                {field.name === length - 1 &&
                                                                    <div
                                                                        onClick={() => {
                                                                            add();
                                                                            const wrap = document.getElementById('physicians');
                                                                            setTimeout(() => {
                                                                                wrap?.parentElement?.parentElement?.scroll({
                                                                                    top: 1000000,
                                                                                    behavior: 'smooth',
                                                                                });
                                                                            }, 200);
                                                                        }}
                                                                        className={s.add}
                                                                    >
                                                                        + Collaborating physician
                                                                    </div>
                                                                }
                                                            </div>
                                                        </Form.Item>);
                                                })}
                                            </div>
                                        );
                                    }}
                                </Form.List>
                            </>
                        }
                        {
                            !enable && hasValue &&
                            <>
                                {
                                    physicianArray?.map((item: FormPhysician) => {
                                        return (
                                            <div className={s.subModule} key={`${item.state}_${item.choice}`}>
                                                <div className={s.row}>
                                                    <div
                                                        style={{
                                                            width: '300px',
                                                        }}
                                                        className={s.displayItem}
                                                    >
                                                        <div className={s.label}>State</div>
                                                        <div className={s.value}>{item.state}</div>
                                                    </div>
                                                </div>
                                                <div className={s.row}>
                                                    <div
                                                        className={s.displayItem}
                                                    >
                                                        <div className={s.label}>Do you have a collaborating physician now?</div>
                                                        <div className={s.value}>
                                                            {`${item.choice}` === '1' && 'No, it’s not required in this state.'}
                                                            {`${item.choice}` === '2' && 'No, I will need a collabrating physician in the future.'}
                                                            {`${item.choice}` === '3' && 'Yes, I have a collaborating physician'}
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    `${item.choice}` === '3' &&
                                                    <>
                                                        <div className={s.row}>
                                                            <div
                                                                style={{
                                                                    width: '300px',
                                                                }}
                                                                className={s.displayItem}
                                                            >
                                                                <div className={s.label}>Physician’s name</div>
                                                                <div className={s.value}>{item.name}</div>
                                                            </div>

                                                            <div
                                                                style={{
                                                                    width: '300px',
                                                                }}
                                                                className={s.displayItem}
                                                            >
                                                                <div className={s.label}>Personal phone number</div>
                                                                <div className={s.value}>{item.tel}</div>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    width: '300px',
                                                                }}
                                                                className={s.displayItem}
                                                            >
                                                                <div className={s.label}>Email</div>
                                                                <div className={s.value}>{item.email}</div>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                style={{
                                                                    width: '300px',
                                                                }}
                                                                className={s.displayItem}
                                                            >
                                                                <div className={s.label}>Physician’s address</div>
                                                                <div className={s.value}>{item.address}</div>
                                                            </div>

                                                            <div
                                                                style={{
                                                                    width: '300px',
                                                                }}
                                                                className={s.displayItem}
                                                            >
                                                                <div className={s.label}>ZIP code</div>
                                                                <div className={s.value}>{item.zip}</div>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                style={{
                                                                    width: '300px',
                                                                }}
                                                                className={s.displayItem}
                                                            >
                                                                <div className={s.label}>Date of Birth</div>
                                                                <div className={s.value}>{item.birthdayDate ? formatDate(item.birthdayDate) : ''}</div>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                style={{
                                                                    width: '300px',
                                                                }}
                                                                className={s.displayItem}
                                                            >
                                                                <div className={s.label}>Physician’s NPI number</div>
                                                                <div className={s.value}>{item.npi}</div>
                                                            </div>

                                                            <div
                                                                style={{
                                                                    width: '300px',
                                                                }}
                                                                className={s.displayItem}
                                                            >
                                                                <div className={s.label}>Licensed states</div>
                                                                <div className={s.value}>{item.licenseState}</div>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    width: '180px',
                                                                }}
                                                                className={s.displayItem}
                                                            >
                                                                <div className={s.label}>Physician’s specialities</div>
                                                                <div className={s.value}>{item.specialities}</div>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                style={{
                                                                    width: '300px',
                                                                }}
                                                                className={s.displayItem}
                                                            >
                                                                <div className={s.label}>Effective date of the agreement</div>
                                                                <div className={s.value}>{item.effectiveDate ? formatDate(item.effectiveDate) : ''}</div>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    width: '300px',
                                                                }}
                                                                className={s.displayItem}
                                                            >
                                                                <div className={s.label}>Expiration date of the agreement</div>
                                                                <div className={s.value}>{item.expDate ? formatDate(item.expDate) : ''} </div>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                style={{
                                                                    width: '80%',
                                                                }}
                                                                className={s.displayItem}
                                                            >
                                                                <div className={s.label}>Uploaded license</div>
                                                                <div className={s.value}>
                                                                    <LicenseImg src={item.url} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                style={{
                                                                    width: '80%',
                                                                }}
                                                                className={s.displayItem}
                                                            >
                                                                <div className={s.label}>
                                                                    Collaborating physician’s DEA number
                                                                    <Popover
                                                                        placement="bottomLeft"
                                                                        content={<div className={s.toolWrap}>A DEA number is required for your collaborating physician to prescribe controlled medicines in Kiwi EHR when you purchase e-Prescription + EPCS for them. You can add the DEA number here or later in your UniProfile under Collaborating Physicians.</div>}
                                                                        trigger="hover"
                                                                    >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                                                            <path d="M5.99582 6.98047C6.36261 6.20216 7.35231 5.64453 8.51566 5.64453C9.9913 5.64453 11.1875 6.54171 11.1875 7.64844C11.1875 8.58322 10.3341 9.36851 9.17977 9.58995C8.81747 9.65946 8.51566 9.9514 8.51566 10.3203M8.51562 12.3242H8.5223M14.5273 8.98438C14.5273 12.3046 11.8358 14.9961 8.51562 14.9961C5.19544 14.9961 2.50391 12.3046 2.50391 8.98438C2.50391 5.66419 5.19544 2.97266 8.51562 2.97266C11.8358 2.97266 14.5273 5.66419 14.5273 8.98438Z" stroke="#6B7280" strokeWidth="1.33594" strokeLinecap="round" strokeLinejoin="round" />
                                                                        </svg>
                                                                    </Popover>
                                                                </div>
                                                                <div className={s.value}>{ item.deaNumber}</div>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        );
                                    })
                                }

                            </>
                        }
                        {

                            !enable && !hasValue &&
                                <div>
                                    {EMPTY_PLACEHOLDER}
                                </div>

                        }
                    </div>
                </Spin>
            </Form>

        </div>
    );
};

export default CollaboratingPhysician;
