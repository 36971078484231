// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--NnwcW {
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  height: 100%;
}
.s-module__wrap--NnwcW img {
  max-width: 280px;
  width: 86%;
  margin-top: 48px;
  margin-bottom: 32px;
}
.s-module__wrap--NnwcW h2 {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  color: var(--gray-900);
  line-height: 48px;
  flex-shrink: 0;
  margin-bottom: 16px;
}
.s-module__wrap--NnwcW .s-module__des--YMohw {
  color: var(--gray-500);
  font-size: 18px;
  line-height: 30px;
  width: 90%;
  max-width: 800px;
  text-align: center;
  margin-bottom: 24px;
}
.s-module__wrap--NnwcW .s-module__content--oC5jy {
  flex-grow: 1;
  overflow-y: scroll;
}
`, "",{"version":3,"sources":["webpack://./src/components/SubscriptionSuccess/s.module.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EAEA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,YAAA;AACJ;AATA;EAUQ,gBAAA;EACA,UAAA;EACA,gBAAA;EACA,mBAAA;AAER;AAfA;EAgBQ,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;AAER;AAxBA;EAyBQ,sBAAA;EACA,eAAA;EACA,iBAAA;EACA,UAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;AAER;AAjCA;EAkCQ,YAAA;EACA,kBAAA;AAER","sourcesContent":[".wrap {\n    background: #fff;\n    padding: 16px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding: 32px;\n    height: 100%;\n    img {\n        max-width: 280px;\n        width: 86%;\n        margin-top: 48px;\n        margin-bottom: 32px;\n    }\n    h2 {\n        text-align: center;\n        font-size: 32px;\n        font-weight: bold;\n        color: var(--gray-900);\n        line-height: 48px;\n        flex-shrink: 0;\n        margin-bottom: 16px;\n    }\n    .des {\n        color: var(--gray-500);\n        font-size: 18px;\n        line-height: 30px;\n        width: 90%;\n        max-width: 800px;\n        text-align: center;\n        margin-bottom: 24px;\n    }\n    .content {\n        flex-grow: 1;\n        overflow-y: scroll;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--NnwcW`,
	"des": `s-module__des--YMohw`,
	"content": `s-module__content--oC5jy`
};
export default ___CSS_LOADER_EXPORT___;
