import React from 'react';
import { Button, Input, Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import useSettings from 'hooks/useSettings';
import { EnumFields } from 'types/enumerationData';
import { PlusOutlined } from '@ant-design/icons';
import s from './s.module.less';
import { Meta } from 'types/common';

type ChannelTagSelectProps = {
    value?: string[];
    onChange?: (val: string[]) => void,
    disableValues?:string[]
} & SelectProps;

const ChannelTagSelect = (props:ChannelTagSelectProps) => {
    const { onChange, disableValues, value, ...reset } = props;
    const [setting, loading] = useSettings();
    const [pendingVal, setPendingVal] = React.useState<string>();
    const [options, setOptions] = React.useState<Meta[]>([]);
    React.useEffect(() => {
        if (setting && setting.CHANNEL_TAG) {
            const defaultOption = setting[EnumFields.CHANNEL_TAG] || [];
            setOptions(defaultOption);
        }
    }, [setting]);

    return (
        <Select
            mode="tags"
            style={{ width: '100%' }}
            placeholder="Tags Mode"
            onChange={onChange}
            {...reset}
            loading={loading}
            value={value}
            dropdownRender={(menu) => (
                <>
                    {menu}
                    <div className={s.addWrap}>
                        <Input
                            placeholder="Ex. On promotion"
                            value={pendingVal}
                            style={{ width: '180px' }}
                            onChange={(e) => {
                                setPendingVal(e.target.value);
                            }}
                        />
                        <Button
                            type="text"
                            className={s.btn}
                            icon={<PlusOutlined />}
                            onClick={() => {
                                if (pendingVal) {
                                    const find = options?.find((item) => item.value === pendingVal);
                                    if (!find) {
                                        const newOptions:Meta[] = [...options, { value: pendingVal, label: pendingVal }];
                                        const newVal:string[] = value ? [...value, pendingVal] : [pendingVal];
                                        setPendingVal(undefined);
                                        setOptions(newOptions);
                                        onChange?.(newVal);
                                    }
                                }
                            }}
                        >
                            Add item
                        </Button>
                    </div>
                </>
            )}
        >
            {
                options.map((meta:Meta) => {
                    return (
                        <Select.Option key={meta.value}>{meta.label}</Select.Option>
                    );
                })
            }

        </Select>
    );
};

export default ChannelTagSelect;
