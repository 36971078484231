import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import useSettings from 'hooks/useSettings';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { EnumFields } from 'types/enumerationData';
import dayjs from 'dayjs';
import { Meta } from 'types/common';
import { RecurringType } from 'types/calendar';

type RepeatsSelectProps = {
    value?: string;
    onChange?: (val: string) => void,
    day?:dayjs.Dayjs,
} & SelectProps;

const RepeatsSelect = (props:RepeatsSelectProps) => {
    const { onChange, day, ...reset } = props;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [setting, loading] = useSettings();
    let options = [
        {
            label: 'Does not repeat',
            value: RecurringType.NOT_REPEAT,
        },
        {
            label: 'Daily',
            value: RecurringType.DAILY,
        },
        {
            label: 'Weekly', //Weekly on Friday
            value: RecurringType.WEEKDAY,
        },
        {
            label: 'Monthly', //Monthly on the first Friday
            value: RecurringType.MONTHLY_ON_DATE,
        },
        {
            label: 'Every weekday (Monday to Friday)',
            value: RecurringType.WEEKDAY,
        },
    ];
    if (day) {
        options = [
            {
                label: 'Does not repeat',
                value: RecurringType.NOT_REPEAT,
            },
            {
                label: 'Daily',
                value: RecurringType.DAILY,
            },
            {
                label: `Weekly on ${day.format('dddd')}`, //Weekly on Friday
                value: RecurringType.WEEKDAY,
            },
            {
                label: `Monthly on the first ${day.format('dddd')}`, //Monthly on the first Friday
                value: RecurringType.MONTHLY_ON_FIRST_WEEKDAY,
            },
            {
                label: `Monthly on the ${day.format('DD')}`, //Monthly on the 6th
                value: RecurringType.MONTHLY_ON_DATE,
            },
            {
                label: 'Every weekday (Monday to Friday)',
                value: RecurringType.WEEKDAY,
            },
        ];
    }

    return (
        <Select {...reset} onChange={onChange} loading={loading}>
            {
                options.map((option:Meta) => {
                    return (
                        <Select.Option
                            key={option.value}
                            value={option.value}
                        >
                            {option.label}
                        </Select.Option>
                    );
                })
            }
        </Select>
    );
};

export default RepeatsSelect;
