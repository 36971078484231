import { Button, Modal, Upload, message } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PhotoEditor from 'components/PhotoEditor';
import { uploadFile } from 'api/common';
import { selectProviderChannelPhoto } from 'api/applicationForm';
import { selectProviderChannelPhoto as opsSelectProviderChannelPhoto } from 'api/operation';
import s from './s.module.less';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadChangeParam } from 'antd/es/upload';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import { FIRST_TIME_UPLOAD_PROFILE_PHOTO } from 'constants/common';
import { Email } from 'constants/contact';

interface IProps {
    isNotInUniprofile?: boolean;
    mode?: 'popup' | 'inner';
    borderRadius?: boolean;
    isOps?: boolean;
    providerId: number;
    value?: string;
    title?: string;
    okText?: string;
    accept?: string;
    onChange?: (imageUrl: string) => void;
}

const PhotoEditorModal = ({
    isNotInUniprofile,
    mode = 'popup',
    borderRadius,
    isOps,
    providerId,
    value = '',
    title,
    okText = 'Save',
    accept = 'image/*',
    onChange,
}: IProps) => {
    const photoEditorRef = useRef();
    const fileInfoRef = React.useRef<UploadChangeParam<UploadFile>>();
    const [imageSrc, setImageSrc] = useState('');
    const [showPhotoEditModal, setShowPhotoEditModal] = useState(false);
    const [showPhotoSelectModal, setShowPhotoSelectModal] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const [showProfilePhotoTip, setShowProfilePhotoTip] = React.useState(false);
    const [isFirstTimeUploadProfilePhoto, setIsFirstTimeUploadProfilePhoto] = React.useState(false);

    useEffect(() => {
        setImageSrc(value);
    }, [value]);

    useEffect(() => {
        if (!isNotInUniprofile && !isOps) {
            setIsFirstTimeUploadProfilePhoto(!localStorage.getItem(FIRST_TIME_UPLOAD_PROFILE_PHOTO));
        }
    }, [isNotInUniprofile]);

    const handleHideAvatarUploadModal = useCallback(() => {
        setShowPhotoSelectModal(false);
    }, []);
    const handleShowAvatarEditdModal = useCallback(() => {
        handleHideAvatarUploadModal();
        setShowPhotoEditModal(true);
    }, [handleHideAvatarUploadModal]);

    const handleShowAvatarUploadModal = useCallback(() => {
        setShowPhotoSelectModal(true);
    }, []);

    const onCancel = useCallback(() => {
        setShowPhotoEditModal(false);
        if (isFirstTimeUploadProfilePhoto) {
            setShowProfilePhotoTip(true);
        }
    }, [isFirstTimeUploadProfilePhoto]);

    const handleChange: UploadProps['onChange'] = useCallback((info: UploadChangeParam<UploadFile>) => {
        fileInfoRef.current = info;
        if (info.file.status === 'uploading') {
            setLoading(true);
        }
    }, []);

    const props: UploadProps = useMemo(() => (
        {
            name: 'file',
            multiple: false,
            onChange: handleChange,
            accept,
            showUploadList: false,
            customRequest: async (data) => {
                // const hasImage = !!imageSrc;

                try {
                    const formData = new FormData();

                    formData.append('file', data.file);
                    const res = await uploadFile(formData);
                    if (!res.error) {
                        if (fileInfoRef.current) {
                            fileInfoRef.current.file.status = 'done';
                        }

                        if (typeof onChange === 'function') {
                            setImageSrc(res.data?.data);
                            onChange(res.data?.data);
                            setShowProfilePhotoTip(false);

                            // if (!hasImage) {
                                handleShowAvatarEditdModal();
                            // }
                        }
                    }
                } catch (e) {
                    console.error(e);
                } finally {
                    setLoading(false);
                }
            },
        }
    ), [handleChange, accept, imageSrc, onChange, handleShowAvatarEditdModal]);

    const handleSaveAvatar = useCallback(async (avatarSrc: string) => {
        let res;

        if (isOps) {
            res = await opsSelectProviderChannelPhoto({
                providerId,
                selectedPhoto: avatarSrc || '',
            });
        } else {
            res = await selectProviderChannelPhoto({
                photo: avatarSrc || '',
            });
        }

        if (res && !res.error) {
            setShowPhotoEditModal(false);
            setImageSrc(avatarSrc);
            if (typeof onChange === 'function') {
                onChange(avatarSrc);
            }
            if (!isNotInUniprofile) {
                localStorage.setItem(FIRST_TIME_UPLOAD_PROFILE_PHOTO, 'false');
            }
        } else {
            console.error(res);
        }
    }, [isOps, onChange, providerId]);
    const handleUpload = useCallback(async (file: File) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('file', file, `${providerId}.png`);
        const res = await uploadFile(formData);

        setLoading(false);

        if (res.error) {
            message.error(res.error);
        } else {
            handleSaveAvatar(res?.data?.data);
        }
    }, [handleSaveAvatar, providerId]);
    const handleSave = useCallback(async () => {
        setLoading(true);
        try {
            const imageFile = await photoEditorRef.current?.getImage();
            if (imageFile) {
                imageFile.toBlob((blob) => {
                    handleUpload(blob);
                });
            }
        } catch (e) {
            console.error(e);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 800);
        }
    }, [handleUpload]);

    const editContentRedner = useMemo(() => {
        return (
            <div className={s.previewBox}>
                <img className={s.previewImage} style={borderRadius ? {} : { borderRadius: 0 }} src={imageSrc} />
                <div className={s.btns}>
                    <Button className={s.showEditModalBtn} onClick={handleShowAvatarEditdModal}>Edit</Button>
                    <Upload {...props}>
                        <Button loading={loading} icon={<UploadOutlined />}>Change</Button>
                    </Upload>
                </div>
            </div>
        );
    }, [borderRadius, handleShowAvatarEditdModal, imageSrc, loading, props]);

    const uploadButtenRender = useMemo(() => {
        return isFirstTimeUploadProfilePhoto ? (
            <Button onClick={() => setShowProfilePhotoTip(true)}>Upload</Button>
        ) : (
            <Upload {...props}>
                <Button loading={loading} icon={<UploadOutlined />}>Upload</Button>
            </Upload>
        );
    }, [isFirstTimeUploadProfilePhoto, loading, props]);

    return (
        <div className={s.wrap}>
            <Modal
                width={800}
                title="Profile photo"
                centered
                open={showPhotoEditModal}
                okText={okText}
                onOk={handleSave}
                cancelText={isFirstTimeUploadProfilePhoto ? 'Back' : 'Cancel'}
                onCancel={onCancel}
                confirmLoading={loading}
                destroyOnClose
            >
                <PhotoEditor borderRadius={borderRadius} width={200} height={200} ref={photoEditorRef} imageSrc={imageSrc} />
            </Modal>
            <Modal
                width={800}
                open={showProfilePhotoTip}
                title='Profile photo'
                onCancel={() => setShowProfilePhotoTip(false)}
                footer={
                    <div className={s.profilePhotoTipFooterButtons}>
                        <Button onClick={() => setShowProfilePhotoTip(false)}>Cancel</Button>
                        <Upload {...props}>
                            <Button type='primary' loading={loading}>Upload</Button>
                        </Upload>
                    </div>
                }
            >
                <div className={s.profilePhotoTip}>
                    <div className={s.profilePhotoTipTitle}>Upload a profile photo to make a great first impression on patients</div>
                    <div className={s.profilePhotoTipList}>
                        <div className={s.tipItem}>
                            <div className={`${s.tipIcon} ${s.tipIcon1}`}></div>
                            <div className={s.tipContent}>
                                <div className={s.tipTitle}>Present yourself professionally</div>
                                <div className={s.tipText}>Choose a photo where you're dressed in attire that reflects your profession and conveys a sense of competence and trustworthiness.</div>
                            </div>
                        </div>
                        <div className={s.tipItem}>
                            <div className={`${s.tipIcon} ${s.tipIcon2}`}></div>
                            <div className={s.tipContent}>
                                <div className={s.tipTitle}>Show your friendliness</div>
                                <div className={s.tipText}>A warm smile and friendly expression can make a big difference in helping patients feel comfortable and at ease.</div>
                            </div>
                        </div>
                        <div className={s.tipItem}>
                            <div className={`${s.tipIcon} ${s.tipIcon3}`}></div>
                            <div className={s.tipContent}>
                                <div className={s.tipTitle}>Use a simple background</div>
                                <div className={s.tipText}>Avoid cluttered or distracting backgrounds that take the focus away from you. A plain wall or a natural setting works well.</div>
                            </div>
                        </div>
                        <div className={s.tipItem}>
                            <div className={`${s.tipIcon} ${s.tipIcon4}`}></div>
                            <div className={s.tipContent}>
                                <div className={s.tipTitle}>Upload a high quality image</div>
                                <div className={s.tipText}>Make sure your photo is clear and well-lit, with no blurriness or pixelation. A high-quality image reflects professionalism and attention to detail.</div>
                            </div>
                        </div>
                        <div className={s.tipItem}>
                            <div className={`${s.tipIcon} ${s.tipIcon5}`}></div>
                            <div className={s.tipContent}>
                                <div className={s.tipTitle}>Need professional help?</div>
                                <div className={s.tipText}>Reach out to <a href={`mailto:${Email.support}`}>{Email.support}</a> if you need guidance with what images to use.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            {
                mode === 'popup' ? (
                    <>
                        <Modal
                            width={800}
                            title={title}
                            centered
                            open={showPhotoSelectModal}
                            onCancel={handleHideAvatarUploadModal}
                            footer={null}
                        >
                            {editContentRedner}
                        </Modal>
                        <div className={s.profileAvatar}>
                            <img className={s.avatar} src={imageSrc} />
                            {
                                imageSrc ?
                                    <Button type="default" onClick={handleShowAvatarUploadModal}>Change</Button> :
                                    uploadButtenRender
                            }
                        </div>
                    </>
                ) : editContentRedner
            }
        </div>
    );
};

export default PhotoEditorModal;
