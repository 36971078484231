// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--duNLS h2 {
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
}
.s-module__wrap--duNLS .s-module__subTitle--LH_Ib {
  color: var(--gray-600);
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin-top: 8px;
}
.s-module__wrap--duNLS .s-module__contentInner--CkDAU {
  background: var(--gray-50);
  padding: 28px 28px 1px 28px;
  margin-top: 16px;
}
.s-module__wrap--duNLS .s-module__contentInner--CkDAU .s-module__module--lkLxy {
  background: #fff;
  margin-bottom: 28px;
  padding: 12px 24px;
}
.s-module__wrap--duNLS .s-module__contentInner--CkDAU .s-module__module--lkLxy h3 {
  color: var(--gray-800);
  font-size: 20px;
  line-height: 44px;
  font-weight: 500;
  border-bottom: 1px solid var(--bottom-line-color);
}
.s-module__wrap--duNLS .s-module__contentInner--CkDAU .s-module__module--lkLxy .s-module__row--Ht3CN {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
}
.s-module__wrap--duNLS .s-module__contentInner--CkDAU .s-module__module--lkLxy .s-module__rowItem--qjTu5 {
  margin-right: 36px;
}
.s-module__wrap--duNLS .s-module__contentInner--CkDAU .s-module__module--lkLxy .s-module__colItem--CiwHG {
  display: flex;
  flex-direction: column;
}
.s-module__row--Ht3CN {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
}
.s-module__row--Ht3CN .s-module__displayItem--_uAat {
  margin-bottom: 12px;
}
.s-module__row--Ht3CN .s-module__displayItem--_uAat .s-module__label--fvZvC {
  color: #6B7280;
  font-size: 14px;
  line-height: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/onboarding/Preview/s.module.less"],"names":[],"mappings":"AAAA;EAEI,eAAA;EACA,iBAAA;EACA,iBAAA;AAAJ;AAJA;EAOI,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;AAAJ;AAXA;EAcI,0BAAA;EACA,2BAAA;EACA,gBAAA;AAAJ;AAhBA;EAkBM,gBAAA;EACA,mBAAA;EACA,kBAAA;AACN;AArBA;EAsBQ,sBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,iDAAA;AAER;AA5BA;EA6BQ,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;AAER;AAlCA;EAmCQ,kBAAA;AAER;AArCA;EAsCQ,aAAA;EACA,sBAAA;AAER;AAIA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;AAFF;AAFA;EAWI,mBAAA;AANJ;AALA;EAOM,cAAA;EACA,eAAA;EACA,iBAAA;AACN","sourcesContent":[".wrap{\n  h2 {\n    font-size: 24px;\n    line-height: 28px;\n    font-weight: bold;\n  }\n  .subTitle{\n    color: var(--gray-600);\n    font-size: 20px;\n    font-weight: 500;\n    line-height: 28px;\n    margin-top: 8px;\n  }\n  .contentInner{\n    background: var(--gray-50);\n    padding: 28px 28px 1px 28px;\n    margin-top: 16px;\n    .module{\n      background: #fff;\n      margin-bottom: 28px;\n      padding: 12px 24px;\n      h3{\n        color: var(--gray-800);\n        font-size: 20px;\n        line-height: 44px;\n        font-weight: 500;\n        border-bottom: 1px solid var(--bottom-line-color);\n      }\n      .row{\n        display: flex;\n        flex-direction: row;\n        align-items: center;\n        margin-top: 16px;\n      }\n      .rowItem{\n        margin-right: 36px;\n      }\n      .colItem{\n        display: flex;\n        flex-direction: column;\n      }\n    }\n  }\n}\n\n.row {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-top: 16px;\n  .displayItem{\n    .label {\n      color: #6B7280;\n      font-size: 14px;\n      line-height: 20px;\n    }\n    margin-bottom: 12px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--duNLS`,
	"subTitle": `s-module__subTitle--LH_Ib`,
	"contentInner": `s-module__contentInner--CkDAU`,
	"module": `s-module__module--lkLxy`,
	"row": `s-module__row--Ht3CN`,
	"rowItem": `s-module__rowItem--qjTu5`,
	"colItem": `s-module__colItem--CiwHG`,
	"displayItem": `s-module__displayItem--_uAat`,
	"label": `s-module__label--fvZvC`
};
export default ___CSS_LOADER_EXPORT___;
