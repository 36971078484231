import React from 'react';
import s from './s.module.less';
import { EScroeType } from 'types/common';

type Props = {
    value?: EScroeType
};

const ScoreLabel = (props:Props) => {
    const { value } = props;
    let className = '';
    let display = '';
    if (value === EScroeType.TERRIBLE) {
        display = 'Terrible';
        className = s.terrible;
    }
    if (value === EScroeType.BAD) {
        display = 'Bad';
        className = s.bad;
    }
    if (value === EScroeType.OKAY) {
        display = 'Okey';
        className = s.okay;
    }
    if (value === EScroeType.GOOD) {
        display = 'Good';
        className = s.good;
    }
    if (value === EScroeType.AMAZING) {
        display = 'Amazing';
        className = s.amazing;
    }
    return (
        <span className={`${s.wrap} ${className}`}>
            {display}
        </span>
    );
};

export default ScoreLabel;
