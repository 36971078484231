// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/common/plusIcon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrapper--VYyYn {
  font-family: 'Inter';
  font-style: normal;
}
.s-module__wrapper--VYyYn .s-module__tags--vyzl6 {
  margin-top: 16px;
  display: flex;
  align-items: center;
}
.s-module__wrapper--VYyYn .s-module__tags--vyzl6 .s-module__tag--EqY0t {
  margin-right: 8px;
  padding: 2px 4px 2px 10px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray-800);
  background-color: var(--gray-200);
  border-radius: 6px;
  cursor: pointer;
}
.s-module__wrapper--VYyYn .s-module__tags--vyzl6 .s-module__tag--EqY0t::after {
  content: '';
  margin-left: 2px;
  display: flex;
  width: 16px;
  height: 16px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center no-repeat;
  background-size: cover;
}
body .s-module__concave--MDzTo {
  box-shadow: inset 5px 5px 10px var(--gray-700), inset -2px -2px 4px #fff !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/SubscriptionButton/s.module.less"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,kBAAA;AACJ;AAHA;EAIQ,gBAAA;EACA,aAAA;EACA,mBAAA;AAER;AARA;EASY,iBAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,iCAAA;EACA,kBAAA;EACA,eAAA;AAEZ;AAAY;EACI,WAAA;EACA,gBAAA;EACA,aAAA;EACA,WAAA;EACA,YAAA;EACA,oEAAA;EACA,sBAAA;AAEhB;AAGA;EAEQ,mFAAA;AAFR","sourcesContent":[".wrapper {\n    font-family: 'Inter';\n    font-style: normal;\n    .tags {\n        margin-top: 16px;\n        display: flex;\n        align-items: center;\n\n        .tag {\n            margin-right: 8px;\n            padding:  2px 4px 2px 10px;\n            display: flex;\n            align-items: center;\n            font-weight: 500;\n            font-size: 14px;\n            line-height: 20px;\n            color: var(--gray-800);\n            background-color: var(--gray-200);\n            border-radius: 6px;\n            cursor: pointer;\n\n            &::after {\n                content: '';\n                margin-left: 2px;\n                display: flex;\n                width: 16px;\n                height: 16px;\n                background: url('assets/common/plusIcon.svg') center no-repeat;\n                background-size: cover;\n            }\n        }\n    }\n}\nbody {\n    .concave {\n        box-shadow: inset 5px 5px 10px var(--gray-700), inset -2px -2px 4px #fff !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `s-module__wrapper--VYyYn`,
	"tags": `s-module__tags--vyzl6`,
	"tag": `s-module__tag--EqY0t`,
	"concave": `s-module__concave--MDzTo`
};
export default ___CSS_LOADER_EXPORT___;
