import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { Checkbox, Divider, Input, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { SearchOutlined } from '@ant-design/icons';

type TValue = { content: string, dictKey: any }[];

interface IProps {
    fieldNames?: any;
    placeholder?: string;
    value?: any;
    options: TValue;
    onChange?: (v: any[]) => void;
}

const NumberSelector = ({
    fieldNames = {
        label: 'content',
        value: 'dictKey',
    },
    placeholder,
    value,
    options,
    onChange,
}: IProps) => {
    const [checkAll, setCheckAll] = useState(false);
    const [val, setVal] = useState<any[]>([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [filterInput, setFilterInput] = useState('');
    const [filteredOptions, setFilteredOptions] = useState<TValue>([]);
    const [checkAllDisabled, setCheckAllDisabled] = useState(false);

    useEffect(() => {
        setCheckAllDisabled(filteredOptions.every((option) => !!option.disabled));
    }, [filteredOptions]);

    useEffect(() => {
        setIndeterminate(val.length > 0 && val.length < filteredOptions?.filter((option) => !option.disabled)?.length);
    }, [val, filteredOptions]);

    useEffect(() => {
        if (value) {
            setVal(value);
        }
    }, [value]);

    useEffect(() => {
        if (filteredOptions?.some((option) => !option.disabled)) {
            const isCheckAll = filteredOptions?.filter((option) => !option.disabled)?.every((option) => val.includes(option.dictKey));

            setCheckAll(isCheckAll);
        }
    }, [filteredOptions, val]);

    useEffect(() => {
        if (filterInput) {
            setFilteredOptions(options.filter((option) => {
                return option.content?.toLowerCase().indexOf(filterInput?.toLowerCase()) > -1;
            }));
        } else {
            setFilteredOptions(options);
        }
    }, [filterInput, options]);

    const maxTagPlaceholder = useCallback((omittedValues: any[]) => {
        const linkText = omittedValues.length === 1 ? 'link' : 'links';

        return <span className={s.tagPlaceholder}>{`${omittedValues.length} ${linkText} selected`}</span>;
    }, []);

    const handleAllCheckChange = useCallback((e: CheckboxChangeEvent) => {
        const { checked } = e.target;
        setCheckAll(checked);
        if (checked && filteredOptions) {
            const allValues = filteredOptions.filter((option) => !option.disabled).map((option) => option.dictKey);
            setVal(allValues);
            onChange?.(allValues);
        } else {
            setVal([]);
            onChange?.([]);
        }
    }, [onChange, filteredOptions]);

    const handleChange = useCallback((e: any[]) => {
        setVal(e);
        onChange?.(e);
    }, [onChange]);

    const handleFilter = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setFilterInput(e.target.value);
    }, []);

    return (
        <div className={s.wrap}>
            <Select
                maxTagCount={0}
                className={s.selector}
                mode="multiple"
                placeholder={placeholder}
                value={val}
                fieldNames={fieldNames}
                showSearch={false}
                onChange={handleChange}
                options={filteredOptions}
                maxTagPlaceholder={maxTagPlaceholder}
                dropdownRender={(menu) => (
                    <>
                        <Input
                            addonBefore={<SearchOutlined />}
                            onChange={handleFilter}
                            onKeyDown={
                                (event) => {
                                    if (event.key === 'Backspace') {
                                        return event.stopPropagation();
                                    }
                                }
                            }
                        />
                        {
                            filteredOptions?.length > 0 ?
                                <>
                                    <Divider style={{ margin: '8px 0' }} />
                                    <Checkbox disabled={checkAllDisabled} indeterminate={indeterminate} checked={checkAll} onChange={handleAllCheckChange}>Select all</Checkbox>
                                </> : null
                        }
                        {menu}
                    </>
                )}
            />
        </div>
    );
};

export default NumberSelector;
