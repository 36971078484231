// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--L6ORH {
  background: #f9fafb;
  border: 1px solid #E9ECEF;
  padding: 32px 16px;
  border-radius: 12px;
}
.s-module__wrap--L6ORH .s-module__header--zS8fF {
  margin-bottom: 8px;
  font-size: 14px;
}
.s-module__wrap--L6ORH .s-module__footer--lmUB3 {
  margin-top: 24px;
  color: #6B7280;
  font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/components/form/TextContentInput/s.module.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,mBAAA;AACF;AALA;EAOI,kBAAA;EACA,eAAA;AACJ;AATA;EAYI,gBAAA;EACA,cAAA;EACA,eAAA;AAAJ","sourcesContent":[".wrap {\n  background: #f9fafb;\n  border: 1px solid #E9ECEF;\n  padding: 32px 16px;\n  border-radius: 12px;\n\n  .header {\n    margin-bottom: 8px;\n    font-size: 14px;\n  }\n\n  .footer {\n    margin-top: 24px;\n    color: #6B7280;\n    font-size: 14px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--L6ORH`,
	"header": `s-module__header--zS8fF`,
	"footer": `s-module__footer--lmUB3`
};
export default ___CSS_LOADER_EXPORT___;
