import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import useService from 'hooks/useService';
import { Meta, Service } from 'types/common';

type ServiceSelectProps = {
    value?: string;
    onChange?: (val: string) => void,
    channelId?: number, //if have channel id then get data from serivices in channel obj
} & SelectProps;

const ServiceSelect = (props:ServiceSelectProps) => {
    const { onChange, channelId, ...reset } = props;
    const [services, loading] = useService(channelId);
    const options:Meta[] = services?.map((service:Service) => {
        return {
            label: service.name,
            value: service.id!,
        };
    }) || [];
    return (
        <Select
            {...reset}
            onChange={onChange}
            loading={loading}
        >
            {
                options.map((option:Meta) => {
                    return (
                        <Select.Option
                            key={option.value}
                            value={option.value}
                        >
                            {option.label}
                        </Select.Option>
                    );
                })
            }
        </Select>
    );
};

export default ServiceSelect;
