import React from 'react';
import { Form, Spin, Radio, DatePicker, InputNumber, Button, Input, message } from 'antd';
import { formatDate } from 'utils/common';
import { Physician } from 'types/common';
import { FormPhysician } from 'types/form';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { providerPhysicianUpdate } from 'api/provider';
import { opPhysicianUpdate } from 'api/operation';
import StateSelect from 'components/form/StateSelect';
import FileUpload from 'components/form/FileUpload';
import FormValidationHelper from 'utils/validation';
import { PhysicianData } from 'data/provider';
import LicenseImg from 'components/LicenseImg';

type Props = {
    editable:boolean,
    initValue?: Partial<FormPhysician>,
    state:string,
    providerId?:number
    onChange?: (value:Partial<FormPhysician>)=>void,
    viewOnly?:boolean,
    onNullState?: ()=>void,
    isOperation?:boolean,
};

const CollaboratingPhysician = (props: Props) => {
    const { editable, initValue, state = 'CA', viewOnly, providerId, onChange, onNullState, isOperation } = props;
    const [enable, setEnable] = React.useState(editable);
    const [,forceUpdate] = React.useState({});
    const [form] = Form.useForm<FormPhysician>();
    const [submiting, setSubmiting] = React.useState(false);
    const [physicianOption, setPhysicianOption] = React.useState<number | undefined>(form.getFieldValue('choice'));

    const onSubmit = async () => {
        if (!state) {
            onNullState?.();
            return;
        }
        try {
            setSubmiting(true);
            const formValues = await form.validateFields();
            const physicianData:Partial<Physician> = {
                address: formValues.address,
                effectiveDate: formatDate(formValues.effectiveDate),
                email: formValues.email,
                expDate: formatDate(formValues.expDate) || '',
                licenseState: formValues.licenseState,
                name: formValues.name,
                npi: formValues.npi,
                choice: physicianOption,
                physicianState: formValues.physicianState,
                specialities: formValues.specialities,
                state,
                tel: formValues.tel,
                url: formValues.url,
                yearExp: formValues.yearExp,
                zip: formValues.zip,
            };
            const fn = isOperation ? opPhysicianUpdate : providerPhysicianUpdate;
            const result = await fn(physicianData, providerId!);
            if (!result.error) {
                message.success('Collaborating physician info has updated');
                setEnable(false);
                if (onChange) {
                    onChange(PhysicianData.serverToForm(physicianData as Physician));
                }
            } else {
                message.error(result.error);
            }
        } catch (e:any) {
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };

    React.useEffect(() => {
        if (initValue) {
            setPhysicianOption(initValue.choice);
            forceUpdate({});
            if (initValue.choice === 3) {
                form.setFieldsValue(initValue);
            } else {
                form.setFieldValue('choice', initValue.choice);
            }
        }
    }, []);

    return (
        <div className={s.wrap}>
            <Form
                form={form}
                name="basic"
                className={commonS.formStyle1}
                initialValues={{ remember: true }}
                autoComplete="off"
                layout="vertical"
            >
                <Spin spinning={submiting}>
                    <div className={s.module}>
                        <h3><span>Collaborating Physician</span>
                            {!enable && !viewOnly &&
                            <Button
                                size="small"
                                onClick={() => {
                                    setEnable(true);
                                }}
                            >
                                Edit
                            </Button>}
                        </h3>
                        {
                            enable &&
                            <>
                                <div className={s.row}>
                                    <div className={s.rowItem}>
                                        <Form.Item
                                            style={{ width: '500px' }}
                                            label=""
                                            name="choice"
                                            rules={[{ required: true, message: 'Field is required' }]}
                                        >
                                            <Radio.Group onChange={async (v) => {
                                                if (v.target.value !== 3) {
                                                    const physicianData = {
                                                        state,
                                                        choice: v.target.value,
                                                        email: '',
                                                    };
                                                    setSubmiting(true);
                                                    const result = await providerPhysicianUpdate(physicianData, providerId!);
                                                    setSubmiting(false);
                                                    if (!result.error) {
                                                        if (onChange) {
                                                            onChange(physicianData);
                                                        }
                                                    } else {
                                                        message.error(result.error);
                                                    }
                                                }
                                                setPhysicianOption(v.target.value);
                                            }}
                                            >
                                                <Radio value={1}>No, it’s not requried in this state.</Radio>
                                                {/* <Radio value={2}>No, I will need a collabrating physician in the future.</Radio> */}
                                                <Radio value={3}>Yes, I have a collaborating physician.</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </div>
                                </div>
                                {
                                    physicianOption === 3 &&
                                    <div className={s.add}>
                                        <h4>Please add information of your collaborating physician:</h4>
                                        <div className={s.row}>
                                            <div className={s.rowItem}>
                                                <Form.Item
                                                    style={{ width: '320px' }}
                                                    label="Physician’s name"
                                                    name="name"
                                                    rules={[{ required: true, message: 'Physician name is required' }]}
                                                >
                                                    <Input placeholder="Collaborating physician’s name" />
                                                </Form.Item>
                                            </div>
                                            <div className={s.rowItem}>
                                                <Form.Item
                                                    style={{ width: '280px' }}
                                                    label="Practice phone number"
                                                    name="tel"
                                                    rules={[
                                                        { required: true, message: 'Practice phone number is required' },
                                                        FormValidationHelper.validatePhoneNumber('Phone number must contain only number or -'),
                                                    ]}
                                                >
                                                    <Input maxLength={30} />
                                                </Form.Item>
                                            </div>
                                            <div className={s.rowItem}>
                                                <Form.Item
                                                    style={{ width: '320px' }}
                                                    label="Physician’s email"
                                                    name="email"
                                                    rules={[{ required: true, message: 'Physician’s email is required' }]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className={s.row}>
                                            <div className={s.rowItem}>
                                                <Form.Item
                                                    style={{ width: '320px' }}
                                                    label="Physician’s address"
                                                    name="address"
                                                    rules={[{ required: true, message: 'Address is required' }]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </div>
                                            <div className={s.rowItem}>
                                                <Form.Item
                                                    style={{ width: '180px' }}
                                                    label="State"
                                                    name="physicianState"
                                                    rules={[{ required: true, message: 'State is required' }]}
                                                >
                                                    <StateSelect />
                                                </Form.Item>
                                            </div>
                                            <div className={s.rowItem}>
                                                <Form.Item
                                                    style={{ width: '160px' }}
                                                    label="ZIP code"
                                                    name="zip"
                                                    rules={[{ required: true, message: 'ZIP code is required' }]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className={s.row}>
                                            <div className={s.rowItem}>
                                                <Form.Item
                                                    style={{ width: '320px' }}
                                                    label="Physician’s NPI number"
                                                    name="npi"
                                                    validateFirst
                                                    rules={[
                                                        {
                                                            required: true, message: 'NPI is required' },
                                                        FormValidationHelper.validateLicenseNumber('The NPI must contain only letters or numbers'),
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </div>
                                            <div className={s.rowItem}>
                                                <Form.Item
                                                    style={{ width: '180px' }}
                                                    label="Licensed states"
                                                    name={['licenseState']}
                                                    rules={[{ required: true, message: 'License state is required' }]}
                                                >
                                                    <StateSelect />
                                                </Form.Item>
                                            </div>
                                            <div className={s.rowItem}>
                                                <Form.Item
                                                    style={{ width: '320px' }}
                                                    label="Physician’s specialities"
                                                    name="specialities"
                                                    rules={[{ required: true, message: 'Specialities is required' }]}
                                                >
                                                    <Input placeholder="Specialities and seperate with “,”" />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className={s.row}>
                                            <div className={s.rowItem}>
                                                <Form.Item
                                                    style={{ width: '320px' }}
                                                    label="Effective date of the agreement"
                                                    name={['effectiveDate']}
                                                    rules={[{ required: true, message: 'Effective date is required' }]}
                                                >
                                                    <DatePicker style={{ width: '100%' }} />
                                                </Form.Item>
                                            </div>
                                            <div className={s.rowItem}>
                                                <Form.Item
                                                    style={{ width: '320px' }}
                                                    label="Expiration date of the agreement"
                                                    name={['expDate']}
                                                >
                                                    <DatePicker
                                                        disabledDate={(date) => {
                                                            const now = (new Date()).getTime();
                                                            if (!date) {
                                                                return false;
                                                            }
                                                            return date?.valueOf() < now;
                                                        }}
                                                        style={{ width: '100%' }}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className={s.row}>
                                            <div className={s.rowItem}>
                                                <Form.Item
                                                    style={{ width: '500px' }}
                                                    label="Agreement upload"
                                                    name={['url']}
                                                    rules={[{ required: true, message: 'Agreement is required' }]}
                                                >
                                                    <FileUpload />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className={s.buttonWrap}>
                                            <Button
                                                className={commonS.lightBtn}
                                                size="small"
                                                onClick={onSubmit}
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </div>
                                }
                            </>}
                        {
                            !enable &&
                            <>
                                <div className={s.row}>
                                    <div
                                        className={s.displayItem}
                                    >
                                        <div className={s.label}>Do you have a collaborating physician now?</div>
                                        <div className={s.value}>
                                            { form.getFieldValue('choice') === 1 && 'No, it’s not required in this state.'}
                                            { form.getFieldValue('choice') === 2 && 'No, I will need a collabrating physician in the future.'}
                                            { form.getFieldValue('choice') === 3 && 'Yes, I have a collaborating physician'}
                                        </div>
                                    </div>
                                </div>
                                {
                                    form.getFieldValue('choice') === 3 &&
                                    <>
                                        <div className={s.row}>
                                            <div
                                                style={{
                                                    width: '300px',
                                                }}
                                                className={s.displayItem}
                                            >
                                                <div className={s.label}>Physician’s name</div>
                                                <div className={s.value}>{ form.getFieldValue('name')}</div>
                                            </div>

                                            <div
                                                style={{
                                                    width: '300px',
                                                }}
                                                className={s.displayItem}
                                            >
                                                <div className={s.label}>Personal phone number</div>
                                                <div className={s.value}>{ form.getFieldValue('tel')}</div>
                                            </div>
                                            <div
                                                style={{
                                                    width: '300px',
                                                }}
                                                className={s.displayItem}
                                            >
                                                <div className={s.label}>Email</div>
                                                <div className={s.value}>{ form.getFieldValue('email')}</div>
                                            </div>
                                        </div>
                                        <div className={s.row}>
                                            <div
                                                style={{
                                                    width: '300px',
                                                }}
                                                className={s.displayItem}
                                            >
                                                <div className={s.label}>Physician’s address</div>
                                                <div className={s.value}>{ form.getFieldValue('address')}</div>
                                            </div>

                                            <div
                                                style={{
                                                    width: '300px',
                                                }}
                                                className={s.displayItem}
                                            >
                                                <div className={s.label}>State</div>
                                                <div className={s.value}>{ form.getFieldValue('state')}</div>
                                            </div>
                                            <div
                                                style={{
                                                    width: '300px',
                                                }}
                                                className={s.displayItem}
                                            >
                                                <div className={s.label}>ZIP code</div>
                                                <div className={s.value}>{ form.getFieldValue('zip')}</div>
                                            </div>
                                        </div>
                                        <div className={s.row}>
                                            <div
                                                style={{
                                                    width: '300px',
                                                }}
                                                className={s.displayItem}
                                            >
                                                <div className={s.label}>Physician’s NPI number</div>
                                                <div className={s.value}>{ form.getFieldValue('npi')}</div>
                                            </div>

                                            <div
                                                style={{
                                                    width: '300px',
                                                }}
                                                className={s.displayItem}
                                            >
                                                <div className={s.label}>Licensed states</div>
                                                <div className={s.value}>{ form.getFieldValue('licenseState')}</div>
                                            </div>
                                            <div
                                                style={{
                                                    width: '180px',
                                                }}
                                                className={s.displayItem}
                                            >
                                                <div className={s.label}>Physician’s specialities</div>
                                                <div className={s.value}>{ form.getFieldValue('specialities')}</div>
                                            </div>
                                        </div>
                                        <div className={s.row}>
                                            <div
                                                style={{
                                                    width: '300px',
                                                }}
                                                className={s.displayItem}
                                            >
                                                <div className={s.label}>Effective date of the agreement</div>
                                                <div className={s.value}>{ formatDate(form.getFieldValue('effectiveDate'))}</div>
                                            </div>
                                            <div
                                                style={{
                                                    width: '300px',
                                                }}
                                                className={s.displayItem}
                                            >
                                                <div className={s.label}>Expiration date of the agreement</div>
                                                <div className={s.value}>{ form.getFieldValue('expDate') ? formatDate(form.getFieldValue('expDate')) : 'Null'}</div>
                                            </div>
                                        </div>
                                        <div className={s.row}>
                                            <div
                                                style={{
                                                    width: '80%',
                                                }}
                                                className={s.displayItem}
                                            >
                                                <div className={s.label}>Uploaded license</div>
                                                <div className={s.value}>
                                                    <LicenseImg src={form.getFieldValue('url')} />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </div>
                </Spin>
            </Form>

        </div>
    );
};

export default CollaboratingPhysician;
