// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__status--y5uqN {
  padding: 2px 10px;
  display: inline-block;
  background: #DBDBDB;
  border-radius: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: var(--gray-800);
  white-space: nowrap;
}
.s-module__status--y5uqN::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 6px;
  background-color: var(--gray-400);
}
.s-module__status--y5uqN.s-module__approved--Xcseg {
  background-color: var(--green-100);
  color: var(--green-800);
}
.s-module__status--y5uqN.s-module__approved--Xcseg::before {
  background-color: var(--green-400);
}
.s-module__status--y5uqN.s-module__rejected--ebu6s {
  background-color: var(--red-100);
  color: var(--red-800);
}
.s-module__status--y5uqN.s-module__rejected--ebu6s::before {
  background-color: var(--red-400);
}
.s-module__status--y5uqN.s-module__followup--sGckJ {
  background-color: var(--blue-100);
  color: var(--blue-800);
}
.s-module__status--y5uqN.s-module__followup--sGckJ::before {
  background-color: var(--blue-400);
}
`, "",{"version":3,"sources":["webpack://./src/components/ProviderUniprofileStatusTag/s.module.less"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,qBAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AACI;EACI,WAAA;EACA,qBAAA;EACA,UAAA;EACA,WAAA;EACA,kBAAA;EACA,iBAAA;EACA,iCAAA;AACR;AAEI;EACI,kCAAA;EACA,uBAAA;AAAR;AACQ;EACI,kCAAA;AACZ;AAGI;EACI,gCAAA;EACA,qBAAA;AADR;AAEQ;EACI,gCAAA;AAAZ;AAII;EACI,iCAAA;EACA,sBAAA;AAFR;AAGQ;EACI,iCAAA;AADZ","sourcesContent":[".status {\n    padding: 2px 10px;\n    display: inline-block;\n    background: #DBDBDB;\n    border-radius: 10px;\n    font-weight: 500;\n    font-size: 12px;\n    line-height: 16px;\n    text-align: center;\n    color: var(--gray-800);\n    white-space: nowrap;\n\n    &::before {\n        content: '';\n        display: inline-block;\n        width: 8px;\n        height: 8px;\n        border-radius: 50%;\n        margin-right: 6px;\n        background-color: var(--gray-400);\n    }\n\n    &.approved {\n        background-color: var(--green-100);\n        color: var(--green-800);\n        &::before {\n            background-color: var(--green-400);\n        }\n    }\n\n    &.rejected {\n        background-color: var(--red-100);\n        color: var(--red-800);\n        &::before {\n            background-color: var(--red-400);\n        }\n    }\n\n    &.followup {\n        background-color: var(--blue-100);\n        color: var(--blue-800);\n        &::before {\n            background-color: var(--blue-400);\n        }\n    }\n\n    // &.golive {\n    //     background-color: var(--blue-100);\n    //     color: var(--blue-800);\n    //     &::before {\n    //         background-color: var(--blue-400);\n    //     }\n    // }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": `s-module__status--y5uqN`,
	"approved": `s-module__approved--Xcseg`,
	"rejected": `s-module__rejected--ebu6s`,
	"followup": `s-module__followup--sGckJ`
};
export default ___CSS_LOADER_EXPORT___;
