import React from 'react';
import s from './s.module.less';
import Banner from './be-productive--work-be-productive.svg';
import { Button } from 'antd';
import { getPendingSubscriptionChannel, getPendingSubscriptionPlan } from 'utils/localstore';
import { Channel } from 'types/common';
import { TSubscriptionItem } from 'types/subscription';
import SubscriptionStore from 'store/Subscription';
import { CouponType } from 'types/provider';

type Props = {
    onDone:()=>void
};

const SubscriptionSuccess = (props:Props) => {
    const payPlan = SubscriptionStore.get('currentPlan');

    const { onDone } = props;
    const channel:Channel | undefined = getPendingSubscriptionChannel();
    const plan:TSubscriptionItem | undefined = getPendingSubscriptionPlan();
    const isFromChannelSub = !!channel;
    const isFromPlanSub = !!plan;
    let name = 'Kiwi Plan';
    if (isFromPlanSub) {
        name = `${plan?.type} plan`;
    }
    if (isFromChannelSub) {
        name = `${channel?.name}`;
    }
    return (
        <div className={s.wrap}>
            <img src={Banner} />
            <h2>Your subscription has been successfully processed.</h2>
            <div className={s.des}>
                Thank you for subscribing to the {name}. Get ready to unlock new possibilities and enhance your practice. Please review and accept the updated terms to continue using our app.
            </div>
            <Button
                onClick={onDone}
                type="primary"
                size="large"
            >
                { payPlan?.couponType !== CouponType.HEADWAY ? 'Review agreement' : 'Done' }
            </Button>
        </div>
    );
};

export default SubscriptionSuccess;
