import React from 'react';
import { Checkbox, Input } from 'antd';
import { CheckboxGroupProps } from 'antd/lib/checkbox/Group';
import { OTHERS_KEY } from 'constants/meta';

type CheckGroupWithInputProps = {
    value?: string[];
    onChange?: (val: string[]) => void
} & CheckboxGroupProps;

const CheckGroupWithInput = (Props:CheckGroupWithInputProps) => {
    const { value = [], onChange, options, ...reset } = Props;
    const [checkOthers, setCheckOthers] = React.useState(false);
    const [inputVal, setInputVal] = React.useState('');
    const optionsValues = options?.map((item:any) => item.value) || [];
    const boxValues = [...value, checkOthers && OTHERS_KEY].filter(Boolean).filter((val) => optionsValues.includes(val));
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const inputValues = value.filter((val) => !optionsValues.includes(val));
    const [hasInit, setHasInit] = React.useState(false);

    React.useEffect(() => {
        // optionsValues has fixed value others
        if (value && value.length > 0 && !hasInit && optionsValues && optionsValues.length > 1) {
            const inpValues = value.filter((val) => !optionsValues.includes(val) && !!val.trim());
            if (inpValues.length > 0) {
                setCheckOthers(true);
                setInputVal(inpValues.join(','));
                setHasInit(true);
            }
        }
    }, [value, optionsValues]);

    return (
        <>
            <Checkbox.Group
                value={boxValues}
                onChange={(val) => {
                    if (val && val.includes(OTHERS_KEY)) {
                        setCheckOthers(true);
                        const vals = val?.filter((key) => key !== OTHERS_KEY) || [];
                        onChange?.([...vals, ...inputValues]);
                    } else {
                        setCheckOthers(false);
                        setInputVal('');
                        const vals = val?.filter((key) => key !== OTHERS_KEY) || [];
                        onChange?.([...vals]);
                    }
                }}
                options={options}
                {...reset}
            />
            <Input
                value={inputVal}
                onChange={(e) => {
                    setInputVal(e.target.value);
                    const currentBoxValues = boxValues.filter((key) => key !== OTHERS_KEY);
                    const val = e.target.value;
                    const valArray = val ? val.split(',') : [];
                    onChange?.([...currentBoxValues, ...valArray]);
                }}
                disabled={!checkOthers}
                style={{ marginTop: 4 }}
                placeholder="Add your other licenses and seperate with “,”"
            />
        </>
    );
};

export default CheckGroupWithInput;
