import React from 'react';
import { Input, Radio, RadioProps } from 'antd';
import { InputProps, TextAreaProps } from 'antd/lib/input';

const TextArea = (props: TextAreaProps) => <Input.TextArea {...props} />;
const TextInput = (props: InputProps) => <Input {...props} />;
const RadioInput = (props: RadioProps) => <Radio {...props} />;

const editorTypeName = {
    textarea: 'textarea',
    rating: 'rating',
    input: 'input',
    radioButtons: 'radioButtons',
    radio: 'radio',
};

export default {
    [editorTypeName.input]: TextInput,
    [editorTypeName.textarea]: TextArea,
    [editorTypeName.radio]: RadioInput,
};
