import React from 'react';
import { Select, message } from 'antd';
import { SelectProps } from 'antd/lib/select';
import useSettings from 'hooks/useSettings';
import { EnumFields } from 'types/enumerationData';
import { Meta } from 'types/common';

type ConditionSelectProps = {
    value?: string | string[];
    onChange?: (val: string | string[]) => void;
    disableValues?: string[];
    mode?: 'multiple' | '';
    maxCount?: number;
    isLabel?: boolean; // value is 'value' or 'label'
} & SelectProps;

const ConditionSelect = (props:ConditionSelectProps) => {
    const { onChange, disableValues, mode, maxCount, isLabel, ...reset } =
      props;
    const [setting, loading] = useSettings();
    const stateOption = setting[EnumFields.HELLO_KLARITY_CONDITION]?.sort((a: Meta, b: Meta) => {
        const stringA = a.label.toLowerCase();
        const stringB = b.label.toLowerCase();
        if (stringA < stringB) {
            return -1;
        }
        if (stringA > stringB) {
            return 1;
        }
        return 0;
    }) || [];

    const handleChange = (val: string | string[]) => {
        if (maxCount && val.length > maxCount) {
            message.warning(`You can only select up to ${maxCount} items.`);
        } else {
            onChange?.(val);
        }
    };

    return (
        <Select
            {...reset}
            onChange={handleChange}
            loading={loading}
            showSearch
            virtual={false}
            mode={mode}
        >
            {stateOption.map((option: Meta) => {
                return (
                    <Select.Option
                        disabled={
                            !!(
                                disableValues?.includes((isLabel ? option.label : option.value) as string) &&
                        reset.value !== (isLabel ? option.label : option.value)
                            )
                        }
                        key={option.value}
                        value={isLabel ? option.label : option.value}
                    >
                        {option.label}
                    </Select.Option>
                );
            })}
        </Select>
    );
};

export default ConditionSelect;
