import React, { useCallback, useEffect, useMemo, useState } from 'react';
import s from './s.module.less';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import { removeHtmlTag } from 'utils/common';

interface IProps {
    placeholder?: string;
    className?: string;
    height?: string;
    width?: string;
    style?: Record<string, any>;
    value?: string;
    maxLength?: number;
    removeHtmlTags?: boolean;
    onChange?: (v: string) => void
}

const RichText = ({
    placeholder = '',
    className = '',
    height = '100%',
    width = '100%',
    style,
    value = '',
    maxLength,
    onChange,
    removeHtmlTags = true,
}: IProps) => {
    const [painText, setPainText] = useState('');

    useEffect(() => {
        setPainText(removeHtmlTag(value));
    }, [value]);

    const handleChange = useCallback((e: ContentEditableEvent) => {
        let html = e.target.value;

        if (removeHtmlTags) {
            html = html.replace(/<\/?span[^>]*>|<\/?a[^>]*>/gi, '');
            html = html.replace(/style=\".*"/g, '');
        }

        if (!html?.replace(/<\/?.+?>/g, '').replace(/&nbsp;/g, '').replace(/ /g, '')) {
            html = '';
        }

        onChange?.(html);
    }, [onChange, removeHtmlTags]);

    const wrapperStyle = useMemo(() => {
        return ({
            paddingBottom: maxLength ? '28px' : 0,
        });
    }, [maxLength]);

    const editStyle = useMemo(() => {
        return ({
            ...style,
            width,
            height,
        });
    }, [height, style, width]);

    return (
        <div className={`${s.wrapper} ${className}`} style={wrapperStyle}>
            <ContentEditable
                placeholder={placeholder}
                className={s.editor}
                style={editStyle}
                html={value} // innerHTML of the editable div
                onChange={handleChange} // handle innerHTML change
            />
            { maxLength && <div className={s.lengthNumber}>{painText ? painText.length : 0} / {Number(maxLength)}</div> }
        </div>
    );
};

export default RichText;
