import React, { useCallback } from 'react';
import s from './s.module.less';
import { ISubscriptopnTalkSumbit } from 'types/subscription';
import { Button, Form, Input } from 'antd';

type Props = {
    onDone:()=>void
};

const SubscriptionTalk = ({ onDone }: Props) => {
    const [formInstance] = Form.useForm<ISubscriptopnTalkSumbit>();

    const handleFormFinish = useCallback(() => {}, []);
    return (
        <div className={s.wrap}>
            <div className={s.bgImage} />
            <div className={s.content}>
                <div className={s.title}>Let's talk!</div>
                <div className={s.desc}>Schedule your 30 minute call with a Kiwi practice marketing specialist. Together we'll explore your unique business and how we can help it grow.</div>
                <Form
                    form={formInstance}
                    name="form"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={handleFormFinish}
                    scrollToFirstError
                >
                    <div className={s.row}>
                        <Form.Item
                            // label="First name"
                            className={s.formItem}
                            name="firstName"
                            style={{ flex: 1, marginRight: '32px' }}
                            rules={[
                                { required: true, message: 'Firs name is required' },
                            ]}
                        >
                            <Input placeholder="First name" />
                        </Form.Item>
                        <Form.Item
                            // label="Last name"
                            className={s.formItem}
                            name="lastName"
                            style={{ flex: 1 }}
                            rules={[
                                { required: true, message: 'Last name is required' },
                            ]}
                        >
                            <Input placeholder="Last name" />
                        </Form.Item>
                    </div>
                    <div className={s.row}>
                        <Form.Item
                            // label="Email address"
                            className={s.formItem}
                            name="email"
                            rules={[
                                { required: true, message: 'email is required' },
                            ]}
                        >
                            <Input placeholder="Email address" />
                        </Form.Item>
                    </div>
                    <div className={s.row}>
                        <Form.Item
                            // label="Phone number"
                            className={s.formItem}
                            name="phone"
                            rules={[
                                { required: true, message: 'Phone number is required' },
                            ]}
                        >
                            <Input maxLength={30} />
                        </Form.Item>
                    </div>
                    <div className={s.row}>
                        <Form.Item
                            // label="Practice name"
                            className={s.formItem}
                            name="practiceName"
                            rules={[
                                { required: true, message: 'Your practice name is required' },
                            ]}
                        >
                            <Input placeholder="Enter your practice name" />
                        </Form.Item>
                    </div>
                    <div className={s.row}>
                        <Form.Item
                            // label="Number of providers"
                            className={s.formItem}
                            name="number"
                            rules={[
                                { required: true, message: 'Number of providers is required' },
                            ]}
                        >
                            <Input placeholder="Number of providers " />
                        </Form.Item>
                    </div>
                    <div className={s.row}>
                        <Form.Item
                            // label="Specialties: Mental health, Therapy, Primary care, Dentist, etc."
                            className={s.formItem}
                            name="specialties"
                            rules={[
                                { required: true, message: 'Specialties is required' },
                            ]}
                        >
                            <Input placeholder="Specialties: Mental health, Therapy, Primary care, Dentist, etc." />
                        </Form.Item>
                    </div>
                    <div className={s.row}>
                        <Button type="primary" htmlType="submit">Find a time</Button>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default SubscriptionTalk;
