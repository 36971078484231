import React from 'react';
import s from './s.module.less';
import { Spin, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import MyAccount from './components/MyAccount';
import SubscriptionInfo from './components/SubscriptionInfo';
//import SubscriptionInfo from 'components/Subscription'
import KlarityPlanSubscriptionInfo from './components/KlarityPlanSubscriptionInfo';
import useProviderHomeInfo from 'hooks/useProviderHomeInfo';
import { shouldShowKlarityPlanUI } from 'utils/provider';

const ManageSubscription = () => {
    const [homeInfo, loadingHome] = useProviderHomeInfo();
    const showKlarityPlanUI = shouldShowKlarityPlanUI(homeInfo);

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'My account',
            children: <MyAccount />,
        },
        {
            key: '2',
            label: 'Subscription',
            children: showKlarityPlanUI ? <KlarityPlanSubscriptionInfo /> : <SubscriptionInfo />,
        },
    ];
    return (
        <div className={s.wrap}>
            <Spin spinning={loadingHome}>
                <div className={s.content}>
                    <div className={s.contentInner}>
                        <Tabs defaultActiveKey="2" items={items} />
                    </div>
                </div>
            </Spin>
        </div>
    );
};

export default ManageSubscription;
