import { Alert, Button, Checkbox, DatePicker, Form, Input, Modal, Spin, Tooltip, message } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import s from './s.module.less';
import dayjs from 'dayjs';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { IProvderAgreementSignInput, IProvderAgreementSignFormData, IProvderAgreementSignResponse } from 'types/provider';
import { getProviderAgreementInfo, signProviderAgreement } from 'api/provider';
import { TSubscriptionType } from 'types/subscription';
import SuccessfullyImage from 'assets/agreement/agreementSignSuccess.png';
import NotfoundImage from 'assets/agreement/nofilefound.png';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import useProviderHomeInfo from 'hooks/useProviderHomeInfo';
import GlobalLayerStore from 'store/GlobalLayer';
import HomeStore from 'store/Home';
import { closeUniprofileAgreementSignModal } from 'utils/globalLayerControl';
import FormValidationHelper from 'utils/validation';

const ProviderAgreement = () => {
    const [getGlobalLayer] = GlobalLayerStore.useStore();
    const [, setHomeStore] = HomeStore.useStore();
    const [data, loadingHome, refetch] = useProviderHomeInfo();
    const agreementReviewTypeList = useMemo(() => {
        return data?.agreementReviewTypeList || [];
    }, [data?.agreementReviewTypeList]);
    const navigation = useNavigate();
    const [agreementForm] = Form.useForm<IProvderAgreementSignFormData>();
    const [checked, setChecked] = useState(false);
    const [showCheckTip, setShowCheckTip] = useState(false);
    const [name, setName] = useState('');
    const [date, setDate] = useState(dayjs().format('MM/DD/YYYY'));
    const [address, setAddress] = useState('');
    const [checkoutLoading, setCheckoutLoading] = useState(false);
    const [agreementData, setAgreementData] = useState<IProvderAgreementSignResponse>();
    const [currentAgreementIndex, setCurrentAgreementIndex] = useState(0);
    const [getInfoLoading, setGetInfoLoading] = useState(true);
    const [completed, setCompleted] = useState(false);
    const time = 10;
    const fetchSI = useRef<NodeJS.Timer | null>(null);
    const ST = useRef<NodeJS.Timer | null>(null);
    const [noneAgreement, setNoneAgreement] = useState(false);

    const getAgreementInfo = useCallback(async (planType: TSubscriptionType) => {
        setGetInfoLoading(true);
        try {
            const res = await getProviderAgreementInfo(planType);

            if (res && !res.error) {
                setAgreementData(res.data?.data);
            } else {
                message.error(res?.error);
            }
        } catch (e) {
            console.error(e);
        }
        setGetInfoLoading(false);
    }, []);

    const reset = useCallback(() => {
        setChecked(false);
        setShowCheckTip(false);
        setName('');
        setDate(dayjs().format('MM/DD/YYYY'));
        setAddress('');
        agreementForm.resetFields();
    }, [agreementForm]);

    useEffect(() => {
        if (agreementReviewTypeList) {
            const currentAgreement = agreementReviewTypeList[currentAgreementIndex];
            if (currentAgreement) {
                getAgreementInfo(currentAgreement);
            }
        }
    }, [getAgreementInfo, agreementReviewTypeList, currentAgreementIndex]);

    useEffect(() => {
        agreementForm.setFieldsValue({
            contractSignDate: dayjs(),
        });
    }, [agreementForm, currentAgreementIndex]);

    useEffect(() => {
        setCheckoutLoading(false);
    }, []);

    useEffect(() => {
        const loopFetch = getGlobalLayer('showUniprofileAgreementSignModal')?.loopFetch;

        if (loopFetch && agreementReviewTypeList.length === 0) {
            if (!fetchSI.current) {
                fetchSI.current = setInterval(() => {
                    refetch();
                }, 1000);
            }

            if (!ST.current) {
                ST.current = setTimeout(() => {
                    if (fetchSI.current) {
                        clearInterval(fetchSI.current);
                        fetchSI.current = null;
                    }

                    if (ST.current) {
                        clearTimeout(ST.current);
                        ST.current = null;
                    }

                    if (agreementReviewTypeList.length === 0) {
                        setNoneAgreement(true);
                    }
                }, time * 1000);
            }
        } else if (agreementReviewTypeList.length > 0) {
            if (fetchSI.current) {
                clearInterval(fetchSI.current);
                fetchSI.current = null;
            }

            if (ST.current) {
                clearTimeout(ST.current);
                ST.current = null;
            }
        }
    }, [agreementReviewTypeList, getGlobalLayer, refetch]);

    useEffect(() => {
        if (agreementReviewTypeList.length > 0) {
            setCompleted(currentAgreementIndex === agreementReviewTypeList.length);
        }
    }, [agreementReviewTypeList.length, currentAgreementIndex]);

    // patientRating modal
    const hideModal = useCallback(() => {
        closeUniprofileAgreementSignModal();
    }, []);

    const handleCheckBoxChange = useCallback((e: CheckboxChangeEvent) => {
        setChecked(e.target.checked);
    }, []);

    const handleNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    }, []);
    const handleDateChange = useCallback((e: any, dateString: string) => {
        setDate(dateString);
    }, []);
    const handleAddressChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setAddress(e.target.value);
    }, []);

    const handleAgreementSubmit = useCallback(async (formData: IProvderAgreementSignInput) => {
        setCheckoutLoading(true);

        try {
            const res = await signProviderAgreement(formData);

            if (res && !res.error) {
                if (currentAgreementIndex < agreementReviewTypeList.length) {
                    setCurrentAgreementIndex(currentAgreementIndex + 1);
                    reset();
                }
            } else {
                message.error(res?.error);
            }
        } catch (e) {
            console.error(e);
        }
        setCheckoutLoading(false);
    }, [agreementReviewTypeList, currentAgreementIndex, reset]);

    const handleFormSubmit = useCallback(() => {
        const currentPlan = agreementReviewTypeList?.[currentAgreementIndex];
        if (currentPlan) {
            if (!checked) {
                setShowCheckTip(true);
            } else {
                setShowCheckTip(false);
                agreementForm.validateFields().then((formData: IProvderAgreementSignFormData) => {
                    handleAgreementSubmit({
                        ...formData,
                        contractSignDate: formData.contractSignDate.format('MM/DD/YYYY'),
                        type: currentPlan || TSubscriptionType.FREE,
                    });
                }).catch((e) => {
                    console.error(e);
                });
            }
        }
    }, [agreementReviewTypeList, currentAgreementIndex, checked, agreementForm, handleAgreementSubmit]);

    const handleBackToHome = useCallback(() => {
        hideModal();
        navigation(`${PATH.DASHBOARD}/${PATH.PROVIDER_HOME}`);
        setHomeStore('reload', true);
    }, [hideModal, navigation]);

    const contractUrl = agreementData?.contractUrl || '';

    const renderContent = useMemo(() => {
        if (noneAgreement) {
            return (
                <div className={s.successWrap}>
                    <img className={s.successImage} src={NotfoundImage} />
                    <p className={s.wrongText}>Oops! The contract is not ready yet, please return to the home page and try to refresh it later.</p>
                    <Button type="primary" onClick={handleBackToHome}>Back to home</Button>
                </div>
            );
        }

        if (completed) {
            return (
                <div className={s.successWrap}>
                    <img className={s.successImage} src={SuccessfullyImage} />
                    <div className={s.successTitle}>Agreement updated successfully</div>
                    <p className={s.successSubTitle}>Thank you for agreeing to the updated terms.</p>
                    <p className={s.successSubTitle}>A copy of the agreement has been sent to your email for your records.</p>
                    <Button type="primary" onClick={handleBackToHome}>Back to home</Button>
                </div>
            );
        }

        return (
            <div className={s.wrap}>
                <div className={s.title}>{
                    agreementReviewTypeList.length < 2 ? getGlobalLayer('showUniprofileAgreementSignModal')?.title : `${getGlobalLayer('showUniprofileAgreementSignModal')?.title} (${currentAgreementIndex + 1}/${agreementReviewTypeList.length})`
                }
                </div>
                <div className={s.subTitle}>We’ve updated our user agreement to serve you better. Please review and accept the updated terms before continuing to use our app.</div>

                {
                    (getInfoLoading || loadingHome) ? <Spin spinning /> :
                        (
                            <div className={s.contentBox}>
                                <div className={s.iframeWrap}>
                                    <iframe className={s.iframe} title="overview" src={contractUrl} />
                                    {/* <iframe className={s.iframe} title="overview" src={`https://docs.google.com/viewer?url=${contractUrl}&embedded=true`} /> */}
                                </div>
                                <div className={s.confirmBox}>
                                    <Checkbox checked={checked} className={s.checkbox} onChange={handleCheckBoxChange}>By checking this box, I agree to the terms outlined in the agreement.</Checkbox>
                                    {!checked && showCheckTip && <Alert className={s.alertMessage} message="Please accept the agreement to proceed to the next step." type="error" showIcon />}
                                    <Form
                                        form={agreementForm}
                                        id="agreementForm"
                                        layout="vertical"
                                    >
                                        <div className={s.foromItems}>
                                            <div className={s.formItem}>
                                                <Form.Item
                                                    label="Signature"
                                                    name="contractSignName"
                                                    style={{ marginBottom: '4px' }}
                                                    validateFirst
                                                    rules={[
                                                        { required: true, message: 'This is a required field' },
                                                    ]}
                                                >
                                                    <Input style={{ width: '100%' }} type="text" onChange={handleNameChange} placeholder="Type your name" />
                                                </Form.Item>
                                                <div className={s.signatureText}>{name}</div>
                                            </div>
                                            <div className={`${s.formItem} ${s.fromItemDate}`}>
                                                <Form.Item
                                                    label="Today’s date"
                                                    name="contractSignDate"
                                                    style={{ marginBottom: '4px' }}
                                                    validateFirst
                                                    rules={[
                                                        { required: true, message: 'This is a required field' },
                                                    ]}
                                                >
                                                    <DatePicker style={{ width: '100%' }} format="MM/DD/YYYY" disabledDate={(current) => current && current > dayjs().endOf('day')} onChange={handleDateChange} />
                                                </Form.Item>
                                                <div className={s.signatureText}>{date}</div>
                                            </div>
                                            <div className={`${s.formItem} ${s.formItemAddress}`}>
                                                <Form.Item
                                                    label={
                                                        <div className={s.formLabel}>Address
                                                            <Tooltip overlayClassName={s.tipCard} overlayInnerStyle={{ padding: '12px' }} color="#FFFFFF" title={<div className={s.tipText}>Enter your official address to receive legal documents and critical updates for your practice and contract obligations.</div>}>
                                                                <div className={s.questionMarkIcon} />
                                                            </Tooltip>
                                                        </div>
                                                    }
                                                    name="contractSignAddress"
                                                    style={{ marginBottom: '4px' }}
                                                    validateFirst
                                                    rules={[
                                                        { required: true, message: 'This is a required field' },
                                                        // FormValidationHelper.validateNonChinese('Please do not enter Chinese'),
                                                    ]}
                                                >
                                                    <Input style={{ width: '100%' }} type="text" placeholder="Type your address" onChange={handleAddressChange} />
                                                </Form.Item>
                                                <div className={s.signatureText}>{address}</div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        )
                }
            </div>
        );
    }, [address, agreementForm, agreementReviewTypeList, checked, completed, contractUrl, currentAgreementIndex, date, getGlobalLayer, getInfoLoading, handleAddressChange, handleBackToHome, handleCheckBoxChange, handleDateChange, handleNameChange, loadingHome, name, noneAgreement, showCheckTip]);

    return (
        <Modal
            zIndex={10003}
            width="85vw"
            style={{ maxWidth: '1440px' }}
            maskClosable={false}
            open={getGlobalLayer('showUniprofileAgreementSignModal')?.show}
            onCancel={hideModal}
            footer={(completed || noneAgreement) ? null : (
                <div className={s.footer}>
                    <Button onClick={hideModal}>Cancel</Button>
                    <Button type="primary" loading={checkoutLoading} onClick={handleFormSubmit} disabled={getInfoLoading}>Agree</Button>
                </div>
            )}
        >
            {
                renderContent
            }
        </Modal>
    );
};

export default ProviderAgreement;
