// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--HAzL5 {
  padding: 30px 24px;
  font-family: Inter;
  font-style: normal;
  border: 1px solid var(--gray);
  border-radius: 10px;
}
.s-module__wrap--HAzL5 .s-module__title--cEIfl {
  margin-bottom: 16px;
  color: var(--gray-700, #374151);
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  /* 100% */
}
.s-module__wrap--HAzL5 .s-module__box--BJ0cg {
  margin-bottom: 16px;
}
.s-module__wrap--HAzL5 .s-module__box--BJ0cg .s-module__subTitle--CBsOw {
  margin-bottom: 4px;
  color: var(--gray-500);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}
.s-module__wrap--HAzL5 .s-module__box--BJ0cg ul li {
  margin-bottom: 12px;
  color: #111827;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}
.s-module__wrap--HAzL5 .s-module__box--BJ0cg .s-module__noncontent--lep26 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray-500);
}
`, "",{"version":3,"sources":["webpack://./src/components/HelloKlarityFormComponents/Recognition/s.module.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,kBAAA;EACA,kBAAA;EACA,6BAAA;EACA,mBAAA;AACJ;AANA;EAQQ,mBAAA;EACA,+BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACN,SAAS;AACX;AAdA;EAgBQ,mBAAA;AACR;AAjBA;EAmBY,kBAAA;EACA,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACV,aAAa;AACf;AAzBA;EA4BgB,mBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EAAd,aAAa;AACf;AAjCA;EAqCY,oBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AADZ","sourcesContent":[".wrap {\n    padding: 30px 24px;\n    font-family: Inter;\n    font-style: normal;\n    border: 1px solid var(--gray);\n    border-radius: 10px;\n\n    .title {\n        margin-bottom: 16px;\n        color: var(--gray-700, #374151);\n        font-size: 20px;\n        font-weight: 500;\n        line-height: 20px; /* 100% */\n    }\n\n    .box {\n        margin-bottom: 16px;\n\n        .subTitle {\n            margin-bottom: 4px;\n            color: var(--gray-500);\n            font-size: 14px;\n            font-weight: 500;\n            line-height: 20px; /* 142.857% */\n        }\n\n        ul {\n            li {\n                margin-bottom: 12px;\n                color: #111827;\n                font-size: 14px;\n                font-weight: 400;\n                line-height: 20px; /* 142.857% */\n            }\n        }\n\n        .noncontent {\n            font-family: 'Inter';\n            font-style: normal;\n            font-weight: 400;\n            font-size: 14px;\n            line-height: 20px;\n            color: var(--gray-500);\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--HAzL5`,
	"title": `s-module__title--cEIfl`,
	"box": `s-module__box--BJ0cg`,
	"subTitle": `s-module__subTitle--CBsOw`,
	"noncontent": `s-module__noncontent--lep26`
};
export default ___CSS_LOADER_EXPORT___;
