import React, { useCallback, useEffect, useMemo, useState } from 'react';
import s from './s.module.less';
import { Select } from 'antd';
import { IService } from 'types/practiceFront';
import { PracticeAddressUpdateType } from 'types/applicationForm';

interface IProps {
    type: PracticeAddressUpdateType;
    addressList: Array<{ value: number, label: string, isDefault: boolean }>
    addressId: number,
    index: number,
    data: IService,
    onUpdate: (serviceId: number, addressId: number) => void;
}

const ServiceItem = ({
    addressList = [],
    index,
    data,
    addressId,
    type = PracticeAddressUpdateType.UPDATE,
    onUpdate,
}: IProps) => {
    const [showError, setShowError] = useState(false);
    const [selectValue, setSelectValue] = useState(addressId);
    const [addressOptions, setAddressOptions] = useState< Array<{ value: number, label: string, isDefault: boolean }>>([]);

    // useEffect(() => {
    //     if (addressId) {
    //         setSelectValue(addressId);
    //     }
    // }, [addressId]);

    useEffect(() => {
        let defaultSelectedAddressId = addressId;
        let newAddressOptions = addressList;
        if (type === PracticeAddressUpdateType.REMOVE) {
            newAddressOptions = addressList
                .filter((address) => address.value !== addressId);
            const defaultAddress = newAddressOptions.find((address) => !!address.isDefault);

            if (defaultAddress) {
                defaultSelectedAddressId = defaultAddress.value;
            } else if (newAddressOptions.length > 0) {
                defaultSelectedAddressId = newAddressOptions[0].value;
            }
        }

        setAddressOptions(newAddressOptions);
        setShowError(false);
        setSelectValue(defaultSelectedAddressId);
        onUpdate(data?.id || -1, Number(defaultSelectedAddressId));
    }, [addressId, addressList, data?.id, onUpdate, type]);

    useEffect(() => {
        setShowError(!addressId);
    }, [addressId, data]);

    const { description, duration, name, personType, telehealthType, price } = data;

    const visitTypeText = useMemo(() => {
        if (telehealthType && telehealthType.length > 0 && personType && personType.length > 0) {
            return 'Video or in-person visits';
        } else if (telehealthType && telehealthType.length > 0) {
            return 'Video visits only';
        } else if (personType && personType.length > 0) {
            return 'In-person visits only';
        }

        return 'No meeting needed';
    }, [personType, telehealthType]);

    const handleChange = useCallback((value: string) => {
        setShowError(!value);
        setSelectValue(Number(value));
        onUpdate(data?.id || -1, Number(value));
    }, [data.id, onUpdate]);

    const timeInfo = (
        <>
            <span>{duration}mins</span>
            <span>•</span>
            <span>{visitTypeText}</span>
            <span>•</span>
            <span>Existing patients only</span>
        </>
    );

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <div className={s.label}>Service {index + 1}</div>
                <div className={s.btns} />
            </div>
            <div className={s.intro}>
                <div className={s.name}>{name} | ${price} / visit</div>
                <div className={s.desc}>{description}</div>
                <div className={s.timeloc}>
                    <span>{timeInfo}</span>
                </div>
                <div className={`${s.updateAddress} ${s.required}`}>Updated address</div>
                <Select className={s.addressSelect} value={selectValue} options={addressOptions} onChange={handleChange} allowClear />
                {showError && <div className={s.errorMsg}>Updated address is required</div> }
            </div>
        </div>
    );
};

export default ServiceItem;
