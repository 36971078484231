import React from 'react';
import { Button, message, Modal } from 'antd';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import Seperator from './imgs/Seperator.svg';
// import useMyListedChannels from 'hooks/useMyListedChannels';
import FirstStep from './components/FirstStep';
import SecondStep from './components/SecondStep';
import ThirdStep from './components/ThirdStep';
import { EProspectType, EStatus, Prospect } from 'types/common';
import useMyAllProspectList from 'hooks/useMyAllProspectList';
import useMyPatientList from 'hooks/useMyPatientList';
import { ThirdStepDataType } from './types';
import { batchCreateProspect, createProspect } from 'api/prospect';
import { isSameProspect } from 'utils/common';
import useLiveChannels from 'hooks/useLiveChannels';
import { OTHERS_CHANNEL_ID, OTHERS_CHANNEL_NAME, PF_CHANNEL_ID, PF_CHANNEL_NAME } from 'constants/common';
import Done from './imgs/Step.png';
import { isValidEmail, isValidUSPhone } from 'utils/form';

type Props = {
    onNewDataUpdated?: (newData: Partial<Prospect>[]) => void;
    onCannel?: (needRefresh?:boolean)=>void,
    onSuccess?: ()=>void,
    show?:boolean
};

const UploadPatientListModal = (props: Props) => {
    const { onCannel, onSuccess, show, onNewDataUpdated } = props;
    const [step, setStep] = React.useState(1);
    const [secondStepData, setSecondStepData] = React.useState<Partial<Prospect>[] | undefined>(undefined);
    const [thirdStepData, setThirdStepData] = React.useState<ThirdStepDataType | undefined>(undefined);
    const [data, loadingPatient, run] = useMyAllProspectList();
    const [, , runFetchMyPatient] = useMyPatientList();
    const [submiting, setSubmiting] = React.useState(false);
    const [liveChannels, liveChannelsLoading] = useLiveChannels();

    const [callToApiDataFromThirdStep, setCallToApiDataFromThirdStep] = React.useState<Partial<Prospect>[]>([]);

    React.useEffect(() => {
        if (!show) {
            setStep(1);
            setSecondStepData(undefined);
            setThirdStepData(undefined);
            setSubmiting(false);
            setCallToApiDataFromThirdStep([]);
        }
    }, [show]);

    const isValidEmaliInput = (email?: string) => {
        if (!email) {
            return false;
        }
        return isValidEmail(email);
    };

    const isValidTelInput = (tel?: string) => {
        if (!tel) {
            return false;
        }
        return isValidUSPhone(tel);
    };

    const handleNext = async () => {
        if (step === 1) {
            setStep(step + 1);
            return;
        }
        if (step === 2) {
            const thirdData:ThirdStepDataType = {
                importedAmount: 0,
                missingInfoAmount: 0,
                pendingSelectAmount: 0,
                pendingSelectData: [],
            };
            const callToApiData:Partial<Prospect>[] = [];

            const existEmailProspectMap:Record<string, Partial<Prospect>> = {};
            const newAddEmailProspectMap:Record<string, Partial<Prospect>[]> = {};

            data?.forEach((item:Prospect) => {
                existEmailProspectMap[item.email] = item;
            });
            if (secondStepData) {
                for (let i = 0; i < secondStepData.length; i++) {
                    const item = secondStepData[i];
                    if (!isValidTelInput(item.tel)) {
                        item.tel = '';
                    }
                    if (!isValidEmaliInput(item.email) || !item.email) {
                        thirdData.missingInfoAmount += 1;
                    } else if (newAddEmailProspectMap[item.email]) {
                        const currentItems = newAddEmailProspectMap[item.email];
                        const exist = currentItems.find((existItem) => {
                            return isSameProspect(existItem, item);
                        });
                        if (!exist) {
                            currentItems.push(item);
                        }
                    } else if (existEmailProspectMap[item.email]) {
                        //detect duplication logic
                        const existItem = existEmailProspectMap[item.email];
                        if (isSameProspect(existItem, item)) {
                            //all the same
                            existItem.type = EProspectType.PATIENT;
                            newAddEmailProspectMap[item.email] = [existItem];
                        } else {
                            //all set to PATIENT
                            item.type = EProspectType.PATIENT;
                            existItem.type = EProspectType.PATIENT;
                            newAddEmailProspectMap[item.email] = [item, existItem];
                        }
                    } else {
                        newAddEmailProspectMap[item.email] = [{ ...item, type: EProspectType.PATIENT }];
                    }
                }
                Object.keys(newAddEmailProspectMap).forEach((key) => {
                    const items = newAddEmailProspectMap[key];
                    if (items.length === 1) {
                        callToApiData.push(items[0]);
                        thirdData.importedAmount += 1;
                    } else {
                        thirdData.pendingSelectAmount += 1;
                        thirdData.pendingSelectData.push(items);
                    }
                });
            }
            if (callToApiData.length > 0) {
                if (callToApiData) {
                    setSubmiting(true);
                    const batchAdditionData = callToApiData.map((e) => {
                        const item = { ...e, type: EProspectType.PATIENT };
                        if (item.channel) {
                            const targetChannel = liveChannels?.find((channel) => { return channel.name?.toLocaleLowerCase()?.includes(item.channel!.toLowerCase()); });
                            if (targetChannel) {
                                item.channel = targetChannel.name;
                                item.channelId = targetChannel.id;
                            } else {
                                if (PF_CHANNEL_NAME.toLowerCase().includes(item.channel.toLowerCase())) {
                                    item.channelId = PF_CHANNEL_ID;
                                }
                                if (OTHERS_CHANNEL_NAME.toLowerCase().includes(item.channel.toLowerCase())) {
                                    item.channelId = OTHERS_CHANNEL_ID;
                                }
                            }
                        }
                        return item;
                    });

                    try {
                        const result = await batchCreateProspect(batchAdditionData);

                        if (result.error) {
                            message.error(result.error);
                        } else {
                            onNewDataUpdated?.(batchAdditionData);
                        }
                    } catch (e) {
                        console.error(e);
                    }
                    setSubmiting(false);
                }
            }
            setThirdStepData(thirdData);
            setStep(step + 1);
            return;
        }
        if (step === 3) {
            if (callToApiDataFromThirdStep && callToApiDataFromThirdStep.length > 0) {
                setSubmiting(true);

                const batchAdditionData = callToApiDataFromThirdStep.map((e) => {
                    const item = {
                        ...e,
                        type: EProspectType.PATIENT,
                    };

                    if (item.channel) {
                        const targetChannel = liveChannels?.find((channel) => { return channel.name?.toLocaleLowerCase()?.includes(item.channel!.toLowerCase()); });
                        if (targetChannel) {
                            item.channel = targetChannel.name;
                            item.channelId = targetChannel.id;
                        } else {
                            if (PF_CHANNEL_NAME.toLowerCase().includes(item.channel.toLowerCase())) {
                                item.channelId = PF_CHANNEL_ID;
                            }
                            if (OTHERS_CHANNEL_NAME.toLowerCase().includes(item.channel.toLowerCase())) {
                                item.channelId = OTHERS_CHANNEL_ID;
                            }
                        }
                    }

                    return item;
                });

                try {
                    const result = await batchCreateProspect(batchAdditionData);

                    if (result && !result.error) {
                        setSubmiting(false);
                        run();
                        runFetchMyPatient();
                        onSuccess?.();
                        onNewDataUpdated?.(batchAdditionData);
                    } else {
                        message.error(result.error);
                    }
                } catch (e) {
                    console.error(e);
                }
            } else {
                run();
                runFetchMyPatient();
                onSuccess?.();
            }
        }
    };

    return (
        <Modal
            title="Upload patient list"
            closable
            className={`${commonS.modalFixHeightWrap} ${commonS.modalFixSetedHeightWrap}`}
            width="1200px"
            onCancel={() => {
                setStep(1);
                setSecondStepData(undefined);
                setThirdStepData(undefined);
                onCannel?.();
            }}
            okText="Save"
            open={show}
            footer={
                <div className={s.footer}>
                    {
                        step === 2 ?
                            <Button
                                onClick={() => {
                                    setStep(1);
                                }}
                                loading={submiting}
                            >
                                Back
                            </Button>
                            :
                            <span />
                    }
                    <Button
                        onClick={handleNext}
                        type="primary"
                        loading={submiting}
                        disabled={step === 2 && !secondStepData}
                    >
                        {step === 3 ? 'Finish' : 'Next'}
                    </Button>
                </div>
            }
            destroyOnClose
        >
            <div className={s.wrap}>
                <div className={s.stepWrap}>
                    <div className={step === 1 ? `${s.stepItem} ${s.stepItemActive}` : s.stepItem}>
                        <div className={s.left}>
                            {
                                step > 1 ? <img src={Done} className={s.icon} /> : <span className={s.num}>01</span>
                            }
                        </div>
                        <div className={s.right}>
                            <div className={s.title}>
                                PREPARE
                            </div>
                            <div className={s.desc}>
                                Access template and input patient data
                            </div>
                        </div>
                    </div>
                    <div className={step === 2 ? `${s.stepItem} ${s.stepItemActive}` : s.stepItem}>
                        <div className={s.spe}>
                            <img src={Seperator} />
                        </div>
                        <div className={s.left}>
                            {
                                step > 2 ? <img src={Done} className={s.icon} /> : <span className={s.num}>02</span>
                            }
                        </div>
                        <div className={s.right}>
                            <div className={s.title}>
                                UPLOAD
                            </div>
                            <div className={s.desc}>
                                Upload .csv file
                            </div>
                        </div>
                    </div>
                    <div className={step === 3 ? `${s.stepItem} ${s.stepItemActive}` : s.stepItem}>
                        <div className={s.spe}>
                            <img src={Seperator} />
                        </div>
                        <div className={s.left}>
                            03
                        </div>
                        <div className={s.right}>
                            <div className={s.title}>
                                CONFIRM
                            </div>
                            <div className={s.desc}>
                                Review patient upload
                            </div>
                        </div>
                    </div>
                </div>
                {
                    step === 1 && <FirstStep />
                }
                {
                    step === 2 &&
                    <SecondStep
                        onUpdate={(_data) => {
                            setSecondStepData(_data);
                        }}
                    />
                }
                {
                    step === 3 &&
                    <ThirdStep
                        data={thirdStepData}
                        onUpdate={(_data) => {
                            setCallToApiDataFromThirdStep(_data || []);
                        }}
                    />
                }
            </div>
        </Modal>
    );
};

export default UploadPatientListModal;
