import React, { useCallback, useEffect } from 'react';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { Button, Form, Input, Tooltip } from 'antd';
import { IAboutMe, EditModuleEnum, FormFields } from 'types/practiceFront';
import { removeHtmlTag } from 'utils/common';
import RichText from 'components/RichText';
import { IntroTextLength } from 'constants/common';
import PhotoList from 'components/PhotoList';
import ErrorBlock from '../ErrorBlock';
import PhotoEditorModal from 'components/PhotoEditorModal';
import ProfileTip from 'assets/practiceFront/PF_profile_tip.png';
import AiTextEditor from 'components/AI/AiTextEditor';
import { EAIEditorType } from 'types/ai';

interface IProps {
    providerId: number;
    showEditingTip?: boolean;
    isEditing?: boolean;
    initFormValue: IAboutMe;
    onEditStatusChange: (status: boolean, field: EditModuleEnum) => void;
    onSubmit: (value: IAboutMe, field: EditModuleEnum) => void;
}

const AboutMe = ({
    providerId,
    showEditingTip,
    isEditing,
    initFormValue,
    onEditStatusChange,
    onSubmit,
}: IProps) => {
    const [aboutMeForm] = Form.useForm<IAboutMe>();

    useEffect(() => {
        if (initFormValue) {
            aboutMeForm.setFieldsValue(initFormValue);
        }
    }, [initFormValue, aboutMeForm]);

    const { intro, headLine, yearExp, photo } = initFormValue;

    const handleSubmit = useCallback(() => {
        aboutMeForm.validateFields().then((values) => {
            onSubmit(values, EditModuleEnum.ABOUTME);
        }).catch((e) => {
            console.error(e);
        });
    }, [aboutMeForm, onSubmit]);

    const introWithBr = intro?.replace(/\n/g, '<br />');

    return (
        <div className={s.wrap} id={EditModuleEnum.ABOUTME}>
            <div className={s.header}>
                <div className={s.title}>About me</div>
                <div className={s.btns}>
                    {
                        isEditing ?
                            <>
                                <Button onClick={() => onEditStatusChange(false, EditModuleEnum.ABOUTME)}>Cancel</Button>
                                <Button type="primary" onClick={handleSubmit}>Save changes</Button>
                            </>
                            : <Button onClick={() => onEditStatusChange(true, EditModuleEnum.ABOUTME)}>Edit</Button>
                    }
                </div>
            </div>
            {
                showEditingTip && isEditing && <ErrorBlock />
            }

            <div className={s.body}>
                {
                    !isEditing ?
                        (
                            <div className={s.viewWrap}>
                                <div className={s.viewItem}>
                                    <div className={s.label}>About me </div>
                                    <div className={s.value} dangerouslySetInnerHTML={{ __html: introWithBr }} />
                                </div>
                                <div className={s.viewItem}>
                                    <div className={s.label}>Number of Years in Healthcare</div>
                                    <div className={s.value}>{yearExp}</div>
                                </div>
                                <div className={s.viewItem}>
                                    <div className={s.label}>Headline</div>
                                    <div className={s.value}>{headLine}</div>
                                </div>
                                <div className={s.viewItem}>
                                    <div className={s.label}>Profile photo</div>
                                    <PhotoList previewImageStyle={{ borderRadius: '50%', width: '125px', height: '125px' }} enable={false} min={1} data={[photo]} />
                                </div>
                            </div>
                        )
                        :
                        (
                            <div className={s.formWrap}>
                                <Form
                                    form={aboutMeForm}
                                    name={EditModuleEnum.ABOUTME}
                                    className={commonS.formStyle1}
                                    autoComplete="off"
                                    layout="vertical"
                                >
                                    <div className={s.itemBox}>
                                        <div className={s.item}>
                                            <Form.Item
                                                // label="About me"
                                                name={FormFields.INTRO}
                                                validateFirst
                                                rules={[
                                                    { required: true, message: 'About me is a required field' },
                                                    {
                                                        validator: (rule, value) => {
                                                            if (removeHtmlTag(value).length > IntroTextLength) {
                                                                return Promise.reject(`Maximum ${IntroTextLength} characters`);
                                                            } else {
                                                                return Promise.resolve();
                                                            }
                                                        },
                                                    },
                                                    {
                                                        validator: (rule, value) => {
                                                            if (value.indexOf('<strong>') > -1) {
                                                                return Promise.reject('Please remove all gray placeholder text');
                                                            } else {
                                                                return Promise.resolve();
                                                            }
                                                        },
                                                    },
                                                ]}
                                            >
                                                <AiTextEditor size="small" type={EAIEditorType.ABOUTME} wrapClass={s.textarea} maxLength={IntroTextLength} placeholder="Ex. Years of experience, work experience and title, affiliated associations, treatment philosophy" />
                                                {/* <RichText className={s.textarea} maxLength={IntroTextLength} placeholder="Ex. Years of experience, work experience and title, affiliated associations, treatment philosophy" /> */}
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className={s.itemBox}>
                                        <div className={s.item}>
                                            <Form.Item
                                                label="Number of Years in Healthcare"
                                                name={FormFields.YEAR_EXP}
                                            >
                                                <Input type="number" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className={s.itemBox}>
                                        <div className={s.item}>
                                            <Form.Item
                                                label="Headline"
                                                name={FormFields.HEADLINE}
                                                validateFirst
                                                rules={[
                                                    { required: true, message: 'Headline is a required field' },
                                                ]}
                                            >
                                                <Input type="text" placeholder="Ex. Psychiatric Nurse Practitioner, Adult Psychiatric & Mental Health nurse Practitioner" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className={s.itemBox}>
                                        <div className={s.item}>
                                            <div className={s.formLabel}>Profile photo <a style={{ color: 'red' }}>*</a>
                                                <Tooltip overlayClassName={s.tipCard} overlayInnerStyle={{ padding: '12px' }} color="#FFFFFF" title={<div className={s.tipText}>Photos that include a background typically achieve the best possible appearance and visibility across various channels. <img src={ProfileTip} /></div>}>
                                                    <div className={s.questionMarkIcon} />
                                                </Tooltip>
                                            </div>
                                            <div className={s.subLabel}>Upload a professional photo of yourself with a preferred background. A top-notch profile photo is likely to generate 3x more clicks. You can crop and rotate the image as needed.</div>
                                            <Form.Item
                                                name={FormFields.PHOTO}
                                                validateFirst
                                                rules={[
                                                    { required: true, message: 'Profile photo is a required field' },
                                                ]}
                                            >
                                                <PhotoEditorModal
                                                    isNotInUniprofile
                                                    providerId={Number(providerId)}
                                                    title="Edit photo"
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        )
                }
            </div>
        </div>
    );
};

export default AboutMe;
