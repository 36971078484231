import { wildcard as surveyWildcard } from './survey';

const RegExps = Object.freeze({
    variable: /^[a-zA-Z_]+[a-zA-Z0-9_]*$/,

    name: /^[A-Za-z0-9-_ ]+$/,

    tagKey: /^[A-Za-z0-9-_]+$/,

    numbersOrLetters: /^[A-Za-z0-9]+$/,

    licenseNumber: /^[A-Za-z0-9.]+$/,

    otp: /^\d{6}$/,

    permission: /^[0-7]{3}$/,

    /**
     * Examples: 0, 000, 00123, 10%, 100%, 000100%.
     */
    nonNegativeNumberOrPercent: /^([0-9]*|0*%|0*[1-9][0-9]?%|0*100%)$/,
    /**
     * Examples: 0, 123, 10%, 100%.
     */
    nonNegativeNumberOrPercentStrict: /^(0|[1-9][0-9]*|[1-9][0-9]?%|100%)$/,

    fileDirectory: /^(\/([a-zA-z0-9-_])*)+$/,
    fileGroupOrOwner: /^[a-zA-Z][a-zA-Z0-9-_]{0,49}$/,
    fileName: /^[A-Za-z0-9-_ .]+$/,
    fileMode: /^[0-7]{3}$/,

    filePath: /^(\/([a-zA-z0-9-_.])+)+$/,
    path: /^(\/([a-zA-z0-9-_.])*)+$/,

    ip: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
    subnet: /^((\b|\.)(1|2(?!5(?=6|7|8|9)|6|7|8|9))?\d{1,2}){4}(-((\b|\.)(1|2(?!5(?=6|7|8|9)|6|7|8|9))?\d{1,2}){4}|\/((1|2|3(?=1|2))\d|\d))\b$/,

    relativeFilePath: /^(([a-zA-z0-9-_])+\/?)*$/,

    permissionError: /^you don[\s\S]*permission[\s\S]*/,
    // email: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
    email: /^((?!\.)[\w\-_.+]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gm,
    surveyWildcard: new RegExp(`(${surveyWildcard.join('|')})`),
    phone: /^\+?1?[-. ]?\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4})$/, //old /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/
    nonChineseRegex: /[^\u4E00-\u9FA5]/g,
});

export default RegExps;
