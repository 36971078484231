import React, { useCallback } from 'react';
import { Button, Modal, Spin, message } from 'antd';
import s from './s.module.less';
import { closeKlarityPlanAdvertisingModal } from 'utils/globalLayerControl';
import GlobalLayerStore from 'store/GlobalLayer';
import useSubscriptionPlan from 'hooks/useSubscriptionPlan';
import { TSubscriptionType } from 'types/subscription';
import { getPayLink } from 'api/subscription';
import { setPendingSubscriptionPlan, setSubscriptionCallbackPath } from 'utils/localstore';

const KlarityPlanModal = () => {
    const [plansData, loadingSubscriptionPlan] = useSubscriptionPlan();
    const [getGlobalLayer] = GlobalLayerStore.useStore();
    const [loading, setLoading] = React.useState(false);

    const bestValuePlan = plansData?.klarityPlan?.find((plan) => plan.name === TSubscriptionType.KLARITY_PLUS);

    const setPaySuccessPath = useCallback(() => {
        const paySuccessPath = getGlobalLayer('showKlarityPlanAdvertising')?.paySuccessPath;
        if (paySuccessPath) {
            setSubscriptionCallbackPath(paySuccessPath);
        }
    }, [getGlobalLayer]);

    const handleClose = useCallback(() => {
        setPaySuccessPath();
        getGlobalLayer('showKlarityPlanAdvertising')?.callback?.();
        closeKlarityPlanAdvertisingModal();
    }, [getGlobalLayer, setPaySuccessPath]);

    const handleStartBestValue = useCallback(async () => {
        if (bestValuePlan && bestValuePlan.quarterlyPlanId) {
            setLoading(true);
            const planId = bestValuePlan.quarterlyPlanId;
            const result = await getPayLink(planId, {
                planType: TSubscriptionType.KLARITY_PLUS,
            });
            if (!result.error) {
                const link = result.data.data;
                if (link) {
                    setPaySuccessPath();
                    setPendingSubscriptionPlan(bestValuePlan);
                    window.open(link, '_self');
                } else {
                    message.error('Pay link not found');
                }
            }

            setLoading(false);
        } else {
            message.error('Plan not set');
        }
    }, [bestValuePlan, setPaySuccessPath]);

    return (
        <Modal
            className={s.modal}
            width={992}
            open
            title={<div className={s.header}><div className={s.new}>New</div></div>}
            footer={null}
            onCancel={handleClose}
        >
            <div className={s.wrap}>
                <Spin spinning={!!loadingSubscriptionPlan}>
                    <div className={s.title}>Exclusive plan for Klarity users: attract more patients & grow your practice</div>
                    <div className={s.subTitle}>Upgrade to a 3-month plan and enjoy <span className={s.strong}>15% off</span> – It's our best value!</div>
                    <div className={s.body}>
                        <div className={s.banner} />
                        <Button loading={loading} className={s.mainBtn} type="primary" onClick={handleStartBestValue}>🚀 Start 3-month subscription to save 15%</Button>
                        <a className={s.link} onClick={handleClose}>Check out all Kiwi plans for Klarity users</a>
                    </div>
                </Spin>
            </div>
        </Modal>
    );
};

export default KlarityPlanModal;
