import React from 'react';
import s from './s.module.less';

const ErrorBlock = () => {
    return (
        <div className={s.wrap}>
            <div className={s.errorIcon} />
            <div className={s.errorInfo}>Please save your changes before proceeding to edit another section</div>
        </div>
    );
};

export default ErrorBlock;
