import React from 'react';
import s from './s.module.less';
import { Prospect } from 'types/common';
import { ThirdStepDataType } from '../../types';
import { Radio } from 'antd';

type Props = {
    onUpdate: (data?: Partial<Prospect>[]) => void;
    data?: ThirdStepDataType,
};

const ThirdStep = (props:Props) => {
    const { data, onUpdate } = props;
    const needAction = data?.pendingSelectData && data?.pendingSelectData?.length > 0;
    const hasMiss = !!(data?.missingInfoAmount && data.missingInfoAmount > 0);
    const hasSelect = !!(data?.pendingSelectAmount && data.pendingSelectAmount > 0);
    const pendingSelectData = data?.pendingSelectData || [];
    const [pendingSelect, setPendingSelect] = React.useState<number[]>([]);

    React.useEffect(() => {
        if (data?.pendingSelectData) {
            setPendingSelect(data?.pendingSelectData?.map(() => 1));
        }
    }, [data]);

    React.useEffect(() => {
        const selectData: Partial<Prospect>[] = [];

        if (pendingSelect.length === pendingSelectData?.length) {
            for (let i = 0; i < pendingSelectData.length; i++) {
                if (pendingSelectData[i].length > pendingSelect[i]) {
                    selectData.push(pendingSelectData[i][pendingSelect[i]]);
                } else {
                    selectData.push(pendingSelectData[i][pendingSelect[i]]);
                }
            }
        }
        onUpdate?.([...selectData]);
    }, [pendingSelect, pendingSelectData]);

    return (
        <div className={s.wrap}>
            <h3>Upload summary</h3>
            <ul className={s.des}>
                <li><span className={s.highlight}>{data?.importedAmount}</span> patients imported successfully</li>
                {
                    hasMiss && <li><span className={s.highlight}>{data?.missingInfoAmount}</span> patients were not added due to missing information</li>
                }
                {
                    hasSelect && <li><span className={s.highlight}>{data?.pendingSelectAmount}</span> entries were not added due to duplication with existing data</li>
                }
            </ul>
            {
                needAction && (
                    <>
                        <h3>Patient information conflict detected</h3>
                        <div className={s.des}>The following patients have the same email address but different name or phone number. </div>
                        <div className={s.des}>Please review and choose which record to keep for each. </div>
                        <div className={s.forSelect}>
                            {
                                pendingSelectData?.map((items, inx) => {
                                    return (
                                        <div className={s.selectItem} key={items[0]?.email}>
                                            <Radio.Group
                                                onChange={(e) => {
                                                    const newData = [...pendingSelect];
                                                    newData[inx] = e.target.value;
                                                    setPendingSelect(newData);
                                                }}
                                                value={pendingSelect[inx]}
                                            >
                                                <div className={s.selectItemIn}>
                                                    {
                                                        items.map((item, index) => {
                                                            const val = index;
                                                            return (
                                                                <Radio value={val} key={index}>
                                                                    {item.email} - {item.firstName} {item.lastName} - {item.tel} {item.channel ? `- ${item.channel}` : 'Others'}
                                                                </Radio>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </Radio.Group>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </>
                )
            }

        </div>
    );
};

export default ThirdStep;
