import React from 'react';
import s from './s.module.less';
import { IBasicInfo } from 'types/helloKlarity';
import { getProviderNameDisplay } from 'utils/provider';
import { EMPTY_PLACEHOLDER } from 'constants/common';

interface IProps {
    initFormValue: IBasicInfo;
}

const BasicInfo = ({
    initFormValue,
}: IProps) => {
    const { firstName, lastName, middleName, credential, title, photo, headLine, npi, language, intro, yearExp, providerPractice } = initFormValue || {};
    const providerName = getProviderNameDisplay({
        firstName,
        lastName,
        middleName,
    });
    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <div className={s.title}>Basic Info</div>
            </div>
            <div className={s.body}>
                <div className={s.viewWrap}>
                    <div className={s.viewItem}>
                        <div className={s.label}>Name</div>
                        <div className={s.value}>{providerName || EMPTY_PLACEHOLDER}</div>
                    </div>
                    <div className={s.viewItem}>
                        <div className={s.label}>Title</div>
                        <div className={s.value}>{title || EMPTY_PLACEHOLDER}</div>
                    </div>
                    <div className={s.viewItem}>
                        <div className={s.label}>Credentials</div>
                        <div className={s.value}>{credential?.join(',') || EMPTY_PLACEHOLDER}</div>
                    </div>
                    <div className={s.viewItem}>
                        <div className={s.label}>Headlines</div>
                        <div className={s.value}>{headLine || EMPTY_PLACEHOLDER}</div>
                    </div>
                    <div className={s.viewItem}>
                        <div className={s.label}>Years of experience in healthcare</div>
                        <div className={s.value}>{yearExp || EMPTY_PLACEHOLDER}</div>
                    </div>
                    <div className={s.viewItem}>
                        <div className={s.label}>Profile photo </div>
                        <div className={s.value}>
                            {
                                photo ? <img className={s.avatar} src={photo} /> : EMPTY_PLACEHOLDER
                            }
                        </div>
                    </div>
                    <div className={s.viewItem}>
                        <div className={s.label}>About me </div>
                        <div className={s.value} dangerouslySetInnerHTML={{ __html: intro }} />
                    </div>
                    <div className={s.row}>
                        <div className={s.viewItem}>
                            <div className={s.label}>NPI number</div>
                            <div className={s.value}>{npi || EMPTY_PLACEHOLDER}</div>
                        </div>
                        <div className={s.viewItem}>
                            <div className={s.label}>Language</div>
                            <div className={s.value}>{language || EMPTY_PLACEHOLDER}</div>
                        </div>
                    </div>
                    <div className={s.viewItem}>
                        <div className={s.label}>Practice name</div>
                        <div className={s.value}>{providerPractice?.practiceName || EMPTY_PLACEHOLDER}</div>
                    </div>
                    <div className={s.viewItem}>
                        <div className={s.label}>Practice Logo</div>
                        <div className={s.value}>
                            {
                                providerPractice?.practiceLogo ? <img className={s.logo} src={providerPractice.practiceLogo} /> : EMPTY_PLACEHOLDER
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BasicInfo;
