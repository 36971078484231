import React from 'react';
import { Button, ButtonProps, message } from 'antd';
import { getPayLink } from 'api/subscription';
import { TSubscriptionItem } from 'types/subscription';
import { setPendingSubscriptionPlan } from 'utils/localstore';
import s from './s.module.less';

type IProps = ButtonProps & {
    selectedPanId?: number;
    text?: string;
    planId?: number;
    plan?: TSubscriptionItem;
    onPlanSelect?: (plan: TSubscriptionItem, planId: number) => void;
    promotionCode?: string,
};

const SubscriptionButton = (props: IProps) => {
    const { text = 'Upgrade', planId, plan, className, selectedPanId, promotionCode, ...reset } = props;
    const [loading, setLoading] = React.useState(false);

    return (
        <Button
            {...reset}
            className={`${selectedPanId && selectedPanId === planId ? s.concave : ''} ${className || ''}`}
            loading={loading}
            style={{ fontSize: '14px' }}
            onClick={async () => {
                if (typeof props.onPlanSelect === 'function' && plan && planId) {
                    props.onPlanSelect(plan, planId);
                } else {
                    if (!planId) {
                        message.error('Plan not set');
                        return;
                    }
                    setLoading(true);
                    const result = await getPayLink(planId, undefined, promotionCode);
                    if (!result.error) {
                        const link = result.data.data;
                        if (link) {
                            setPendingSubscriptionPlan(plan);
                            window.open(link, '_self');
                        } else {
                            message.error('Pay link not found');
                        }
                    }

                    setLoading(false);
                }
            }}
        >{text}
        </Button>
    );
};

export default SubscriptionButton;
