import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import s from './s.module.less';
import SwiperCore, { Autoplay } from 'swiper';
import BotChatLogo from 'assets/aiChat/botChatLogo.svg';
import { Progress } from 'antd';
import { getResumeAnalysisProcess } from 'api/uniprofileResume';
import { ResumeStepName } from 'pages/UniprofileResume/types';
import { getAvaDocumentId } from 'utils/localstore';
import useGetUniprofileResumeInfo from 'hooks/useGetUniprofileResumeInfo';

interface IProps {
    // documentId?: string;
    onNext: () => void;
    onFinish?: () => void;
}

SwiperCore.use([Autoplay]);

const AnalysisProcess = ({
    // documentId,
    onNext,
    onFinish,
}: IProps) => {
    const documentId = getAvaDocumentId();
    // const [timeCounter, setTimeCounter] = useState(0);
    const [steps, setSteps] = useState({
        [ResumeStepName.PROFILE]: false,
        [ResumeStepName.HEALTHCARE_EXPERIENCE]: false,
        [ResumeStepName.EDUCATION]: false,
        [ResumeStepName.ADDITIONAL_INFORMATION]: false,
        [ResumeStepName.PRACTICE]: false,
        [ResumeStepName.HEADLINE]: false,
        [ResumeStepName.TREATMENT_APPROACH]: false,
        [ResumeStepName.TREATMENT_PHILOSOPHY]: false,
        [ResumeStepName.PRACTICE_DESCRIPTION]: false,
        ABOUTME: false,
    });
    const [hasSent, setHasSent] = useState(false);
    const loopInstance = useRef<ReturnType<typeof setInterval>>();
    const analysisPercentage = useRef(100);
    const intervalTime = useRef(5000);
    const [,infoLoading,,fetchResuInfo] = useGetUniprofileResumeInfo();

    // const counterInstance = useRef<ReturnType<typeof setInterval>>();

    // const setTimeCouter = useCallback(() => {
    //     if (!counterInstance.current) {
    //         counterInstance.current = setInterval(() => {
    //             setTimeCounter(timeCounter + 1);
    //         }, 1000);
    //     }
    // }, [timeCounter]);

    // useEffect(() => {
    //     setTimeCouter();
    // }, [setTimeCouter]);

    const startAnalysisRequests = useCallback(async (id: string, step: string) => {
        return getResumeAnalysisProcess({
            documentId: id,
            step,
        });
        // try {
        //     const requestResult = await getResumeAnalysisProcess({
        //         documentId: id,
        //         step,
        //     });
        //     if (requestResult && !requestResult.error && requestResult.data.data !== false) {
        //         setSteps({
        //             ...steps,
        //             [step]: true,
        //         });
        //     }
        // } catch (e) {
        //     console.error(e);
        // }
    }, []);

    // const getAboutme = useCallback(async (id: string) => {
    //     try {
    //         const requestResult = await getResumeAnalysisProcess({
    //             documentId: id,
    //             step: 'ABOUTME',
    //         });
    //         if (requestResult && !requestResult.error) {
    //             setSteps({
    //                 ...steps,
    //                 aboutMe: true,
    //             });
    //         }
    //     } catch (e) {
    //         console.error(e);
    //     }
    // }, [steps]);

    const destoryInterval = useCallback(() => {
        if (loopInstance.current) {
            clearInterval(loopInstance.current);
        }
    }, []);

    const fetchData = useCallback(async (id: string) => {
        const unfinishedSteps = Object.entries(steps).filter(([, v]) => !v);
        const requests = unfinishedSteps.map(([step]) => {
            return startAnalysisRequests(id, step);
        });

        try {
            const results = await Promise.all(requests);
            unfinishedSteps.forEach(([stepKey], index) => {
                const result = results[index]?.data?.data;

                if (result) {
                    steps[stepKey] = true;

                    setSteps({
                        ...steps,
                    });
                }
            });
        } catch (e) {
            console.error(e);
        }
        // getAboutme(id);
    }, [startAnalysisRequests, steps]);

    const loopGetData = useCallback((id: string) => {
        if (!loopInstance.current) {
            fetchData(id);
            loopInstance.current = setInterval(() => {
                fetchData(id);
            }, intervalTime.current);
        }
    }, [fetchData]);

    useEffect(() => {
        if (documentId && !hasSent) {
            loopGetData(documentId);
            setHasSent(true);
        }
    }, [documentId, hasSent, loopGetData]);

    const percentage = useMemo(() => {
        const result = (Object.values(steps).filter((e) => e).length / Object.keys(steps).length) * analysisPercentage.current;
        return result === 0 ? 5 : result;
    }, [steps]);

    const fetchFinished = useCallback(async () => {
        // clearInterval(counterInstance.current);
        await fetchResuInfo(documentId as string);
        destoryInterval();
        onNext();
        onFinish?.();
    }, [destoryInterval, documentId, fetchResuInfo, onFinish, onNext]);

    useEffect(() => {
        if (Object.values(steps).filter((v) => !!v).length === Object.keys(steps).length) {
            fetchFinished();
        }
    }, [fetchFinished, steps]);

    useEffect(() => {
        return () => {
            destoryInterval();
        };
    }, [destoryInterval]);

    return (
        <div className={s.wrap}>
            {/* <div style={{ fontSize: '20px' }}>{timeCounter}</div> */}
            <div className={s.contentWrap}>
                <div className={s.descWrap}>
                    <div className={s.inner}>
                        <img src={BotChatLogo} />
                        <div className={s.title}>Lower your initial rate</div>
                        <div className={s.subTitle}>“You won’t acquire patients if you cannot attract them. Be competitive with your initial rate. Be profitable with your follow-up rate.”</div>
                    </div>
                    <div className={s.progressBar}>
                        <Progress className={s.progress} percent={percentage} showInfo={false} strokeColor="#00816B" />
                    </div>
                </div>
                <div className={s.processIntro}>
                    <div className={s.introTitle}><span className={s.avaLogo}>Ava</span> is processing your resume...</div>
                    <p>Please don't close the browser while Ava is setting up your UniProfile.</p>
                    <p>This might take 1 to 3 minutes.</p>
                </div>
            </div>
        </div>
    );
};

export default AnalysisProcess;
