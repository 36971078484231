import React, { useCallback, useState } from 'react';
import { Button, Input, message, Popover } from 'antd';
import s from './s.module.less';

interface IProps {
    url?: string;
    onUrlChange?: (url: string) => void;
    onCoverImageFileChange?: (file: File | null) => void;
    extraFooterBeforeCapture?: React.ReactElement,
}

const VideoPreview = ({
    url = '',
    extraFooterBeforeCapture,
    onUrlChange,
    onCoverImageFileChange,
}: IProps) => {
    const videoRef = React.useRef<HTMLVideoElement>(null);
    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    const [file, setFile] = useState<File | null>(null);
    const [coverUrl, setCoverUrl] = useState("");
    const [isPlay, setIsPlay] = useState(false);

    const captureFrame = (disableNote?: boolean) => {
        const video = videoRef.current;
        const canvas = canvasRef.current;

        if (video && canvas) {
            const context = canvas.getContext('2d');
            if (!context) return;
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            // 将当前视频帧绘制到 canvas 上
            context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

            canvas.toBlob((blob) => {
                if (blob) {
                    const file = new File([blob], 'cover.png', { type: 'image/png' });
                    setFile(file);
                    onCoverImageFileChange?.(file);
                    if (!disableNote) {
                        message.success('Cover image updated!');
                    }
                }
            }, 'image/png');

            // 将 canvas 内容转换为图片 URL
            const imageUrl = canvas.toDataURL('image/png');
            setCoverUrl(imageUrl);
            // 创建一个链接元素并触发下载
            const link = document.createElement('a');
            link.href = imageUrl;
            link.download = 'frame.png';
            //link.click();
        }
    };

    React.useEffect(() => {
        const videoElement = videoRef.current;

        if (videoElement) {
            const handlePause = () => {
                setIsPlay(false)
            };
            const handlePlay = () => {
                setIsPlay(true)
            };
            const handleCanPlay = () => {
                console.log('Video is ready to play');
                setTimeout(() => {
                    captureFrame(true);
                }, 1000)
                // 这里可以进行其他当视频准备好播放后的逻辑处理，比如更新 UI 状态
            };

            // 绑定暂停事件
            videoElement.addEventListener('pause', handlePause);
            videoElement.addEventListener('play', handlePlay);
            videoElement.addEventListener('canplay', handleCanPlay);

            // 清理函数，组件卸载时移除事件监听
            return () => {
                videoElement.removeEventListener('pause', handlePause);
                videoElement.removeEventListener('pause', handlePlay);
            };
        }
    }, []);

    return (
        <div
            className={s.wrap}
        >
            <video
                ref={videoRef}
                width={300}
                height={400}
                controls
                src={url}
                crossOrigin={'anonymous'}
            >
                您的浏览器不支持 HTML5 视频。
            </video>
            <div className={s.btnWrap}>
                <Button
                    className={s.btn}
                    onClick={() => {
                        onUrlChange?.('');
                    }}
                >
                    Replace video
                </Button>
                {/* <Popover
                    trigger="hover"
                    title=""
                    placement='topRight'
                    content={<div className={s.toolWrap}>
                        <div>We will take first picture in video as cover image, but you can select others if you want</div>
                    </div>}
                >

                </Popover> */}

            </div>
            <div className={s.capWrap}>
                <div className={s.capTitle}>Set up cover image</div>
                <div className={s.coverWrap}>
                    <div className={s.cvLeft}>
                        {coverUrl && <img className={s.coverImg} src={coverUrl} />}
                        {/* <Button
                        size='small'
                        type='primary'
                        className={s.captureBtn}
                        onClick={captureFrame}>
                        {coverUrl ? "Change cover photo" : "Select as cover photo"}
                    </Button> */}
                    </div>
                    <div className={s.cvRight}>
                        <p>The first frame is set as your default cover image. To change it, play the video and click 'Update cover image' at any point. The current frame will be saved as your new cover.</p>
                        <Button
                            onClick={() => {
                                captureFrame();
                            }}
                            disabled={isPlay}
                            type="primary"
                            className={s.captureBtn}
                        >
                            Update cover image
                        </Button>
                    </div>
                </div>

                <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
            </div>
        </div>
    );
};

export default VideoPreview;
