import React from 'react';

const LikedSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
        <g clipPath="url(#clip0_3081_11065)">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.45 1.41821C6.64405 1.14224 6.92525 0.939524 7.2484 0.842644C7.57156 0.745765 7.9179 0.760347 8.23177 0.884048C8.54564 1.00775 8.8088 1.23338 8.97897 1.52469C9.14913 1.816 9.21641 2.15605 9.17 2.49021L8.973 3.89021H10.889C11.456 3.89021 12.216 4.15821 12.833 4.65221C13.466 5.15821 14 5.94421 14 7.00021C14 7.98621 13.523 9.50121 12.831 10.7552C12.481 11.3882 12.061 11.9882 11.586 12.4352C11.117 12.8772 10.543 13.2212 9.899 13.2212C7.469 13.2212 5.385 12.6612 4.326 12.3612C4.30325 12.3546 4.27969 12.3512 4.256 12.3512C4.18801 12.3509 4.1229 12.3238 4.07492 12.2756C4.02694 12.2274 4 12.1622 4 12.0942V5.22021C4.00004 5.01438 4.0636 4.81358 4.182 4.64521L6.45 1.41821ZM1.375 4.52821C0.615 4.52821 0 5.14421 0 5.90321V11.6532C0 12.0179 0.144866 12.3676 0.402728 12.6255C0.660591 12.8833 1.01033 13.0282 1.375 13.0282C1.73967 13.0282 2.08941 12.8833 2.34727 12.6255C2.60513 12.3676 2.75 12.0179 2.75 11.6532V5.90321C2.75 5.14321 2.134 4.52821 1.375 4.52821Z" fill="#00816B" />
        </g>
        <defs>
            <clipPath id="clip0_3081_11065">
                <rect width="14" height="14" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default LikedSVG;
