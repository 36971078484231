// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--LCsjx {
  width: 100%;
}
.s-module__wrap--LCsjx .ant-select-multiple .ant-select-selection-item {
  background-color: transparent !important;
  border: none !important;
}
.s-module__wrap--LCsjx .s-module__selector--h9eFp {
  width: 100%;
}
.s-module__wrap--LCsjx .s-module__tagPlaceholder--mD_im {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}
`, "",{"version":3,"sources":["webpack://./src/components/NumberSelector/s.module.less"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;AAFA;EAKY,wCAAA;EACA,uBAAA;AAAZ;AANA;EAWQ,WAAA;AAFR;AATA;EAeQ,WAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EAHN,aAAa;AACf","sourcesContent":[".wrap {\n    width: 100%;\n\n    :global {\n        .ant-select-multiple .ant-select-selection-item {\n            background-color: transparent !important;\n            border: none !important;\n        }\n    }\n\n    .selector {\n        width: 100%;\n    }\n\n    .tagPlaceholder {\n        color: #000;\n        font-family: Inter;\n        font-size: 14px;\n        font-style: normal;\n        font-weight: 400;\n        line-height: 20px; /* 142.857% */\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--LCsjx`,
	"selector": `s-module__selector--h9eFp`,
	"tagPlaceholder": `s-module__tagPlaceholder--mD_im`
};
export default ___CSS_LOADER_EXPORT___;
