import React from 'react';
import { Button, Spin, message, Radio } from 'antd';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { updateChannelDetail } from 'api/operation';
import { ChannelItem, EChannelServiceConfig } from 'types/common';

type Props = {
    editable:boolean,
    initValue?: { name?:string, serviceConfig?:EChannelServiceConfig },
    channelId?:number,
    viewOnly?:boolean,
    onChange?: (value:Partial<ChannelItem>)=>void,
    onNullState?: ()=>void,
    isOperation?:boolean,
};

type ServiceConfigInfo = {
    title:string,
    content:string,
};

const serviceConfigInfoMap:Record<EChannelServiceConfig, ServiceConfigInfo> = {
    [EChannelServiceConfig.NO_LIMIT]: {
        title: 'Multiple Service Types, No Limitation',
        content: 'This option is for channels that have no limitations on the number of service types or services that can be presented. Providers can create as many services under each service type as they want and all services will be presented on this channel.',
    },
    [EChannelServiceConfig.SINGLE_TYPE_MULT_SERVICE]: {
        title: 'Single Service Type, Multiple Services',
        content: 'This option is for channels that allow multiple services under one service type. Providers can create multiple services under each service type and choose which services to present on this channel.',
    },
    [EChannelServiceConfig.SINGLE_TYPE_SINGLE_SERVICE]: {
        title: 'Single Service Type, Single Service',
        content: 'This option is for channels that require only one service per service type. Providers will be able to create only one service per service type to be presented on this channel.',
    },
};

const ServiceConfig = (props: Props) => {
    const { editable = true, viewOnly, initValue, channelId, onChange, onNullState, isOperation } = props;
    const [enable, setEnable] = React.useState(!!editable);
    const [submiting, setSubmiting] = React.useState(false);
    const [,forceUpdate] = React.useState({});
    const [currentServiceConfig, setCurrentServiceConfig] = React.useState<EChannelServiceConfig>(EChannelServiceConfig.NO_LIMIT);

    const serviceConfigInfo = serviceConfigInfoMap[currentServiceConfig];

    React.useEffect(() => {
        setEnable(!!editable);
    }, [editable]);

    const onSubmit = async () => {
        try {
            setSubmiting(true);
            const item:Partial<ChannelItem> = {
                serviceConfig: currentServiceConfig,
                name: initValue?.name || 'New Channel',
            };
            if (channelId) {
                item.id = channelId;
            }
            const result = await updateChannelDetail(item);

            if (!result.error) {
                const id = result.data.data;
                message.success('Channel info has updated');
                setEnable(false);
                if (id && onChange && !channelId) {
                    //create new
                    onChange({ id });
                }
            } else {
                message.error(result.error);
            }
        } catch (e:any) {
            console.log(e);
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };

    React.useEffect(() => {
        if (initValue && initValue.serviceConfig) {
            setCurrentServiceConfig(initValue.serviceConfig);
            forceUpdate({});
        }
    }, [initValue]);

    return (
        <div className={s.wrap}>
            <div
                className={commonS.formStyle1}
            >
                <Spin spinning={submiting}>
                    <div className={s.module}>
                        <h3><span>Services configuration</span>
                            {!enable && !viewOnly &&
                            <Button
                                size="small"
                                onClick={() => {
                                    setEnable(true);
                                }}
                            >
                                Edit
                            </Button>}
                        </h3>
                        {
                            enable &&
                            <>
                                <div className={s.row}>
                                    <div className={s.itemList}>
                                        {
                                            Object.keys(serviceConfigInfoMap).map((key:string) => {
                                                const item = serviceConfigInfoMap[key as EChannelServiceConfig];
                                                const isCurrent = currentServiceConfig === key as EChannelServiceConfig;
                                                return (
                                                    <div
                                                        key={key}
                                                        className={isCurrent ? `${s.itemWrap} ${s.itemWrapActive}` : s.itemWrap}
                                                        onClick={() => {
                                                            if (!isCurrent) {
                                                                setCurrentServiceConfig(key as EChannelServiceConfig);
                                                            }
                                                        }}
                                                    >
                                                        <div className={s.title}>
                                                            <Radio
                                                                onChange={(e) => {
                                                                    const check = e.target.checked;
                                                                    if (check) {
                                                                        setCurrentServiceConfig(key as EChannelServiceConfig);
                                                                    }
                                                                }}
                                                                checked={currentServiceConfig === key as EChannelServiceConfig}
                                                            />
                                                            {item.title}
                                                        </div>
                                                        <div className={s.content}>{item.content}</div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                                <div className={s.buttonWrap}>
                                    <Button
                                        className={commonS.lightBtn}
                                        size="small"
                                        onClick={onSubmit}
                                    >Save
                                    </Button>
                                </div>
                            </>
                        }
                        {
                            !enable &&
                            <div className={s.row}>
                                <div
                                    style={{
                                        width: '600px',
                                    }}
                                    className={s.displayItem}
                                >
                                    <div
                                        className={`${s.itemWrap} ${s.itemWrapActive}`}
                                    >
                                        <div className={s.title}>
                                            {serviceConfigInfo?.title}
                                        </div>
                                        <div
                                            style={{ paddingLeft: 0 }}
                                            className={s.content}
                                        >{serviceConfigInfo?.content}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </Spin>
            </div>
        </div>
    );
};

export default ServiceConfig;
