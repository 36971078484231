import React from 'react';
import s from './s.module.less';

interface IProps {
    step: number;
    bottom?: string;
    totalStep?: number;
}

const ProgressBar = ({
    step = 0,
    bottom,
    totalStep = 4,
}: IProps) => {
    return (
        <div className={s.wrap} style={{ bottom: bottom || '82px' }}>
            {
                new Array(totalStep).fill('').map((e, i) => {
                    return <div key={i} className={`${i > step ? '' : s.actived} ${s.bar}`} />;
                })
            }
        </div>
    );
};

export default ProgressBar;
