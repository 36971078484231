import React, { } from 'react';
import s from './s.module.less';
import { TConfigMedication, TMedication } from 'types/common';
import { Button, Form, Input, InputNumber, message, Modal, Radio, Select, Spin } from 'antd';
import MedicationTable from 'components/MedicationTable';
import { FormMedications } from 'types/common';
import commonS from 'styles/common.module.less';
import { REQUIRED_FIELD } from 'constants/common';
import TextArea from 'antd/es/input/TextArea';
import useProviderMedication from 'hooks/useProviderMedication';
import { updateProviderMedicationList } from 'api/common';
import { opsUpdateProviderMedicationList } from 'api/operation';
interface IProps {
    showEmptyMedicationNote?: boolean;
    onSuccess?: () => void;
    onChange?: (medications: TConfigMedication[]) => void;
    defaultValue?: TConfigMedication[];
    isOps?: boolean;
    providerId?: number;
}

const MedicationSelect = (props: IProps) => {
    const { showEmptyMedicationNote, onSuccess, onChange, defaultValue, isOps, providerId } = props;
    const [medications, medicationsLoading, refetch] = useProviderMedication(isOps, providerId);
    const [selectedMedications, setSelectedMedications] = React.useState<TMedication[]>([]);
    const [showMedicationsModal, setShowMedicationsModal] = React.useState(false);
    const [medicationQuantityMap, setMedicationQuantityMap] = React.useState<Record<string, number>>({});
    const [form] = Form.useForm<FormMedications>();
    const [hasInitDefaultValues, setHasInitDefaultValues] = React.useState(false);
    const [submiting, setSubmiting] = React.useState(false);

    React.useEffect(() => {
        if (defaultValue && defaultValue.length > 0 && !hasInitDefaultValues && medications && medications.length > 0) {
            const _medications = medications.filter((med) => {
                return defaultValue.find((item) => item.medicationId === med.id);
            });
            setHasInitDefaultValues(true);
            setSelectedMedications(_medications);
            const _medicationQuantityMap: Record<string, number> = {};
            for (let i = 0; i < defaultValue.length; i++) {
                const item = defaultValue[i];
                _medicationQuantityMap["_" + item.medicationId] = item.quantity;
            }
            setMedicationQuantityMap(_medicationQuantityMap);
        }
    }, [defaultValue, hasInitDefaultValues, medications])

    React.useEffect(() => {
        const _medications: TConfigMedication[] = [];
        for (let i = 0; i < selectedMedications.length; i++) {
            const medication = selectedMedications[i];
            const key = "_" + medication.id;
            let quantity = 1;
            if (medicationQuantityMap[key] && medicationQuantityMap[key] > 0) {
                quantity = medicationQuantityMap[key];
            }
            _medications.push({
                medicationId: medication.id,
                quantity,
            });
        }
        onChange?.(_medications);
    }, [medicationQuantityMap, selectedMedications]);

    React.useEffect(() => {
        if (medications && medications.length > 0 && selectedMedications && selectedMedications.length > 0) {
            setSelectedMedications(medications.filter((med) => selectedMedications.find((item) => item.id === med.id)));
        }
    }, [medications])

    const isEmpty = medications && medications.length === 0;
    const hasSelect = selectedMedications && selectedMedications.length > 0;

    const handleSave = async () => {
        try {
            setSubmiting(true);
            const formValues = await form.validateFields();
            const medicationArray = formValues.medications || [];
            const addData: Partial<TMedication>[] = [];
            for (let i = 0; i < medicationArray.length; i++) {
                const data = medicationArray[i];
                const aData: Partial<TMedication> = {
                    medicationName: data.medicationName,
                    fulfillmentPharmacyName: data.fulfillmentPharmacyName,
                    price: data.price,
                    isBranded: data.isBranded,
                    dosage: data.dosage,
                    medicationInfo: data.medicationInfo,
                    shippingRestriction: data.shippingRestriction,
                    isColdStorage: data.isColdStorage,
                };
                if (data.id) {
                    aData.id = data.id;
                }
                addData.push(aData);
            }
            let result: any;
            if (isOps && providerId) {
                result = await opsUpdateProviderMedicationList(providerId, addData as TMedication[]);
            } else {
                result = await updateProviderMedicationList(addData as TMedication[]);
            }
            setSubmiting(false);
            if (!result.error) {
                message.success('Medications has updated');
                setShowMedicationsModal(false);
                refetch();
                onSuccess?.();
                return;
            }
        } catch (e: any) {
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setSubmiting(false);
        }
    }

    React.useEffect(() => {
        if (showMedicationsModal && form) {
            form.setFieldsValue({
                medications: medications && medications.length > 0 ? medications.slice() : [{}]
            })
        }
    }, [showMedicationsModal, medications])

    if (medicationsLoading) {
        return (
            <Spin />
        )
    }

    return (
        <div className={s.wrap}>
            {
                isEmpty ?
                    <>
                        <div className={s.emptyTips}>You haven’t added any medications. Click ‘Manage medications’ to add your medications.</div>
                        <Button
                            onClick={() => {
                                setShowMedicationsModal(true);
                            }}
                        >
                            Manage medications
                        </Button>
                    </>
                    :
                    <>
                        <div className={s.mSelectAdd}>
                            <div className={s.left}>
                                <div className={s.mSelect}>
                                    <Select
                                        style={{ width: '400px' }}
                                        maxTagCount="responsive"
                                        placeholder={"Search to select"}
                                        mode='multiple'
                                        value={selectedMedications?.map((med) => med.id)}
                                        onChange={(value) => {
                                            const medicationList = medications?.filter((med) => value.includes(med.id));
                                            setSelectedMedications(medicationList || []);
                                        }
                                        }
                                        optionLabelProp="label"
                                    >
                                        {
                                            medications?.map((med) => {
                                                return (
                                                    <Select.Option label={med.medicationName} key={med.medicationName} value={med.id}>
                                                        {med.medicationName} | {med.isBranded ? 'Branded' : 'Non-Branded'} - {med.dosage} - ${med.price}
                                                    </Select.Option>
                                                );
                                            })
                                        }
                                    </Select>
                                </div>
                            </div>
                            <div className={s.right}>
                                <Button
                                    onClick={() => {
                                        setShowMedicationsModal(true);
                                    }}
                                >
                                    Manage medications
                                </Button>
                            </div>
                        </div>
                        {
                            hasSelect &&
                            <div className={s.mTable}>
                                <MedicationTable
                                    onRemove={(id) => {
                                        const key = "_" + id;
                                        const _newMedicationQuantityMap = {
                                            ...medicationQuantityMap
                                        };
                                        delete _newMedicationQuantityMap[key];
                                        setMedicationQuantityMap(_newMedicationQuantityMap);
                                        setSelectedMedications(selectedMedications.filter((item) => item.id !== id));
                                    }}
                                    medicationQuantityMap={medicationQuantityMap}
                                    data={selectedMedications}
                                    onQuantityChange={(id, value) => {
                                        const key = "_" + id;
                                        const _newMedicationQuantityMap = {
                                            ...medicationQuantityMap, ...{
                                                [key]: value
                                            }
                                        };
                                        setMedicationQuantityMap(_newMedicationQuantityMap);
                                    }}
                                />
                            </div>
                        }

                    </>
            }

            <Modal
                title="Manage medications"
                open={showMedicationsModal}
                onCancel={() => {
                    setShowMedicationsModal(false);
                }}
                className={commonS.modalFixHeightWrap}
                width={900}
                okText="Save"
                onOk={handleSave}
                okButtonProps={{
                    loading: submiting,
                }}
            >
                <div className={s.mWrap}>
                    <Form
                        form={form}
                        name="basic"
                        className={commonS.formStyle1}
                        autoComplete="off"
                        layout="vertical"
                    >
                        <Form.List name="medications" initialValue={[{}]}>
                            {(fields, { add, remove }) => {
                                const { length } = fields;
                                return (
                                    <div id="medication">
                                        {fields.map((field) => {
                                            return (
                                                <Form.Item
                                                    key={field.key}
                                                    noStyle
                                                >
                                                    <div className={s.mItem}>
                                                        <Form.Item name={[field.name, 'id']} style={{ position: 'absolute' }}>
                                                            <Input type="hidden" />
                                                        </Form.Item>
                                                        <div className={s.row}>
                                                            <div className={s.rowItem}>
                                                                <Form.Item
                                                                    style={{ width: '80%' }}
                                                                    label="Medication name"
                                                                    name={[field.name, 'medicationName']}
                                                                    validateFirst
                                                                    rules={[
                                                                        { required: true, message: REQUIRED_FIELD },
                                                                    ]}
                                                                >
                                                                    <Input maxLength={150} placeholder="" />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div className={s.rowItem}>
                                                                <Form.Item
                                                                    style={{ width: '80%' }}
                                                                    label="Fulfillment pharmacy name"
                                                                    name={[field.name, 'fulfillmentPharmacyName']}
                                                                    validateFirst
                                                                    rules={[
                                                                        { required: true, message: REQUIRED_FIELD },
                                                                    ]}
                                                                >
                                                                    <Input placeholder="" />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div className={s.rowItem}>
                                                                <Form.Item
                                                                    style={{ width: '320px' }}
                                                                    label="Brand"
                                                                    name={[field.name, 'isBranded']}
                                                                    validateFirst
                                                                    rules={[
                                                                        { required: true, message: REQUIRED_FIELD },
                                                                    ]}
                                                                >
                                                                    <Radio.Group>
                                                                        <Radio value={true}>Branded</Radio>
                                                                        <Radio value={false}>Non-brand</Radio>
                                                                    </Radio.Group>
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div className={s.rowItem}>
                                                                <Form.Item
                                                                    style={{ width: '360px' }}
                                                                    label="Dosage"
                                                                    name={[field.name, 'dosage']}
                                                                    validateFirst
                                                                    rules={[
                                                                        { required: true, message: REQUIRED_FIELD },
                                                                    ]}
                                                                >
                                                                    <Input placeholder="" maxLength={50} />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div className={s.rowItem}>
                                                                <Form.Item
                                                                    style={{ width: '360px' }}
                                                                    label="Price"
                                                                    name={[field.name, 'price']}
                                                                    validateFirst
                                                                    rules={[
                                                                        { required: true, message: REQUIRED_FIELD },
                                                                    ]}
                                                                >
                                                                    <InputNumber
                                                                        addonAfter="USD"
                                                                        min={0.01}
                                                                        max={1000}
                                                                        placeholder="" />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div className={s.rowItem}>
                                                                <Form.Item
                                                                    style={{ width: '80%' }}
                                                                    label="Provide detailed information about the medications to patients"
                                                                    name={[field.name, 'medicationInfo']}
                                                                    validateFirst
                                                                >
                                                                    <TextArea
                                                                        rows={3}
                                                                        maxLength={800}
                                                                        placeholder="" />
                                                                </Form.Item>
                                                            </div>
                                                        </div>

                                                        <div className={s.row}>
                                                            <div className={s.rowItem}>
                                                                <Form.Item
                                                                    style={{ width: '80%' }}
                                                                    label={
                                                                        <div className={s.labelWrap}>
                                                                            <div className={s.label}>Cold storage</div>
                                                                            <div className={s.labelDes}>Does the mediation require a specific temperature range during storage and transportation to ensure efficacy and safety?</div>
                                                                        </div>
                                                                    }
                                                                    name={[field.name, 'isColdStorage']}
                                                                    validateFirst
                                                                >
                                                                    <Radio.Group>
                                                                        <Radio value={true}>Yes</Radio>
                                                                        <Radio value={false}>No</Radio>
                                                                    </Radio.Group>
                                                                </Form.Item>
                                                            </div>
                                                        </div>

                                                        <div className={s.row}>
                                                            <div className={s.rowItem}>
                                                                <Form.Item
                                                                    style={{ width: '80%' }}
                                                                    label={"Shipping restriction"}
                                                                    name={[field.name, 'shippingRestriction']}
                                                                    validateFirst
                                                                >
                                                                    <TextArea
                                                                        rows={3}
                                                                        maxLength={800}
                                                                        placeholder="Please add shipping restrictions here, for example any states or zones prohibited." />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.removeWrap}>
                                                            <Button
                                                                onClick={() => {
                                                                    remove(field.name)
                                                                }}
                                                                danger
                                                                size="small"
                                                            >Remove
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    {field.name === length - 1 &&
                                                        <div
                                                            onClick={() => {
                                                                add();
                                                                const wrap = document.getElementById('cmecLicense');
                                                                setTimeout(() => {
                                                                    wrap?.parentElement?.parentElement?.scroll({
                                                                        top: 1000000,
                                                                        behavior: 'smooth',
                                                                    });
                                                                }, 200);
                                                            }}
                                                            className={s.add}
                                                        >
                                                            + Add another medication
                                                        </div>
                                                    }
                                                </Form.Item>);
                                        })}
                                    </div>);
                            }}
                        </Form.List>
                    </Form>
                </div>
            </Modal>
        </div>
    );
};

export default MedicationSelect;
