import { EnumFields, SettingEnum } from 'types/enumerationData';
import { TFunStatus, TSubscriptionItem, TSubscriptionItemFromServer, TSubscriptionType } from 'types/subscription';

const funList: TFunStatus[] = [
    {
        name: 'Basic channels',
        des: '2 basic channels are included in the Free Plan, including WebMD Standard and Nextdoor',
        support: true,
    },
    {
        name: 'Standard channels',
        des: 'Standard channels are considered the “must-have” channels for healthcare providers to gain credibility and build brand. They help you gain online presence to allow patients to find you.',
        support: false,
    },
    {
        name: 'Premium channels',
        des: 'Premium channels offer advanced placements to allow you standout among other providers.',
        support: false,
        sign: '*',
    },
    {
        name: 'Premium channel discount',
        des: 'Enjoy premium channel discounts only available in Kiwi - up to $400 in value a month',
        support: false,
    },
    {
        name: 'Practice website builder: Practice Front',
        des: 'A professionally designed website for your practice.',
        support: true,
    },
    {
        name: 'Advanced website builder: Practice Front premium',
        des: 'Offering advanced features like online contact form, appointment request form/direct booking, and local search engine optimization.',
        support: false,
    },
    {
        name: 'Themes and apps for your Practice Front',
        des: 'Access to a wide range of apps to enhance the functionality of your Practice website.',
        support: false,
    },
    {
        name: 'Advanced personalized themes and apps for your Practice Front',
        des: "Access premium, customizable themes and advanced apps to enhance your Practice website's distinctiveness and functionality.",
        support: false,
    },
    {
        name: 'Reputation management',
        des: 'Help you gain recognition from your patients and further grow the visibility of your channel profiles.',
        support: false,
    },
    {
        name: 'Prospect managerment',
        des: 'Centralize prospect information from all marketing channels in one place to simplify communication and scheduling.',
        support: false,
    },
    {
        name: 'Appointment management',
        des: 'Sync and manage appointments from Practice Front and all marketing channels in one place.',
        support: false,
    },
    {
        name: 'Virtual phone line',
        des: 'Never miss a call with the exclusive Virtual Phone Line feature, worth $10/month. You will receive a unique Kiwi phone number, redirecting calls to your personal phone. Missed calls automatically trigger an SMS with your Headway booking links.',
        support: false,
        sign: '*',
    },
];

const conbindFunList = (changeItems: Partial<TFunStatus>[]): TFunStatus[] => {
    return funList.slice().map((item, index) => {
        if (changeItems.length <= index) {
            return { ...item };
        }
        return { ...item, ...changeItems[index] };
    });
};

export const SUBSCRIPTION_PLANS: TSubscriptionItem[] = [
    {
        name: 'Free',
        des: 'Explore free Kiwi Health tools',
        monthlyPrice: 0,
        quarterlyPrice: 0,
        semiAnnualPrice: 0,
        yearlyPrice: 0,
        contactOnly: false,
        type: TSubscriptionType.FREE,
        subType: undefined,
        isCurrent: false,
        funList: conbindFunList(
            [
                { support: true },
                { support: false },
                { support: false },
                { support: false },
                { support: true },
                { support: false },
                { support: false },
                { support: false },
                { support: false },
                { support: false },
                { support: false },
            ],
        ),
    },
    {
        name: 'Essential',
        highlight: '',
        des: 'Best for mental health and primary care providers early in their career looking to build online presence',
        monthlyPrice: 49,
        quarterlyPrice: 42,
        semiAnnualPrice: 40,
        yearlyPrice: 39,
        contactOnly: false,
        type: TSubscriptionType.ESSENTIAL,
        subType: undefined,
        isCurrent: false,
        funList: conbindFunList(
            [
                { support: true },
                { support: true, supportText: 'Up to 6' },
                { support: true, supportText: 'Unlimited' },
                { support: true },
                { support: true },
                { support: true },
                { support: true },
                { support: false },
                { support: false },
                { support: true },
                { support: true },
                { support: false },
            ],
        ),
    },
    {
        name: 'Growth',
        highlight: 'Best value',
        des: 'Perfect for mental health and primary care providers looking to build their caseload and establish professional reputation',
        priceTips: 'Early Bird Offer! Price set to rise soon!',
        monthlyPrice: 99,
        quarterlyPrice: 84,
        semiAnnualPrice: 81,
        yearlyPrice: 80,
        contactOnly: false,
        type: TSubscriptionType.GROWTH,
        subType: undefined,
        isCurrent: false,
        funList: conbindFunList(
            [
                { support: true },
                { support: true, supportText: 'All standard channels (20+)' },
                { support: true, supportText: 'Unlimited' },
                { support: true },
                { support: true },
                { support: true },
                { support: true },
                { support: true },
                { support: true },
                { support: true },
                { support: true },
                { support: true },
            ],
        ),
    },
];

export const GROUP_SUBSCRIPTION_PLAN: TSubscriptionItem = {
    name: 'Group Practice',
    highlight: '',
    des: 'Solutions for group practices that are looking for effective marketing',
    monthlyPrice: 0,
    quarterlyPrice: 0,
    semiAnnualPrice: 0,
    yearlyPrice: 0,
    contactOnly: true,
    type: TSubscriptionType.GROUP_PRACTICE,
    subType: undefined,
    isCurrent: false,
    funList: conbindFunList(
        [
            { support: true },
            { support: true, supportText: 'All standard channels (20+)' },
            { support: true, supportText: 'Unlimited' },
            { support: true },
            { support: true },
            { support: true },
            { support: true },
            { support: true },
            { support: true },
            { support: true },
            { support: true },
            { support: true },
        ],
    ),
};

export const SUBSCRIPTION_DETAIL_LINK = 'https://www.kiwihealth.com/pricing';

export const KIWI_CONTACT_LINK = 'https://www.kiwihealth.com/#contact';

export const GROUP_PRICE_CONTACT_LINK = 'https://calendly.com/klarity-and-kiwi/30-minutes-with-lysander?preview_source=et_card&month=2024-08';

export const PROVIDER_PAY_PLAN_TYPE: SettingEnum[] = [
    {
        dictKey: TSubscriptionType.FREE,
        content: TSubscriptionType.FREE,
        type: EnumFields.PROVIDER_PAY_PLAN_TYPE,
        id: 1,
    },
    {
        dictKey: TSubscriptionType.ESSENTIAL,
        content: TSubscriptionType.ESSENTIAL,
        type: EnumFields.PROVIDER_PAY_PLAN_TYPE,
        id: 2,
    },
    {
        dictKey: TSubscriptionType.GROWTH,
        content: TSubscriptionType.GROWTH,
        type: EnumFields.PROVIDER_PAY_PLAN_TYPE,
        id: 3,
    },
    {
        dictKey: TSubscriptionType.KLARITY_STARTER,
        content: TSubscriptionType.KLARITY_STARTER,
        type: EnumFields.PROVIDER_PAY_PLAN_TYPE,
        id: 5,
    },
    {
        dictKey: TSubscriptionType.KLARITY_PLUS,
        content: TSubscriptionType.KLARITY_PLUS,
        type: EnumFields.PROVIDER_PAY_PLAN_TYPE,
        id: 6,
    },
    {
        dictKey: TSubscriptionType.KLARITY_PRO,
        content: TSubscriptionType.KLARITY_PRO,
        type: EnumFields.PROVIDER_PAY_PLAN_TYPE,
        id: 7,
    },
    {
        dictKey: TSubscriptionType.GROUP_PRACTICE,
        content: TSubscriptionType.GROUP_PRACTICE,
        type: EnumFields.PROVIDER_PAY_PLAN_TYPE,
        id: 4,
    },
];

//map for growth yearly plan: https://docs.google.com/spreadsheets/d/1Oo82LvSi0TFiEw1VdYKUMnoAA8OPfljCnankhfouvFc/edit#gid=1343487863
export const TRIAL_PRICE_ID: string = 'price_1O5MOyLWCWKPYTPjGPO7bTw8';

export const isTrialUser = (currentSubscription?: TSubscriptionItemFromServer): boolean => {
    if (currentSubscription?.priceId === TRIAL_PRICE_ID) {
        return true;
    }
    return false;
};
