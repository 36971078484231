// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--eOnfg .s-module__timePicker--Y1oUR {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.s-module__wrap--eOnfg .s-module__dateSelect--uUdja {
  width: 45%;
}
.s-module__wrap--eOnfg .s-module__timeSelect--Ka_Qn {
  width: 23%;
}
.s-module__wrap--eOnfg .s-module__allDayCheck--gQi59 {
  line-height: 36px;
  padding-left: 4px;
}
.s-module__wrap--eOnfg .s-module__dateSelectAllDay--RfzvG {
  width: 90%;
}
`, "",{"version":3,"sources":["webpack://./src/components/form/TimeRangerPicker/s.module.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;AAAJ;AALA;EASI,UAAA;AADJ;AARA;EAaI,UAAA;AAFJ;AAXA;EAgBI,iBAAA;EACA,iBAAA;AAFJ;AAfA;EAqBI,UAAA;AAHJ","sourcesContent":[".wrap {\n  .timePicker {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n  }\n\n  .dateSelect {\n    width: 45%;\n  }\n\n  .timeSelect {\n    width: 23%;\n  }\n  .allDayCheck{\n    line-height: 36px;\n    padding-left: 4px;\n  }\n\n  .dateSelectAllDay{\n    width: 90%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--eOnfg`,
	"timePicker": `s-module__timePicker--Y1oUR`,
	"dateSelect": `s-module__dateSelect--uUdja`,
	"timeSelect": `s-module__timeSelect--Ka_Qn`,
	"allDayCheck": `s-module__allDayCheck--gQi59`,
	"dateSelectAllDay": `s-module__dateSelectAllDay--RfzvG`
};
export default ___CSS_LOADER_EXPORT___;
