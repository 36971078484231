import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Alert, Button, Checkbox, Form, Input, Modal, InputNumber, Select, Switch, Tooltip, message } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import commonS from 'styles/common.module.less';
import s from './s.module.less';
import Exclamation from 'assets/setting/Exclamation.svg';

import DurationSelect from 'components/form/DurationSelect';
import ColorSelect from 'components/form/ColorSelect';
import IntakeqFormSelect from 'components/form/IntakeqFormSelect';

import { providerServiceCreate, providerServiceUpdate } from 'api/appointmentSetting';
import { serviceTemplateCreate, serviceTemplateUpdate, createProviderService, updateProviderService } from 'api/operation';
import { Service } from 'types/common';
import { ServiceFormFieldEnum, ServiceModal } from './types';

import ProviderStore from 'store/Provider';
import { PATIENT_TYPES } from 'constants/meta';

import useServiceTypeData from 'hooks/useServiceTemplateData';
import { jumpToFormError } from 'utils/common';
import { Link, useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';

type Props = {
    addressList?: Array<{ value: number, label: string, isDefault: boolean }>;
    isOps?: boolean
    isTemplate?: boolean
    // 运营端不应该再保存的时候时候传当前登录用户的id
    providerId?: number
    isEdit?: boolean
    serviceItem?: Service
    onCancel?:()=>void
    onSuccess?: ()=>void
    showChannel?: boolean
    showIntakeqForm?:boolean
};

const ServiceUpdateModal = ({ providerId, isEdit, serviceItem, onCancel, onSuccess, showChannel, showIntakeqForm, isOps, isTemplate, addressList = [] }:Props) => {
    const navigate = useNavigate();
    const [submiting, setSubmiting] = useState(false);
    const [isCheckedTelehealth, setIsCheckedTelehealth] = useState(false);
    const [isCheckedInPerson, setIsCheckedInPerson] = useState(false);
    const [isCheckedNoMeeting, setIsCheckedNoMeeting] = useState(false);
    const [showTypeError, setShowTypeError] = useState(false);
    const [getUser] = ProviderStore.useStore();
    const [serviceTemplateData, serviceTemplateDataLoading] = useServiceTypeData(isOps);
    const [currentServiceTemplateData, setCurrentServiceTemplateData] = useState<Service>();
    const [enableSlidingScaleCheckStatus, setEnableSlidingScaleCheckStatus] = useState(false);

    const user = getUser('data');
    const [form] = Form.useForm<ServiceModal>();

    const handleTelehealthChange = (e: CheckboxChangeEvent) => {
        setIsCheckedTelehealth(e.target.checked);
        setIsCheckedNoMeeting(false);
    };
    const handleInPersonChange = (e: CheckboxChangeEvent) => {
        setIsCheckedInPerson(e.target.checked);
        setIsCheckedNoMeeting(false);
    };

    const onSelectNoMeeting = () => {
        setIsCheckedTelehealth(false);
        setIsCheckedInPerson(false);
        setIsCheckedNoMeeting(!isCheckedNoMeeting);
        form.setFieldsValue({
            [ServiceFormFieldEnum.DURATION]: 0,
            [ServiceFormFieldEnum.TELEHEALTHTYPE]: [],
            [ServiceFormFieldEnum.PERSONADDRESS]: '',
            [ServiceFormFieldEnum.PERSONCITY]: '',
            [ServiceFormFieldEnum.PERSONSTATE]: '',
            [ServiceFormFieldEnum.PERSONZIP]: '',
            [ServiceFormFieldEnum.PERSONTYPE]: [],
            [ServiceFormFieldEnum.ADDRESS_ID]: null,
        });
    };

    const handleEnableSlidingScaleChange = (check: boolean) => {
        setEnableSlidingScaleCheckStatus(check);
    };

    const onSave = async () => {
        if (!isCheckedInPerson && !isCheckedNoMeeting && !isCheckedTelehealth && !isOps) {
            setShowTypeError(true);
            const meetTypeDom = document.getElementById('meetingType');
            meetTypeDom?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return;
        }
        setSubmiting(true);
        try {
            const formValues = await form.validateFields();
            const serviceId = serviceItem?.id;

            const params :Service = {
                colorId: formValues[ServiceFormFieldEnum.COLOR_ID],
                description: formValues[ServiceFormFieldEnum.DESCRIPTION],
                duration: !isCheckedNoMeeting ? formValues[ServiceFormFieldEnum.DURATION] : 0,
                name: formValues[ServiceFormFieldEnum.NAME],
                personAddress: isCheckedInPerson ? formValues[ServiceFormFieldEnum.PERSONADDRESS] : '',
                personCity: isCheckedInPerson ? formValues[ServiceFormFieldEnum.PERSONCITY] : '',
                personState: isCheckedInPerson ? formValues[ServiceFormFieldEnum.PERSONSTATE] : '',
                personType: isCheckedInPerson && formValues[ServiceFormFieldEnum.PERSONTYPE] ? formValues[ServiceFormFieldEnum.PERSONTYPE].toString() : '',
                personZip: isCheckedInPerson ? formValues[ServiceFormFieldEnum.PERSONZIP] : '',
                providerId: providerId || user?.id,
                sendForm: formValues[ServiceFormFieldEnum.SENDFORM],
                telehealthType: isCheckedTelehealth && formValues[ServiceFormFieldEnum.TELEHEALTHTYPE] ? formValues[ServiceFormFieldEnum.TELEHEALTHTYPE].toString() : '',
                type: formValues[ServiceFormFieldEnum.TYPE],
                price: formValues[ServiceFormFieldEnum.PRICE],
                addressId: formValues[ServiceFormFieldEnum.ADDRESS_ID],
                enableSlidingScale: !!enableSlidingScaleCheckStatus,
                lowestPrice: formValues[ServiceFormFieldEnum.LOWEST_PRICE],
                highestPrice: formValues[ServiceFormFieldEnum.HIGHEST_PRICE],
            };
            const lowestPriceVal = formValues[ServiceFormFieldEnum.LOWEST_PRICE] as number;
            const highestPriceVal = formValues[ServiceFormFieldEnum.HIGHEST_PRICE] as number;
            if (enableSlidingScaleCheckStatus && lowestPriceVal > highestPriceVal) {
                message.error('Highest price should be greater than lowest price');
                return;
            }
            if (!enableSlidingScaleCheckStatus) {
                params.lowestPrice = undefined;
                params.highestPrice = undefined;
            }
            let updateFun = isOps ? updateProviderService : providerServiceUpdate;
            let createFun = isOps ? createProviderService : providerServiceCreate;
            if (isTemplate) {
                updateFun = serviceTemplateUpdate;
                createFun = serviceTemplateCreate;
            }
            const apiFunc = isEdit ? updateFun(params, serviceId!) : createFun(params);
            const { data } = await apiFunc;
            if (data.code === 200) {
                onSuccess?.();
            }
        } catch (error) {
            console.error(error);
            jumpToFormError(document.getElementById('services_modal')?.parentNode as any || undefined);
        } finally {
            setSubmiting(false);
        }
    };

    useEffect(() => {
        if (serviceItem) {
            const {
                colorId,
                description,
                duration, name,
                personAddress,
                personCity, personState,
                personType, personZip, sendForm,
                telehealthType, price, type,
                addressId, enableSlidingScale,
                lowestPrice, highestPrice,
            } = serviceItem;

            if (telehealthType) setIsCheckedTelehealth(true);
            if (personType) setIsCheckedInPerson(true);
            if (isEdit && !telehealthType && !personType) setIsCheckedNoMeeting(true);

            form.setFieldsValue({
                [ServiceFormFieldEnum.TYPE]: type,
                [ServiceFormFieldEnum.PRICE]: price,
                [ServiceFormFieldEnum.COLOR_ID]: colorId,
                [ServiceFormFieldEnum.DESCRIPTION]: description,
                [ServiceFormFieldEnum.DURATION]: duration,
                [ServiceFormFieldEnum.NAME]: name,
                [ServiceFormFieldEnum.PERSONADDRESS]: personAddress,
                [ServiceFormFieldEnum.PERSONCITY]: personCity,
                [ServiceFormFieldEnum.PERSONSTATE]: personState,
                [ServiceFormFieldEnum.PERSONTYPE]: personType?.split(','),
                [ServiceFormFieldEnum.PERSONZIP]: personZip,
                [ServiceFormFieldEnum.SENDFORM]: sendForm,
                [ServiceFormFieldEnum.TELEHEALTHTYPE]: telehealthType?.split(','),
                [ServiceFormFieldEnum.ADDRESS_ID]: addressId,
                [ServiceFormFieldEnum.LOWEST_PRICE]: enableSlidingScale ? lowestPrice : undefined,
                [ServiceFormFieldEnum.HIGHEST_PRICE]: enableSlidingScale ? highestPrice : undefined,
            });
            setEnableSlidingScaleCheckStatus(!!enableSlidingScale);
        }
    }, [serviceItem, isEdit, form]);

    useEffect(() => {
        if (currentServiceTemplateData && !isEdit) {
            const {
                colorId,
                description,
                duration,
                name,
                personAddress,
                personCity,
                personState,
                personType,
                personZip,
                sendForm,
                telehealthType,
                price,
                type,
                enableSlidingScale,
                highestPrice,
                lowestPrice,
            } = currentServiceTemplateData;

            form.setFieldsValue({
                [ServiceFormFieldEnum.TYPE]: type,
                [ServiceFormFieldEnum.PRICE]: price,
                [ServiceFormFieldEnum.COLOR_ID]: colorId,
                [ServiceFormFieldEnum.DESCRIPTION]: description,
                [ServiceFormFieldEnum.DURATION]: duration,
                [ServiceFormFieldEnum.NAME]: name,
                [ServiceFormFieldEnum.PERSONADDRESS]: personAddress,
                [ServiceFormFieldEnum.PERSONCITY]: personCity,
                [ServiceFormFieldEnum.PERSONSTATE]: personState,
                [ServiceFormFieldEnum.PERSONTYPE]: personType?.split(','),
                [ServiceFormFieldEnum.PERSONZIP]: personZip,
                [ServiceFormFieldEnum.SENDFORM]: sendForm,
                [ServiceFormFieldEnum.TELEHEALTHTYPE]: telehealthType?.split(','),
                [ServiceFormFieldEnum.LOWEST_PRICE]: enableSlidingScale ? lowestPrice : undefined,
                [ServiceFormFieldEnum.HIGHEST_PRICE]: enableSlidingScale ? highestPrice : undefined,
            });
            setEnableSlidingScaleCheckStatus(!!currentServiceTemplateData.enableSlidingScale);
        }
    }, [currentServiceTemplateData, isEdit, form]);

    const showMore = isEdit || isTemplate || (!!currentServiceTemplateData);

    const handleRedirectToCreateUniprofile = useCallback(() => {
        navigate(`${PATH.DASHBOARD}/${PATH.CHANNEL}?type=uniprofile`);
    }, [navigate]);

    const addressSelectRender = useMemo(() => {
        if (addressList.length > 0 || isOps) {
            return <Select options={addressList} />;
        }

        return (
            <Select
                style={{ width: '100%' }}
                onChange={handleRedirectToCreateUniprofile}
                optionLabelProp="label"
            >
                <Select.Option value={null} label={null}>
                    <p>No address found. <Link to={`${PATH.DASHBOARD}/${PATH.CHANNEL}?type=uniprofile`}>Complete UniProfile</Link> to add a practice address.</p>
                </Select.Option>
            </Select>
        );
    }, [addressList, handleRedirectToCreateUniprofile, isOps]);

    return (
        <Modal
            title={isEdit ? form.getFieldValue(ServiceFormFieldEnum.TYPE) : 'Add a new service'}
            closable
            className={commonS.modalFixHeightWrap}
            width="660px"
            destroyOnClose
            onCancel={onCancel}
            okText="Save"
            open
            footer={
                <div>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button loading={submiting} type="primary" onClick={onSave}>Save</Button>
                </div>
            }
        >
            <div className={s.wrap} id="services_modal">
                <Form
                    form={form}
                    name="basic"
                    className={commonS.formStyle1}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    layout="vertical"
                    scrollToFirstError
                >
                    {
                        !isEdit &&
                        <>
                            <h3>Service type</h3>
                            <Form.Item
                                className={s.rowItem}
                                label="Type name"
                                name={ServiceFormFieldEnum.TYPE}
                                rules={[{ required: true, message: 'Type name is required' }]}
                            >
                                {
                                    isTemplate ?
                                        <Input style={{ width: '300px' }} />
                                        :
                                        <Select
                                            style={{ width: '300px' }}
                                            disabled={isEdit}
                                            loading={serviceTemplateDataLoading}
                                            onChange={(val) => {
                                                const target = serviceTemplateData.find((item:Service) => item.type === val);
                                                setCurrentServiceTemplateData(target);
                                            }}
                                        >
                                            {
                                                serviceTemplateData.map((service:Service) => {
                                                    return (
                                                        <Select.Option value={service.type} key={service.type}>
                                                            {service.type}
                                                        </Select.Option>
                                                    );
                                                })
                                            }
                                        </Select>
                                }
                            </Form.Item>
                        </>
                    }
                    {
                        showMore &&
                        <>
                            <h3>Basic Information</h3>
                            <Form.Item
                                className={s.rowItem}
                                label="Service name"
                                name={ServiceFormFieldEnum.NAME}
                                rules={[{ required: true, message: 'Service name is required' }]}
                            >
                                <Input style={{ width: '90%' }} />
                            </Form.Item>

                            <Form.Item
                                className={s.rowItem}
                                label="Description"
                                name={ServiceFormFieldEnum.DESCRIPTION}
                            >
                                <Input.TextArea style={{ width: '90%' }} maxLength={500} rows={4} />
                            </Form.Item>

                            <Form.Item
                                className={s.rowItem}
                                label="Duration"
                                name={ServiceFormFieldEnum.DURATION}
                            >
                                <DurationSelect disabled={isCheckedNoMeeting} style={{ width: '150px' }} />
                            </Form.Item>
                            <Form.Item
                                className={s.rowItem}
                                label="Price"
                                name={ServiceFormFieldEnum.PRICE}
                                rules={[{ required: !isTemplate, message: 'Service price is required' }]}
                            >
                                <InputNumber precision={2} addonBefore="$" addonAfter="USD" style={{ width: '220px' }} />
                            </Form.Item>

                            <div className={s.rowItem} style={{ marginTop: '12px', marginBottom: '12px' }}>
                                <div className={s.switchItem}>
                                    <Switch defaultChecked={false} checked={enableSlidingScaleCheckStatus} onChange={handleEnableSlidingScaleChange} />
                                    <span className={s.switchLabel}>Enable sliding scale</span>
                                    <Tooltip
                                        overlayClassName={s.tipCard}
                                        overlayInnerStyle={{ padding: '12px' }}
                                        color="#FFFFFF"
                                        title={
                                            <div className={s.tipText}>Sliding fee scales adjust costs by income, improving healthcare access. Proof of income and household size is required.</div>
                                        }
                                    >
                                        <div className={s.questionMarkIcon} />
                                    </Tooltip>
                                </div>
                            </div>

                            {
                                enableSlidingScaleCheckStatus && (
                                    <div>
                                        <Form.Item
                                            className={s.rowItem}
                                            label="Lowest price"
                                            name={ServiceFormFieldEnum.LOWEST_PRICE}
                                            rules={[{ required: true, message: 'Lowest price is required' }]}
                                        >
                                            <InputNumber precision={2} addonBefore="$" addonAfter="USD" style={{ width: '220px' }} />
                                        </Form.Item>
                                        <Form.Item
                                            className={s.rowItem}
                                            label="Highest price"
                                            name={ServiceFormFieldEnum.HIGHEST_PRICE}
                                            rules={[{ required: true, message: 'Highest price is required' }]}
                                        >
                                            <InputNumber precision={2} addonBefore="$" addonAfter="USD" style={{ width: '220px' }} />
                                        </Form.Item>
                                    </div>
                                )
                            }

                            <h3 id="meetingType">Meeting Types</h3>
                            {
                                showTypeError &&
                                <Alert style={{ marginTop: 16, marginBottom: 16 }} message="Please select a meeting type for this service" type="error" showIcon />
                            }
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <div className={s.checkbox}>
                                        <Checkbox checked={isCheckedTelehealth} onChange={handleTelehealthChange}>Telehealth</Checkbox>
                                        <div className={s.des}>I can see patient via telehealth</div>
                                    </div>

                                    {
                                        isCheckedTelehealth && (
                                            <div className={s.checkboxDetail}>
                                                <div className={s.row}>
                                                    <div className={s.displayItem}>
                                                        <Form.Item
                                                            label="Select the type of patients you’re seeing for the telehealth visit"
                                                            name={ServiceFormFieldEnum.TELEHEALTHTYPE}
                                                            validateFirst
                                                            rules={[
                                                                { required: !isTemplate, message: 'patient type is required' },
                                                            ]}
                                                        >
                                                            <Checkbox.Group
                                                                className={s.mt12}
                                                                options={PATIENT_TYPES}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <div className={s.checkbox}>
                                        <Checkbox checked={isCheckedInPerson} onChange={handleInPersonChange}>In-person</Checkbox>
                                        <div className={s.des}>I can see patient in my local office</div>
                                    </div>

                                    {
                                        !isCheckedInPerson && (
                                            <div className={`${commonS.tips} ${s.tips}`}>
                                                <img src={Exclamation} />
                                                <p>If you don&apos;t offer in-person visit, you can&apos;t prescribe controlled-medication periodically in certain states.</p>
                                            </div>
                                        )
                                    }

                                    {
                                        isCheckedInPerson && (
                                            <div className={s.checkboxDetail}>
                                                <div className={s.title}>Enter your office address for the in-person visits.</div>
                                                <div className={s.row}>
                                                    <div
                                                        className={s.displayItem}
                                                    >
                                                        <Form.Item
                                                            label="Office address"
                                                            name={ServiceFormFieldEnum.ADDRESS_ID}
                                                            validateFirst
                                                            rules={[
                                                                { required: !isTemplate, message: 'Office address is a required field' },
                                                            ]}
                                                        >
                                                            {addressSelectRender}
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className={s.row}>
                                                    <div
                                                        className={s.displayItem}
                                                    >
                                                        <Form.Item
                                                            label="Select the type of patients you’re seeing for the in-person visit"
                                                            name={ServiceFormFieldEnum.PERSONTYPE}
                                                            validateFirst
                                                            rules={[
                                                                { required: !isTemplate, message: 'patient type is required' },
                                                            ]}
                                                        >
                                                            <Checkbox.Group
                                                                className={s.mt12}
                                                                options={PATIENT_TYPES}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>

                            <div className={s.rowItem}>
                                <Checkbox checked={isCheckedNoMeeting} onChange={onSelectNoMeeting}><span>No meeting needed</span></Checkbox>
                                <div className={s.noMeetingDes}>This service doesn’t need to see patient, and the duration will be 0 mins.</div>
                            </div>

                            <h3>Color code in your practice calendar</h3>

                            <Form.Item
                                className={s.rowItem}
                                label=""
                                name={ServiceFormFieldEnum.COLOR_ID}
                            >
                                <ColorSelect useAdminApi={isOps} style={{ width: '150px' }} />
                            </Form.Item>

                            {
                                showIntakeqForm &&
                                <Form.Item
                                    className={s.rowItem}
                                    label="Intake form"
                                    name={ServiceFormFieldEnum.SENDFORM}
                                >
                                    <IntakeqFormSelect style={{ width: '300px' }} />
                                </Form.Item>
                            }
                        </>
                    }
                </Form>
            </div>
        </Modal>
    );
};

export default ServiceUpdateModal;
