// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__optionLabelItem--eyEY2 {
  padding: 8px 0 12px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: var(--gray-700);
  cursor: pointer;
}
.s-module__optionLabelItem--eyEY2.s-module__optionLink--XsNEh {
  border-bottom: 1px solid var(--gray-100);
  color: var(--primary-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/form/IntakeqFormSelect/s.module.less"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,oBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,sBAAA;EACA,eAAA;AACJ;AACI;EACI,wCAAA;EACA,2BAAA;AACR","sourcesContent":[".optionLabelItem {\n    padding: 8px 0 12px;\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 500;\n    font-size: 14px;\n    color: var(--gray-700);\n    cursor: pointer;\n\n    &.optionLink {\n        border-bottom: 1px solid var(--gray-100);\n        color: var(--primary-color);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionLabelItem": `s-module__optionLabelItem--eyEY2`,
	"optionLink": `s-module__optionLink--XsNEh`
};
export default ___CSS_LOADER_EXPORT___;
