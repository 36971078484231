// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--NdmKu {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #1F87DD;
  background: var(--light-blue-50, #F0F9FF);
  padding: 16px;
}
.s-module__wrap--NdmKu b {
  font-weight: bold;
}
.s-module__wrap--NdmKu .s-module__icon--syBBM {
  flex-shrink: 0;
}
.s-module__wrap--NdmKu .s-module__info--uUVvw {
  flex-grow: 1;
}
.s-module__wrap--NdmKu .s-module__check--UxEtu {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
  color: #1F87DD;
  cursor: pointer;
}
.s-module__wrap--NdmKu .s-module__close--itis7 {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/Notes/s.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,yBAAA;EACA,yCAAA;EACA,aAAA;AACF;AARA;EAUI,iBAAA;AACJ;AAXA;EAcI,cAAA;AAAJ;AAdA;EAkBI,YAAA;AADJ;AAjBA;EAsBI,cAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,cAAA;EACA,eAAA;AAFJ;AA1BA;EAgCI,cAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;AAHJ","sourcesContent":[".wrap {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  border-radius: 6px;\n  border: 1px solid #1F87DD;\n  background: var(--light-blue-50, #F0F9FF);\n  padding: 16px;\n\n  b {\n    font-weight: bold;\n  }\n\n  .icon {\n    flex-shrink: 0;\n  }\n\n  .info {\n    flex-grow: 1;\n  }\n\n  .check {\n    flex-shrink: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 0 32px;\n    color: #1F87DD;\n    cursor: pointer;\n  }\n\n  .close {\n    flex-shrink: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: #333;\n    cursor: pointer;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--NdmKu`,
	"icon": `s-module__icon--syBBM`,
	"info": `s-module__info--uUVvw`,
	"check": `s-module__check--UxEtu`,
	"close": `s-module__close--itis7`
};
export default ___CSS_LOADER_EXPORT___;
