import { SUBSCRIPTION_PLANS } from './klarityPlanSubscription';

const subscriptionsPaidPlans = [
    {
        ...SUBSCRIPTION_PLANS[0],
        des: 'Explore free Kiwi Health tools for your practice growth',
        featureList: [
            {
                name: '10 standard channels, Psychology Today, Mental health match',
                desc: 'Get listed on selected standard and premium channels to reach more patients. Kiwi covers the cost of any premium channels included in your plan.',
            },
            {
                name: 'Premium channel discount',
                desc: 'Kiwi’s exclusive discount on premium channel subscriptions.',
            },
            {
                name: 'Practice front',
                desc: 'A Kiwi Health profile page built with cutting-edge medical SEO technology, expanding your reach across multiple locations and boosting your online presence.',
            },
            {
                name: '150 mins call- time in Virtual front desk',
                desc: 'HIPAA-trained virtual receptionist specialized in answering incoming patient calls and booking appointments while you are busy.',
            },
            {
                name: 'Klarity success coach',
                desc: 'Work one-on-one with a dedicated Klarity support specialist to develop a personalized strategy to maximize your online presence, attract more patients, and achieve your practice goals.',
            },
        ],
    },
    {
        ...SUBSCRIPTION_PLANS[1],
        des: 'Best for mental health and primary care providers early in their career looking to build online presence',
        featureList: [
            {
                name: 'All features in the Starter plan',
            },
            {
                name: '25+ standard channels, Psychology Today, Mental health match, All Counseling',
                desc: 'Choose up to 6 standard channels with the Essential plan to maximize your reach.',
            },
            {
                name: '250 mins call- time in Virtual front desk',
                desc: 'Enjoy premium channel discounts only available in Kiwi - up to $400 in value a month.',
            },
        ],
    },
    {
        ...SUBSCRIPTION_PLANS[2],
        des: 'Perfect for mental health and primary care providers looking to build their caseload and establish professional reputation.',
        featureList: [
            {
                name: 'All features in Plus plan',
            },
            {
                name: '25+ standard channels, Psychology Today, Mental health match, All Counseling, WebMD premium, Vitals',
                desc: 'Gain access to all available standard channels.',
            },
            {
                name: '500 mins call- time in Virtual front desk',
                desc: 'Access premium, customizable themes and advanced apps to enhance your practice website\'s distinctiveness and functionality.',
                support: true,
                supportText: 'Unlimited',
            },
        ],
    },
];

export default subscriptionsPaidPlans;
