import React from 'react';
import s from './s.module.less';

const CCCTermsNConditions = () => {
    const html = `
    <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
    <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">Overview</span>
    </p>
    <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
        <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">The Care Commitment Card (hereafter “Card”) is a prepaid card purchased by the patient for discounted healthcare services. Upon payment, the patient receives a Card that grants access to services valued up to the declared amount. The Card is redeemable for various services offered within Klarity Health’s participating network of providers.</span>
    </p>
    <p>
        <br/>
    </p>
    <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
        <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">Eligibility</span>
    </p>
    <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
        <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">User Restrictions:</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"> The Card is available to users who meet Klarity Health’s eligibility criteria. Use of the Card may be subject to state-specific licensure requirements for healthcare providers.</span>
    </p>
    <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
        <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">Service Availability:</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"> The Card covers specified services offered by Klarity Health within the user’s state of residence. Availability of services may vary based on provider participation and regulatory constraints.</span>
    </p>
    <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
        <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">Provider Criteria:</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"> Providers reserve the right to determine the clinical appropriateness of services requested under the Card.</span>
    </p>
    <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
        <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">Purchase Terms and Validity</span>
    </p>
    <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
        <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">One-Time Purchase:</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"> The Card is a single-purchase product, granting the cardholder discounted access to eligible services as defined by Klarity Health.</span>
    </p>
    <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
        <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">Validity Period:</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"> The Card does not expire and is valid from the purchase date onward. Unused discounts expire at the end of this period and are non-refundable.</span>
    </p>
    <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
        <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">Refund Policy:</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"> Refund requests must align with Klarity Health’s refund policy, and refunds may be subject to processing fees.</span>
    </p>
    <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
        <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">Exchange for Cash:</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"> The Card is not eligible for cash or cash equivalent exchanges.</span>
    </p>
    <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
        <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">User Obligations and Prohibited Uses</span>
    </p>
    <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
        <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">Limitations on Usage:</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"> The Card is for the purchaser’s use only and is non-transferable unless Klarity Health explicitly permits otherwise.</span>
    </p>
    <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
        <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">Users agree to follow provider guidance on the use of services accessed with the Card. Any misuse or attempts to access services beyond those clinically necessary may result in suspension or termination of the Card.</span>
    </p>
    <p>
        <br/>
    </p>
    <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
        <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">Data Collection and Privacy</span>
    </p>
    <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
        <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">Klarity Health collects minimal data at the time of Card purchase. Data collected during healthcare visits will be subject to applicable privacy policies, including HIPAA, as relevant. Personal data will be handled per Klarity Health’s privacy policy and applicable federal and state laws.</span>
    </p>
    <p>
        <br/>
    </p>
    <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
        <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">Disclaimers and Limitations of Liability</span>
    </p>
    <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
        <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">The Card offers discounted access to healthcare services without guaranteeing specific outcomes, including prescriptions. Klarity Health and affiliated providers are not liable for any outcomes of services accessed with the Card.</span>
    </p>
    <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
        <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">Services covered by the Card may vary based on provider availability, regulatory changes, or other factors. Klarity Health disclaims liability for any modifications in service scope or availability during the Card’s validity.</span>
    </p>
    <p style="line-height:1.38;margin-left: 10pt;text-indent: -10pt;margin-top:9pt;margin-bottom:0pt;padding:0pt 0pt 0pt 10pt;">
        <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">•</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"><span class="Apple-tab-span" style="text-wrap-mode: nowrap;">	</span></span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">The Card does not provide medical advice, diagnosis, or treatment. Users should consult providers directly for all health concerns and specific medical needs.</span>
    </p>
    <p>
        <br/>
    </p>
    <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
        <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">Governing Law and Jurisdiction</span>
    </p>
    <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
        <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">These Terms and Conditions are governed by the laws of Delaware, Klarity Health’s state of incorporation. Disputes will be subject to the jurisdiction of courts in Delaware or California.</span>
    </p>
    <p>
        <br/>
    </p>
    <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
        <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">Modifications to Terms</span>
    </p>
    <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
        <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">Klarity Health reserves the right to amend or update these Terms and Conditions at any time, with changes communicated through official Klarity Health channels.</span>
    </p>
    <p>
        <br/>
    </p>
    <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
        <span style="font-size: 10pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">Disputes</span>
    </p>
    <p style="line-height:1.38;margin-top:9pt;margin-bottom:0pt;">
        <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">Any disputes arising from participation in the Promotion will be resolved in accordance with Klarity’s standard dispute resolution procedures.</span>
    </p>
    <p>
        <br/>
    </p>
    <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
        <span style="font-size: 10pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">Contact Information</span>
    </p>
    <p style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;">
        <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">For questions or concerns regarding the Promotion, please contact:</span>
    </p>
    <p>
        <span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-weight: 700; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;">Email:</span><span style="font-size: 10.5pt; font-family: Arial, sans-serif; color: rgb(14, 14, 14); font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: normal; font-variant-emoji: normal; vertical-align: baseline; white-space: pre-wrap;"> providersupport@helloklarity.com</span>
    </p>
    <p>
        <br/>
    </p>
        `;
    return (
        <div className={s.wrap}>
            <div className={s.title}>Care Commitment Card Terms and Conditions</div>
            <div className={s.content} dangerouslySetInnerHTML={{ __html: html }} />
        </div>
    );
};

export default CCCTermsNConditions;
