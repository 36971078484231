import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { Form } from 'antd';
import ProviderStore from 'store/Provider';
import { QualificationFormFieldEnumInterface, PreviewFormInterface, QualificationFormFieldEnum, ProfileFormInterface, ProfileFormFieldEnum } from 'types/provider';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import StateSelect from 'components/form/StateSelect';

import DeaLicense from 'components/onboarding/DeaLicense';
import CollaboratingPhysician from 'components/onboarding/CollaboratingPhysician';
import ControlledSubstanceCertification from 'components/onboarding/ControlledSubstanceCertification';
import ContinuingMedicalEducationCertificates from 'components/onboarding/ContinuingMedicalEducationCertificates';
import NurseAndPhysiciansAssistantLicense from 'components/onboarding/NurseAndPhysiciansAssistantLicense';

import ProfilePanel from 'components/onboarding//ProfilePanel';
import PracticeExperiencePanel from 'components/onboarding//PracticeExperiencePanel';

type Props = {
    form: FormInstance<PreviewFormInterface>,
    hideTitle?: boolean,
    className?:string,
    editable?:boolean,
    isOperation?:boolean,
    providerId:number,
};

const Preview = (props: Props) => {
    const { form, hideTitle, className, editable, isOperation, providerId } = props;
    const supportPracticeExp = true;
    const [getUser] = ProviderStore.useStore();
    const user = getUser('data');
    const [profileForm] = Form.useForm<ProfileFormInterface>();
    const qualifications:QualificationFormFieldEnumInterface[] = form.getFieldValue('qualifications') || [];
    return (
        <div className={s.wrap}>
            {
                !hideTitle &&
                <>
                    <h2>Hello, {user?.firstName}!</h2>
                    <p className={s.subTitle}>Welcome to Kiwi Health application process! First, let&apos;s get to know you.</p>
                </>
            }
            <div className={`${s.contentInner} ${className || ''}`}>
                <Form
                    form={form}
                    name="basic"
                    className={commonS.formStyle1}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    layout="vertical"
                >
                    <div className={s.module}>
                        <ProfilePanel
                            form={profileForm}
                            showSaveButton
                            viewOnly={!editable}
                            editable={false}
                            providerId={providerId}
                            isOperation={isOperation}
                            initValue={{
                                [ProfileFormFieldEnum.EMAIL]: form.getFieldValue('email'),
                                [ProfileFormFieldEnum.GMAIL]: form.getFieldValue('gmail'),
                                [ProfileFormFieldEnum.NPI_NUMBER]: form.getFieldValue('npi'),
                                [ProfileFormFieldEnum.YEARS_OF_EXPERIENCE]: form.getFieldValue('yearExp'),
                                [ProfileFormFieldEnum.CITY]: form.getFieldValue('city'),
                                [ProfileFormFieldEnum.TEL]: form.getFieldValue('tel'),
                                [ProfileFormFieldEnum.MAILING_ADDRESS]: form.getFieldValue('address'),
                                [ProfileFormFieldEnum.ZIP_CODE]: form.getFieldValue('zip'),
                                [ProfileFormFieldEnum.STATE]: form.getFieldValue('state'),
                                [ProfileFormFieldEnum.SPECIALITYINFAMILY]: form.getFieldValue('specialityInFamily'),
                                [ProfileFormFieldEnum.SPECIALITYINHEALTH]: form.getFieldValue('specialityInHealth'),
                            }}
                        />
                    </div>
                    {
                        supportPracticeExp &&
                        <div className={s.module}>
                            <PracticeExperiencePanel
                                form={profileForm}
                                showSaveButton
                                viewOnly={!editable}
                                editable={false}
                                providerId={providerId}
                                isOperation={isOperation}
                                initValue={{
                                    [ProfileFormFieldEnum.EMAIL]: form.getFieldValue('email'),
                                    [ProfileFormFieldEnum.NPI_NUMBER]: form.getFieldValue('npi'),
                                    [ProfileFormFieldEnum.YEARS_OF_EXPERIENCE]: form.getFieldValue('yearExp'),
                                    [ProfileFormFieldEnum.CITY]: form.getFieldValue('city'),
                                    [ProfileFormFieldEnum.TEL]: form.getFieldValue('tel'),
                                    [ProfileFormFieldEnum.MAILING_ADDRESS]: form.getFieldValue('address'),
                                    [ProfileFormFieldEnum.ZIP_CODE]: form.getFieldValue('zip'),
                                    [ProfileFormFieldEnum.STATE]: form.getFieldValue('state'),
                                    [ProfileFormFieldEnum.SPECIALITYINFAMILY]: form.getFieldValue('specialityInFamily'),
                                    [ProfileFormFieldEnum.SPECIALITYINHEALTH]: form.getFieldValue('specialityInHealth'),
                                }}
                            />
                        </div>
                    }
                    <div>
                        <h3>Practice state</h3>
                    </div>
                    {
                        qualifications.map((qualification) => {
                            return (
                                <div key={qualification[QualificationFormFieldEnum.STATE]}>
                                    <StateSelect value={qualification[QualificationFormFieldEnum.STATE]} disabled style={{ width: 360, marginBottom: 36 }} />
                                    <div className={s.module}>
                                        <DeaLicense
                                            isOperation={isOperation}
                                            viewOnly={!editable}
                                            state={qualification[QualificationFormFieldEnum.STATE]}
                                            inited
                                            providerId={providerId}
                                            initValue={qualification[QualificationFormFieldEnum.DEA_LICENSES]}
                                        />
                                    </div>
                                    <div className={s.module}>
                                        <CollaboratingPhysician
                                            isOperation={isOperation}
                                            state={qualification[QualificationFormFieldEnum.STATE]}
                                            viewOnly={!editable}
                                            editable={false}
                                            providerId={providerId}
                                            initValue={qualification[QualificationFormFieldEnum.COLLABORATING_PHYSICIAN]}
                                        />
                                    </div>
                                    <div className={s.module}>
                                        <NurseAndPhysiciansAssistantLicense
                                            isOperation={isOperation}
                                            state={qualification[QualificationFormFieldEnum.STATE]}
                                            viewOnly={!editable}
                                            nurseInited={!!qualification[QualificationFormFieldEnum.NURSE_AND_PHYSICIAN_ASSISTANT_LICENSES]?.nurse}
                                            paInited={!!qualification[QualificationFormFieldEnum.NURSE_AND_PHYSICIAN_ASSISTANT_LICENSES]?.pa}
                                            providerId={providerId}
                                            nurseInitValue={qualification[QualificationFormFieldEnum.NURSE_AND_PHYSICIAN_ASSISTANT_LICENSES]?.nurse}
                                            paInitValue={qualification[QualificationFormFieldEnum.NURSE_AND_PHYSICIAN_ASSISTANT_LICENSES]?.pa}
                                        />
                                    </div>
                                    {qualification[QualificationFormFieldEnum.CONTROLLED_SUBSTANCE_CERTIFICATION] &&
                                    <div className={s.module}>
                                        <ControlledSubstanceCertification
                                            isOperation={isOperation}
                                            state={qualification[QualificationFormFieldEnum.STATE]}
                                            viewOnly={!editable}
                                            inited
                                            providerId={providerId}
                                            initValue={qualification[QualificationFormFieldEnum.CONTROLLED_SUBSTANCE_CERTIFICATION]}
                                        />
                                    </div>
                                    }
                                    {!!(qualification[QualificationFormFieldEnum.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES]?.licenses?.length) &&
                                    <div className={s.module}>
                                        <ContinuingMedicalEducationCertificates
                                            isOperation={isOperation}
                                            viewOnly={!editable}
                                            state={qualification[QualificationFormFieldEnum.STATE]}
                                            inited
                                            providerId={providerId}
                                            initValue={qualification[QualificationFormFieldEnum.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES]}
                                        />
                                    </div>
                                    }
                                </div>
                            );
                        })
                    }
                </Form>
            </div>
        </div>
    );
};

export default Preview;
