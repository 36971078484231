import React, { useCallback, useEffect, useState } from 'react';
import PhotoUploader from 'components/PhotoUploader';
import s from './s.module.less';

interface IProps {
    previewImageStyle?: Record<string, any>;
    selectedPhotoIndex?: number;
    min: number;
    data?: Array<string> | string;
    enable: boolean;
    valid?: boolean;
    validFun?: (photos: string, inx: number)=> boolean
    errorMessage?: string;
    onChange?: (urls:string | Array<string>) => void
    responseType?: string;
}

const PhotoList = ({
    previewImageStyle = {},
    selectedPhotoIndex,
    min = 3,
    data,
    enable = false,
    responseType,
    valid,
    validFun,
    errorMessage,
    onChange,
}: IProps) => {
    const [photos, setPhotos] = useState<Array<string>>([]);

    const checkPhotoListMinLength = useCallback((photosData: Array<string>) => {
        const existPhotos = photosData.filter((photo) => !!photo);

        if (existPhotos && existPhotos.length < min) {
            return existPhotos.concat(new Array(min - existPhotos.length).fill(''));
        } else {
            return existPhotos.concat(['']);
        }
    }, [min]);

    const handleReturnResponse = useCallback((photosData: Array<string>) => {
        if (typeof onChange === 'function') {
            const submitData = photosData.filter((photo) => !!photo);
            if (responseType === 'string') {
                onChange(submitData.join(','));
            } else {
                onChange(submitData);
            }
        }
    }, [onChange, responseType]);

    const handleChange = useCallback((url: string, index: number) => {
        photos[index] = url;
        const newPhotos = checkPhotoListMinLength(photos);

        setPhotos(newPhotos);
        handleReturnResponse(newPhotos);
    }, [handleReturnResponse, photos, checkPhotoListMinLength]);

    const removePhotoByIndex = useCallback((index: number) => {
        photos.splice(index, 1);

        setPhotos(checkPhotoListMinLength(photos));
        handleReturnResponse(photos);
    }, [photos, checkPhotoListMinLength, handleReturnResponse]);

    useEffect(() => {
        if (typeof data === 'string') {
            setPhotos([data]);
        } else {
            setPhotos(checkPhotoListMinLength(data || []));
        }
    }, [min, data, checkPhotoListMinLength, responseType]);

    return (
        <div className={s.wrapper}>
            <div className={s.photoList}>
                {
                    photos.map((photo, index) => {
                        let isError = false;
                        if (valid && photos?.filter((e) => !!e).length < min && !photo) {
                            isError = true;
                        }
                        if (validFun) {
                            isError = validFun(photo, index);
                        }
                        return enable ? (
                            <PhotoUploader isError={isError} errorMessage={errorMessage} index={index} onChange={handleChange} key={index} cls={s.photo} url={photo} onRemove={removePhotoByIndex} />
                        ) : (
                            photo && (
                                <div key={index} className={`${s.photo} ${selectedPhotoIndex === index && s.selected}`}>
                                    <img className={`${s.placeholder}`} style={previewImageStyle} src={photo} />
                                </div>
                            )
                            // <div key={index} className={`${s.placeholder} ${s.photo}`} style={{ backgroundImage: `url(${photo})` }} />
                        );
                    })
                }
            </div>
        </div>
    );
};

export default PhotoList;
