import React from 'react';
import { Button } from 'antd';
import s from './s.module.less';
// import useMyListedChannels from 'hooks/useMyListedChannels';
import GoogleDoc from './googleDoc.png';
import { Email } from 'constants/contact';
import { PATIENT_TEMPLATE_GOOGLE_DOC_URL } from 'constants/common';

const FirstStep = () => {
    return (
        <div className={s.tipsWrap}>
            <h3>Step1: Access our Google Sheets template</h3>
            <div className={s.info}>
                Click below to access the template and start inputting your patient data.
            </div>
            <div className={s.googleBtnWrap}>
                <Button
                    className={s.googleBtn}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M6.66675 4.00008H4.00008C3.2637 4.00008 2.66675 4.59703 2.66675 5.33341V12.0001C2.66675 12.7365 3.2637 13.3334 4.00008 13.3334H10.6667C11.4031 13.3334 12.0001 12.7365 12.0001 12.0001V9.33341M9.33341 2.66675H13.3334M13.3334 2.66675V6.66675M13.3334 2.66675L6.66675 9.33341" stroke="#111827" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <span
                        onClick={() => {
                            window.open(PATIENT_TEMPLATE_GOOGLE_DOC_URL, '_blank');
                        }}
                    >Open Google Sheets template
                    </span>
                </Button>
            </div>
            <h3>Step 2: Make a copy to your drive</h3>
            <div className={s.info}>
                Click File and select &quot;Make a copy&quot; to create your own patient contact sheet. If &apos;Make a Copy&apos; is unavailable, please sign in to your Google account and retry.
            </div>
            <div className={s.imgWrap}>
                <img src={GoogleDoc} />
            </div>
            <h3>Step 3: Input patient information and download as a .csv(Comma Separated Values) file</h3>
            <div className={s.info}>
                Fill in the patient contact information in your copied template. Ensure all fields are accurately completed. The email address is a  mandatory field and cannot be left empty.
            </div>

            <div className={s.contact}>
                Need help?
                <a href={`mailto:${Email.support}`}> Contact us</a>
            </div>
        </div>
    );
};

export default FirstStep;
