import React, { useEffect, useMemo } from 'react';
import { Form, Button, DatePicker, Input, message, Modal } from 'antd';
import { License, ELicenseType } from 'types/common';
import { FormLicense } from 'types/form';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import FileUpload from 'components/form/FileUpload';
import { providerLicenseUpdate } from 'api/provider';
import { opLicenseUpdate } from 'api/operation';
import { formatDate } from 'utils/common';
import { LicenseData } from 'data/provider';
import FormValidationHelper from 'utils/validation';
import LicenseImg from 'components/LicenseImg';
import { EMPTY_PLACEHOLDER, REQUIRED_FIELD, SNAPSHOT_COLOR } from 'constants/common';
import Alert from 'components/Alert';
import { renderChangUI } from 'types/changeHighLight';

type Props = {
    isKlarityUser?: boolean,
    inited?:boolean,
    initValue?: FormLicense,
    state?:string,
    providerId?:number,
    onChange?: (value:Partial<FormLicense>)=>void
    viewOnly?:boolean,
    onNullState?: ()=>void,
    isOperation?:boolean,
    isEditing?:boolean,
    onEditableChange?: (editing: boolean) => void,
};

const MalpracticeInsuranceCertificate = (props: Props) => {
    const { inited = false, initValue, providerId, onChange, viewOnly, onNullState, isOperation, isKlarityUser, isEditing, onEditableChange } = props;
    const [submiting, setSubmiting] = React.useState(false);

    //enable and show modal is little function cover..

    const [enable, setEnable] = React.useState(!!inited);

    //mean already filled and display modal
    const [showModal, setShowModal] = React.useState(false);
    const [form] = Form.useForm<FormLicense>();
    const [currentValue, setCurrentValue] = React.useState<FormLicense>();
    const [,forceUpdate] = React.useState({});

    useEffect(() => {
        setEnable(!!isEditing)
    }, [isEditing])

    const onSubmit = async () => {
        try {
            setSubmiting(true);
            const formValues = await form.validateFields();
            const data = formValues;
            const licenseData:Partial<License> = {
                type: ELicenseType.MIC,
                secondType: '1',
                licenseNumber: data.licenseNumber,
                expireDate: formatDate(data.expireDate),
                schedule: data.schedule?.join(',') || '',
                url: data.url,
                id: data.id,
            };
            const fn = isOperation ? opLicenseUpdate : providerLicenseUpdate;
            // eslint-disable-next-line no-await-in-loop
            const result = await fn(licenseData, providerId!);
            if (!result.error) {
                message.success('Malpractice insurance certificate license info has updated');
                setEnable(false);
                setShowModal(false);
                onEditableChange?.(false);
                if (onChange) {
                    const val:FormLicense = LicenseData.serverToForm(licenseData as License);
                    onChange(val);
                    form.setFieldsValue(val);
                    setCurrentValue(val);
                    forceUpdate({});
                }
            }
        } catch (e:any) {
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };

    React.useEffect(() => {
        if (initValue) {
            form.setFieldsValue(initValue);
            setCurrentValue(initValue);
            forceUpdate({});
        }
    }, [initValue]);
    const hasValue = !!currentValue?.licenseNumber;

    const bodyRender = useMemo(() => {
        if (enable) {
            return (
                <Form
                    form={form}
                    name="basic"
                    className={commonS.formStyle1}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item
                        noStyle
                    >
                        <div className={s.modalWrap}>
                            <Form.Item name="id" style={{position: 'absolute'}}>
                                <Input type="hidden" />
                            </Form.Item>
                            <div className={s.row}>
                                <div className={s.rowItem}>
                                    <Form.Item
                                        style={{ width: '360px' }}
                                        label="Certificate number"
                                        name={['licenseNumber']}
                                        validateFirst
                                        rules={[
                                            { required: true, message: REQUIRED_FIELD },
                                            FormValidationHelper.validateLicenseNumber('Must contain only letters or numbers'),
                                        ]}
                                    >
                                        <Input maxLength={50} placeholder="" />
                                    </Form.Item>
                                </div>
                                <div className={s.rowItem}>
                                    <Form.Item
                                        style={{ width: '360px' }}
                                        label="Expiration date"
                                        name={['expireDate']}
                                        rules={[{ required: true, message: REQUIRED_FIELD }]}
                                    >
                                        <DatePicker
                                            disabledDate={(date) => {
                                                const now = (new Date()).getTime();
                                                if (!date) {
                                                    return false;
                                                }
                                                return date?.valueOf() < now;
                                            }}
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.rowItem}>
                                <Form.Item
                                    style={{ width: '500px' }}
                                    label="Certificate copy"
                                    name={['url']}
                                    rules={[{ required: true, message: REQUIRED_FIELD }]}
                                >
                                    <FileUpload />
                                </Form.Item>
                            </div>
                        </div>
                    </Form.Item>
                </Form>
            )
        } else if (hasValue) {
            return (
                (
                    <div className={s.subModule} key={currentValue?.licenseNumber}>
                        <div className={s.row}>
                            <div
                                style={{
                                    width: '300px',
                                }}
                                className={s.displayItem}
                            >
                                <div className={s.label}>Certificate number</div>
                                <div className={s.value}>{ currentValue?.licenseNumber}</div>
                                {renderChangUI('licenseNumber', currentValue, !!isOperation)}
                            </div>
                            <div className={s.displayItem}>
                                <div className={s.label}>Expiration date</div>
                                <div className={s.value}>{ currentValue?.expireDate?.format('MM-DD-YYYY')}</div>
                            </div>
                        </div>

                        <div className={s.row}>
                            <div
                                style={{
                                    width: '80%',
                                }}
                                className={s.displayItem}
                            >
                                <div className={s.label}>Certificate copy</div>
                                <div className={s.value}>
                                    <LicenseImg src={currentValue?.url} />
                                </div>
                                {renderChangUI('url', currentValue, !!isOperation, (val:string) => { return <div style={{ border: `1px solid ${SNAPSHOT_COLOR}`, display: 'inline-block', marginTop: '16px' }}><LicenseImg src={val} /></div>; })}
                            </div>
                        </div>
                    </div>
                )
            )
        }

        return (
            <div>
                {EMPTY_PLACEHOLDER}
            </div>
        )
    }, [enable, hasValue, currentValue]);

    return (
        <div className={s.wrap}>
            <h3>
                <div className={s.left}>
                    {/* {!enable && (
                        <span
                            onClick={() => {
                                // if (!state) {
                                //     onNullState?.();
                                //     return;
                                // }
                                setShowModal(true);
                            }}
                            className={commonS.addIcon}
                            style={{ marginRight: 16 }}
                        />)} */}
                    <span>Malpractice insurance certificate</span>
                </div>
                {
                    !viewOnly && !enable &&
                    <Button
                        size="small"
                        onClick={() => {
                            onEditableChange?.(true);
                            setEnable(true);
                        }}
                    >
                        Edit
                    </Button>
                }
                {
                    enable && !viewOnly &&
                    <div className={s.btnWrap}>
                        <Button
                            type="primary"
                            size="small"
                            onClick={onSubmit}
                        >
                            Save
                        </Button>
                        <Button
                            size="small"
                            onClick={() => {
                                form.setFieldsValue(initValue);
                                onEditableChange?.(false);
                                setEnable(false);
                            }}
                            style={{ marginLeft: 16 }}
                        >
                            Cancel
                        </Button>
                    </div>
                }
            </h3>
            {
                !hasValue && !isKlarityUser &&
                <div className={s.alert}>
                    <Alert text="Malpractice insurance certificate is mandatory in order to be listed on Klarity." />
                </div>
            }
            { 
                !hasValue && isKlarityUser && (
                    <div className={s.klarityUserTip}>
                        <div className={s.tipIcon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM11 6C11 6.55228 10.5523 7 10 7C9.44772 7 9 6.55228 9 6C9 5.44772 9.44772 5 10 5C10.5523 5 11 5.44772 11 6ZM9 9C8.44772 9 8 9.44772 8 10C8 10.5523 8.44772 11 9 11V14C9 14.5523 9.44772 15 10 15H11C11.5523 15 12 14.5523 12 14C12 13.4477 11.5523 13 11 13V10C11 9.44772 10.5523 9 10 9H9Z" fill="#60A5FA" />
                            </svg>
                        </div>
                        <div className={s.tipText}>Based on Klarity's policy, your Malpractice Insurance must have a minimum coverage of $1,000,000 per occurrence and $3,000,000 annual aggregate.</div>
                    </div>
                )
            }
            {
                bodyRender
            }

            <Modal
                title={hasValue ? 'Edit malpractice insurance certificate' : 'Add malpractice insurance certificate'}
                closable
                className={commonS.modalFixHeightWrap}
                width="80%"
                style={{
                    maxWidth: '800px',
                }}
                onCancel={() => {
                    form.resetFields();
                    form.setFieldsValue(currentValue || {});
                    setShowModal(false);
                }}
                okText="Save"
                open={showModal}
                onOk={onSubmit}
                destroyOnClose
                okButtonProps={{ loading: submiting }}
            >
                <Form
                    form={form}
                    name="basic"
                    className={commonS.formStyle1}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item
                        noStyle
                    >
                        <div className={s.modalWrap}>
                            <Form.Item name="id">
                                <Input type="hidden" />
                            </Form.Item>
                            <div className={s.row}>
                                <div className={s.rowItem}>
                                    <Form.Item
                                        style={{ width: '360px' }}
                                        label="Certificate number"
                                        name={['licenseNumber']}
                                        validateFirst
                                        rules={[
                                            { required: true, message: REQUIRED_FIELD },
                                            FormValidationHelper.validateLicenseNumber('Must contain only letters or numbers'),
                                        ]}
                                    >
                                        <Input maxLength={50} placeholder="" />
                                    </Form.Item>
                                </div>
                                <div className={s.rowItem}>
                                    <Form.Item
                                        style={{ width: '360px' }}
                                        label="Expiration date"
                                        name={['expireDate']}
                                        rules={[{ required: true, message: REQUIRED_FIELD }]}
                                    >
                                        <DatePicker
                                            disabledDate={(date) => {
                                                const now = (new Date()).getTime();
                                                if (!date) {
                                                    return false;
                                                }
                                                return date?.valueOf() < now;
                                            }}
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.rowItem}>
                                <Form.Item
                                    style={{ width: '500px' }}
                                    label="Certificate copy"
                                    name={['url']}
                                    rules={[{ required: true, message: REQUIRED_FIELD }]}
                                >
                                    <FileUpload />
                                </Form.Item>
                            </div>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default MalpracticeInsuranceCertificate;
