// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--Z9zdJ .s-module__cropperBox--NioML {
  padding: 25px;
  position: relative;
  height: 300px;
  margin: auto;
  border-radius: 50%;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.s-module__wrap--Z9zdJ .s-module__actionWrap--UCZZI {
  display: flex;
  align-items: center;
  gap: 46px;
}
.s-module__wrap--Z9zdJ .s-module__actionWrap--UCZZI .s-module__box--cscQR {
  flex: 1;
}
.s-module__wrap--Z9zdJ .s-module__actionWrap--UCZZI .s-module__box--cscQR .s-module__silderBox--P2fVT {
  display: flex;
  align-items: center;
  gap: 8px;
}
.s-module__wrap--Z9zdJ .s-module__actionWrap--UCZZI .s-module__box--cscQR .s-module__silderBox--P2fVT .s-module__slider--FyOM_ {
  flex: 1;
}
.s-module__wrap--Z9zdJ .s-module__actionWrap--UCZZI .s-module__box--cscQR .s-module__silderBox--P2fVT .s-module__increase--JS7AN {
  height: 24px;
  width: 24px;
  cursor: pointer;
}
.s-module__wrap--Z9zdJ .s-module__actionWrap--UCZZI .s-module__box--cscQR .s-module__silderBox--P2fVT .s-module__decrease--DuxVp {
  height: 24px;
  width: 24px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/PhotoEditor/s.module.less"],"names":[],"mappings":"AAAA;EAEQ,aAAA;EACA,kBAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAR;AAVA;EAaQ,aAAA;EACA,mBAAA;EACA,SAAA;AAAR;AAfA;EAkBY,OAAA;AAAZ;AAlBA;EAsBgB,aAAA;EACA,mBAAA;EACA,QAAA;AADhB;AAvBA;EA2BoB,OAAA;AADpB;AA1BA;EA+BoB,YAAA;EACA,WAAA;EACA,eAAA;AAFpB;AA/BA;EAoCoB,YAAA;EACA,WAAA;EACA,eAAA;AAFpB","sourcesContent":[".wrap {\n    .cropperBox {\n        padding: 25px;\n        position: relative;\n        height: 300px;\n        margin: auto;\n        border-radius: 50%;\n        box-sizing: content-box;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n    }\n    .actionWrap {\n        display: flex;\n        align-items: center;\n        gap: 46px;\n\n        .box {\n            flex: 1;\n            .label {}\n\n            .silderBox {\n                display: flex;\n                align-items: center;\n                gap: 8px;\n\n                .slider {\n                    flex: 1;\n                }\n\n                .increase {\n                    height: 24px;\n                    width: 24px;\n                    cursor: pointer;\n                }\n                .decrease {\n                    height: 24px;\n                    width: 24px;\n                    cursor: pointer;\n                }\n            }\n        }\n    }\n\n    canvas {\n        // border-radius: 50%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--Z9zdJ`,
	"cropperBox": `s-module__cropperBox--NioML`,
	"actionWrap": `s-module__actionWrap--UCZZI`,
	"box": `s-module__box--cscQR`,
	"silderBox": `s-module__silderBox--P2fVT`,
	"slider": `s-module__slider--FyOM_`,
	"increase": `s-module__increase--JS7AN`,
	"decrease": `s-module__decrease--DuxVp`
};
export default ___CSS_LOADER_EXPORT___;
