import React, { useMemo } from 'react';
import s from './s.module.less';
import KlarityPlanCard from './KlarityPlanCard';
import PaymentInfo from '../PaymentInfo';
import subscriptionsPaidPlans from 'constants/klarityPlanSubscriptionsPaidPlans';
import SubscriptionStore from 'store/Subscription';
import useSubscriptionPlan from 'hooks/useSubscriptionPlan';
import { Spin } from 'antd';
import { TSubscriptionType } from 'types/subscription';
import useGetProviderPlanDetail from 'hooks/useGetProviderPlanDetail';
import ProviderChannelsStore from 'store/ProviderChannles';
import homeStore from 'store/Home';
import { getProviderKlarityPlanLevel } from 'utils/provider';

const KlarityPlanSubscriptionInfo = () => {
    const [plansData, loadingSubscriptionPlan] = useSubscriptionPlan();
    const subscriptionPlan = plansData.klarityPlan;
    const [deital, detailLoading] = useGetProviderPlanDetail();
    const [getSubscription] = SubscriptionStore.useStore();
    const currentPlan = getSubscription('currentPlan');
    const [getMyChannels] = ProviderChannelsStore.useStore();
    const myChannels = getMyChannels('data');
    const [getHomeStore] = homeStore.useStore();
    const homeInfo = getHomeStore('data');

    const myPlanLevel = getProviderKlarityPlanLevel(homeInfo);

    const finalPlans = useMemo(() => {
        return subscriptionPlan
            .map((plan) => {
                const constPlan: any = subscriptionsPaidPlans.find((e) => e.type === plan.type) || {};
                return {
                    ...constPlan,
                    ...plan,
                };
            })
            .filter((plan) => plan.type !== TSubscriptionType.GROUP_PRACTICE);
    }, [subscriptionPlan]);

    return (
        <Spin spinning={!!loadingSubscriptionPlan || !!detailLoading}>
            <div className={s.wrap}>
                <PaymentInfo currentPlan={currentPlan} deital={deital} homeInfo={homeInfo} />
                <div className={s.cardBox}>
                    {
                        finalPlans.map((plan, index) => {
                            const { monthlyPlanId, type, name, featureList, highlight, subType, monthlyPrice } = plan;

                            return (
                                <div key={index} className={s.cardWrap}>
                                    <KlarityPlanCard
                                        id={monthlyPlanId}
                                        currentPlan={currentPlan}
                                        price={monthlyPrice}
                                        subType={subType}
                                        highlight={highlight}
                                        type={type}
                                        name={name}
                                        myPlanLevel={myPlanLevel}
                                        featureList={featureList}
                                    />
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </Spin>
    );
};

export default KlarityPlanSubscriptionInfo;
