import React from 'react';
import s from './s.module.less';
import unStartIcon from 'assets/common/unStar.svg';
import startedIcon from 'assets/common/stared.svg';

type StarProp = {
    width?: number;
    value?: number;
    gap?: number; // full = 5, if gap = 1, means only support set starValue = 0,1,2,3,4,5, if gap = 0.5, starValue = 0,0.5,1,1.5 ...
    onChange?: (startValue: number) => void;
    editable?: boolean;
};

export default function Star(props: StarProp) {
    const {
        width = 200,
        value = 0,
        gap = 1,
        onChange,
        editable = true,
    } = props;

    const arr = new Array(5)
        .toString()
        .split(',')
        .map((item, index) => {
            return index;
        });

    const clickAreaItemCount = 5 / gap;
    const clickItemArr = new Array(clickAreaItemCount)
        .toString()
        .split(',')
        .map((item, index) => {
            return index;
        });

    const wrapWidth = `${width}px`;
    const itemWidth = `${width / 5}px`;
    const staredWidth = `${(value / 5) * 100}%`;
    const clickItemWidth = `${width / clickAreaItemCount}px`;

    return (
        <div className={s.wrap} style={{ width: wrapWidth, height: itemWidth }}>
            <div className={s.unStarWrap}>
                {arr.map((key) => {
                    return <img className={s.unStarItem} key={key} src={unStartIcon} />;
                })}
            </div>
            <div className={s.staredWrap} style={{ width: staredWidth }}>
                {arr.map((key) => {
                    return (
                        <img
                            className={s.staredItem}
                            style={{ width: itemWidth, height: itemWidth }}
                            key={key}
                            src={startedIcon}
                        />
                    );
                })}
            </div>
            <div className={s.clickAreaWrap}>
                {clickItemArr.map((key) => {
                    return (
                        <span
                            className={s.clickItem}
                            style={{
                                width: clickItemWidth,
                                cursor: editable ? 'pointer' : 'default',
                            }}
                            key={key}
                            onClick={() => {
                                if (!editable) {
                                    return;
                                }
                                const newVal = key * gap + gap;
                                onChange?.(newVal);
                            }}
                        />
                    );
                })}
            </div>
            {/* little trick for select star value = 0 */}
            <div
                style={{ width: '5%', cursor: editable ? 'pointer' : 'default' }}
                onClick={() => {
                    onChange?.(0);
                }}
                className={s.clearAreaWrap}
            />
        </div>
    );
}
