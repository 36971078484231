import React, { useCallback, useEffect, useRef } from 'react';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { Button, message, Modal, Spin } from 'antd';
import ReviewItem from 'pages/dashboard/KlarityReviewsDashboradPage/components/WaitForReply/components/ReviewItem';
import ReviewDisputeStore from 'store/ReviewDispute';
import { getDisputeReviewDetail } from 'api/survey';
import { TReviewItem } from 'pages/dashboard/KlarityReviewsDashboradPage/types';
import { getPrivateFilePreview } from 'api/ehr';
import DisputeStatus from 'components/DisputeStatusTag';
import dayjs from 'dayjs';

interface IProps {
    title?: string;
    onCancel?: () => void;
}

const ReviewDisputeViewModal = ({
    title = 'Dispute details',
    onCancel,
}: IProps) => {
    const NonText = useRef('-');
    const [getDiputeStore, setDisputeStore] = ReviewDisputeStore.useStore();
    const reviewItem = getDiputeStore('currentReview');
    const [loading, setLoading] = React.useState(true);
    const [disputeReview, setDisputeReview] = React.useState<TReviewItem>();
    const [previewLoading, setPreviewLoading] = React.useState(false);

    const fetchData = useCallback(async () => {
        if (reviewItem) {
            setLoading(true);
            try {
                const res = await getDisputeReviewDetail(reviewItem.id);

                if (res && !res.error) {
                    setDisputeReview(res.data.data);
                } else {
                    message.error(res?.error);
                }
            } catch (e) {
                console.error(e);
            }
        }
        setLoading(false);
    }, [reviewItem]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleCancel = useCallback(() => {
        setDisputeStore('showViewModal', false);
        setDisputeStore('currentReview', undefined);
        onCancel?.();
    }, [onCancel]);

    const handleLinkClick = useCallback(async (v?: string) => {
        if (v) {
            setPreviewLoading(true);
            try {
                const res = await getPrivateFilePreview(v);

                if (res && !res.error && res.data?.data?.singedUrl) {
                    window.open(res.data.data.singedUrl, '_blank');
                } else if (!res.data?.data?.singedUrl) {
                    message.error('there is no file to preview.');
                } else {
                    message.error(res?.error);
                }
            } catch (e) {
                console.error(e);
            }
            setPreviewLoading(false);
        }
    }, []);

    return (
        <Modal
            width={780}
            open={!!getDiputeStore('showViewModal')}
            title={title}
            className={commonS.modalFixHeightWrap}
            footer={[
                <Button key="back" type="primary" onClick={handleCancel}>
                    Close
                </Button>,
            ]}
            onCancel={handleCancel}
        >
            {
                loading ? <Spin spinning /> : (
                    <Spin spinning={previewLoading}>
                        <div className={s.wrap}>
                            <div className={s.reviewItem}>
                                <ReviewItem
                                    review={reviewItem}
                                    hideRightPart
                                    hideBorder
                                />
                            </div>
                            <div className={s.disputeInfo}>
                                <div className={s.header}>
                                    <div className={s.title}>Dispute by you</div>
                                    <div className={s.time}>{disputeReview?.disputeSubmitAt && dayjs(disputeReview?.disputeSubmitAt).format('MMMM DD, YYYY')}</div>
                                </div>
                                <div className={s.body}>
                                    <div className={s.item}>
                                        <div className={s.label}>Reason for dispute</div>
                                        <div className={s.value}>{disputeReview?.disputeReasonType || NonText.current}</div>
                                    </div>
                                    <div className={s.item}>
                                        <div className={s.label}>Details</div>
                                        <div className={`${s.value} ${s.textarea}`}>{disputeReview?.disputeReasonDetail || NonText.current}</div>
                                    </div>
                                    <div className={s.item}>
                                        <div className={s.label}>Support documents</div>
                                        <div className={s.value}>
                                            {
                                                disputeReview?.disputeSupportFile && Object.keys(disputeReview.disputeSupportFile).length > 0 && (
                                                    <div className={s.docList}>
                                                        {
                                                            Object.keys(disputeReview.disputeSupportFile).map((v, i) => (
                                                                <div key={i} className={s.docLink} onClick={() => handleLinkClick(v)}>{disputeReview.disputeSupportFile?.[v]}</div>
                                                            ))
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={s.disputeProcess}>
                                <div className={s.header}>
                                    <div className={s.title}>Dispute process</div>
                                    { disputeReview?.disputeDecisionAt ? <div className={s.time}>Last updated: {dayjs(disputeReview?.disputeDecisionAt).format('MMMM DD, YYYY')}</div> : '-' }
                                </div>
                                <div className={s.body}>
                                    <DisputeStatus status={disputeReview?.disputeStatus} />
                                    <div className={s.item}>
                                        <div className={s.label}>Decisions</div>
                                        <div className={`${s.value} ${s.textarea}`}>{disputeReview?.disputeDecisionReason || 'No decisions made yet.'}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Spin>
                )
            }
        </Modal>
    );
};

export default ReviewDisputeViewModal;
