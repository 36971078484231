import React from 'react';
import { Form, Button, Spin, message, Tag, Input } from 'antd';
import s from './s.module.less';
import { ChannelDetailedInformationFormInterface } from 'types/channel';
import commonS from 'styles/common.module.less';
import { stripHtmlTags } from 'utils/common';
import TextArea from 'antd/es/input/TextArea';
import ChannelTagSelect from 'components/form/ChannelTagSelect';
import { ChannelItem } from 'types/common';
import { updateChannelDetail } from 'api/operation';

type Props = {
    editable: boolean,
    initValue?: Partial<ChannelDetailedInformationFormInterface>,
    channelId?: number,
    viewOnly?: boolean,
    onChange?: (value: Partial<ChannelItem>) => void,
    onNullState?: () => void,
    isOperation?: boolean,
};

const DetailInformationComp = (props: Props) => {
    const { editable = true, viewOnly, initValue, channelId, onChange, onNullState, isOperation } = props;
    const [enable, setEnable] = React.useState(!!editable);
    const [form] = Form.useForm<ChannelDetailedInformationFormInterface>();
    const [submiting, setSubmiting] = React.useState(false);
    const [, forceUpdate] = React.useState({});

    const onSubmit = async () => {
        try {
            setSubmiting(true);
            const formValues = await form.validateFields();
            const item: Partial<ChannelItem> = {
                channelDesc: stripHtmlTags(formValues.channelDesc),
                economics: stripHtmlTags(formValues.economics),
                caseStudy: stripHtmlTags(formValues.caseStudy),
                news: stripHtmlTags(formValues.news),
                tagList: formValues.tagList,
                name: formValues.name || initValue?.name || 'New Channel',
                systemEmail: formValues.systemEmail,
            };
            if (channelId) {
                item.id = channelId;
            }
            const result = await updateChannelDetail(item);

            if (!result.error) {
                const id = result.data.data;
                message.success('Channel info has updated');
                form.setFieldsValue({
                    channelDesc: stripHtmlTags(formValues.channelDesc),
                    economics: stripHtmlTags(formValues.economics),
                    caseStudy: stripHtmlTags(formValues.caseStudy),
                    news: stripHtmlTags(formValues.news),
                });
                setEnable(false);
                if (onChange && !channelId) {
                    //create new
                    onChange({ id });
                }
            } else {
                message.error(result.error);
            }
        } catch (e: any) {
            console.log(e);
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };

    React.useEffect(() => {
        if (initValue) {
            const newValue: any = {};
            Object.keys(initValue).forEach((key) => {
                const value = (initValue as any)[key];
                newValue[key] = form.getFieldValue(key) || value;
                if (Array.isArray(form.getFieldValue(key)) && form.getFieldValue(key).length === 0) {
                    newValue[key] = value || [];
                }
            });
            form.setFieldsValue(newValue);
            forceUpdate({});
        }
    }, [initValue]);

    return (
        <div className={s.wrap}>
            <Form
                form={form}
                name="detail"
                className={commonS.formStyle1}
                autoComplete="off"
                layout="vertical"
            >
                <Spin spinning={submiting}>
                    <div className={s.module}>
                        <h3>
                            <span>Detailed information for Kiwi users</span>
                            {!enable && !viewOnly &&
                                <Button
                                    size="small"
                                    onClick={() => {
                                        setEnable(true);
                                    }}
                                >
                                    Edit
                                </Button>}
                        </h3>
                        {
                            enable &&
                            <>
                                <div className={s.rowItem}>
                                    <Form.Item
                                        style={{ width: '80%' }}
                                        label="Value Prop"
                                        name={['channelDesc']}
                                        validateFirst
                                        rules={[
                                            { required: true, message: 'Value prop is required' },
                                        ]}
                                    >
                                        <TextArea rows={5} placeholder="Ex. KiwiHealth is a mental health platform that allows patients to quickly and easily find healthcare providers for their mental health care needs. Because KiwiHealth is seamlessly integrated with Kiwi Health, KiwiHealth is the easiest platform to bring in a large volume of new patients, offer patients both in-person and online services, and have transparent pricing and billing for patients, which are automatically synced with Kiwi Health. Currently, KiwiHealth strong care coordination and patient support team enable over 100 different providers across 30+ states to service more than 30k patients for a personalized health care experience." />
                                    </Form.Item>
                                </div>
                                <div className={s.rowItem}>
                                    <Form.Item
                                        style={{ width: '80%' }}
                                        label="Economics & Terms"
                                        name={['economics']}
                                        rules={[{ required: true, message: 'Economics & Terms is required' }]}
                                    >
                                        <TextArea
                                            rows={5}
                                            placeholder="Ex. KiwiHealth’s pricing model is varied by state.&#10;Consultation session - $20 &#10;Initial visit - $149 &#10;Follow-ups - $59 &#10;Refills - $25"
                                        />
                                    </Form.Item>
                                </div>
                                <div className={s.rowItem}>
                                    <Form.Item
                                        style={{ width: '80%' }}
                                        label="Case study"
                                        name={['caseStudy']}
                                    >
                                        <TextArea rows={5} placeholder="Ex. As an independent practitioner, KiwiHealth has given me the autonomy to apply evidence-based practice without physician oversight when caring for my patients. I have enjoyed a generous visit fee and still have leadership and ancillary support when needed. I make my own schedule and revise it as needed. I have had a substantial patient assignment panel during my time with Klarity, and my patients have expressed satisfaction with their outcomes." />
                                    </Form.Item>
                                </div>
                                <div className={s.rowItem}>
                                    <Form.Item
                                        style={{ width: '80%' }}
                                        label="News"
                                        name={['news']}
                                    >
                                        <TextArea rows={3} placeholder="Ex. KiwiHealth is offering 5% discount for new providers!" />
                                    </Form.Item>
                                </div>
                                <div className={s.rowItem}>
                                    <Form.Item
                                        style={{ width: '360px' }}
                                        label="Tags"
                                        name={['tagList']}
                                    >
                                        <ChannelTagSelect placeholder="Tap enter to add new tag" />
                                    </Form.Item>
                                </div>
                                <div className={s.rowItem}>
                                    <Form.Item
                                        style={{ width: '360px' }}
                                        label="System Email"
                                        name={['systemEmail']}
                                    >
                                        <Input />
                                    </Form.Item>
                                </div>
                                <div className={s.buttonWrap}>
                                    <Button
                                        className={commonS.lightBtn}
                                        size="small"
                                        onClick={onSubmit}
                                    >Save
                                    </Button>
                                </div>
                            </>
                        }
                        {
                            !enable &&
                            <>
                                <div className={s.row}>
                                    <div
                                        style={{
                                            width: '80%',
                                        }}
                                        className={s.displayItem}
                                    >
                                        <div className={s.label}>Value Prop</div>
                                        <div className={s.value} dangerouslySetInnerHTML={{ __html: form.getFieldValue('channelDesc') }} />
                                    </div>
                                </div>
                                <div className={s.row}>
                                    <div
                                        style={{
                                            width: '80%',
                                        }}
                                        className={s.displayItem}
                                    >
                                        <div className={s.label}>Economics & Terms</div>
                                        <div className={s.value} dangerouslySetInnerHTML={{ __html: form.getFieldValue('economics') }} />
                                    </div>
                                </div>
                                <div className={s.row}>
                                    <div
                                        style={{
                                            width: '80%',
                                        }}
                                        className={s.displayItem}
                                    >
                                        <div className={s.label}>Case study</div>
                                        <div className={s.value} dangerouslySetInnerHTML={{ __html: form.getFieldValue('caseStudy') }} />
                                    </div>
                                </div>
                                <div className={s.row}>
                                    <div
                                        style={{
                                            width: '80%',
                                        }}
                                        className={s.displayItem}
                                    >
                                        <div className={s.label}>News</div>
                                        <div className={s.value} dangerouslySetInnerHTML={{ __html: form.getFieldValue('news') }} />
                                    </div>
                                </div>
                                <div className={s.row}>
                                    <div
                                        style={{
                                            width: '80%',
                                        }}
                                        className={s.displayItem}
                                    >
                                        <div className={s.label}>Tags</div>
                                        <div className={s.value}>{
                                            (form.getFieldValue('tagList') && form.getFieldValue('tagList').length > 0) ?
                                                form.getFieldValue('tagList').map((tag: string) => {
                                                    return (
                                                        <Tag key={tag} color="#DBEAFE">{tag}</Tag>
                                                    );
                                                }) : 'Null'}
                                        </div>
                                    </div>
                                </div>
                                <div className={s.row}>
                                    <div
                                        style={{
                                            width: '80%',
                                        }}
                                        className={s.displayItem}
                                    >
                                        <div className={s.label}>System Email</div>
                                        <div className={s.value}>{form.getFieldValue('systemEmail')}</div>
                                    </div>
                                </div>
                            </>
                        }

                    </div>
                </Spin>
            </Form>
        </div>
    );
};

export default DetailInformationComp;
