import React from 'react';
// import s from './s.module.less';
import { Modal } from 'antd';
import GlobalLayerStore from 'store/GlobalLayer';
import { EType } from 'types/helloKlarity';

interface IProps {
    type?: EType;
    onOk: () => void;
    onCancel: () => void;
    loading?: boolean,
}

const MappingModalConfirm = ({
    type = EType.HELLOW_KLARITY,
    onOk,
    onCancel,
    loading,
}: IProps) => {
    const [getGlobalLayer] = GlobalLayerStore.useStore();
    const unmapPlatformName = type === EType.HELLOW_KLARITY ? 'Airtable' : 'Kiwi Health';
    const platformName = type === EType.HELLOW_KLARITY ? 'Kiwi Health' : 'Airtable';

    return (
        <Modal
            title={`Map the provider profile page to ${platformName} data? `}
            open={getGlobalLayer('showProviderMappingConfirmModal')}
            okText={`Map to ${platformName} data`}
            onOk={onOk}
            onCancel={onCancel}
            confirmLoading={loading}
        >
            <p>{`This provider also exists in ${unmapPlatformName}. Are you sure you want to unmap the provider from ${unmapPlatformName}, and populate provider profile page with ${platformName} data?`}</p>
        </Modal>
    );
};

export default MappingModalConfirm;
