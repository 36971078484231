// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrapper--qdNi4 {
  position: relative;
}
.s-module__wrapper--qdNi4 .s-module__editor--CJXkg {
  padding: 9px 13px;
  background: var(--white);
  border: 1px solid var(--gray-300);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  overflow-x: hidden;
  overflow-y: auto;
}
.s-module__wrapper--qdNi4 .s-module__editor--CJXkg[placeholder]:empty:before {
  content: attr(placeholder);
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--gray-500);
}
.s-module__wrapper--qdNi4 .s-module__editor--CJXkg[placeholder]:empty:focus:before {
  content: "";
}
.s-module__wrapper--qdNi4 .s-module__lengthNumber--x1VYN {
  text-align: right;
  padding-top: 8px;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray-500);
}
`, "",{"version":3,"sources":["webpack://./src/components/RichText/s.module.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AAFA;EAIQ,iBAAA;EACA,wBAAA;EACA,iCAAA;EACA,2CAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;AACR;AACQ;EACI,0BAAA;EACA,oBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AACZ;AAEQ;EACI,WAAA;AAAZ;AAvBA;EA2BQ,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,oBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AADR","sourcesContent":[".wrapper {\n    position: relative;\n\n    .editor {\n        padding: 9px 13px;\n        background: var(--white);\n        border: 1px solid var(--gray-300);\n        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);\n        border-radius: 6px;\n        overflow-x: hidden;\n        overflow-y: auto;\n\n        &[placeholder]:empty:before {\n            content: attr(placeholder);\n            font-family: 'Inter';\n            font-style: normal;\n            font-weight: 400;\n            font-size: 14px;\n            line-height: 24px;\n            color: var(--gray-500);\n        }\n        \n        &[placeholder]:empty:focus:before {\n            content: \"\";\n        }\n    }\n    .lengthNumber {\n        text-align: right;\n        padding-top: 8px;\n        position: absolute;\n        right: 0;\n        bottom: 0;\n        width: 100%;\n        font-family: 'Inter';\n        font-style: normal;\n        font-weight: 400;\n        font-size: 14px;\n        line-height: 20px;\n        color: var(--gray-500);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `s-module__wrapper--qdNi4`,
	"editor": `s-module__editor--CJXkg`,
	"lengthNumber": `s-module__lengthNumber--x1VYN`
};
export default ___CSS_LOADER_EXPORT___;
