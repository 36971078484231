// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--_1Dvk {
  padding: 30px 24px;
  border: 1px solid var(--gray);
  border-radius: 10px;
  font-family: 'Inter';
  font-style: normal;
}
.s-module__wrap--_1Dvk .s-module__header--INDYX {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
}
.s-module__wrap--_1Dvk .s-module__header--INDYX .s-module__title--mM1Ee {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: var(--gray-700);
}
.s-module__wrap--_1Dvk .s-module__header--INDYX .s-module__btns--kCuWO button {
  margin-left: 8px;
}
.s-module__wrap--_1Dvk .s-module__body--JCsrU .s-module__item--Y6HWI {
  margin-bottom: 16px;
}
.s-module__wrap--_1Dvk .s-module__body--JCsrU .s-module__noncontent--Iqa1P {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray-500);
}
`, "",{"version":3,"sources":["webpack://./src/components/PracticeFrontFromComponents/WorkExperience/s.module.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,6BAAA;EACA,mBAAA;EACA,oBAAA;EACA,kBAAA;AACJ;AANA;EAQQ,mBAAA;EACA,aAAA;EACA,8BAAA;AACR;AAXA;EAaY,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AACZ;AAjBA;EAqBgB,gBAAA;AADhB;AApBA;EA4BY,mBAAA;AALZ;AAvBA;EAgCY,oBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AANZ","sourcesContent":[".wrap {\n    padding: 30px 24px;\n    border: 1px solid var(--gray);\n    border-radius: 10px;\n    font-family: 'Inter';\n    font-style: normal;\n\n    .header {\n        margin-bottom: 16px;\n        display: flex;\n        justify-content: space-between;\n\n        .title {\n            font-weight: 500;\n            font-size: 20px;\n            line-height: 20px;\n            color: var(--gray-700);\n        }\n    \n        .btns {\n            button {\n                margin-left: 8px;\n            }\n        }\n    }\n\n    .body {\n        .item {\n            margin-bottom: 16px;\n        }\n\n        .noncontent {\n            font-family: 'Inter';\n            font-style: normal;\n            font-weight: 400;\n            font-size: 14px;\n            line-height: 20px;\n            color: var(--gray-500);\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--_1Dvk`,
	"header": `s-module__header--INDYX`,
	"title": `s-module__title--mM1Ee`,
	"btns": `s-module__btns--kCuWO`,
	"body": `s-module__body--JCsrU`,
	"item": `s-module__item--Y6HWI`,
	"noncontent": `s-module__noncontent--Iqa1P`
};
export default ___CSS_LOADER_EXPORT___;
