import React from 'react';
import Icon from '@ant-design/icons';
import HomeIconSVG from './HomeIcon';
import AppointmentIconIconSVG from './AppointmentIconIcon';
import ChannelsIconSVG from './ChannelsIcon';
import ExternalLinkIconSVG from './ExternalLinkIcon';
import PracticeFrontIconSVG from './PracticeFrontIcon';
import ProspectsIconSVG from './ProspectsIcon';
import AccessProfileSVG from './AccessProfileIcon';
import AccessClipboardCheckSVG from './AccessClipboardCheckIcon';
import AccessKeySVG from './AccessKeyIcon';
import LogoutSVG from './LogoutIcon';
import ConfigSVG from './ConfigIcon';
import MenuHealthSVG from './MenuHealth';
import MenuKiwiSVG from './MenuKiwi';
import MenuHelloSVG from './MenuHello';
import ReputationManagementSVG from './ReputationManagementIcon';
import MenuHelloKlaritySVG from './MenuHelloKlarity';
import MenuContractLinkSVG from './MenuContractLink';
import ProviderSearchIconSVG from './ProviderSearchIcon';
import KlarityReviewsSVG from './KlarityReviewsIcon';
import EhrIconSVG from './EhrIcon';

export const HomeIcon = <Icon component={HomeIconSVG} />;
export const EhrIcon = <Icon component={EhrIconSVG} />;
export const AppointmentIconIcon = <Icon component={AppointmentIconIconSVG} />;
export const ChannelsIcon = <Icon component={ChannelsIconSVG} />;
export const ExternalLinkIcon = <Icon component={ExternalLinkIconSVG} />;
export const PracticeFrontIcon = <Icon component={PracticeFrontIconSVG} />;
export const ProspectsIcon = <Icon component={ProspectsIconSVG} />;
export const AccessProfileIcon = <Icon component={AccessProfileSVG} />;
export const ProviderSearchIcon = <Icon component={ProviderSearchIconSVG} />;
export const AccessClipboardCheckIcon = <Icon component={AccessClipboardCheckSVG} />;
export const AccessKeyIcon = <Icon component={AccessKeySVG} />;
export const LogoutIcon = <Icon component={LogoutSVG} />;
export const ConfigIcon = <Icon component={ConfigSVG} />;
export const MenuHealthIcon = <Icon component={MenuHealthSVG} />;
export const MenuKiwiIcon = <Icon component={MenuKiwiSVG} />;
export const MenuHelloIcon = <Icon component={MenuHelloSVG} />;
export const ReputationManagementIcon = <Icon component={ReputationManagementSVG} />;
export const MenuHelloKlarityIcon = <Icon component={MenuHelloKlaritySVG} />;
export const MenuContractLinkIcon = <Icon component={MenuContractLinkSVG} />;
export const KlarityReviewsIcon = <Icon component={KlarityReviewsSVG} />;
