import React from 'react';
import type { UploadChangeParam } from 'antd/es/upload';
import { Button, Spin, Upload } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { uploadFile } from 'api/common';
import UploadIcon from './upload.svg';
import { getFileNameFromUrl } from 'utils/common';
import PdfIcon from './pdf.png';
import s from './s.module.less';

const { Dragger } = Upload;

type FileUploadProps = {
    value?: string;
    tips?: string;
    onChange?: (val: string) => void
    accept?:string
};

const FileUpload = (Props:FileUploadProps) => {
    const { onChange, accept, value, tips } = Props;
    const [loading, setLoading] = React.useState(false);
    const [imageUrl, setImageUrl] = React.useState<string>(UploadIcon);

    const fileInfoRef = React.useRef<UploadChangeParam<UploadFile>>();
    const [showUploadList, setShowUploadList] = React.useState(false);
    const displayInitImgName = !!value;

    const getBase64 = (img: RcFile, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        reader.readAsDataURL(img);
    };

    const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
        fileInfoRef.current = info;
        if (info.file.status === 'uploading') {
            setLoading(true);
        }
    };
    const props: UploadProps = {
        name: 'file',
        multiple: false,
        onChange: handleChange,
        accept: accept || '*',
        showUploadList,
        onDrop() {
            //console.log('Dropped files', e.dataTransfer.files);
        },
        customRequest: async (data) => {
            //https://github.com/react-component/upload#customrequest
            try {
                const formData = new FormData();
                formData.append('file', data.file);
                const res = await uploadFile(formData);
                if (!res.error) {
                    if (fileInfoRef.current) {
                        fileInfoRef.current.file.status = 'done';
                    }
                    setShowUploadList(true);
                    const url = res.data?.data;
                    const isPdf = fileInfoRef.current?.file?.name?.toLowerCase().endsWith('pdf');
                    if (isPdf) {
                        setImageUrl(PdfIcon);
                    } else {
                        getBase64(data.file as RcFile, (base64Url) => {
                            setImageUrl(base64Url);
                        });
                    }
                    onChange?.(url);
                }
            } catch (e) {
                setShowUploadList(false);
                console.error(e);
            } finally {
                setLoading(false);
            }
        },
    };
    return (
        <div className={s.wrap}>
            <Spin spinning={loading}>
                {!value && (
                    <Dragger {...props}>
                        <p className={s.iconWrap}>
                            <img src={UploadIcon} className={s.icon} />
                        </p>
                        <p className={s.uploadText}>
                            <span>Upload a file</span> or drag and drop
                        </p>
                        <p className={s.uploadHint}>{tips ?? 'PNG, JPG, PDF up to 10MB'}</p>
                    </Dragger>
                )}
            </Spin>
            {displayInitImgName && (
                <div className={s.uploadName}>
                    <span>{getFileNameFromUrl(value)}</span>
                    <span
                        onClick={() => {
                            onChange?.('');
                        }}
                        className={s.remove}
                    >
                        Remove
                    </span>
                </div>
            )}
        </div>
    );
};

export default FileUpload;
