import React, { useCallback, useEffect, useMemo, useState } from 'react';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { Button, Checkbox, Col, Form, Input, Radio, RadioChangeEvent, Row, Space } from 'antd';
import { IInsurance, IInsuranceForm, EditModuleEnum, FormFields, IInsuranceInput } from 'types/practiceFront';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields } from 'types/enumerationData';
import ErrorBlock from '../ErrorBlock';
import { EMPTY_PLACEHOLDER, INSURANCE_TYPE_CASH, INSURANCE_TYPE_CASH_AND_INSURANCE, INSURANCE_TYPE_INSURANCE, PAY_TYPE_TEXT } from 'constants/common';

interface IProps {
    readonly?: boolean;
    showEditingTip?: boolean;
    isEditing?: boolean;
    initFormValue: IInsurance;
    onEditStatusChange: (status: boolean, field: EditModuleEnum) => void;
    onSubmit: (value: IInsuranceInput, field: EditModuleEnum) => void;
}

const Insurance = ({
    readonly,
    showEditingTip,
    isEditing,
    initFormValue,
    onEditStatusChange,
    onSubmit,
}: IProps) => {
    const [showInsuranceList, setShowInsuranceList] = useState(false);
    const [insuranceOptions] = useGetSettingData(EnumFields.INSURANCE);
    const [insuranceForm] = Form.useForm<IInsuranceForm>();

    useEffect(() => {
        if (initFormValue) {
            insuranceForm.setFieldsValue({
                ...initFormValue,
                otherInsuranceList: initFormValue.otherInsuranceList?.join(','),
                supportPayType: initFormValue.supportPayType || INSURANCE_TYPE_CASH,
            });

            setShowInsuranceList(initFormValue.supportPayType !== INSURANCE_TYPE_CASH);
        }
    }, [initFormValue, insuranceForm]);

    const handleRadioChange = useCallback((e: RadioChangeEvent) => {
        setShowInsuranceList(e.target.value !== INSURANCE_TYPE_CASH);
    }, []);

    const handleSubmit = useCallback(() => {
        insuranceForm.validateFields().then((values) => {
            let data: IInsuranceInput = {
                ...values,
                otherInsuranceList: values.otherInsuranceList ? values.otherInsuranceList.split(',') : [],
            };

            if (values.supportPayType === INSURANCE_TYPE_CASH) {
                data = {
                    ...values,
                    insuranceList: null,
                    otherInsuranceList: null,
                };
            }

            onSubmit(data, EditModuleEnum.INSURANCE);
        }).catch((e) => {
            console.error(e);
        });
    }, [insuranceForm, onSubmit]);

    const inusranceList = useMemo(() => {
        const data = initFormValue[FormFields.INSURANCE_LIST] || [];
        if (data?.length > 5) {
            return [...data.slice(0, 5), '...'];
        }

        return data;
    }, [initFormValue]);

    return (
        <div className={s.wrap} id={EditModuleEnum.INSURANCE}>
            <div className={s.header}>
                <div className={s.title}>Insurance</div>
                {
                    !readonly &&
                    <div className={s.btns}>
                        {
                            isEditing ?
                                <>
                                    <Button onClick={() => onEditStatusChange(false, EditModuleEnum.INSURANCE)}>Cancel</Button>
                                    <Button type="primary" onClick={handleSubmit}>Save changes</Button>
                                </>
                                : <Button onClick={() => onEditStatusChange(true, EditModuleEnum.INSURANCE)}>Edit</Button>
                        }
                    </div>
                }
            </div>
            {
                showEditingTip && isEditing && <ErrorBlock />
            }
            <div className={s.body}>
                {
                    !isEditing ?
                        (
                            <div className={s.viewWrap}>
                                <div className={s.viewItem}>
                                    <div className={s.label}>Type</div>
                                    <div className={s.value}>{PAY_TYPE_TEXT[initFormValue.supportPayType]}</div>
                                </div>
                                {
                                    initFormValue.supportPayType !== INSURANCE_TYPE_CASH &&
                                    <>
                                        <div className={s.viewItem}>
                                            <div className={s.label}>In-network Insurance company</div>
                                            {
                                                inusranceList && inusranceList.length > 0 ?
                                                    <div className={s.value}>
                                                        <ul>
                                                            {
                                                                inusranceList.map((e) => <li key={e}>{e}</li>)
                                                            }
                                                        </ul>
                                                    </div> :
                                                    <div className={s.noncontent}>I don’t have any in-network insurance company</div>
                                            }

                                        </div>
                                        <div className={s.viewItem}>
                                            <div className={s.label}>The Names Of Other In-Network Insurance Companies</div>
                                            {
                                                initFormValue?.otherInsuranceList?.length > 0 ?
                                                    <div className={s.value}>
                                                        <ul>
                                                            {
                                                                initFormValue.otherInsuranceList.map((e) => <li key={e}>{e}</li>)
                                                            }
                                                        </ul>
                                                    </div> :
                                                    <div className={s.noncontent}>{EMPTY_PLACEHOLDER}</div>
                                            }

                                        </div>
                                    </>
                                }
                            </div>
                        )
                        :
                        (
                            <div className={s.formWrap}>
                                <Form
                                    form={insuranceForm}
                                    name={EditModuleEnum.INSURANCE}
                                    className={commonS.formStyle1}
                                    autoComplete="off"
                                    layout="vertical"
                                >
                                    <div className={s.itemBox}>
                                        <div className={s.item}>
                                            <Form.Item
                                                // label="Select all insurance companies you are currently in-network "
                                                name="supportPayType"
                                            >
                                                <Radio.Group onChange={handleRadioChange}>
                                                    <Space direction="vertical">
                                                        <Radio value={INSURANCE_TYPE_CASH}>I only support cash pay.</Radio>
                                                        <Radio value={INSURANCE_TYPE_CASH_AND_INSURANCE}>I support both cash pay and insurance.</Radio>
                                                        <Radio value={INSURANCE_TYPE_INSURANCE}>I only accept insurance.</Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    {
                                        showInsuranceList &&
                                        <>
                                            <div className={s.itemBox}>
                                                <div className={s.item}>
                                                    <Form.Item
                                                        label="Select all insurance companies you are currently in-network "
                                                        name={FormFields.INSURANCE_LIST}
                                                    >
                                                        <Checkbox.Group style={{ width: '100%' }}>
                                                            <Row>
                                                                {
                                                                    insuranceOptions?.map((insurance) => {
                                                                        const { id, dictKey } = insurance;
                                                                        return (
                                                                            <Col key={id} span={12}>
                                                                                <Checkbox value={dictKey}>{dictKey}</Checkbox>
                                                                            </Col>
                                                                        );
                                                                    })
                                                                }
                                                            </Row>
                                                        </Checkbox.Group>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className={s.itemBox}>
                                                <div className={s.item}>
                                                    <Form.Item
                                                        label="The Names Of Other In-Network Insurance Companies"
                                                        name={FormFields.OTHER_INSURANCE_LIST}
                                                    >
                                                        <Input placeholder="Add company names and separate by comma " />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </Form>
                            </div>
                        )
                }
            </div>
        </div>
    );
};

export default Insurance;
