import React from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';

type AdapterInputProps = {
    value?: string;
    onChange?: (val: string | undefined) => void;
    adapterFun: (val: string | undefined)=>string | undefined
} & InputProps;

const AdapterInput = (Props:AdapterInputProps) => {
    const { value = '', onChange, adapterFun } = Props;

    return (
        <Input
            value={value}
            onChange={(e) => {
                const val = e.target.value;
                const adapterVal = adapterFun(val);
                onChange?.(adapterVal);
            }}
        />
    );
};

export default AdapterInput;
