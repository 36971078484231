// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__status--aqptP {
  padding: 2px 10px;
  display: inline-block;
  background-color: #DBDBDB;
  border-radius: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: var(--gray-800);
}
.s-module__status--aqptP.s-module__success--R7RER {
  background-color: var(--green-100);
  color: var(--green-800);
}
.s-module__status--aqptP.s-module__followup--adJc4 {
  background-color: var(--purple-100);
  color: var(--purple-800);
}
.s-module__status--aqptP.s-module__failded--zB5Hd {
  background-color: var(--red-100);
  color: var(--red-800);
}
.s-module__status--aqptP.s-module__pending--SdXi_ {
  background-color: var(--yellow-100);
  color: var(--yellow-800);
}
.s-module__status--aqptP.s-module__noneed--Lhp2X {
  background-color: var(--blue-100);
  color: var(--blue-800);
}
`, "",{"version":3,"sources":["webpack://./src/components/PaymentStatusTag/s.module.less"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,qBAAA;EACA,yBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,sBAAA;AACJ;AACI;EACI,kCAAA;EACA,uBAAA;AACR;AAEI;EACI,mCAAA;EACA,wBAAA;AAAR;AAGI;EACI,gCAAA;EACA,qBAAA;AADR;AAII;EACI,mCAAA;EACA,wBAAA;AAFR;AAKI;EACI,iCAAA;EACA,sBAAA;AAHR","sourcesContent":[".status {\n    padding: 2px 10px;\n    display: inline-block;\n    background-color: #DBDBDB;\n    border-radius: 10px;\n    font-weight: 500;\n    font-size: 12px;\n    line-height: 16px;\n    text-align: center;\n    color: var(--gray-800);\n\n    &.success {\n        background-color: var(--green-100);\n        color: var(--green-800);\n    }\n\n    &.followup {\n        background-color: var(--purple-100);\n        color: var(--purple-800);\n    }\n\n    &.failded {\n        background-color: var(--red-100);\n        color: var(--red-800);\n    }\n\n    &.pending {\n        background-color: var(--yellow-100);\n        color: var(--yellow-800);\n    }\n\n    &.noneed {\n        background-color: var(--blue-100);\n        color: var(--blue-800);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": `s-module__status--aqptP`,
	"success": `s-module__success--R7RER`,
	"followup": `s-module__followup--adJc4`,
	"failded": `s-module__failded--zB5Hd`,
	"pending": `s-module__pending--SdXi_`,
	"noneed": `s-module__noneed--Lhp2X`
};
export default ___CSS_LOADER_EXPORT___;
